import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isDesktop: false,
    authenticated: false,
    authUser: null,
    qualities: [],
    groups: [],
    isDarkMode: false,
    isSettingMode: false,
    alert: {
        msg: null,
        status: null,
        timeout: null,
    },
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setIsDesktop: (state, action) => {
            state.isDesktop = action.payload;
        },
        setQualities: (state, action) => {
            state.qualities = action.payload;
        },
        setDarkMode: (state, action) => {
            state.isDarkMode = action.payload;
        },
        setSettingMode: (state, action) => {
            state.isSettingMode = action.payload;
        },
        setAlert: (state, action) => {
            state.alert = action.payload;
        },
        clearAlert: (state) => {
            state.alert = {
                msg: null,
                status: null,
                timeout: null,
            };
        },
        authenticateUser: (state, action) => {
            state.authenticated = true;
            state.authUser = action.payload;
        },
        unauthenticateUser: (state) => {
            state.authenticated = false;
        },
        setGroups: (state, action) => {
            let newGroups = action.payload;
            newGroups = [
                {
                    id: null,
                    name: "All",
                    color: "gray",
                    size_name: "အရွယ်အစား",
                    count_unit: "ခု",
                },
                ...newGroups,
            ];
            state.groups = newGroups;
        },
    },
});

export const {
    setIsDesktop,
    setDarkMode,
    setSettingMode,
    setAlert,
    clearAlert,
    authenticateUser,
    unauthenticateUser,
    setGroups,
    setQualities,
} = appSlice.actions;

export default appSlice.reducer;
