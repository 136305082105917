import React, { memo } from "react";

const LabeledSwitch = ({
    value,
    color,
    left,
    right,
    onChange
}) => {
    return (
        <div className={`flex flex-row h-12 justify-between rounded overflow-hidden border border-${color}`}>
            <button
                className={`flex flex-1 items-center justify-center 
                    ${value === left.value && `bg-${color}`}
                `}
                onClick={() => onChange(left.value)}
            >
                <span
                    className={`text-xs ${
                        value === left.value
                            ? "text-white"
                            : `text-${color}`
                    }`}
                >
                    {left.label}
                </span>
            </button>
            <button
                className={`flex flex-1 items-center justify-center 
                    ${value === right.value && `bg-${color}`}
                `}
                onClick={() => onChange(right.value)}
            >
                <span
                    className={`text-xs ${
                        value === right.value
                            ? "text-white"
                            : `text-${color}`
                    }`}
                >
                    {right.label}
                </span>
            </button>
        </div>
    );
};

export default memo(LabeledSwitch);
