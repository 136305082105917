import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paperSizes from "../../helper/paperSizes";
import WeightStats from "../WeightStats";
import { memo, forwardRef } from "react";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import DateParser from "../../helper/dateParser";

const SaleVoucherWithTax = ({
    paperSize,
    goldPrice,
    date,
    customer,
    item,
    saleType,
    hasDebt,
    hasTax,
    tradeInPrice,
    depositPrice,
    discountPrice,
    soldBy,
}, ref) => {

    const dateParser = new DateParser();

    const { width, height } = paperSizes.find( e => e.name === paperSize ).mm;
    const dateString = dateParser.toString(date);
    const suffix = item.category.suffix ? (saleType === 'attachment_only' ? '(အတွဲသီးသန့်)' : `(${item.category.suffix})` ) : '';
    const weight = saleType === "attachment_only" ? <WeightStats total={item.attachment_weight}/> : <WeightStats total={item.net_weight}/>;
    const serviceWeight = saleType === "attachment_only" ? <WeightStats total={item.attachment_service_weight}/> : <WeightStats total={item.service_weight}/>;
    const gemWeight = <WeightStats total={item.gem_weight}/>;

    const costPrice = saleType === "attachment_only" ? item.attachment_price : item.price; 
    const netPrice = costPrice - item.gem_price - item.category.base_price - item.category.additional_price;

    const price = saleType === "attachment_only" ? item.attachment_price : item.price;
    const taxPrice = hasTax ? Math.round((price * item.quality.tax_percent) / 100) : 0;
    
    const finalPrice = (costPrice + taxPrice) - tradeInPrice - depositPrice - discountPrice;
    const finalLabel = finalPrice < 0 ? 'ပိုငွေ' : (hasDebt ? 'ကျန်ငွေ' : 'စုစုပေါင်း သင့်ငွေ');

    return (
        <div 
            ref={ref} 
            className="flex flex-col bg-white" 
            style={{width: `${width}mm`, height: `${height}mm`}}
        >
            <div 
                className="flex flex-col bg-white" 
                style={{width: `${width}mm`, height: `${height}mm`}}
            >
                <div className="m-10 flex flex-1 flex-col justify-between">
                    
                    {/* data section */}
                    <div className="flex flex-col space-y-9 flex-1">

                        {/* Shop Name */}
                        <div className="flex flex-col items-center justify-center">
                            <span className="text-xs">ကိုခင်ဇော် + မချိုလွင်ဦး</span>
                            <div className="flex flex-row items-center space-x-4">
                                <span className="font-taunggyi text-7xl">ဆု</span>
                                <div className="flex flex-col justify-end mt-9">
                                    <span className="font-yinmar text-2xl">
                                        အာမခံ
                                    </span>
                                    <span className="font-yinmar text-2xl">
                                        ရတနာရွှေဆိုင်
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* Shop Address */}
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col space-y-3">
                                    <span className="text-xs">
                                        အမှတ် (၃၆)၊ B(1) ရုံ၊ စျေးအတွင်း၊ ဇီးကုန်းမြို့။
                                    </span>
                                    <div className="flex flex-row text-xs space-x-3">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FontAwesomeIcon icon={faPhone} size="sm" />
                                            <span className="text-xs">053 34097</span>
                                        </div>
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FontAwesomeIcon icon={faPhone} size="sm" />
                                            <span className="text-xs">09 5363366</span>
                                        </div>
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FontAwesomeIcon icon={faPhone} size="sm" />
                                            <span className="text-xs">09 43048660</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-end space-y-2">
                                    
                                </div>
                            </div>

                            {/* divider */}
                            <div className="border border-dotted border-gray-500"></div>

                        </div>

                        {/* Customer and Item details */}
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col space-y-6">
                                <div className="flex flex-col space-y-4">
                                    <span className="text-xs">အမည် - { customer.name }</span>
                                    <span className="text-xs">နေရပ် - { customer.address }</span>
                                    <span className="text-xs">ဖုန်း - { customer.phone }</span>
                                </div>
                                <span className="text-xs">ပစ္စည်းအမည် - { item.name } { suffix } </span>
                            </div>
                            <div className="flex flex-col items-end space-y-4">
                                <span className="font-bold"># ID - { item.serial }</span>
                                <span className="text-xs">နေ့စွဲ - { dateString }</span>
                                <span className="text-xs">ရွှေစျေးနှုန်း - { new Intl.NumberFormat().format(goldPrice) }</span>
                                <span className="text-xs">ရွှေရည် - {item.quality.name}</span>
                            </div>
                        </div>

                        {/* table */}
                        <table className="w-full">

                            <thead>
                                <tr className="h-12">
                                    <th className="border border-black">
                                        <span className="text-xs font-bold">အလေးချိန်</span>
                                    </th>
                                    <th className="text-xs font-bold border border-black" colSpan="2">
                                        <span className="text-xs font-bold">သင့်ငွေ</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {/* weight*/}
                                <tr className="border border-black">
                                    <td className="flex items-start">
                                        <div className="py-2 px-4">
                                            <div className="flex flex-row items-center h-10 space-x-2">
                                                <span className="text-xs"> အသားတင်ရွှေချိန် - </span>
                                                <div>
                                                    <span className="text-xs">{ weight }</span>
                                                </div>
                                            </div>

                                            <div className="flex flex-row items-center h-10 space-x-2">
                                                <span className="text-xs"> အလျော့တွက် - </span>
                                                <div>
                                                    <span className="text-xs">{ serviceWeight }</span> 
                                                </div>
                                            </div>

                                            { item.has_gem ? (
                                                <div className="flex flex-row items-center h-10 space-x-2">
                                                    <span className="text-xs">ကျောက်ချိန် - </span>
                                                    <div>
                                                        <span className="text-xs">{ gemWeight }</span> 
                                                    </div>
                                                </div>
                                            ) : ''}
                                        </div>
                                    </td>

                                    {/* second_col*/}
                                    <td className="border border-black py-2 px-4">
                                        <div className="flex items-center justify-end h-10">
                                            <span className="text-xs">ရွှေဖိုး + အလျော့တွက်</span>
                                        </div>

                                        {item.category.base_price ? (
                                            <div className="flex items-center justify-end h-10">
                                                <span className="text-xs">လက်ခ</span>
                                            </div>
                                        ) : ''}

                                        {item.has_gem ? (
                                            <div className="flex items-center justify-end h-10">
                                                <span className="text-xs">ကျောက်ဖိုး</span>
                                            </div>
                                        ) : ''}

                                        {item.category.additional_price ? (
                                            <div className="flex items-center justify-end h-10">
                                                <span className="text-xs">
                                                    { item.category.additional_price_name }
                                                </span>
                                            </div>
                                        ) : ''}                         
                                    </td>

                                    {/* third_col*/}
                                    <td className="py-2 px-4">

                                        <div className="flex items-center justify-end h-10">
                                            <span className="text-xs">
                                                { netPrice }
                                            </span>
                                        </div>

                                        {item.category.base_price ? (
                                            <div className="flex items-center justify-end h-10">
                                                <span className="text-xs">
                                                    { item.category.base_price }
                                                </span>
                                            </div>
                                        ) : ''}

                                        {item.has_gem ? (
                                            <div className="flex items-center justify-end h-10">
                                                <span className="text-xs">
                                                    { item.gem_price }
                                                </span>
                                            </div>
                                        ) : ''}

                                        {item.category.additional_price ? (
                                            <div className="flex flex-row items-center justify-end h-10">
                                                <span className="text-xs">
                                                    { item.category.additional_price }
                                                </span>
                                            </div>
                                        ) : ''}
                                    </td>
                                </tr>
                            
                                <tr className="border border-black">
                                    <td className="py-2 px-4" colSpan="2">

                                        <div className="flex items-center justify-end h-10">
                                            <span className="text-xs">သင့်ငွေ</span>
                                        </div>

                                        {hasTax ? (
                                            <div className="flex items-center justify-end h-10">
                                                <span className="text-xs">အခွန် ( { item.quality.tax_percent } % )</span>
                                            </div>
                                        ) : ''}

                                        {tradeInPrice ? (
                                            <div className="flex items-center justify-end h-10">
                                                <span className="text-xs">ပေးရွှေ</span>
                                            </div>
                                        ) : ''}

                                        {depositPrice ? (
                                            <div className="flex items-center justify-end h-10">
                                                <span className="text-xs">စရံငွေ</span>
                                            </div>
                                        ) : ''}

                                        {discountPrice ? (
                                            <div className="flex flex-row items-center justify-end h-10">
                                                <span className="text-xs">လျှော့</span>
                                            </div>
                                        ) : ''}
                                    </td>

                                    <td className="py-2 px-4 border border-black">
                                        
                                        <div className="flex flex-row items-center justify-end h-10">
                                            <span className="text-xs">{ costPrice }</span>
                                        </div>

                                        {hasTax ? (
                                            <div className="flex flex-row items-center justify-end h-10">
                                                <span className="text-xs">+ { taxPrice }</span>
                                            </div>
                                        ) : ''}

                                        {tradeInPrice ? (
                                            <div className="flex flex-row items-center justify-end h-10">
                                                <span className="text-xs"> - { tradeInPrice }</span>
                                            </div>
                                        ) : ''}

                                        {depositPrice ? (
                                            <div className="flex flex-row items-center justify-end h-10">
                                                <span className="text-xs"> - { depositPrice }</span>
                                            </div>
                                        ) : ''}

                                        {discountPrice ? (
                                            <div className="flex flex-row items-center justify-end h-10">
                                                <span className="text-xs"> - { discountPrice }</span>
                                            </div>
                                        ) : ''}
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr className="border border-black h-12">
                                    <td className="py-2 px-4" colSpan="2">
                                        <div className="flex items-end justify-end">
                                            <span className="text-xs font-bold">{ finalLabel }</span>
                                        </div>
                                    </td>

                                    <td className="py-2 px-4 border border-black">
                                        <div className="flex items-end justify-end">
                                            <span className="text-xs font-bold">{ Math.abs(finalPrice) }</span>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    {/* footer */}
                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-row justify-between">
                            <span className="text-xs">အရောင်းဝန်ထမ်း - { soldBy.name }</span>
                            <span className="text-xs">လက်မှတ် _________________</span>
                        </div>

                        {/* divider */}
                        <div className="flex border-b border-b-dotted border-b-gray-500"></div>

                        <div className="flex flex-col space-y-2 items-center">
                            <span className="text-2xs">
                                {/* footer msg */}
                            </span>
                            <span className="text-2xs">
                               {/* footer msg */}
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default memo(forwardRef(SaleVoucherWithTax));