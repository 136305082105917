import React, { memo, useCallback, useState } from "react";
import axios_custom from "../../axios_config/axios_custom";
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/reducers/app-reducer';
import useStoreHelper from "../../hooks/useStoreHelper";
import { WeightInput } from "../inputs";
import Modal from "./Modal";
import Weight from "../../helper/weight";

const AddTraderWeightModal = ({ show, id, onAdded, onClose }) => {

    const weight = new Weight();
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const [traderWeight, setTraderWeight] = useState(weight.defaultValue);
    const [traderWeightHasError, setTraderWeightHasError] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);

    //methods
        const handleTraderWeight = useCallback( ( e ) => {
            setTraderWeight(e.weight);
            setTraderWeightHasError(e.hasError);
        }, []);

        const close = useCallback( () => {
            setTraderWeight(weight.defaultValue);
            onClose();
        }, []);

        const submitAdd = () => {
            if(!traderWeightHasError){
                setIsSubmitting(true);

                axios_custom.put('items/' + id + '/add_trader_weight', {
                    trader_weight: JSON.stringify(traderWeight)
                })
                .then( res => {
                    dispatch(setAlert({
                        msg: res.data.msg,
                        status: 'success',
                        timeout: 3000
                    }));
                    onAdded(id);
                    setTraderWeight(weight.defaultValue);
                    setIsSubmitting(false);
                })
                .catch( error => {
                    setIsSubmitting(false);
                    handleError(error);
                })
            }
        }

    return (
        <Modal
            show={show}
            title="ကုန်သည်အလျော့ထည့်ရန်"
            titleColor="orange"
            submitButtonText="Add"
            onClose={close}
            onSubmit={submitAdd}
            isSubmitting={isSubmitting}
        >
            <div className="flex flex-col m-5">
                <WeightInput
                    title="အလေးချိန်"
                    value={traderWeight}
                    onChange={handleTraderWeight}
                    onPressEnterKey={submitAdd}
                    disableResponsive={true}
                />
            </div>
        </Modal>
    );
};

export default memo(AddTraderWeightModal);
