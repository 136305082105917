import { faCaretDown, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import colors from "../../helper/colors";
import Popup from "reactjs-popup";
import { months, years } from "../../helper/monthsAndYears";
import DateParser from "../../helper/dateParser";

const daysOfWeek = [
    { index: 0, short: 'sun', long: 'sunday' },
    { index: 1, short: 'mon', long: 'monday' },
    { index: 2, short: 'tue', long: 'tuesday' },
    { index: 3, short: 'wed', long: 'wednesday' },
    { index: 4, short: 'thu', long: 'thursday' },
    { index: 5, short: 'fri', long: 'friday' },
    { index: 6, short: 'sat', long: 'saturday' }
];

const datesOfMonth = Array.from({ length: 31 }, (_, index) => index + 1);

const DatePicker = ({
    value = new Date(),
    trigger,
    position = 'bottom center',
    onPick,
    disableFutureDates = true,
    disablePastDates = false,
    disableDatesAfter,
    disableDatesBefore,
    theme = 'light',
    highlightedDates = [], // array of date object
    width = 308,
    offsetX,
    offsetY
}) => {

    const dateParser = new DateParser();

    const today = {
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    }

    const triggerRef = useRef(null);
    const monthPopupRef = useRef(null);
    const yearPopupRef = useRef(null);

    const [selectedMonth, setSelectedMonth] = useState(today.month);
    const [selectedYear, setSelectedYear] = useState(today.year);
    const [selectedDateString, setSelectedDateString] = useState(dateParser.toString(today));

    useEffect( () => {
        const pickedDate = value || new Date();
        const pickedDateInString = dateParser.toString(pickedDate);
        setSelectedMonth(pickedDate.getMonth());
        setSelectedYear(pickedDate.getFullYear());
        setSelectedDateString(pickedDateInString);
    }, [value]);

    //computed
        const currentMonth = useMemo(() => {
            const firstDay = new Date(selectedYear, selectedMonth, 1);
            const lastDay = new Date(selectedYear, selectedMonth + 1, 0);
            
            const longName = firstDay.toLocaleString('en-US', { month: 'long' });
            const shortName = firstDay.toLocaleString('en-US', { month: 'short' });
            const startDate = firstDay.getDate();
            const endDate = lastDay.getDate();
            const startDayIndex = firstDay.getDay();
            const endDayIndex = lastDay.getDay();
        
            return { 
                longName, 
                shortName, 
                startDate, 
                endDate, 
                startDayIndex, 
                endDayIndex, 
            };
        }, [selectedMonth, selectedYear]);

        const currentMonthDates = useMemo( () => {
            const dates = [];
            //push empty cells first
            if(currentMonth.startDayIndex < 6){
                for(let i = 0; i < currentMonth.startDayIndex; i++){
                    dates.push('')
                }
            }
            //push available dates cells
            for(let i = 1; i <= currentMonth.endDate; i++){
                dates.push(i)
            }
            return dates;
        }, [currentMonth]);

        const currentMonthHighlightedDates = useMemo( () => {
            const filteredDateObj = highlightedDates.filter( e => {
                return (e.getFullYear() === selectedYear) && (e.getMonth() === selectedMonth)
            });
            const dateOnly = filteredDateObj.map( e => e.getDate() );
            return dateOnly;
        }, [currentMonth, highlightedDates]);

        const currentMonthDisabledDates = useMemo( () => {
                const disabledDates = [];

                if(disablePastDates || disableDatesBefore){
                    const targetDate = disableDatesBefore ? {
                        day: disableDatesBefore.getDate(),
                        month: disableDatesBefore.getMonth(),
                        year: disableDatesBefore.getFullYear(),
                    } : today;

                    if( selectedYear < targetDate.year ){
                        disabledDates.push(...datesOfMonth);
                    } else {
                        if( (selectedYear === targetDate.year) && (selectedMonth < targetDate.month) ){
                            disabledDates.push(...datesOfMonth);
                        } else {
                            if( (selectedYear === targetDate.year) && (selectedMonth === targetDate.month)){
                                const datesOfMonthCopy = [...datesOfMonth];
                                disabledDates.push(...datesOfMonthCopy.splice(0, targetDate.day - 1))
                            }
                        }
                    }
                }

                if(disableFutureDates || disableDatesAfter){
                    const targetDate = disableDatesAfter ? {
                        day: disableDatesAfter.getDate(),
                        month: disableDatesAfter.getMonth(),
                        year: disableDatesAfter.getFullYear(),
                    } : today;

                    if( selectedYear > targetDate.year ){
                        disabledDates.push(...datesOfMonth);
                    } else {
                        if( (selectedYear === targetDate.year) && (selectedMonth > targetDate.month) ){
                            disabledDates.push(...datesOfMonth);
                        } else {
                            if( (selectedYear === targetDate.year) && (selectedMonth === targetDate.month) && (currentMonth.endDate > targetDate.day) ){
                                const datesOfMonthCopy = [...datesOfMonth];
                                disabledDates.push(...datesOfMonthCopy.slice(targetDate.day))
                            }
                        }
                    }
                }

                return disabledDates;
        }, [selectedMonth, selectedYear]);

    //methods
        const changeMonth = (type) => {
            setSelectedMonth( prevMonth => {
                const newMonth = new Date(selectedYear, prevMonth, 1);
                newMonth.setMonth( (type === 'increase') ? prevMonth + 1 : prevMonth - 1 );
        
                if(type === 'increase' && prevMonth === 11){
                    setSelectedYear( prevYear => prevYear + 1);
                }
                if(type === 'decrease' && prevMonth === 0){
                    setSelectedYear( prevYear => prevYear - 1);
                }
                return newMonth.getMonth();
            });
        }

        const pickMonth = (month) => {
            monthPopupRef.current.close();
            setSelectedMonth(month);
        }

        const pickYear = (year) => {
            yearPopupRef.current.close();
            setSelectedYear(year);
        }

        const pickDate = (date) => {
            if(date !== '' && !currentMonthDisabledDates.includes(date)){
                triggerRef.current.close();
                const pickedDateInObj = new Date(`${selectedMonth + 1}/${date}/${selectedYear}`);
                onPick(pickedDateInObj);
            }
        }

        const setToday = () => {
            triggerRef.current.close();

            setSelectedYear(today.year);
            setSelectedMonth(today.month);
            onPick(new Date());
        }

    //styles
        const cellStyle = useMemo( () => {
            const offset = (width - 28) / 7;//28 is total margin px
            return {
                fontSize: 12,
                width: offset,
                height: offset,
            }
        }, [width]);

    return (
        <Popup
            ref={triggerRef}
            position={position}
            trigger={trigger}
            offsetX={offsetX}
            offsetY={offsetY}
            arrowStyle={{
                color: 'white',
            }}
            nested={true}
        >
            <div className="bg-white border border-dim-gray p-2 rounded-lg">

                <div className="flex items-center h-10">
                    <div className="flex-1 flex flex-row items-center justify-between mx-1">

                        <div className="flex flex-row items-center space-x-1">

                            <Popup
                                ref={monthPopupRef}
                                position="bottom center"
                                arrowStyle={{
                                    color: colors.find( e => e.org === 'gray').dim
                                }}
                                trigger={
                                    <button className="flex flex-row items-center justify-center h-8 w-16 bg-soft-muted rounded-lg space-x-1">
                                        <span className="text-sm text-blue font-bold">
                                            { currentMonth.shortName }
                                        </span>
                                        <FontAwesomeIcon icon={faCaretDown} size="xs" color={ colors.find( e => e.org === 'blue').normal } />
                                    </button>
                            }>
                                <div className="bg-white rounded-lg w-20 border border-muted overflow-auto shadow max-h-52">
                                    <div className="flex flex-col">
                                        { months.map( month => 
                                            <button
                                                key={month.id}
                                                className="last:border-0 flex flex-row h-10 space-x-2 items-center justify-center border-b border-b-muted"
                                                onClick={ () => pickMonth(month.id - 1)}
                                            >
                                                <span className="text-xs">{ month.short }</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Popup>

                            <Popup
                                ref={yearPopupRef}
                                position="bottom center"
                                arrowStyle={{
                                    color: colors.find( e => e.org === 'gray').dim
                                }} 
                                trigger={
                                    <button className="flex flex-row items-center justify-center h-8 w-16 bg-soft-muted rounded-lg space-x-1">
                                        <span className="text-sm text-blue font-bold">
                                            { selectedYear }
                                        </span>
                                        <FontAwesomeIcon icon={faCaretDown} size="xs" color={ colors.find( e => e.org === 'blue').normal } />
                                    </button>
                            }>
                                <div className="max-h-52 bg-white rounded-lg w-20 border border-muted overflow-auto shadow">
                                    <div className="flex flex-col">
                                        { years.map( year => 
                                            <button
                                                key={year}
                                                className="last:border-0 flex flex-row h-10 space-x-2 items-center justify-center border-b border-b-muted"
                                                onClick={ () => pickYear(year)}
                                            >
                                                <span className="text-xs">{ year }</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Popup>

                        </div>

                        <div className="flex flex-row items-center justify-center space-x-2">
                            <button 
                                className="h-8 w-8 bg-soft-muted rounded-full"
                                onClick={ () => changeMonth('decrease')}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} size="sm" color={ colors.find( e => e.org === 'blue').normal } />
                            </button>
                            <button 
                                className="h-8 w-8 bg-soft-muted rounded-full"
                                onClick={ () => changeMonth('increase')}
                            >
                                <FontAwesomeIcon icon={faChevronRight} size="sm" color={ colors.find( e => e.org === 'blue').normal } />
                            </button>
                        </div>

                    </div>
                </div>

                <div 
                    className="flex flex-col"
                    style={{
                        width: width
                    }}
                >
                    <div className="flex flex-row flex-wrap">
                        { daysOfWeek.map( (day, index) =>
                            <div 
                                key={index} 
                                className="flex justify-center items-center font-bold text-soft-gray"
                                style={{
                                    ...cellStyle,
                                    margin: 2,
                                }}
                            >
                                <span>
                                    { day.short.toUpperCase() }
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row flex-wrap">
                        { currentMonthDates.map( (date, index) =>
                            <div
                                key={date + index} 
                                className={`flex justify-center items-center ${ currentMonthHighlightedDates.includes(date) ? 'bg-dim-sky rounded' : '' }`}
                                style={{
                                    ...cellStyle,
                                    height: cellStyle.width - 7,
                                    margin: 2
                                }}
                            >   
                                { date !== '' ? (
                                    <button
                                        className={`flex items-center justify-center cursor-pointer 
                                            ${ (dateParser.toString({day: date, month: selectedMonth, year: selectedYear}) === selectedDateString) ? 'bg-blue text-white rounded-full' : '' }
                                            ${ (dateParser.toString({day: date, month: selectedMonth, year: selectedYear}) === dateParser.toString(today)) ? 'text-blue font-bold' : '' }
                                            ${ currentMonthDisabledDates.includes(date) ? 'opacity-30' : 'text-black' }
                                        `}
                                        style={cellStyle}
                                        onClick={ ()=> pickDate(date) }
                                    >   
                                        <span>{date}</span>
                                    </button>
                                ) : ''}
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex items-center h-10">
                    <div className="flex flex-1 items-center justify-end mx-1">
                        <button 
                            className="flex items-center justify-center rounded-lg bg-soft-muted h-8 w-20"
                            onClick={setToday}
                        >
                            <span className="text-blue text-sm font-semibold">Today</span>
                        </button>
                    </div>
                </div>

            </div>
        </Popup>
    );
};

export default memo(DatePicker);
