import Popup from "reactjs-popup";
import { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import colors from "../../helper/colors";

const PopUpPicker = ({
    data,
    value,
    disabled,
    onPick,
    triggerClassName,
    countBy,
    textColor = "white",
    theme = "light",
    enableIcon = true,
    nullable = false,
    position = 'bottom center',
}) => {
    const themeColors = {
        arrow: theme === "light" ? colors.find( e => e.org === 'muted' ).normal : "gray",
        bg: theme === "light" ? "white" : "black",
        border: theme === "light" ? "dim-gray" : "gray",
        text: theme === "light" ? "black" : "dim-white",
        active: theme === "light" ? "dim-gray" : "gray",
    };

    const icon = position.includes('bottom') ? faCaretDown : faCaretUp;

    const popupRef = useRef();
    const [pickedId, setPickedId] = useState(value);

    const items = useMemo(() => {
        return nullable
            ? [
                  {
                      id: null,
                      name: "Select ",
                  },
                  ...data,
              ]
            : [...data];
    }, [data, nullable]);

    const pickedItem = useMemo(() => {
        const targetItem = items.find((e) => e.id === pickedId);
        if (!targetItem || items.length === 0) {
            return {
                name: "Select ",
            };
        }
        return targetItem;
    }, [pickedId, items]);

    const dropDownHeight = useMemo(() => {
        const heightByItems = items.length * 56;
        const maxHeight = heightByItems > 392 ? 392 : heightByItems;
        return maxHeight;
    }, [items]);

    useEffect(() => {
        value !== pickedId && setPickedId(value);
    }, [value]);

    //methods 
        const pick = (itemId) => {
            popupRef.current.close();
            setPickedId(itemId);
            onPick(itemId);
        }

    return (
        <Popup
            ref={popupRef}
            position={position}
            arrowStyle={{
                color: themeColors.arrow,
                opacity: '90%',
            }}
            trigger={
                <button
                    className={triggerClassName}
                    disabled={disabled}
                >
                    <div className="flex flex-row items-center space-x-1">
                        <span 
                            className={`text-xs min-w-6 text-${textColor}`} 
                        >
                            { pickedItem.name }
                        </span>
                        {enableIcon ? (
                            <FontAwesomeIcon
                                icon={icon}
                                size="xs"
                                color={
                                    colors.find((e) => e.org === textColor).normal
                                }
                            />
                        ) : ''}
                    </div>
                </button>
            }
        >
            {/* content */}
            <div
                className={`overflow-auto bg-soft-${themeColors.bg} rounded mx-1 opacity-95 border border-${themeColors.border}`}
                style={{
                    height: dropDownHeight,
                    minWidth: 100
                }}
            >
                <div className="flex flex-col">
                    {items.map((item, index) => (
                        <button
                            key={index}
                            className={`flex flex-row h-14 px-3 space-x-2 items-center justify-center border-b border-b-${themeColors.border} last:border-none active:bg-${themeColors.active}`}
                            onClick={ () => pick(item.id) }
                        >
                            <span className={`text-xs text-${themeColors.text}`}>
                                {item.name}
                            </span>

                            {countBy && 
                            countBy.length !== 0 && 
                            item.id !== null && 
                            (countBy.find((e) => e.id === item.id)?.count !== 0 )
                            ? (
                                <div className="flex items-center justify-center bg-red rounded-full">
                                    <span className="text-white text-2xs font-bold m-1 min-w-3.5"
                                    >
                                        {
                                            countBy.find(
                                                (e) => e.id === item.id
                                            )?.count
                                        }
                                    </span>
                                </div>
                            ) : '' }
                        </button>
                    ))}
                </div>
            </div>
        </Popup>
    );
};

export default PopUpPicker;