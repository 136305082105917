import React, { useCallback, useEffect, useState } from "react";
import axios_custom from "../axios_config/axios_custom";
import colors from "../helper/colors";
import ItemCard from "../components/ItemCard";
import useNavigation from "../hooks/useNavigation";
import ScrollView from "../components/ScrollView";
import useFormDataConverter from "../hooks/useFormDataConverter";
import StackScreen from "../components/StackScreen";
import { useSelector } from "react-redux";
import { Button } from "../components/inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

const AddedScreen = () => {

    const navigation = useNavigation();
    const formDataConverter = useFormDataConverter();

    const currentRoute = useSelector(state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const formData = formDataConverter.parse(routeState.submitData);
    const { 
        addedItems: orgAddedItems,
        addCount, 
        failedCount 
    } = routeState;

    const [addedItems, setAddedItems] = useState(orgAddedItems);

    //retry
    const [isRetrying, setIsRetrying] = useState(false);
    const [retriedCount, setRetriedCount] = useState(0);

    //reDelete
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);//to prevent retrying after deletion

    //watchers
        //Retry
        useEffect( () => {
            if(isRetrying){
                if(retriedCount < failedCount){
                    axios_custom.post('items', formData)
                    .then( (res) => {
                        setAddedItems( prev => {
                            return [...prev, res.data.result]
                        });
                        setRetriedCount( prev => prev + 1);
                    })
                    .catch( () => {
                        setRetriedCount( prev => prev + 1);
                    });
                } else {
                    setRetriedCount( () => {
                        setIsRetrying(false);
                        return 0;
                    });
                }
            }
        }, [isRetrying, retriedCount]);

        //reDelete
        useEffect( () => {
            if(isDeleting){
                const targetItem = addedItems[0];
                
                if(targetItem){
                    axios_custom.delete('items/' + targetItem.id)
                    .then( () => {
                        setAddedItems( prev => prev.filter( e => e.id !== targetItem.id ));
                    });
                } else {
                    setIsDeleting(false);
                }
            }
        }, [isDeleting, addedItems]);

    //methods
        const reDelete = useCallback( () => {
            if(window.confirm('\nအားလုံးပြန်ဖျက်မှာသေချာပါသလား?')){
                setIsDeleting(true);
                setIsDeleted(true);
            }
        }, []);
        
    return (
        <StackScreen
            renderRight={
                <Button
                    title="Delete All"
                    color="red"
                    activated={isDeleting}
                    activatedText="Deleting"
                    position="right"
                    onClick={reDelete}
                />
            }
        >
            <div className="flex flex-row h-12 bg-white justify-between items-center border-b border-b-dim-gray px-4">
                <div className="flex flex-row items-center justify-center space-x-1">
                    <span className="text-green font-bold text-xs">
                        { addedItems.length }
                    </span>
                    <span className="text-green text-xs">
                        Succeed
                    </span>
                    <span className="text-sm" style={{paddingBottom: 1 }}> | </span>
                    <span className="text-red font-bold text-xs">
                        { addCount - addedItems.length }
                    </span>
                    <span className="text-red text-xs">
                        Failed
                    </span>
                </div>
                <div>
                    { (isDeleted || addCount - addedItems.length === 0) ? (
                        <div className="flex flex-row items-center justify-center space-x-1">
                            <FontAwesomeIcon 
                                icon={faCheck} 
                                color={colors.find( e => e.org === 'green').normal}    
                            />
                            <span className="text-green font-bold text-xs">
                                All Set!
                            </span>
                        </div>
                    ) : (
                        <button 
                            className="flex flex-row items-center justify-end space-x-1"
                            onClick={ () => setIsRetrying(true) }
                            disabled={isRetrying}
                        >
                            { isRetrying ? (
                                <FontAwesomeIcon
                                    className="animate-spin-left" 
                                    icon={faRotateLeft} 
                                    color={colors.find( e => e.org === 'red').soft}    
                                />
                            ) : (
                                <FontAwesomeIcon 
                                    icon={faRotateLeft} 
                                    color={colors.find( e => e.org === 'red').normal}    
                                />
                            )}
                            <span className={`flex self-end font-bold text-xs ${ isRetrying ? 'text-soft-red' : 'text-red'}`}>
                                Retry
                            </span>
                        </button>
                    )}
                </div>
            </div>

            <ScrollView className="flex-1">
                <div className="flex flex-col my-2">
                    
                    <div className="flex-1 flex flex-row flex-wrap">
                        { addedItems.map( item => 
                            <div key={item.id} className="flex justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
                                <ItemCard
                                    key={item.id}
                                    item={item}
                                    displayMode="all"
                                    details={{
                                        show: [],
                                        requireSettingMode: false,
                                    }}
                                    traderWeight={{
                                        show: true,
                                        type: 'toggle',
                                        requireSettingMode: false,
                                    }}
                                    buttons={{
                                        show: true,
                                        normal_buttons: [
                                                            {
                                                                id: 1,
                                                                name: 'Edit',                                                        
                                                                color: 'sky',
                                                                onClick: () => {
                                                                    navigation.navigate('form', {
                                                                        state: {
                                                                            id: item.id,
                                                                            item: item
                                                                        }
                                                                    })
                                                                }
                                                            },
                                                            {
                                                                id: 2,
                                                                name: 'Delete',
                                                                color: 'red',
                                                                onClick: {
                                                                    action: 'delete',
                                                                    params: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            }
                                                        ],
                        
                                        setting_mode_buttons:[]
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    { addedItems.length === 0 ? (
                        <div className="flex items-center justify-center h-16">
                            <span className="text-soft-gray font-bold">No Items Added</span>
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-16">
                            <span className="text-soft-gray text-sm font-bold">End of Results</span>
                        </div>
                    )}
                    
                </div>
            </ScrollView>
        </StackScreen>
    );
};

export default AddedScreen;
