const Switch = ({ value, onChange }) => {
    return (
        <div 
            className={`relative flex h-7 w-12 items-center cursor-pointer rounded-full transition-all duration-300 border-2 ${ value ? 'border-green bg-green' : 'border-soft-gray bg-soft-gray'}`}
            onClick={ () => onChange(!value)}
        >
            <div className={`absolute h-6 w-6 rounded-full transition-all duration-300 shadow bg-white ${ value ? 'right-0' : 'right-5' }`}>
            </div>
        </div>
    );
};
                
export default Switch;