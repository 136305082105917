import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import ItemCard from '../components/ItemCard';
import DebtCard from '../components/DebtCard';
import SectionHeader from '../components/SectionHeader';
import FinanceSummaryBanner from '../components/FinanceSummaryBanner';
import ItemListFrame from '../components/ItemListFrame';
import CollateralCard from '../components/CollateralCard';

const FinanceScreen = () => {

    const fetchApi = useApiFetcher();
    
    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        sort_by: {
            type: 'sold_date',
            order: 'desc'
        }
    });
    const [showLoading, setShowLoading] = useState(false);

    const defaultResponse = {
        count: 0,
        items: [],
        count_by: {
            group: [],
            user: []
        },
        collectable_money: 0,
        all_item_ids: []
    };
    
    //solds
        const [soldPage, setSoldPage] = useState(2);
        const [solds, setSolds] = useState({
            ...defaultResponse,
            collectable_money: {
                total_instant_sold: 0,
                total_deposit_price: 0,
                total_cash_back: 0,
                final_price: 0
            }
        });
        const endSolds = useMemo( () => 
            solds.count === solds.items.length
        , [solds.items.length]);

    //paid_debts
        const [paidDebtPage, setPaidDebtPage] = useState(2);
        const [paid_debts, setPaidDebts] = useState(defaultResponse);

        const endPaidDebts = useMemo( () => 
            paid_debts.count === paid_debts.items.length
        , [paid_debts.items.length]);
    
    //paid_interests
        const [paidInterestPage, setPaidInterestPage] = useState(2);
        const [paid_interests, setPaidInterests] = useState(defaultResponse);

        const endPaidInterests = useMemo( () => 
            paid_interests.count === paid_interests.items.length
        , [paid_interests.items.length]);

    //collaterals
        const [collateralPage, setCollateralPage] = useState(2);
        const [collaterals, setCollaterals] = useState(defaultResponse);

        const endCollaterals = useMemo( () => 
            collaterals.count === collaterals.items.length
        , [collaterals.items.length]);

    //api_fetch    
        useEffect( () => {
            //clear data
            setSolds({
                ...defaultResponse,
                collectable_money: {
                    total_instant_sold: 0,
                    total_deposit_price: 0,
                    total_cash_back: 0,
                    final_price: 0
                }
            });
            setPaidDebts(defaultResponse);
            setPaidInterests(defaultResponse);
            setCollaterals(defaultResponse);
            
            fetchApi(
                'items', 
                {page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setSolds
            );

            fetchApi(
                'paid_debts', 
                {page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setPaidDebts
            );

            fetchApi(
                'paid_interests', 
                {page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setPaidInterests
            );

            fetchApi(
                'collaterals', 
                {page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setCollaterals
            );

            (soldPage !== 2) && setSoldPage(2);
            (paidDebtPage !== 2) && setPaidDebtPage(2);
            (paidInterestPage !== 2) && setPaidInterestPage(2);
            (collateralPage !== 2) && setCollateralPage(2);
        }, [search_filters]);

    //methods 
        const loadMore = () => {
            if(!endSolds){
                fetchApi(
                    'items', 
                    {page: soldPage, ...search_filters}, 
                    solds.items, 
                    setShowLoading, 
                    setSolds, 
                    setSoldPage
                );
            }

            if(!endPaidDebts && endSolds){
                fetchApi(
                    'paid_debts', 
                    {page: paidDebtPage, ...search_filters},
                    paid_debts.items, 
                    setShowLoading, 
                    setPaidDebts, 
                    setPaidDebtPage
                );
            }

            if(!endPaidInterests && endPaidDebts && endSolds){
                fetchApi(
                    'paid_interests', 
                    {page: paidInterestPage, ...search_filters},
                    paid_interests.items, 
                    setShowLoading, 
                    setPaidInterests, 
                    setPaidInterestPage
                );
            }

            if(!endCollaterals && endPaidInterests && endPaidDebts && endSolds){
                fetchApi(
                    'collaterals', 
                    {page: collateralPage, ...search_filters},
                    collaterals.items, 
                    setShowLoading, 
                    setCollaterals, 
                    setCollateralPage
                );
            }
        }

        const updateList = useCallback( (id, type) => {
            let setList;

            switch (type) {
                case 'item':
                    setList = setSolds;
                break;

                case 'paid_debt':
                    setList = setPaidDebts;
                break;

                case 'paid_interest':
                    setList = setPaidInterests;
                break;

                case 'collateral':
                    setList = setCollaterals;
                break;
            }

            setList( prev => {
                const newData = {...prev};
                newData.count = prev.count - 1;
                newData.items = prev.items.filter( e => e.id !== id );
                return newData;
            });
        }, [])
    
    //computed
        const totalCountByUser = useMemo(() => {
            let countByUser = [];
            if(solds.count_by.user.length !== 0 && 
                paid_debts.count_by.user.length !== 0 &&
                paid_interests.count_by.user.length !== 0 &&
                collaterals.count_by.user.length !== 0 ){

                countByUser = solds.count_by.user.map( e => {
                    return {
                        id: e.id,
                        count:
                            e.count + 
                            paid_debts.count_by.user.find( g => g.id === e.id).count +
                            paid_interests.count_by.user.find( g => g.id === e.id).count + 
                            collaterals.count_by.user.find( g => g.id === e.id).count
                    }
                })
            }
            return countByUser;
        }, [solds.count_by, paid_debts.count_by, paid_interests.count_by, collaterals.count_by]);

    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="users"
                searchOnNavChange={false}
                enableAllSearch={true} 
                enabledSearches={['date', 'id', 'word', 'customer']}
                countBy={totalCountByUser}
            />

            <ItemListFrame
                enabledSortTypes={['id', 'sold_date']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ solds.count + paid_debts.count + paid_interests.count + collaterals.count }
                countUnit="ခု"
                isEnd={
                    endSolds && 
                    endPaidDebts && 
                    endPaidInterests && 
                    endCollaterals && 
                    (solds.count + paid_debts.count + paid_interests.count + collaterals.count !== 0)
                }
                banner={
                    <FinanceSummaryBanner
                        onCollected={ () => setSearchFilters({...search_filters}) }
                        collectable={true}
                        date={search_filters.date}
                        user_id={search_filters.user}
                        count={solds.count + paid_debts.count + paid_interests.count + collaterals.count}
                        solds={solds.collectable_money}
                        total_paid_debts={paid_debts.collectable_money}
                        total_paid_interests={paid_interests.collectable_money}
                        total_collaterals={collaterals.collectable_money}
                        all_sold_ids={solds.all_item_ids}
                        all_paid_debt_ids={paid_debts.all_item_ids}
                        all_paid_interests_ids={paid_interests.all_item_ids}
                        all_collaterals_ids={collaterals.all_item_ids}
                    />
                }
            >
                <div className="flex flex-col mt-3 space-y-5">
                    { solds.items.length !== 0 ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="ရောင်းပြီးပစ္စည်းများ ငွေအပ်ရန်"
                                color="green"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                { solds.items.map( item => 
                                    <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <ItemCard 
                                            key={item.id}
                                            onTakeAction={updateList}
                                            item={item}
                                            displayMode={item.status === 'attachment_only_sold' ? 'attachment_only' : 'without_attachment'}
                                            details={{
                                                show: ['sold', 'collectable_money'],
                                                header: 'sold',
                                                requireSettingMode: false,
                                            }}
                                            traderWeight={{
                                                show: true,
                                                type: 'inline',
                                                requireSettingMode: true,
                                            }}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: item.sold_price - item.tradein_price - item.deposit_price - item.discount_price < 0 ? 'ပြန်အမ်း' :  'ငွေအပ်',
                                                                        color: item.sold_price - item.tradein_price - item.deposit_price - item.discount_price < 0 ? 'orange' : 'teal',
                                                                        onClick: {
                                                                            action: item.sold_price - item.tradein_price - item.deposit_price - item.discount_price < 0 ? 'cash_back' : 'collect_money',
                                                                            params: {
                                                                                id: item.id
                                                                            }
                                                                        }
                                                                    },
                                                                ],

                                                setting_mode_buttons:[
                                                                        {
                                                                            id: 1,
                                                                            name: 'Delete',
                                                                            color: 'red',
                                                                            onClick: {
                                                                                action: 'delete',
                                                                                params: {
                                                                                    id: item.id
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            name: 'ငွေအပ်',
                                                                            color: 'teal',
                                                                            onClick: {
                                                                                action: 'collect_money',
                                                                                params: {
                                                                                    id: item.id
                                                                                }
                                                                            }
                                                                        },
                                                                    ]
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}
                    { (endSolds && paid_debts.count !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="အကြွေးဆပ်ငွေများအပ်ရန်"
                                color="orange"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {paid_debts.items.map( item => 
                                    <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <DebtCard 
                                            key={item.id}
                                            onTakeAction={updateList}
                                            data={item}
                                            type="paid_debt"
                                            details={['received']}
                                            showVoucher={true}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ငွေအပ်',
                                                                        color: 'teal',
                                                                        onClick: {
                                                                            action: 'collect_money',
                                                                            params: {
                                                                                id: item.id,
                                                                            }
                                                                        }
                                                                    },
                                                                ],

                                                setting_mode_buttons:[
                                                                        {
                                                                            id: 1,
                                                                            name: 'Delete',
                                                                            color: 'red',
                                                                            onClick: {
                                                                                action: 'delete',
                                                                                params: {
                                                                                    id: item.id
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            name: 'ငွေအပ်',
                                                                            color: 'teal',
                                                                            onClick: {
                                                                                action: 'collect_money',
                                                                                params: {
                                                                                    id: item.id,
                                                                                }
                                                                            }
                                                                        },
                                                                    ]
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}
                    { (endSolds && endPaidDebts && paid_interests.count !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="အတိုးပေးငွေများအပ်ရန်"
                                color="sky"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {paid_interests.items.map( item => 
                                    <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <CollateralCard 
                                            key={item.id}
                                            onTakeAction={updateList}
                                            data={item}
                                            type="paid_interest"
                                            details={['received']}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ငွေအပ်',
                                                                        color: 'teal',
                                                                        onClick: {
                                                                            action: 'collect_money',
                                                                            params: {
                                                                                id: item.id,
                                                                            }
                                                                        }
                                                                    },
                                                                ],

                                                setting_mode_buttons:[
                                                                        {
                                                                            id: 1,
                                                                            name: 'Delete',
                                                                            color: 'red',
                                                                            onClick: {
                                                                                action: 'delete',
                                                                                params: {
                                                                                    id: item.id
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            name: 'ငွေအပ်',
                                                                            color: 'teal',
                                                                            onClick: {
                                                                                action: 'collect_money',
                                                                                params: {
                                                                                    id: item.id,
                                                                                }
                                                                            }
                                                                        },
                                                                    ]
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}
                    { (endSolds && endPaidDebts && endPaidInterests && collaterals.count !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="အပေါင်ပေးငွေ ပြန်အမ်းပေးရန်"
                                color="cyan"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {collaterals.items.map( item => 
                                    <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <CollateralCard 
                                            key={item.id}
                                            onTakeAction={updateList}
                                            data={item}
                                            type="collateral"
                                            details={['sold']}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ပြန်အမ်း',
                                                                        color: 'cyan',
                                                                        onClick: {
                                                                            action: 'collect_money',
                                                                            params: {
                                                                                id: item.id,
                                                                            }
                                                                        }
                                                                    },
                                                                ],

                                                setting_mode_buttons:[
                                                                        {
                                                                            id: 1,
                                                                            name: 'Delete',
                                                                            color: 'red',
                                                                            onClick: {
                                                                                action: 'delete',
                                                                                params: {
                                                                                    id: item.id
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            name: 'ပြန်အမ်း',
                                                                            color: 'cyan',
                                                                            onClick: {
                                                                                action: 'collect_money',
                                                                                params: {
                                                                                    id: item.id,
                                                                                }
                                                                            }
                                                                        },
                                                                    ]
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}
                </div>

            </ItemListFrame>
        </>
    )
}

export default FinanceScreen;