import React, { memo, useRef } from "react";
import { BaseInput } from "../../bases";

const MainSearchInput = ({
    type,
    value,
    onChange,
    onSearch,
    onRefresh,
    placeholder = '',
    disabled = false,
    onPressEnterKey = () => null
}) => {

    const inputRef = useRef(null);

    return (
        <>
            <BaseInput
                ref={inputRef}
                type={type}
                className={`h-full flex-1 text-sm outline-none rounded-none overflow-auto placeholder:text-sm px-3 ${disabled ? 'bg-muted' : 'bg-white'}`}
                placeholder={placeholder}
                value={value}
                onPressEnterKey={onPressEnterKey}
                onChange={onChange}
                disabled={disabled}
            />

            {value !== '' ? (
                <div className="flex pr-4 items-center bg-white h-full">
                    <button
                        className="flex items-center justify-center w-4 h-4 bg-dim-gray rounded-full"
                        onClick={ () => {
                            inputRef.current.focus();
                            onChange('');
                        }}
                    >
                        <span className="text-white text-xs">X</span>
                    </button>
                </div>
            ) : (
                ''
            )}

            <button
                className="px-4 h-full bg-blue items-center justify-center"
                onClick={onSearch}
            >
                <span className="text-white text-sm">Find</span>
            </button>

            <button
                className="px-4 h-full bg-gray items-center justify-center rounded-r"
                onClick={onRefresh}
            >
                <span className="text-white text-sm">All</span>
            </button>
        </>
    );
};

export default memo(MainSearchInput);
