import React, { useEffect, useMemo, useState } from "react";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import { Button, HiddenActionButtons, Switch } from "../components/inputs";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import NoticeBanner from "../components/NoticeBanner";
import ActivityIndicator from "../components/ActivityIndicator";
import { useSelector } from "react-redux";
import useNavigation from "../hooks/useNavigation";

const CategoryScreen = () => {
    const groups = useSelector( state => state.app.groups );
    const { handleError } = useStoreHelper();
    const navigation = useNavigation(); 

    const currentRoute = useSelector( state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const targetGroupId = routeState?.groupId ?? null;
    const newCategoryId = routeState?.newCategoryId ?? null;

    const [showAll, setShowAll] = useState(false);
    const [categories, setCategories] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    
    //mounted
    useEffect( () => {
        setShowLoading(true);

        const controller = new AbortController();
        const signal = controller.signal;

        axios_custom.get('categories', { signal })
        .then( (res) => {
            setShowLoading(false);
            setCategories(res.data.results);
        })
        .catch( (error) => {
            setShowLoading(false);
            handleError(error);
        });

        return () => {
            controller.abort();
        }
    }, []);

    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === targetGroupId );
        }, [targetGroupId, groups]);

        const categoriesByCurrentGroup = useMemo( () => {
            const data = (targetGroupId === null || showAll)
            ? categories
            : categories.filter( e => e.group_id === targetGroupId);
            
            if(newCategoryId && data.length !== 0){
                const newCategory = data.find( e => e.id === newCategoryId );
                return [
                    newCategory,
                    ...data.filter( e => e.id !== newCategoryId )
                ]
            }

            return data;
        }, [categories, newCategoryId, targetGroupId, showAll]);

    //methods
        const submitDelete = (category_id) => {
            if(window.confirm('\nဖျက်မှာ သေချာပါသလား?')){
                setShowLoading(true);
                axios_custom.delete('categories/' + category_id)
                .then( () => {
                    setShowLoading(false)
                    setCategories( prev => prev.filter( e => e.id !== category_id ))
                })
                .catch( error => {
                    handleError(error);
                });
            }
        }

    return (
        <StackScreen
            renderRight={
                <Button
                    title={isEditMode ? 'Cancel' : 'Edit'}
                    color="blue"
                    onClick={ () => setIsEditMode( prev => !prev )}
                />
            }
            title={ showAll 
                ? 'အမျိုးအစားခွဲများအားလုံး' 
                : (currentGroup ? `${currentGroup.name}၏အမျိုးအစားခွဲများ` : 'အမျိုးအစားခွဲများ')
            }
        >
            <ScrollView 
                className="flex flex-col flex-1 bg-soft-muted"
                disableBackToTop={true}
            >

                <div className="flex flex-col mb-10">

                    <NoticeBanner
                        title="သတိပြုရန်"
                        icon={faCircleExclamation}
                        color="yellow"                                            
                        msges={[
                            'ပစ္စည်းထည့်ပြီးသား အမျိုးအစားခွဲများအား ပြန်ဖျက်ခွင့်မပြုပါ။',
                            'ဖျက်လိုပါက ထိုအမျိုးအစားခွဲအောက်တွင်ထည့်ထားသော ပစ္စည်းအားလုံးအား ဖျက် (or) အခြားအမျိုးအစားခွဲတစ်ခုသို့ ပြောင်းပေးရပါမည်။'
                        ]}
                    />

                    <div className="flex flex-row items-center justify-end space-x-3 h-12 bg-dim-white border-b border-b-dim-gray px-4">
                        <span className="text-xs font-bold text-soft-black">အမျိုးအစားခွဲအားလုံးပြပါ</span>
                        <Switch
                            value={showAll}
                            onChange={setShowAll}
                        />
                    </div>
                
                    {/* header bar */}
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center justify-between h-14 bg-dim-white border-b border-b-dim-gray">
                            <div className="flex justify-start ml-4">
                                { showLoading ? (
                                    <ActivityIndicator size="sm"/>
                                ) : (
                                    <div className="flex flex-row space-x-1 items-center">
                                        <span className="text-red">
                                            { categoriesByCurrentGroup.length }
                                        </span>
                                        <span className="text-gray text-sm">
                                            ခု
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-end mr-4">
                                <button 
                                    className="flex items-center justify-center rounded bg-green"
                                    onClick={ () => navigation.navigate('category_form') }
                                >
                                    <span className="text-white text-xs m-2">
                                        + Add New
                                    </span>
                                </button>
                            </div> 
                        </div>
                    </div>

                    <div className="flex flex-col">
                        { categoriesByCurrentGroup.map( category => 
                            <div 
                                key={category.id} 
                                className={`flex flex-row border-b border-b-dim-gray 
                                    ${ newCategoryId === category.id ? 'bg-dim-green' : 'bg-white'}
                                `}
                            >
                                <button 
                                    className="flex flex-1 items-center h-14"
                                    onClick={() => navigation.navigate('category_details', { state: { category: category } })}
                                >
                                    <div className="flex flex-row items-center space-x-2 ml-4">
                                        { newCategoryId === category.id ? (
                                            <span className="text-red text-xs italic font-bold">* New</span>
                                        ) : ''}
                                        <span className="text-sm">
                                            { category.name }
                                        </span>
                                    </div>
                                </button>

                                <HiddenActionButtons
                                    show={isEditMode}
                                    buttons={category.deleteable
                                        ? ['edit', 'delete']
                                        : ['edit']
                                    }
                                    disabled={showLoading ? ['*'] : []}
                                    onEdit={() => navigation.navigate('category_form', { state: {
                                        category: category
                                    } })}
                                    onDelete={() => submitDelete(category.id)}
                                />
                            </div>
                        )}
                    </div>

                </div>
            </ScrollView>
        </StackScreen>
    );
};

export default CategoryScreen;
