import React, { memo, useMemo, useState } from 'react';
import axios_custom from '../axios_config/axios_custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setQualities, setAlert } from '../store/reducers/app-reducer';
import useStoreHelper from '../hooks/useStoreHelper';
import { BaseInput } from './bases';
import { SubmitButton, TagInput } from './inputs';
import colors from '../helper/colors';
import { motion, AnimatePresence } from "framer-motion";

const PricingComponent = ({ onChange }) => {

    const allQualities = useSelector( state => state.app.qualities );
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [goldPrices, setGoldPrices] = useState({});
    const [taxPercent, setTaxPercent] = useState(0);

    const [showForm, setShowForm] = useState(false);

    const qualities = useMemo( () => 
        allQualities.filter( e => !e.is_plainsell)
    , [allQualities])

    //methods
        const toggleForm = () => {
            setShowForm( prev => {
                setGoldPrices(qualities.reduce((obj, quality) => {
                    obj[quality.id] = quality.goldprice;
                    return obj;
                }, {}));
                setTaxPercent(qualities[0].tax_percent);
                return !prev;
            });
        }

        const handleGoldPriceInput = (qualityId, value) => {
            setGoldPrices( prev => {
                const newSet = {...prev};
                newSet[qualityId] = value;
                return newSet;     
            });
        }

        const submitChange = () => {
            setIsSubmitting(true);
            
            axios_custom.put('pricings', { 
                goldprices: goldPrices,
                tax_percent: taxPercent
            })
            .then( (res) => {

                dispatch(setQualities(res.data.new_qualities));

                dispatch(setAlert({
                    msg: res.data.msg,
                    status: 'success',
                    timeout: 3500
                }));

                setShowForm(false);
                setIsSubmitting(false);
                
                if(onChange){
                    onChange();
                }
            })
            .catch( (error) => {
                setShowForm(false);
                setIsSubmitting(false);
                handleError(error);
            });
        };

    return (
        <div className="relative flex flex-col w-5/6 sm:w-96">
            <div className="flex flex-col rounded-lg shadow overflow-hidden">

                <div className="flex flex-row h-10 bg-soft-black">
                    <div className="flex-1 flex flex-row items-center mx-3 space-x-2">
                        <span className="text-xs text-white">
                            ရွှေစျေးနှုန်းများ
                        </span>
                    </div>
                    <div className="flex-1 flex items-center justify-end mx-2">
                        <button 
                            className="flex items-center justify-center rounded h-7 px-2 space-x-1 shadow bg-red"
                            onClick={toggleForm}
                        >
                            <span className="text-xs text-white">
                                Change
                            </span>
                            <FontAwesomeIcon
                                className={`transition-all duration-200 ${ showForm ? '-rotate-180' : 'rotate-0'}`}
                                icon={faChevronDown}
                                color={ colors.find( e => e.org === 'white').normal }
                                size="2xs"
                            />
                        </button>
                    </div>
                </div>

                <div className="flex flex-row">
                    { qualities.map( (quality) => (
                        <div key={quality.id} className="flex-1 h-8 flex justify-center items-center bg-muted border-r border-dim-gray last:border-none">
                            <span className="text-2xs font-bold text-soft-black">
                                { quality.name }
                            </span>
                        </div>
                    ))}
                </div>

                <div className="flex flex-row">
                    { qualities.map( (quality) => (
                        <div key={quality.id} className="flex-1 h-9 flex justify-center items-center border-r border-muted last:border-none">
                            <span className="text-xs text-black">
                                { new Intl.NumberFormat().format(quality.goldprice) }
                            </span>
                        </div>
                    ))}
                </div>

            </div>

            <AnimatePresence>
                {showForm ? (
                    <motion.div
                        className="absolute overflow-auto bg-white z-10 rounded-lg border border-muted shadow-lg mt-10 w-full"
                        initial={{ opacity: 1, y: -5 }}
                        animate={{ opcity: 1, y: 2 }}
                        exit={{ opacity: 0, y: -5 }}
                    >
                        <div className="flex flex-col m-3 space-y-1">
                            { qualities.map( quality => (
                                <TagInput
                                    key={quality.id}
                                    type="number"
                                    value={goldPrices[quality.id]}
                                    className="px-3 text-xs"
                                    tagMinWidth={103}
                                    tagPosition="start"
                                    onPressEnterKey={submitChange}
                                    tagLabel={quality.name}
                                    onChange={ text => handleGoldPriceInput(quality.id, text)}
                                />
                            ))}

                            <div className="flex flex-row justify-between pt-1">
                                <div className="flex flex-row space-x-2 items-center w-24 ml-1">
                                    <span className="text-xs">
                                        အခွန်
                                    </span>
                                    <BaseInput
                                        type="number"
                                        value={taxPercent}
                                        className="text-center text-xs p-2 rounded-lg bg-muted w-full focus:outline-none"
                                        onChange={setTaxPercent}
                                    />
                                    <span className="text-xs">
                                        %
                                    </span>
                                </div>
                                <div className="flex-1 flex flex-row items-center justify-end space-x-1">
                                    <div>
                                        <SubmitButton
                                            title="Cancel"
                                            height="8"
                                            color="gray"
                                            fontSize="xs"
                                            onClick={() => setShowForm(false)}
                                        />
                                    </div>
                                    <div>
                                        <SubmitButton
                                            title="Set"
                                            height="8"
                                            color="green"
                                            fontSize="xs"
                                            activated={isSubmitting}
                                            onClick={submitChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                ) : ''}
            </AnimatePresence>
        </div>
    )
}

export default memo(PricingComponent);
        