import { faBars, faBell, faCog, faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import NotificationComponent from "../NotificationComponent";
import { useDispatch, useSelector } from 'react-redux';
import { setSettingMode, setDarkMode } from '../../store/reducers/app-reducer';
import Popup from "reactjs-popup";
import useNavigation from "../../hooks/useNavigation";
import MobileSideDrawer from "./MobileSideDrawer";
import { Switch, DarkModeSwitch } from "../inputs";
import DesktopSideDrawer from "./DesktopSideDrawer";

const NavToggles = () => {

    const authUser = useSelector( state => state.app.authUser );
    const isDarkMode = useSelector( state => state.app.isDarkMode);
    const isSettingMode = useSelector( state => state.app.isSettingMode);
    const isDesktop = useSelector( state => state.app.isDesktop);

    const dispatch = useDispatch();
    const navigation = useNavigation();

    const notiRef = useRef(null);
    const settingRef = useRef(null);
    const [showDrawer, setShowDrawer] = useState(false);
    
    const [notiCount, setNotiCount] = useState(0);

    return (
        <div className="flex flex-row items-center">

            {/* noti */}
            <Popup
                ref={notiRef}
                position="bottom right"
                offsetX={50}
                arrow={false}              
                trigger={
                    <div className="relative flex items-center justify-center h-11 w-11">
                        <button className="flex items-center justify-center bg-gray rounded-full w-8 h-8">
                            <FontAwesomeIcon icon={faBell} color="white"/>
                        </button>
                        { (notiCount !== 0) ? (
                            <div className="absolute top-0 right-0 flex items-center justify-center bg-red h-4 rounded-full">
                                <span className="text-white text-2xs px-2">
                                    {notiCount}
                                </span>
                            </div>
                        ) : ''}
                    </div>
                }
            >
                <NotificationComponent
                    onNotificationsChange={ (notiCount) => setNotiCount(notiCount)}
                />

            </Popup>

            {/* setting */}
            <Popup
                ref={settingRef}
                position="bottom right"
                arrowStyle={{
                    color: 'gray'
                }}
                trigger={
                    <div className="flex items-center justify-center h-11 ml-2 mr-3">
                        <button className="flex items-center justify-center bg-gray rounded-full w-8 h-8">
                            <FontAwesomeIcon icon={faCog} color="white"/>
                        </button>
                    </div>
                }
            >
                <div className="flex flex-col bg-soft-black border border-gray rounded-lg w-60">
                    <div className="flex flex-col space-y-3 my-5 mx-7">

                        <div className="flex items-center justify-center h-6">
                            <span className="font-bold text-lg text-white">Settings</span>
                        </div>

                        <div className="flex flex-col h-24">
                            <div className="flex-1 flex flex-row items-center justify-between">
                                <span className="text-white">Dark Mode</span>
                                <DarkModeSwitch
                                    value={isDarkMode}
                                    onChange={ () => null }
                                />
                            </div>
                            
                            <div className="flex flex-col mb-2 mt-1">
                                <span className="text-gray text-2xs">* DarkMode is currently unavailable.</span>
                                <span className="text-gray text-2xs">* Coming Soon...</span>
                            </div>

                            <div className="flex-1 flex flex-row items-center justify-between">
                                <span className="text-white">Setting Mode</span>
                                <Switch
                                    value={isSettingMode}
                                    onChange={ value => dispatch(setSettingMode(value)) }
                                />
                            </div>
                        </div>

                        <div className="flex h-10">
                            <button 
                                className="flex-1 flex items-center justify-center border border-white rounded"
                                onClick={ () => {
                                    navigation.navigate('user_details', {
                                        state: {
                                            user: authUser
                                        }
                                    });
                                } }
                            >
                                <div className="flex flex-row items-center space-x-2">
                                    <FontAwesomeIcon icon={faTools} color="white"/>
                                    <span className="text-white">Account Setting</span>
                                </div>
                            </button>
                        </div>

                    </div>
                </div>
            </Popup>

            {/* drawer */}
            <div className="flex items-center justify-center h-11">
                <button 
                    className="flex items-center justify-center bg-gray rounded-full w-8 h-8"
                    onClick={ () => setShowDrawer( prev => !prev ) } 
                >
                    <FontAwesomeIcon icon={faBars} color="white"/>
                </button>
            </div>

            { isDesktop ? (
                <DesktopSideDrawer
                    show={showDrawer}
                    onClose={() => setShowDrawer(false)}
                />
            ) : (
                <MobileSideDrawer
                    show={showDrawer}
                    onClose={() => setShowDrawer(false)}
                />
            )}

        </div>
    );
};

export default NavToggles;
