import React, { useCallback, useEffect, useMemo, useState } from "react";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import { Button, HiddenActionButtons } from "../components/inputs";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import NoticeBanner from "../components/NoticeBanner";
import ActivityIndicator from "../components/ActivityIndicator";
import { useDispatch, useSelector } from "react-redux";
import useNavigation from "../hooks/useNavigation";
import { setAlert } from "../store/reducers/app-reducer";

const UsersScreen = () => {

    const authUser = useSelector( state => state.app.authUser);
    const { handleError, logOut } = useStoreHelper();
    const dispatch = useDispatch();
    const navigation = useNavigation(); 
    const currentRoute = useSelector( state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const newUserId = routeState?.newUserId ?? null;

    const [allUsers, setAllUsers] = useState([]);

    const [isEditMode, setIsEditMode] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    
    //mounted
    useEffect( () => {
        setShowLoading(true);

        const controller = new AbortController();
        const signal = controller.signal;

        axios_custom.get('users', { signal })
        .then( (res) => {
            setShowLoading(false);
            let users = res.data.results.filter( user => user.id !== authUser.id && !user.is_admin );
            if(!authUser.is_admin){
                //users with manager_users role cannot edit each other
                users = users.filter( user => !user.abilities.includes('manage_users') );
            }
            setAllUsers(users);
        })
        .catch( (error) => {
            setShowLoading(false);
            handleError(error);
        });
        return () => {
            controller.abort();
        }
    }, []);

    //computed
        const users = useMemo( () => {
            const newUser = allUsers.find( e => e.id === newUserId);
            let data = allUsers;
            if(newUser){
                data = [
                    newUser,
                    ...data.filter( e => e.id !== newUserId )
                ]
            }
            return {
                active: data.filter( e => !e.is_revoked ),
                revoked: data.filter( e => e.is_revoked )
            }
        }, [allUsers, newUserId]);

    //methods
        const makeAdmin = useCallback( (id) => {
            if(window.confirm(
                `\nဤဝန်ထမ်းအား Admin လွှဲပြောင်းမှာ သေချာပါသလား?
                \nAdmin ဖြစ်သွားလျှင် လုပ်ပိုင်ခွင့်အားလုံးရရှိသွားမည်ဖြစ်သည်။
                \nယခုသင့် account သည် ဝန်ထမ်းအကောင့် အဖြစ်ပြောင်းသွားပါမည်။`
            )){
                setShowLoading(true);
                axios_custom.put(`users/${id}/make_admin`)
                .then( res => {                            
                    dispatch(setAlert({
                        msg: res.data.msg + ' ကျေးဇူးပြု၍ Login ပြန်ဝင်ပေးပါ။',
                        status: 'success',
                        timeout: 3000
                    }));
                    logOut(setShowLoading);
                })
                .catch( error => {
                    handleError(error);
                });
            }
        }, []);

        const toggleRevoke = useCallback( (id, revoke) => {
            const msg = revoke 
            ? '\nဤအကောင့်အား ပိတ်သိမ်းမှာသေချာပါသလား?'
            : '\nဤအကောင့်အား ပြန်ဖွင့်ပေးမှာသေချာပါသလား?';

            if(window.confirm(msg)){
                setShowLoading(true);
                axios_custom.put(`users/${id}/toggle_revoke`, {
                    revoke: revoke
                })
                .then( () => {
                    setShowLoading(false);
                    setAllUsers( prevUsers =>
                        prevUsers.map(e => e.id === id ? { ...e, is_revoked: revoke } : e )
                    );
                })
                .catch( error => {
                    handleError(error);
                })
            }
        }, []);

        const submitDelete = useCallback( (id) => {
            if(window.confirm('\nဖျက်မှာသေချာပါသလား?')){
                setShowLoading(true);
                axios_custom.delete('users/' + id)
                .then( () => {
                    setShowLoading(false);
                    setAllUsers( prevUsers => prevUsers.filter( e => e.id !== id ));
                })
                .catch( error => {
                    handleError(error);
                });
            }
        }, []);

    return (
        <StackScreen
            renderRight={
                <Button
                    title={isEditMode ? 'Cancel' : 'Edit'}
                    color="blue"
                    onClick={ () => setIsEditMode( prev => !prev )}
                />
            }
        >
            <ScrollView className="flex flex-col flex-1 bg-soft-muted">

                <NoticeBanner
                    title="သတိပြုရန်"
                    icon={faCircleExclamation}
                    color="yellow"                                            
                    msges={[
                        'Admin သည် တစ်ယောက်သာ ဖြစ်ခွင့်ရှိသည်။ Admin ဖြစ်ပါက လုပ်ပိုင်ခွင့်အားလုံး ရရှိနေမည်ဖြစ်သည်။',
                        'စာရင်းပြင် / ဖျက် / ရောင်း / အကြွေးမှတ် / စသည်ဖြင့် task တစ်ခုခုလုပ်ထားသောအကောင့်အား အပြီးပြန်ဖျက်လို့မရတော့ပါ။',
                        'ဖျက်လိုပါက အကောင့်အား Ban (or) ပိတ်သိမ်း​နိုင်ပါသည်။'
                    ]}
                />

                {/* header bar */}
                <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-between h-14 bg-dim-white border-b border-b-dim-gray">
                        <div className="flex justify-start ml-4">
                            { showLoading ? (
                                <ActivityIndicator size="sm"/>
                            ) : (
                                <div className="flex flex-row space-x-1 items-center">
                                    <span className="text-red">
                                        { users.active.length }
                                    </span>
                                    <span className="text-gray text-sm">
                                        ယောက်
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-end mr-4">
                            <button 
                                className="flex items-center justify-center rounded bg-green"
                                onClick={ () => navigation.navigate('user_form') }
                            >
                                <span className="text-white text-xs m-2">
                                    + Add New
                                </span>
                            </button>
                        </div> 
                    </div>
                </div>
                
                {/* users list */}
                <div className="flex flex-col mb-10">
                    { users.active.map( user => 
                        <div 
                            key={user.id} 
                            className={`flex flex-row border-b border-b-dim-gray
                                ${ newUserId === user.id ? 'bg-dim-green' : 'bg-white'}
                            `}
                        >
                            <button 
                                className="flex flex-1 items-center h-14"
                                onClick={() => navigation.navigate('user_details', { state: { user: user } })}
                            >
                                <div className="flex flex-row items-center space-x-2 ml-4">
                                    { newUserId === user.id ? (
                                        <span className="text-red text-xs italic font-bold">* New</span>
                                    ) : ''}
                                    <span className="text-sm">
                                        { user.name }
                                    </span>
                                </div>
                            </button>

                            <HiddenActionButtons
                                show={isEditMode}
                                buttons={ authUser.is_admin
                                    ? (user.deleteable ? ['promote', 'edit', 'ban', 'delete'] : ['promote', 'edit', 'ban'] )
                                    : user.deleteable ? ['edit', 'ban', 'delete'] : ['edit', 'ban'] 
                                }
                                disabled={showLoading ? ['*'] : []}
                                onPromote={() => makeAdmin(user.id)}
                                onEdit={() => navigation.navigate('user_form', { state: {
                                    user: user
                                } })}
                                onDelete={() => submitDelete(user.id)}
                                onBan={() => toggleRevoke(user.id, true)}
                            />
                        </div>
                    )}

                    { users.revoked.length !== 0 ? (
                        <div className="flex items-center bg-dim-white h-10 border-y border-y-dim-gray mt-3">
                            <span className="text-xs ml-3 font-bold text-red">
                                ပိတ်သိမ်းထားသောအကောင့်များ
                            </span>
                        </div>
                    ) : ''}
                    
                    { users.revoked.map( user => 
                        <div 
                            key={user.id} 
                            className="flex flex-row bg-dim-white border-b border-b-dim-gray"
                        >
                            <button 
                                className="flex flex-1 items-center h-14"
                                onClick={() => navigation.navigate('user_details', { state: { user } })}
                            >
                                <span className="text-sm ml-4">
                                    { user.name }
                                </span>
                            </button>

                            <HiddenActionButtons
                                show={isEditMode}
                                buttons={ user.deleteable ? ['edit', 'delete', 'restore'] : ['edit', 'restore'] }
                                disabled={showLoading ? ['*'] : []}
                                onEdit={() => navigation.navigate('user_form', { state: user })}
                                onDelete={() => submitDelete(user.id)}
                                onRestore={() => toggleRevoke(user.id, false)}
                            />
                        </div>
                    )}
                </div>

            </ScrollView>

        </StackScreen>
    );
};

export default UsersScreen;
