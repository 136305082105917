import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { useSelector } from "react-redux";
import { BaseInput } from "../../bases";

const DoubleInput = ({
    title,
    type,
    left,
    right,
    onPressEnterKey = () => null,
    onClick,
}) => {

    const isDesktop = useSelector( state => state.app.isDesktop );

    return (
        <div className="flex flex-col space-y-2">
            <span className="text-white text-xs">
                {title}
            </span>

            { isDesktop ? (
                <div className="flex flex-col">
                    <BaseInput
                        type={type}
                        className="h-11 pl-4 text-sm bg-white overflow-auto rounded-t placeholder:text-xs focus:outline-none"
                        placeholder={left.placeholder}
                        value={left.value}
                        onPressEnterKey={onPressEnterKey}
                        onChange={ text => left.onChange(text)}
                        {...left.props}
                    />
                    <div className="border-b border-b-dim-gray"></div>
                    <BaseInput
                        type={type}
                        className="h-11 pl-4 text-sm bg-white overflow-auto rounded-b placeholder:text-xs focus:outline-none"
                        placeholder={right.placeholder}
                        value={right.value}
                        onPressEnterKey={onPressEnterKey}
                        onChange={ text => right.onChange(text)}
                        {...right.props}
                    />
                    <div className="flex mt-1">
                        <button
                            className="bg-sky items-center justify-center rounded h-8 px-3"
                            onClick={onClick}
                        >
                            <div className="flex flex-row items-center justify-center space-x-1">
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                    color="white"
                                    size="xs"
                                />
                                <span className="text-white text-sm">Find</span>
                            </div>
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex flex-row h-11 bg-white rounded">
                    <BaseInput
                        type={type}
                        className="flex-1 pl-4 text-sm bg-white overflow-auto rounded-l placeholder:text-xs focus:outline-none"
                        placeholder={left.placeholder}
                        value={left.value}
                        onPressEnterKey={onPressEnterKey}
                        onChange={ text => left.onChange(text)}
                        {...left.props}
                    />
                    <div className="border-l border-l-dim-gray"></div>
                    <BaseInput
                        type={type}
                        className="flex-1 pl-4 text-sm bg-white overflow-auto placeholder:text-xs focus:outline-none"
                        placeholder={right.placeholder}
                        value={right.value}
                        onPressEnterKey={onPressEnterKey}
                        onChange={ text => right.onChange(text)}
                        {...right.props}
                    />
                    <button
                        className="px-3 bg-sky items-center justify-center rounded-r"
                        onClick={onClick}
                    >
                        <div className="flex flex-row items-center justify-center space-x-1">
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                color="white"
                                size="xs"
                            />
                            <span className="text-white text-sm">Find</span>
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
};

export default memo(DoubleInput);
