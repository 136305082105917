import axios from 'axios'
import Hasher from '../helper/hasher';

const apiUrl = process.env.REACT_APP_API_URL;

const axios_custom = axios.create({
    baseURL: apiUrl
});

axios_custom.interceptors.request.use( config => {

        const storedToken = localStorage.getItem('token');

        if (storedToken) {
            const hasher = new Hasher();
            const token = hasher.decrypt(storedToken);
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        
        return config;
        
    },
        error => {
        Promise.reject(error);
    }
);

export default axios_custom;