import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import ScrollView from '../components/ScrollView';
import ItemCard from '../components/ItemCard';
import DebtCard from '../components/DebtCard';
import SectionHeader from '../components/SectionHeader';
import { useSelector } from 'react-redux';
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemListFrame from '../components/ItemListFrame';
import CollateralCard from '../components/CollateralCard';

const ManageRequestScreen = () => {

    const fetchApi = useApiFetcher();
    
    const currentRoute = useSelector( state => state.router.currentRoute );
    const routeState = currentRoute.params?.state;

    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        id: routeState?.id ?? null,
        group: routeState?.group ?? null,
        sort_by: {
            type: 'requested_date',
            order: 'desc'
        }
    });

    const [showLoading, setShowLoading] = useState(false);

    const defaultResponse = {
        count: 0,
        items: [],

        count_by: {
            group: [],
            user: []
        }
    };
    
    //manage_requests
        const [manageRequestPage, setManageRequestPage] = useState(2);
        const [manage_requests, setManageRequests] = useState(defaultResponse);

        const endManageRequests = useMemo( () =>
            manage_requests.count === manage_requests.items.length
        , [manage_requests.items.length]);

    //debts
        const [debtPage, setDebtPage] = useState(2);
        const [debts, setDebts] = useState(defaultResponse);

        const endDebts = useMemo( () =>
            debts.count === debts.items.length
        , [debts.items.length]);

    //paid_debts
        const [paidDebtPage, setPaidDebtPage] = useState(2);
        const [paid_debts, setPaidDebts] = useState(defaultResponse)

        const endPaidDebts = useMemo( () => 
            paid_debts.count === paid_debts.items.length
        , [paid_debts.items.length]);

    //collaterals
        const [collateralPage, setCollateralPage] = useState(2);
        const [collaterals, setCollaterals] = useState(defaultResponse);

        const endCollaterals = useMemo( () =>
            collaterals.count === collaterals.items.length
        , [collaterals.items.length]);

    //paid_interests
        const [paidInterestPage, setPaidInterestPage] = useState(2);
        const [paid_interests, setPaidInterests] = useState(defaultResponse)

        const endPaidInterests = useMemo( () => 
            paid_interests.count === paid_interests.items.length
        , [paid_interests.items.length]);

    //api_fetch    
    useEffect( () => {

        //clear data
        setManageRequests(defaultResponse);
        setDebts(defaultResponse);
        setPaidDebts(defaultResponse);
        setCollaterals(defaultResponse);
        setPaidInterests(defaultResponse);
        
        fetchApi(
            'manage_requests', 
            {page: 1, ...search_filters}, 
            [], 
            setShowLoading, 
            setManageRequests
        );

        fetchApi(
            'debts', 
            {page: 1, ...search_filters}, 
            [], 
            setShowLoading, 
            setDebts
        );

        fetchApi(
            'paid_debts', 
            {page: 1, ...search_filters}, 
            [], 
            setShowLoading, 
            setPaidDebts
        );

        fetchApi(
            'collaterals', 
            {page: 1, ...search_filters}, 
            [], 
            setShowLoading, 
            setCollaterals
        );

        fetchApi(
            'paid_interests', 
            {page: 1, ...search_filters}, 
            [], 
            setShowLoading, 
            setPaidInterests
        );

        (manageRequestPage !== 2) && setManageRequestPage(2);
        (debtPage !== 2) && setDebtPage(2);
        (paidDebtPage !== 2) && setPaidDebtPage(2);
        (collateralPage !== 2) && setCollateralPage(2);
        (paidInterestPage !== 2) && setPaidInterestPage(2);

    }, [search_filters]);

    //methods 
        const loadMore = () => {
            if(!endManageRequests){
                fetchApi(
                    'manage_requests', 
                    {page: manageRequestPage, ...search_filters}, 
                    manage_requests.items, 
                    setShowLoading, 
                    setManageRequests, 
                    setManageRequestPage
                );
            }

            if(!endDebts && endManageRequests){
                fetchApi(
                    'debts', 
                    {page: debtPage, ...search_filters}, 
                    debts.items, 
                    setShowLoading, 
                    setDebts, 
                    setDebtPage
                );
            }

            if(!endPaidDebts && endDebts && endManageRequests){
                fetchApi(
                    'paid_debts', 
                    {page: paidDebtPage, ...search_filters}, 
                    paid_debts.items, 
                    setShowLoading, 
                    setPaidDebts, 
                    setPaidDebtPage
                );
            }

            if(!endCollaterals && endPaidDebts && endDebts && endManageRequests){
                fetchApi(
                    'collaterals', 
                    {page: collateralPage, ...search_filters}, 
                    collaterals.items, 
                    setShowLoading, 
                    setCollaterals, 
                    setCollateralPage
                );
            }

            if(!endPaidInterests && endCollaterals && endPaidDebts && endDebts && endManageRequests){
                fetchApi(
                    'paid_interests', 
                    {page: paidInterestPage, ...search_filters}, 
                    paid_interests.items, 
                    setShowLoading, 
                    setPaidInterests, 
                    setPaidInterestPage
                );
            }
        }

        const updateList = useCallback( (id, type) => {
            let setList;

            switch (type) {
                case 'item':
                    setList = setManageRequests;
                break;

                case 'debt':
                    setList = setDebts;
                break;
            
                case 'paid_debt':
                    setList = setPaidDebts;
                break;

                case 'collateral':
                    setList = setCollaterals;
                break;
            
                case 'paid_interest':
                    setList = setPaidInterests;
                break;
            }

            setList( prev => {
                const newData = {...prev};
                newData.count = prev.count - 1;
                newData.items = prev.items.filter( e => e.id !== id );
                return newData;
            })

        }, [])
    
    //computed
        const editRequests = useMemo( () => manage_requests.items.filter( e => e.request_type === 'edit') , [manage_requests]);
        const deleteRequests = useMemo( () => manage_requests.items.filter( e => e.request_type === 'delete') ,[manage_requests]);
        const putBackRequests = useMemo( () => manage_requests.items.filter( e => e.request_type === 'putback') ,[manage_requests]);

        const totalCountByUser = useMemo(() => {
            let countByUser = [];

            if(manage_requests.count_by.user.length !== 0 && 
                debts.count_by.user.length !== 0 && 
                paid_debts.count_by.user.length !== 0 &&
                collaterals.count_by.user.length !== 0 && 
                paid_interests.count_by.user.length !== 0
            ){
                countByUser = manage_requests.count_by.user.map( e => {
                    return {
                        id: e.id,
                        count: 
                            e.count + 
                            debts.count_by.user.find( g => g.id === e.id).count + 
                            paid_debts.count_by.user.find( g => g.id === e.id).count +
                            collaterals.count_by.user.find( g => g.id === e.id).count + 
                            paid_interests.count_by.user.find( g => g.id === e.id).count
                    }
                })
            }

            return countByUser;
        }, [manage_requests.count_by, debts.count_by, paid_debts.count_by, collaterals.count_by, paid_interests.count_by]);
   
    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="users"
                searchOnNavChange={false}
                enableAllSearch={true} 
                enabledSearches={['date', 'id', 'word']}
                countBy={totalCountByUser}
            />

            <ItemListFrame
                enabledSortTypes={['id', 'requested_date']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ manage_requests.count + debts.count + paid_debts.count + collaterals.count + paid_interests.count }
                countUnit="ခု"
                isEnd={
                    endManageRequests && 
                    endDebts && 
                    endPaidDebts && 
                    endCollaterals && 
                    endPaidInterests && 
                    (manage_requests.count + debts.count + paid_debts.count + collaterals.count + paid_interests.count !== 0)}
            >
                <div className="flex flex-col mt-3 space-y-5">
                    { (editRequests.length !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="ပစ္စည်းစာရင်းပြင်ရန်"
                                color="blue"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {editRequests.map( data => 
                                    <div key={data.id} className="flex flex-row flex-wrap justify-center w-full">
                                        <div className="w-full lg:w-5/12">
                                            {/* old_card */}
                                            <ItemCard 
                                                item={data.item}
                                                displayMode="all"
                                                details={{
                                                    show: ['requested'],
                                                    header: 'requested',
                                                    requireSettingMode: false,
                                                }}
                                                traderWeight={{ show: false }}
                                                buttons={{
                                                    show: false,
                                                }}
                                            />
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <FontAwesomeIcon className="hidden lg:block" icon={faArrowRight}/>
                                            <FontAwesomeIcon className="block lg:hidden" icon={faArrowDown}/>
                                        </div>
                                        {/* new_card */}
                                        <div className="w-full lg:w-5/12">
                                            <ItemCard 
                                                onTakeAction={updateList}
                                                item={data}
                                                highlightedId={data.id}
                                                displayMode="all"
                                                details={{
                                                    show: [],
                                                    requireSettingMode: false,
                                                }}
                                                traderWeight={{ show: false }}
                                                buttons={{
                                                    show: true,
                                                    normal_buttons: [
                                                                        {
                                                                            id: 1,
                                                                            name: 'ပယ်ဖျက်',
                                                                            color: 'gray',
                                                                            onClick: {
                                                                                action: 'reject',
                                                                                params: {
                                                                                    id: data.id,
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            id: 2,
                                                                            name: 'ပြင်မယ်',
                                                                            color: 'blue',
                                                                            onClick: {
                                                                                action: 'update',
                                                                                params: {
                                                                                    id: data.id,
                                                                                }
                                                                            }
                                                                        }
                                                                    ],

                                                    setting_mode_buttons: []
                                                }}
                                            />
                                        </div>
                                    
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}

                    { (deleteRequests.length !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="ပစ္စည်းစာရင်းဖျက်ရန်"
                                color="red"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {deleteRequests.map( data => 
                                    <div key={data.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <ItemCard 
                                            key={data.id}
                                            onTakeAction={updateList}
                                            item={data.item}
                                            displayMode="all"
                                            details={{
                                                show: ['requested'],
                                                header: 'requested',
                                                requireSettingMode: false,
                                            }}                                          
                                            traderWeight={{ show: false }}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ပယ်ဖျက်',
                                                                        color: 'gray',
                                                                        onClick: {
                                                                            action: 'reject',
                                                                            params: {
                                                                                id: data.id,
                                                                            },
                                                                        }
                                                                    },
                                                                    {
                                                                        id: 2,
                                                                        name: 'ဖျက်မယ်',
                                                                        color: 'red',
                                                                        onClick: {
                                                                            action: 'delete',
                                                                            params: {
                                                                                id: data.item.id,
                                                                            },
                                                                        }
                                                                    }
                                                                ],

                                                setting_mode_buttons: []
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}

                    { (putBackRequests.length !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="ရောင်းပြီးပစ္စည်းစာရင်းပြန်ထည့်ရန်"
                                color="purple"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {putBackRequests.map( data => 
                                    <div key={data.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <ItemCard 
                                            key={data.id}
                                            onTakeAction={updateList}
                                            item={data.item}
                                            displayMode={data.item.status === 'attachment_only_sold' ? 'attachment_only' : 'without_attachment'}
                                            details={{
                                                show: ['sold', 'requested'],
                                                header: 'requested',
                                                requireSettingMode: false,
                                            }}
                                            traderWeight={{
                                                show: false,
                                                requireSettingMode: false,
                                            }}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ပယ်ဖျက်',
                                                                        color: 'gray',
                                                                        onClick: {
                                                                            action: 'reject',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        id: 2,
                                                                        name: 'စာရင်းပြန်ထည့်',
                                                                        color: 'purple',
                                                                        onClick: {
                                                                            action: 'putback',
                                                                            params: {
                                                                                id: data.item.id,
                                                                            }
                                                                        }
                                                                    }
                                                                ],

                                                setting_mode_buttons: []
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}

                    { (endManageRequests && debts.count !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="အကြွေးစာရင်းဖျက်ရန်"
                                color="orange"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {debts.items.map( data => 
                                    <div key={data.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <DebtCard 
                                            key={data.id}
                                            onTakeAction={updateList}
                                            data={data}
                                            type="debt"
                                            details={['requested']}
                                            showVoucher={true}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ပယ်ဖျက်',
                                                                        color: 'gray',
                                                                        onClick: {
                                                                            action: 'reject',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        id: 2,
                                                                        name: 'ဖျက်မယ်',
                                                                        color: 'red',
                                                                        onClick: {
                                                                            action: 'delete',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    }
                                                                ],

                                                setting_mode_buttons: []
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}

                    { (endManageRequests && endDebts && paid_debts.count !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="အကြွေးပေးထားသောစာရင်းဖျက်ရန်"
                                color="rose"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {paid_debts.items.map( data => 
                                    <div key={data.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <DebtCard 
                                            key={data.id}
                                            onTakeAction={updateList}
                                            data={data}
                                            type="paid_debt"
                                            details={['received', 'requested']}
                                            showVoucher={false}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ပယ်ဖျက်',                                                        
                                                                        color: 'gray',
                                                                        onClick: {
                                                                            action: 'reject',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        id: 2,
                                                                        name: 'ဖျက်မယ်',
                                                                        color: 'red',
                                                                        onClick: {
                                                                            action: 'delete',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    }
                                                                ],

                                                setting_mode_buttons: []
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}

                    { (endManageRequests && endDebts && endPaidDebts && collaterals.count !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="အပေါင်စာရင်းဖျက်ရန်"
                                color="sky"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {collaterals.items.map( data => 
                                    <div key={data.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <CollateralCard 
                                            key={data.id}
                                            onTakeAction={updateList}
                                            data={data}
                                            type="collateral"
                                            details={['requested']}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ပယ်ဖျက်',
                                                                        color: 'gray',
                                                                        onClick: {
                                                                            action: 'reject',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        id: 2,
                                                                        name: 'ဖျက်မယ်',
                                                                        color: 'red',
                                                                        onClick: {
                                                                            action: 'delete',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    }
                                                                ],

                                                setting_mode_buttons: []
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}

                    { (endManageRequests && endDebts && endPaidDebts && endCollaterals && paid_interests.count !== 0) ? (
                        <div className="flex flex-col">
                            <SectionHeader
                                title="အပေါင် အတိုးရှင်းထားသောစာရင်းဖျက်ရန်"
                                color="cyan"
                            />
                            <div className="flex-1 flex flex-row flex-wrap">
                                {paid_interests.items.map( data => 
                                    <div key={data.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                                        <CollateralCard 
                                            key={data.id}
                                            onTakeAction={updateList}
                                            data={data}
                                            type="paid_interest"
                                            details={['received', 'requested']}
                                            buttons={{
                                                show: true,
                                                normal_buttons: [
                                                                    {
                                                                        id: 1,
                                                                        name: 'ပယ်ဖျက်',                                                        
                                                                        color: 'gray',
                                                                        onClick: {
                                                                            action: 'reject',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        id: 2,
                                                                        name: 'ဖျက်မယ်',
                                                                        color: 'red',
                                                                        onClick: {
                                                                            action: 'delete',
                                                                            params: {
                                                                                id: data.id,
                                                                            }
                                                                        }
                                                                    }
                                                                ],

                                                setting_mode_buttons: []
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : ''}

                </div>

            </ItemListFrame>

            <ScrollView
                className="min-h-screen h-full bg-white pt-48"
                onEndReached={loadMore}
            >
            </ScrollView>
        </>
    )
}

export default ManageRequestScreen;