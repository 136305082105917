import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { notiPropsByStatus } from "../helper/notiProps";
import colors from "../helper/colors";
import { useDispatch, useSelector } from "react-redux";
import { faCircleCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { clearAlert } from "../store/reducers/app-reducer";

const Toast = () => {

    const alert = useSelector( state => state.app.alert );
    const currentRoute = useSelector( state => state.router.currentRoute );
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [progressBarWidthPercent, setProgressBarWidthPercent] = useState(100);

    useEffect(() => {
        setProgressBarWidthPercent(100);
        let notiCloseTimeOut;
        if (alert.msg && currentRoute.name !== 'login') {
            setShow(true);

            if(alert.timeout){
                //just to make some delay for showing progress bar animation
                setTimeout(() => {
                    setProgressBarWidthPercent(0);
                }, 100);

                notiCloseTimeOut = setTimeout(() => {
                    setShow(false);
                    setProgressBarWidthPercent(100);
                }, alert.timeout);
            }
        } else {
            setShow(false);
        }
    
        return () => {
            if (notiCloseTimeOut) {
                clearTimeout(notiCloseTimeOut);
            }
        };
    }, [alert]);

    const alertProps = useMemo( () => {
        if(alert.msg){
            const props = notiPropsByStatus.find( e => e.status === alert.status );
            const colorCode = colors.find( e => e.org === props.color).normal;
            return {
                icon: props.icon,
                colorCode: colorCode,
                color: props.color
            }
        } 
        return {
            icon: faCircleCheck,
            color: 'green'
        };
    }, [alert]);

    return (
        <div 
            className="fixed right-0 top-0 sm:w-96 w-full z-50 transition-position duration-700"
            style={{
                top: show ? '0%' : '-100%'
            }}
        >
            { (alert.msg && currentRoute.name !== 'login') ? (
                <div className="flex flex-col justify-between m-3 rounded backdrop-blur-sm bg-gray/80 min-h-12 overflow-hidden">
                    <div className="flex flex-row items-center flex-1 my-3">
                        <div className="flex items-center mx-3">
                            <FontAwesomeIcon
                                icon={alertProps.icon}
                                color={alertProps.colorCode}
                            />
                        </div>
                        <span className="flex items-center flex-1 text-dim-gray text-xs">
                            { alert.msg }
                        </span>
                        <button 
                            className="flex items-center mx-3"
                            onClick={() => dispatch(clearAlert())}
                        >
                            <FontAwesomeIcon
                                icon={faX}
                                color={ colors.find( e => e.org === 'gray' ).dim}
                                size="xs"
                            />
                        </button>
                    </div>
                    {/* timeout progress */}
                    <div className={`h-1 w-96 transition-width duration-1000 bg-${alertProps.color}`}
                        style={{
                            width: `${progressBarWidthPercent}%`,
                            transitionProperty: 'width',
                            transitionDuration: `${alert.timeout}ms`,
                            transitionTimingFunction: 'ease-in-out',
                        }}
                    />
                </div>
            ) : ''}
            
        </div>
    );
};

export default Toast;
