import { faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import useNavigation from "../../hooks/useNavigation";
import { useSelector } from "react-redux";
import routes from "../../router/routes";
import colors from "../../helper/colors";

//components
    const NavItem = memo(({
        title,
        icon,
        isActive,
        hightlightIconOnly,
        navigate,
        badgeCount
    }) => {
        const navigation = useNavigation();

        return (
            <button 
                className={`flex flex-row items-center h-12 rounded-lg 
                    ${ (!hightlightIconOnly && isActive) ? 'bg-gray' : ''}
                `}
                onClick={ () => navigation.navigate(navigate.to, navigate.parmas ?? {} )}
            >
                <div className="flex flex-row items-center space-x-3 h-full">
                    <div className={`flex relative items-center justify-center w-12 h-full rounded-lg
                        ${ (hightlightIconOnly && isActive) ? 'bg-gray' : ''}
                    `}>
                        <FontAwesomeIcon
                            icon={icon.name} 
                            color={icon.color}
                            size="lg"
                        />
                        {badgeCount && badgeCount !== 0 ? (
                            <div className="absolute top-0 right-0 flex items-center justify-center mt-1 bg-red h-4 rounded-full">
                                <span className="text-white text-2xs mx-1">
                                    {badgeCount}
                                </span>
                            </div>
                        ) : ''}
                    </div>
                    <span className="text-white text-sm">{title}</span>
                </div>
            </button>
        )
    });

const DesktopSideDrawer = ({ show, onClose }) => {

    const currentRoute = useSelector( state => state.router.currentRoute );
    const authUser = useSelector( state => state.app.authUser );

    return (
        <div 
            className={`fixed flex flex-row top-14 bottom-0 z-30 transition-position duration-300
                ${ show ? 'right-0' : '-right-44'} 
            `}
        >
            <div className="flex flex-col w-60">
                
                <div className="flex-1 overflow-auto bg-soft-black border-t border-black">

                    <div 
                        className="flex flex-col px-2 py-2 space-y-1"
                        style={{
                            borderBottomColor: 'gray',
                            borderBottomWidth: 0.4,
                        }}
                    >
                        <NavItem
                            title={authUser.name}
                            icon={{
                                name: faUser,
                                color: 'white'
                            }}
                            isActive={currentRoute.name === 'individual_sold'}
                            hightlightIconOnly={!show}
                            navigate={{
                                to: 'individual_sold',
                                parmas: {
                                    state: {
                                        user: authUser
                                    }
                                }
                            }}
                        />
                        { authUser.abilities.includes('add_new_items') ? (
                            <NavItem
                                title="ပစ္စည်းအသစ်ထည့်ရန်"
                                icon={{
                                    name: faPlus,
                                    color: colors.find( e => e.org === 'green').normal
                                }}
                                isActive={currentRoute.name === 'form'}
                                hightlightIconOnly={!show}
                                navigate={{ to: 'form' }}
                            />
                        ) : ''}
                    </div>
                    <div className="flex flex-col mx-2 mt-3 mb-5 space-y-3">
                        { routes.filter( e => e.showInDrawer ).map( (route) => (
                            (route.requireAbility === null || authUser.abilities.includes(route.requireAbility)) ? (
                                <NavItem
                                    key={route.id}
                                    title={route.title}
                                    icon={{
                                        name: route.icon,
                                        color: colors.find( e => route.color === e.org).normal
                                    }}
                                    isActive={currentRoute.id === route.id}
                                    hightlightIconOnly={!show}
                                    navigate={{ to: route.name }}
                                    badgeCount={null}
                                />
                            ) : ''
                        ))}
                        
                    </div>
                </div>
                
            </div>
            
        </div>
    )
};

export default memo(DesktopSideDrawer);
