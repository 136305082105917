import Popup from "reactjs-popup";
import { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import colors from "../../helper/colors";

const PopUpList = ({
    open,
    onClose,
    title,
    data,
    triggerClassName,
    textColor = "white",
    theme = "light",
    enableIcon = true,
    position = 'bottom center',
}) => {
    const themeColors = {
        arrow: theme === "light" ? colors.find( e => e.org === 'muted' ).normal : "gray",
        bg: theme === "light" ? "white" : "black",
        border: theme === "light" ? "dim-gray" : "gray",
        text: theme === "light" ? "black" : "dim-white",
        active: theme === "light" ? "dim-gray" : "gray",
    };

    const icon = position.includes('bottom') ? faCaretDown : faCaretUp;
    const popupRef = useRef();

    const dropDownHeight = useMemo(() => {
        const heightByItems = data.length * 56;
        const height = heightByItems > 392 ? 392 : heightByItems;
        return height;
    }, [data]);

    return (
        <Popup
            open={open}
            onClose={onClose}
            ref={popupRef}
            position={position}
            arrowStyle={{
                color: themeColors.arrow,
                opacity: '90%'
            }}
            trigger={
                <button className={triggerClassName}>
                    <div className="flex flex-row items-center space-x-1">
                        <span 
                            className={`text-xs min-w-6 text-${textColor}`} 
                        >
                            { title }
                        </span>
                        {enableIcon ? (
                            <FontAwesomeIcon
                                icon={icon}
                                size="xs"
                                color={
                                    colors.find((e) => e.org === textColor).normal
                                }
                            />
                        ) : ''}
                    </div>
                </button>
            }
        >
            {/* content */}
            <div
                className={`overflow-auto bg-soft-${themeColors.bg} rounded mx-1 opacity-95 border border-${themeColors.border}`}
                style={{
                    height: dropDownHeight,
                    minWidth: 100
                }}
            >
                <div className="flex flex-col">
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`flex flex-row h-14 px-3 space-x-2 items-center justify-center border-b border-b-${themeColors.border} last:border-none`}
                        >
                            <span className={`text-xs text-${themeColors.text}`}>
                                {item}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </Popup>
    );
};

export default PopUpList;