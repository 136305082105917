import React, { memo, useMemo } from 'react'
import { BaseDatePicker } from '../bases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DateParser from '../../helper/dateParser';

const DatePicker = ({
    title = '',
    value,
    onChange,
    highlightedDates = [],
    disableFutureDates = true,
    disablePastDates = false,
    disableDatesAfter = null,
    disableDatesBefore = null,
}) => {

    const dateParser = new DateParser();

    const changeDate = (action) => {
        const currentDate = value || new Date();
        if(action === 'up'){
            currentDate.setDate(currentDate.getDate() + 1);
        } else {
            currentDate.setDate(currentDate.getDate() - 1);
        }
        onChange(new Date(currentDate));
    }

    const maxDateReached = useMemo( () => {
        return value === '' || new Date(value).toDateString() === new Date().toDateString();
    }, [value])

    return (
        <div className="flex flex-col space-y-2">
            <span className="text-gray font-bold text-xs">
                { title }
            </span>
            <div className="flex flex-row overflow-hidden rounded border border-dim-gray h-10">
                <button
                    className="px-6 bg-white items-center justify-center rounded-l"
                    onClick={ () => changeDate('down')}
                >
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </button>
                <BaseDatePicker
                    value={value}
                    onPick={onChange}
                    width={270}
                    offsetX={0}
                    highlightedDates={highlightedDates}
                    disableFutureDates={disableFutureDates}
                    disablePastDates={disablePastDates}
                    disableDatesAfter={disableDatesAfter}
                    disableDatesBefore={disableDatesBefore}
                    trigger={
                        <button className="flex-1 flex justify-center items-center border-x border-x-dim-gray bg-white">
                            <span className="text-xs">
                                { value === '' ? 'Select Date' : dateParser.toString(value)}
                            </span>
                        </button>
                    }
                />
                <button
                    className={`px-6 items-center justify-center ${maxDateReached ? 'bg-muted' : 'bg-white' }`}
                    disabled={maxDateReached}
                    onClick={ () => changeDate('up')}
                >
                    <FontAwesomeIcon icon={faChevronRight} color={maxDateReached ? 'gray' : 'black'}/>
                </button>
            </div>
        </div>
    )
}

export default memo(DatePicker);