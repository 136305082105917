import { faBan, faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useMemo } from "react";

const HiddenActionButtons = ({
    show,
    buttons = [], // edit, delete, promote, ban, restore
    disabled = [],
    onEdit,
    onDelete,
    onPromote,
    onBan,
    onRestore
}) => {

    const maxWidth = useMemo( () => {
        return buttons.length * 3.5;
    }, [buttons]);

    const buttonProps = [
        {
            type: 'edit',
            text: 'Edit',
            color: 'blue',
            onClick: onEdit,
        },
        {
            type: 'delete',
            text: 'Delete',
            color: 'red',
            onClick: onDelete,
        },
        {
            type: 'promote',
            text: <FontAwesomeIcon icon={faCrown} color="black"/>,
            color: 'yellow',
            onClick: onPromote,
        },
        {
            type: 'ban',
            text: <FontAwesomeIcon icon={faBan} color="white"/>,
            color: 'soft-gray',
            onClick: onBan,
        },
        {
            type: 'restore',
            text: 'Restore',
            color: 'green',
            onClick: onRestore,
        },
    ]

    return (
        <div
            className="flex bg-yellow justify-end h-full overflow-hidden transition-width duration-300"
            style={{
                maxWidth: show ? `${maxWidth}rem` : '0rem'
            }}
         >
            {buttons.map( (button, index) => 
                <button
                    key={index}
                    className={`flex items-center justify-center h-full w-14
                        bg-${buttonProps.find( e => e.type === button ).color}
                    `}
                    onClick={buttonProps.find( e => e.type === button ).onClick}
                    disabled={disabled.includes(button) || disabled.includes('*')}
                >
                    <span className="flex text-white text-xs">
                        {buttonProps.find( e => e.type === button ).text}
                    </span>
                </button>
            )}
        </div>
    );
};

export default memo(HiddenActionButtons);
