import { memo, useMemo } from "react";
import { BaseInput } from "../bases";

const TagInput = ({
    title, //string
    type = 'text', //enum
    value,
    tagMinWidth = 55,
    errors = [],
    onChange, //func
    onPressEnterKey = () => null,
    warningMsges = [], //array
    tagLabel = 'Tag', //string
    placeholder = '', 
    tagPosition= 'end', //enum / start,end
    disabled = false,
}) => {

    const showError = useMemo( () => {
        return errors.some( e => e ? e.show : false );
    }, [errors]);

    return (
        <div className="flex flex-col space-y-2">

            { title ? (
                <span className="text-xs font-bold text-gray">{title}</span>
            ) : ''}

            <div className={`flex flex-row rounded bg-white border h-11
                ${showError ? 'border-red' : 'border-dim-gray' }
            `}>
                { (tagPosition === 'start') ? (
                    <div 
                        className="flex items-center justify-center bg-muted rounded-l"
                        style={{
                            minWidth: tagMinWidth
                        }}
                    >
                        <span className="text-xs text-black mx-3">
                            { tagLabel }
                        </span>
                    </div>
                ) : ''}
                <BaseInput 
                    type={type}
                    className={`flex-1 text-sm pl-3 placeholder:text-xs placeholder:text-dim-gray focus:outline-none w-full 
                        ${ tagPosition === 'end' ? 'rounded-l' : 'rounded-r'}
                        ${ disabled ? 'bg-soft-muted' : 'bg-white'}
                    `}
                    placeholder={placeholder}
                    value={value}
                    onPressEnterKey={onPressEnterKey}
                    onChange={onChange}
                    disabled={disabled}
                />
                { (tagPosition === 'end') ? (
                    <div className="flex items-center justify-center bg-muted rounded-r"
                        style={{
                            minWidth: tagMinWidth
                        }}
                    >
                        <span className="text-xs text-black mx-3">
                            { tagLabel }
                        </span>
                    </div>
                ) : ''}
            </div>
            
            { showError &&
                errors.map( (error, index) => 
                    error.show ? (
                        <div key={index} className="flex text-2xs text-red flex-row space-x-1">
                            <span>*</span>
                            <span className="text-2xs">{ error.msg }</span>
                        </div>
                    ) : ''
                )
            }
            { warningMsges.map( (msg, index) => 
                <div key={index} className="flex text-2xs text-gray flex-row space-x-1">
                    <span>*</span>
                    <span>{ msg }</span>
                </div>
            )}
        </div>
    )
}

export default memo(TagInput);