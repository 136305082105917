import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";

const ImagePreviewer = ({ 
    id,
    src, 
    removeable = true, 
    onRemove 
}) => {
    return (
        src ? (
            <div className="flex flex-row justify-between items-center">
                <div className="max-w-60">
                    <img src={src} className="flex-1 w-full" />
                </div>
                { removeable ? (
                    <div className="flex-1 flex items-center justify-center sm:justify-start sm:ml-10">
                        <button
                            className="border border-red w-8 h-8 rounded"
                            onClick={ () => onRemove(id)}
                        >
                            <FontAwesomeIcon icon={faX} color="red" />
                        </button>
                    </div>
                ) : ''}
            </div>
        ) : ''
    );
};

export default memo(ImagePreviewer);
