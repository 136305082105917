import React, { memo } from 'react'

const Collapsible = ({ children, mt = 0, collapsed, onClick, maxHeight = '130vh' }) => {

    const handleClick = () => {
        onClick?.();
    }

    return (
        <div 
            className={`overflow-hidden transition-all duration-300
                ${ onClick ? 'cursor-pointer' : ''}
            `}
            style={{
                maxHeight: collapsed ? '0vh' : maxHeight,
                marginTop: collapsed ? 0 : mt
            }}
            onClick={handleClick}
        >
            {children}
        </div>
    )
}

export default memo(Collapsible)