const AnimatedCard = ({ children, show, className }) => {

    return (
        <div 
            className={`${className} overflow-hidden w-full animate-fadeup transition-height duration-300`}
            style={{
                maxHeight: show ? '170vh' : '0vh'
            }}
        >
            { children }
        </div>
    );
};

export default AnimatedCard;
