import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../store/reducers/app-reducer";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import ItemCard from "../components/ItemCard";
import { Button } from "../components/inputs";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import NoticeBanner from "../components/NoticeBanner";
import useNavigation from "../hooks/useNavigation";

const EditRequestedScreen = () => {

    const { handleError } = useStoreHelper();
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const authUser = useSelector( state => state.app.authUser);
    const currentRoute = useSelector( state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const { old_item: oldItem, new_item: newItem } = routeState;

    const [isUpdating, setIsUpdating] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const submitUpdate = useCallback( () => {
        setIsUpdating(true);
        axios_custom.put('items/' + newItem.id)
        .then( (res) => {
            setIsUpdating(false);
            setIsUpdated(true);
            dispatch(setAlert({
                msg: res.data.msg,
                status: 'success',
                timeout: 3000
            }));

            //just for smooth goback
            setTimeout(() => {
                navigation.goBack();
            }, 1000);
        })
        .catch( (error) => {
            setIsUpdating(false);
            setIsUpdated(true);
            handleError(error)
        });
    }, []);

    return (
        <StackScreen
            renderRight={
                authUser.abilities.includes('manage_items') ? (
                    <Button
                        title="Confirm"
                        color="green"
                        activated={isUpdating}
                        position="Wait..."
                        onClick={submitUpdate}
                    />
                ) : ''
            }
            onBeforeGoBack={ () => {
                if(authUser.abilities.includes('manage_items') && !isUpdated){
                    if(window.confirm(`\nConfirm to edit?`)){
                        submitUpdate()
                    }
                }
            }}
        >
            { !authUser.abilities.includes('manage_items') ? (
                <NoticeBanner
                    title="Info"
                    icon={faCircleInfo}
                    color="sky"
                    msges={[
                        'စာရင်းပြင်ရန် Request လုပ်ပြီးပါပြီ။',
                        'Admin မှ approve လုပ်ပြီးမှသာ စာရင်းပြင်ပြီးဖြစ်ပါမည်။'
                    ]}
                />
            ) : ''}

            <ScrollView className="flex-1">
                
                <div className="flex flex-col my-2">

                    <div className="flex flex-row flex-wrap justify-center">
                        <div className="w-full lg:w-2/6 sm:w-5/12">
                            {/* old_card */}
                            <ItemCard
                                item={oldItem}
                                displayMode="all"
                                details={{
                                    show: [],
                                    requireSettingMode: false,
                                }}
                                traderWeight={{ show: false }}
                                buttons={{
                                    show: false,
                                }}
                            />
                        </div>
                        <div className="flex items-center justify-center">
                            <FontAwesomeIcon className="hidden sm:block" icon={faArrowRight}/>
                            <FontAwesomeIcon className="block sm:hidden" icon={faArrowDown}/>
                        </div>
                        <div className="w-full lg:w-2/6 sm:w-5/12">
                            {/* new_card */}
                            <ItemCard
                                item={newItem}
                                highlightedId={newItem.id}
                                displayMode="all"
                                details={{
                                    show: [],
                                    requireSettingMode: false,
                                }}
                                traderWeight={{
                                    show: true,
                                    type: 'inline',
                                    requireSettingMode: true,
                                }}
                                buttons={{
                                    show: false,
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-center h-16">
                        <span className="text-soft-gray text-sm font-bold">End of Results</span>
                    </div>
                </div>
            </ScrollView>

        </StackScreen>
    );
};

export default EditRequestedScreen;
