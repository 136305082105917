import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGroup, defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import { useSelector } from 'react-redux';
import ItemCard from '../components/ItemCard';
import ItemListFrame from '../components/ItemListFrame';

const IndividualSoldScreen = () => {

    const authUser = useSelector( state => state.app.authUser);
    const currentRoute = useSelector( state => state.router.currentRoute);
    const groups = useSelector( state => state.app.groups);
    const fetchApi = useApiFetcher();
    const routeState = currentRoute.params?.state;
    
    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        id: routeState?.id ?? null,
        group: routeState?.group ?? null,
        user: authUser.id,
        sort_by: {
            type: 'sold_date',
            order: 'desc'
        }
    });

    const [uncollected_items_only, setUncollectedItemsOnly] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [page, setPage] = useState(2);
    const [response, setResponse] = useState({
        count: 0,
        items: [],

        count_by: {
            group: [],
            user: []
        }
    });

    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === search_filters.group ) || defaultGroup
        }, [groups, search_filters.group]);

        const endResult = useMemo( () => response.count === response.items.length, [response.items.length]);

    //api_fetch
        useEffect( () => {
            setResponse({
                count: 0,
                items: [],
        
                count_by: {
                    group: [],
                    user: []
                }
            });
            fetchApi(
                'items', 
                {uncollected_items_only, page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setResponse
            );
            (page !== 2) && setPage(2);
        }, [search_filters, uncollected_items_only]);

    //methods
        const loadMore = () => {
            if(!endResult){
                fetchApi(
                    'items', 
                    {uncollected_items_only, page, ...search_filters}, 
                    response.items, 
                    setShowLoading, 
                    setResponse, 
                    setPage
                );
            }
        }

        const updateList = useCallback( (id) => {
            setResponse( prev => {
                const newData = {...prev};
                newData.count = prev.count - 1;
                newData.items = prev.items.filter( e => e.id !== id);
                return newData;
            })
        }, []);
    
    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="groups"
                searchOnNavChange={false}
                enableAllSearch={true} 
                enabledSearches={['id', 'date', 'word', 'price', 'customer', 'type', 'size', 'weight']}
                countBy={response.count_by.group}
            />

            <ItemListFrame
                enabledSortTypes={['id', 'price', 'added_date', 'sold_date']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ response.count }
                countUnit={ currentGroup.count_unit }
                isEnd={endResult && response.count !== 0}
                toggle={{
                    color: "teal",
                    value: uncollected_items_only,
                    left:{
                        value: true,
                        label: 'ငွေအပ်ရန်ကျန်'
                    },
                    right:{
                        value: false,
                        label: 'အားလုံး'
                    },
                    onChange: setUncollectedItemsOnly
                }}
            >
                <div className="flex-1 flex flex-row flex-wrap">
                    { response.items.map( item => 
                        <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                            <ItemCard 
                                key={item.id}
                                onTakeAction={updateList}
                                item={item}
                    
                                displayMode={item.status === 'attachment_only_sold' ? 'attachment_only' : 'without_attachment'}
                    
                                details={{
                                    show: authUser.abilities.includes('access_finance') ?  ['sold', 'collectable_money'] :  ['sold'],
                                    header: 'sold',
                                    requireSettingMode: false,
                                }}
                    
                                traderWeight={{
                                    show: true,
                                    type: 'inline',
                                    requireSettingMode: true,
                                }}
                    
                                buttons={{
                                    show: true,
                                    normal_buttons: [
                                                        {
                                                            id: 1,
                                                            name: 'စာရင်းပြန်ထည့်',
                                                            color: 'purple',
                                                            onClick: {
                                                                action: 'putback',
                                                                params: {
                                                                    id: item.id,
                                                                }
                                                            }
                                                        },
                                                    ],
                    
                                    setting_mode_buttons:[
                                                            {
                                                                id: 1,
                                                                name: 'Delete',
                                                                color: 'red',
                                                                onClick: {
                                                                    action: 'delete',
                                                                    params: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                id: 2,
                                                                name: 'စာရင်းပြန်ထည့်',
                                                                color: 'purple',
                                                                onClick: {
                                                                    action: 'putback',
                                                                    params: {
                                                                        id: item.id,
                                                                    }
                                                                }
                                                            },
                                                        ]
                                }}
                            />
                        </div>
                    )}
                </div>
            </ItemListFrame>
        </>
    )
}

export default IndividualSoldScreen;