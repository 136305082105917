import React, { memo, useEffect, useMemo, useState } from "react";
import ScrollView from "./ScrollView";
import PricingComponent from "./PricingComponent";
import ActivityIndicator from "./ActivityIndicator";
import { LabeledSwitch, PopUpPicker } from "./inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownLong, faArrowUpLong } from "@fortawesome/free-solid-svg-icons";

const availableSortTypes = [
    {
        id: 1,
        type: 'id',
        name: 'ID',
    },
    {
        id: 2,
        type: 'price',
        name: 'စျေးနှုန်း',
    },
    {
        id: 3,
        type: 'sold_date',
        name: 'ရောင်းသောနေ့',
    },
    {
        id: 4,
        type: 'added_date',
        name: 'စာရင်းထည့်သောနေ့',
    },
    {
        id: 5,
        type: 'requested_date',
        name: 'Request လုပ်သောနေ့',
    }
]

const ItemListFrame = ({
    onScroll,
    enabledSortTypes = [],
    sortByValue,
    onSortByChange,
    isLoading,
    isEnd,
    totalCount,
    countUnit,
    onEndReached,
    onPricingsChange,
    children,
    toggle,
    banner
}) => {

    const [order, setOrder] = useState('asc');
    const [currentSortTypeId, setCurrentSortTypeId] = useState(1);

    useEffect( () => {
        setOrder(sortByValue.order);
        setCurrentSortTypeId(availableSortTypes.find( e => e.type === sortByValue.type ).id)
    }, [sortByValue]);

    //computed
        const sortTypes = useMemo( () => {
            return availableSortTypes.filter( e =>  enabledSortTypes.includes(e.type) );
        }, [enabledSortTypes]);

    //methods
        const changeSortType = (id) => {
            onSortByChange({
                type: availableSortTypes.find( e => e.id === id ).type,
                order: order
            });
        };

        const changeOrder = () => {
            setOrder( prev => {
                onSortByChange({
                    type: availableSortTypes.find( e => e.id === currentSortTypeId ).type,
                    order: prev === 'asc' ? 'desc' : 'asc'
                });
                return prev === 'asc' ? 'desc' : 'asc';
            });
        }

    return (
        <ScrollView
            className="min-h-screen h-full bg-white pt-48 sm:pt-20"
            onEndReached={onEndReached}
            onScroll={onScroll}
        >
            <div className="flex flex-col">
                
                <div className="flex justify-center">
                    <PricingComponent onChange={onPricingsChange}/>
                </div>

                <div className="flex flex-row mt-5">
                    {/* spacer */}
                    <div className="sm:w-64 sm:mr-4 w-0"/>

                    {/* mid section, banner/ item counts / items / loader  */}
                    <div className="flex flex-col flex-1">

                        <div className="flex flex-col space-y-3">
                            { banner ? (
                                <div className="flex justify-center mx-2 my-3">
                                    { banner }
                                </div>
                            ) : ''}

                            <div className="flex items-center h-10 justify-between ml-2 mr-3">
                                <div>
                                    { sortTypes.length !== 0 ? (
                                        <div className="flex flex-row h-9 rounded-lg border border-muted overflow-hidden shadow">
                                            <div className="flex items-center border-r border-dim-gray bg-dim-white">
                                                <span className="text-xs text-soft-black font-bold mx-2">
                                                    Sort By
                                                </span>
                                            </div>
                                            <PopUpPicker
                                                data={sortTypes}
                                                value={currentSortTypeId}
                                                triggerClassName="mx-3"
                                                textColor="gray"
                                                nullable={false}
                                                enableIcon={true}
                                                onPick={changeSortType}
                                            />
                                            <button 
                                                className="flex border-l border-dim-gray bg-white"
                                                onClick={changeOrder}
                                            >
                                                <div className="flex flex-row items-center justify-center h-full space-x-1 mx-3">
                                                    <FontAwesomeIcon 
                                                        icon={ order === 'asc' ? faArrowDownLong : faArrowUpLong }
                                                        size="xs"
                                                    />
                                                    <span className="text-gray text-2xs">
                                                        { order.charAt(0).toUpperCase() + order.slice(1)}
                                                    </span>
                                                </div>
                                            </button>
                                        </div>
                                    ) : ''}
                                </div>
                                <div className="flex flex-row space-x-1 items-center">
                                    <span className="text-soft-black">{ totalCount }</span>
                                    <span className="text-red" >{ countUnit }</span>
                                </div>
                            </div>
                            
                            { toggle ? (
                                <div className="mx-2">
                                    <LabeledSwitch
                                        value={toggle.value}
                                        color={toggle.color}
                                        left={toggle.left}
                                        right={toggle.right}
                                        onChange={toggle.onChange}
                                    />
                                </div>
                            ) : ''}
                        </div>

                        {/* items */}
                        { (totalCount === 0 && !isLoading) ? (
                            <div className="flex items-center justify-center h-32">
                                <span className="text-soft-gray font-bold">No Results Found</span>
                            </div>
                        ) : (
                            <div className="flex flex-col space-y-5">
                                {children ?? ''}
                            </div>
                        )}

                        <div className="flex items-center justify-center h-16">
                            { isLoading ? (
                                <ActivityIndicator/>
                            ) : ''}
                            { isEnd ? (
                                <span className="text-soft-gray text-sm font-bold">End of Results</span>
                            ) : ''}
                        </div>

                    </div>
                    
                    {/* spacer */}
                    <div className="w-0 sm:mr-2 sm:w-16"/>
                </div>

            </div>
        </ScrollView>
    )
};

export default memo(ItemListFrame);
