import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    routeHistory: [],
    currentRoute: {
        id: 1,
        name: 'login',
        title: '',
        icon: '',
        color: '',
        screenType: 'stack',
        showInDrawer: false,
        requireAbility: null,
        backable: false,
        showHeader: false,
    },
};

const routerSlice = createSlice({
    name: "router",
    initialState,
    reducers: {
        setCurrentRoute: (state, action) => {
            state.currentRoute = action.payload;
        },
        setRouteHistory: (state, action) => {
            state.routeHistory = action.payload;
        }
    },
});

export const {
    setCurrentRoute,
    setRouteHistory
} = routerSlice.actions;

export default routerSlice.reducer;
