import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGroup, defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import { useSelector } from 'react-redux';
import ItemCard from '../components/ItemCard';
import useNavigation from '../hooks/useNavigation';
import ItemListFrame from '../components/ItemListFrame';
import ReportCard from '../components/ReportCard';
import DateParser from '../helper/dateParser';

const AddRecordScreen = () => {

    const dateParser = new DateParser();

    const currentRoute = useSelector( state => state.router.currentRoute);
    const groups = useSelector( state => state.app.groups);
    const navigation = useNavigation();
    const fetchApi = useApiFetcher();
    const routeState = currentRoute.params?.state;
    
    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        date: new Date(),
        id: routeState?.id ?? null,
        group: routeState?.group ?? null,
        sort_by: {
            type: 'added_date',
            order: 'desc'
        }
    });

    const [showLoading, setShowLoading] = useState(false);
    const [page, setPage] = useState(2);
    const [response, setResponse] = useState({
        count: 0,
        items: [],

        count_by: {
            group: [],
            user: []
        }
    });

    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === search_filters.group ) || defaultGroup
        }, [groups, search_filters.group]);

        const endResult = useMemo( () => response.count === response.items.length, [response.items.length]);

        const dateLabel = useMemo( () => {
            if(!search_filters.date){
                return '';
            }
            return new Date(search_filters.date).toDateString() === new Date().toDateString()
            ? 'ယနေ့'
            : `${dateParser.toString(search_filters.date)} တွင်`;
        }, [search_filters.date]);
        
    //api_fetch
        useEffect( () => {
            setResponse({
                count: 0,
                items: [],
        
                count_by: {
                    group: [],
                    user: []
                }
            });

            fetchApi('items', {page: 1, ...search_filters}, [], setShowLoading, setResponse);
            (page !== 2) && setPage(2);
        }, [search_filters]);

    //reload on navigate watcher
        useEffect( () => {
            if(routeState?.reload){
                setSearchFilters({...search_filters});
            }
        }, [routeState]);

    //methods
        const loadMore = () => {
            if(!endResult){
                fetchApi('items', {page, ...search_filters}, response.items, setShowLoading, setResponse, setPage);
            }
        }

        const updateList = useCallback( (id) => {
            setResponse( prev => {
                const newData = {...prev};
                newData.count = prev.count - 1;
                newData.items = prev.items.filter( e => e.id !== id);
                return newData;
            })
        }, []);

    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="groups"
                searchOnNavChange={false}
                enableAllSearch={true} 
                enabledSearches={['id', 'date', 'word', 'price', 'type', 'size', 'weight']}
            />

            <ItemListFrame
                enabledSortTypes={['id', 'price', 'added_date', 'sold_date']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ response.count }
                countUnit={ currentGroup.count_unit }
                isEnd={endResult && response.count !== 0}
                banner={ ( search_filters.date && !showLoading ) ? (
                    response.count === 0 ? (
                        <div className="flex items-center justify-center w-full max-w-80 rounded-xl bg-dim-milk border border-dashed">
                            <span className="text-blue text-xs font-bold py-6">
                                {dateLabel}စာရင်းထည့်ထားသောပစ္စည်းမရှိပါ။
                            </span>
                        </div>
                    ) : (
                        <div className="w-full sm:w-1/2">
                            <ReportCard
                                border="border border-dashed"
                                cardColor="milk"
                                rowLabelWidth="1/2"
                                rowJustify="between"
                                title={
                                    <div className="flex justify-center items-center my-1">
                                        <span className="text-sm text-sky font-bold">
                                            {dateLabel}စာရင်းထည့်သော ပစ္စည်းများ
                                        </span>
                                    </div>
                                }
                                rowData={[
                                    ...response.count_by.group.map( e => {
                                        return {
                                            label: 
                                                <span className={`text-xs text-soft-black font-bold text-${e.color}`}>
                                                    {groups.find( g => e.id === g.id).name}
                                                </span>,
                                            value: 
                                                <span className="text-xs text-soft-black">
                                                    { e.count }
                                                </span>
                                        }
                                    }),
                                    'divider',
                                    {
                                        label: 
                                            <span className="text-xs font-bold text-black">
                                                စုစုပေါင်း
                                            </span>,
                                        value: 
                                            <span className="text-xs text-soft-black">
                                                { response.count }
                                            </span>
                                    }

                                ]}
                            />
                        </div>
                    )
                ) : ''}
            >
                <div className="flex-1 flex flex-row flex-wrap">
                    { response.items.map( item => 
                        <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                            <ItemCard
                                key={item.id}
                                onTakeAction={updateList}
                                item={item}
                                displayMode="all"
                                details={{
                                    show: item.status === 'in_stock' ? ['added'] : ['added', 'sold'],
                                    header: item.status !== 'in_stock' ? 'sold' : '',
                                    requireSettingMode: false,
                                }}
                                traderWeight={{
                                    show: true,
                                    type: 'inline',
                                    requireSettingMode: true,
                                }}
                                buttons={{
                                    show: true,
                                    normal_buttons: [],
                                    setting_mode_buttons:[
                                                            {
                                                                id: 1,
                                                                name: 'Edit',
                                                                color: 'sky',
                                                                onClick: () => {
                                                                    navigation.navigate('form', {
                                                                        state: {
                                                                            id: item.id,
                                                                            item: item
                                                                        }
                                                                    })
                                                                }
                                                            },
                                                            {
                                                                id: 2,
                                                                name: 'Delete',
                                                                color: 'red',
                                                                onClick: {
                                                                    action: 'delete',
                                                                    params: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            }
                                                        ]
                                }}
                            />
                        </div>
                    )}
                </div>
            </ItemListFrame>
        </>
    )
}

export default AddRecordScreen;