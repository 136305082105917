module.exports = [
    {
        id: 1,
        org: 'yellow',
        bs: 'warning',
        dark: '#ca8a04',
        normal: '#eab308',
        soft: '#fde047',
        dim: '#fef9c3'
    },
    {
        id: 2,
        org: 'green',
        bs: 'success',
        dark: '#16a34a',
        normal: '#22c55e',
        soft: '#86efac',
        dim: '#dcfce7'
    },
    {
        id: 3,
        org: 'red',
        bs: 'danger',
        dark: '#dc2626',
        normal: '#dc3545',
        soft: '#fca5a5',
        dim: '#fee2e2'
    },
    {
        id: 4,
        org: 'blue',
        bs: 'primary',
        dark: '#2563eb',
        normal: '#3b82f6',
        soft: '#93c5fd',
        dim: '#dbeafe'
    },
    {
        id: 5,
        org: 'sky',
        bs: 'info',
        dark: '#0284c7',
        normal: '#0ea5e9',
        soft: '#7dd3fc',
        dim: '#e0f2fe'
    },
    {
        id: 6,
        org: 'purple',
        bs: 'purple',
        dark: '#9333ea',
        normal: '#a855f7',
        soft: '#d8b4fe',
        dim: '#f3e8ff'
    },
    {
        id: 7,
        org: 'pink',
        bs: 'pink',
        dark: '#db2777',
        normal: '#ec4899',
        soft: '#f9a8d4',
        dim: '#fce7f3'
    },
    {
        id: 8,
        org: 'orange',
        bs: 'orange',
        dark: '#ea580c',
        normal: '#f97316',
        soft: '#fdba74',
        dim: '#ffedd5'
    },
    {
        id: 9,
        org: 'teal',
        bs: 'teal',
        dark: '#0d9488',
        normal: '#14b8a6',
        soft: '#5eead4',
        dim: '#ccfbf1'
    },
    {
        id: 10,
        org: 'cyan',
        bs: 'cyan',
        dark: '#0891b2',
        normal: '#06b6d4',
        soft: '#67e8f9',
        dim: '#cffafe'
    },
    {
        id: 11,
        org: 'lime',
        bs: 'lime',
        dark: '#65a30d',
        normal: '#84cc16',
        soft: '#bef264',
        dim: '#ecfccb'
    },
    {
        id: 12,
        org: 'rose',
        bs: 'rose',
        dark: '#e11d48',
        normal: '#f43f5e',
        soft: '#fda4af',
        dim: '#ffe4e6'
    },
    {
        id: 13,
        org: 'black',
        bs: 'dark',
        dark: '#000000',
        normal: '#000000',
        soft: '#343a40',
        dim: '#343a40'
    },
    {
        id: 14,
        org: 'white',
        bs: 'light',
        dark: '#ffffff',
        normal: '#ffffff',
        soft: '#f8f9fa',
        dim: '#f8f9fa'
    }, 
    {
        id: 15,
        org: 'gray',
        bs: 'secondary',
        dark: '#4b5563',
        normal: '#6b7280',
        soft: '#9ca3af',
        dim: '#CFD4D9'
    },
    {
        id: 16,
        org: 'muted',
        bs: 'muted',
        dark: '#e5e7eb',
        normal: '#e5e7eb',
        soft: '#f3f4f6',
        dim: '#f3f4f6'
    },
    {
        id: 17,
        org: 'milk',
        bs: 'milk',
        dark: '#fef3c7',
        normal: '#ffffdd',
        soft: '#FFFFEA',
        dim: '#FFFFF8'
    },
    {
        id: 18,
        org: 'metal',
        bs: 'metal',
        dark: '#4D4D71',
        normal: '#565584',
        soft: '#9E9DCA',
        dim: '#C5C5E7'
    }
];
