import { memo, forwardRef, useState, useEffect } from "react";

const convertToEngNumbers = (numberString) => {
    const numbersMap = {
        '၀': '0',
        '၁': '1',
        '၂': '2',
        '၃': '3',
        '၄': '4',
        '၅': '5',
        '၆': '6',
        '၇': '7',
        '၈': '8',
        '၉': '9',
    };
    
    const numberArray = numberString.split('');
    const convertedNumberArray = numberArray.map( e => numbersMap[e] ?? e );
    const convertedNumberString = convertedNumberArray.join('');

    return convertedNumberString;
};
  
const BaseInput = ({
    type,
    value = '',
    disabled = false,
    onChange,
    onPressEnterKey = () => null,
    showZero = false,
    ...props
}, ref) => {

    const modifiedValue = value === 0 ? (
        showZero ? '0' : ''
    ) : (
        value ? value.toString() : ''
    );

    const extendedInputProps = {...props};
    delete extendedInputProps.type;
    delete extendedInputProps.inputMode;
    delete extendedInputProps.value;
    delete extendedInputProps.onChange;
    delete extendedInputProps.onKeyUp;

    const isNumberInput = ['number', 'decimal'].includes(type);
    const inputMode = isNumberInput ? (type === 'number' ? 'numeric' : 'decimal') : '';
    const [numberInputValue, setNumberInputValue] = useState(modifiedValue);

    useEffect( () => {
        onChange(convertToEngNumbers(numberInputValue))
    }, [numberInputValue]);

    //methods
        const handleKeyUp = e => {
            if(e.key === 'Enter'){
                onPressEnterKey?.();
            }
        }
        
        const handleOnChange = e => {
            const text = e.currentTarget.value;

            if(isNumberInput){
                const acceptCharRegex = /^[၀-၉0-9.]*$/;
                if (!text.match(acceptCharRegex)) {
                    return;
                }
    
                onChange(text);//require, to work perfectly with mm2en number convert
                setNumberInputValue(text);
            } else {
                onChange(text);
            }
        };

        return (
            <input
                ref={ref}
                type={ type === 'password' ? 'password' : 'text'}
                inputMode={inputMode}
                value={modifiedValue}
                readOnly={disabled}
                onChange={handleOnChange}
                onKeyUp={handleKeyUp}
                {...extendedInputProps}
            />
        )
}

export default memo(forwardRef(BaseInput));