import React, { memo, useCallback, useMemo, useState } from "react";
import axios_custom from "../../axios_config/axios_custom";
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/reducers/app-reducer';
import useStoreHelper from "../../hooks/useStoreHelper";
import Modal from './Modal';
import { Input } from "../inputs";

const PasswordResetModal = ({ show, id, onReset, onClose }) => {

    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    //inputs
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [errorCheckable, setErrorCheckable] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const inputErrors = useMemo( () => {
        const errors = [];

        password === '' && errors.push('password');
        confirmPassword === '' && errors.push('confirm_password');

        //check password match
        if(password !== '' && confirmPassword !== ''){
            if(password !== confirmPassword){
                errors.push('passwords_not_match');
            }
        }

        return errors;
    }, [password, confirmPassword]);

    //methods
        const close = useCallback( () => {
            setPassword('');
            setConfirmPassword('');
            setErrorCheckable(false);
            onClose();
        }, []);

        const submitReset = () => {
            setErrorCheckable(true);
                
            if(inputErrors.length === 0){
                setIsSubmitting(true);
    
                axios_custom.put(`users/${id}/change_password`, {
                    password: password,
                    confirm_password: confirmPassword
                })
                .then( res => {
                    setIsSubmitting(false);
                    dispatch(setAlert({
                        msg: res.data.msg,
                        status: 'success',
                        timeout: 3000
                    }));
                    onReset();
                    close();
                })
                .catch( error => {
                    setIsSubmitting(false);
                    handleError(error);
                });
            }
        }

    return (
        <Modal
            show={show}
            title="Password ပြောင်းရန်"
            titleColor="orange"
            submitButtonText="Reset"
            onClose={close}
            onSubmit={submitReset}
            isSubmitting={isSubmitting}
        >
            <div className="flex flex-col mx-3 my-5 space-y-4">
                <Input
                    title="New Password"
                    type="password"
                    value={password}
                    onChange={ text => setPassword(text)}
                    onPressEnterKey={submitReset}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('password'),
                            msg: 'Password အသစ်ထည့်ပါ!'
                        },
                        {
                            show: errorCheckable && inputErrors.includes('passwords_not_match'),
                            msg: 'Password ၂ ခု မညီပါ! ပြန်ရိုက်ပါ!'
                        },
                    ]}
                />
                <Input
                    title="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={ text => setConfirmPassword(text)}
                    onPressEnterKey={submitReset}
                    warningMsges={[
                        'အပေါ်မှာထည့်ခဲ့သည့် password အားပြန်ရိုက်ပါ။'
                    ]}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('confirm_password'),
                            msg: 'Password Confirm လုပ်ပါ!'
                        },
                        {
                            show: errorCheckable && inputErrors.includes('passwords_not_match'),
                            msg: 'Password ၂ ခု မညီပါ! ပြန်ရိုက်ပါ!'
                        },
                    ]}
                />
            </div>
        </Modal>
    );
};

export default memo(PasswordResetModal);
