const paperSizes = [
    {
        name: 'legal',
        label: 'Legal',
        mm: {
            width: 215.9,
            height: 355.6
        },
        ppi72: {
            width: 612,
            height: 1009
        }
    },
    {
        name: 'letter',
        label: 'US Letter',
        mm: {
            width: 215.9,
            height: 279.4
        },
        ppi72: {
            width: 612,
            height: 792
        }
    },
    {
        name: 'a4',
        label: 'A4',
        mm: {
            width: 210,
            height: 295
        },
        ppi72: {
            width: 595,
            height: 842
        }
    }
];

export default paperSizes;