import DateParser from "../../helper/dateParser";
import paperSizes from "../../helper/paperSizes";
import WeightStats from "../WeightStats";
import { memo, forwardRef } from "react";
        
const PairRow = ({label, value}) => {
    return (
        <div className="flex flex-row items-center">
            <div className="flex-1 flex justify-end items-center">
                <span className="text-xs">{ label }</span>
            </div>
            <span className="flex-1 flex justify-center items-center">:</span>
            <div className="flex-1 flex justify-start items-center">
                <span className="text-xs">{ value }</span>
            </div>
        </div>
    )
}

const SaleVoucherWithoutTax = ({
    paperSize,
    goldPrice,
    date,
    customer,
    item,
    saleType,
    hasDebt,
    hasTax,
    tradeInPrice,
    depositPrice,
    discountPrice,
    soldBy,
}, ref) => {

    const dateParser = new DateParser();

    const { width, height } = paperSizes.find( e => e.name === paperSize ).mm;
    const dateString = dateParser.toString(date);
    const suffix = item.category.suffix ? (saleType === 'attachment_only' ? '(အတွဲသီးသန့်)' : `(${item.category.suffix})` ) : '';
    const weight = saleType === "attachment_only" ? <WeightStats total={item.attachment_weight}/> : <WeightStats total={item.net_weight}/>;
    const serviceWeight = saleType === "attachment_only" ? <WeightStats total={item.attachment_service_weight}/> : <WeightStats total={item.service_weight}/>;
    const gemWeight = <WeightStats total={item.gem_weight}/>;

    const costPrice = saleType === "attachment_only" ? item.attachment_price : item.price; 
    const netPrice = costPrice - item.gem_price - item.category.base_price - item.category.additional_price;

    const price = saleType === "attachment_only" ? item.attachment_price : item.price;
    const taxPrice = hasTax ? Math.round((price * item.quality.tax_percent) / 100) : 0;

    const finalPrice = (costPrice + taxPrice) - tradeInPrice - depositPrice - discountPrice;
    const finalLabel = finalPrice < 0 ? 'ပိုငွေ' : (hasDebt ? 'ကျန်ငွေ' : 'စုစုပေါင်း သင့်ငွေ');

    return (
        <div 
            ref={ref} 
            className="flex flex-col bg-white" 
            style={{width: `${width}mm`, height: `${height}mm`}}
        >
            <div 
                className="flex flex-row justify-center bg-white" 
                style={{width: `${width}mm`, height: `${height}mm`}}
            >
                {/* A5 size */}
                <div 
                    style={{ width: '149mm', height: '210mm'}}
                >
                    <div className="flex flex-col mt-8 mx-5 space-y-8">
                        
                        {/* Customer and Item details */}
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col space-y-4 justify-end">
                                <span className="text-xs">အမည် - { customer.name }</span>
                                <span className="text-xs">နေရပ် - { customer.address }</span>
                                <span className="text-xs">ဖုန်း - { customer.phone }</span>
                            </div>
                            <div className="flex flex-col items-end space-y-4">
                                <span className="font-bold"># ID - { item.serial }</span>
                                <span className="text-xs">နေ့စွဲ - { dateString }</span>
                                <span className="text-xs">ရွှေစျေးနှုန်း - { new Intl.NumberFormat().format(goldPrice) }</span>
                                <span className="text-xs">ရွှေရည် - {item.quality.name}</span>
                            </div>
                        </div>

                        <div className="flex flex-row justify-center">
                            <span className="text-xs">{ item.name } { suffix } </span>
                        </div>

                        <div className="flex flex-col space-y-6 w-full">

                            <div className="space-y-3">
                                <PairRow label="အသားတင်ရွှေချိန်" value={weight}/>
                                <PairRow label="အလျော့တွက်" value={serviceWeight}/>
                                { item.has_gem ? <PairRow label="ကျောက်ချိန်" value={gemWeight}/> : ''}
                            </div>

                            <div className="space-y-3">
                                <PairRow label="ရွှေဖိုး + အလျော့တွက်" value={ new Intl.NumberFormat().format(netPrice)}/>
                                { item.category.base_price ? <PairRow label="လက်ခ" value={`+ ${new Intl.NumberFormat().format(item.category.base_price)}`} /> : '' }
                                { item.has_gem ? <PairRow label="ကျောက်ဖိုး" value={`+ ${new Intl.NumberFormat().format(item.gem_price)}`} /> : '' }
                                { item.category.additional_price ? <PairRow label={item.category.additional_price_name} value={`+ ${new Intl.NumberFormat().format(item.category.additional_price)}`} /> : '' }
                            </div>

                            {/* divider */}
                            <div className="border-b border-gray"></div>

                            <div className="space-y-3">
                                <PairRow label="သင့်ငွေ" value={new Intl.NumberFormat().format(costPrice)}/>
                                { tradeInPrice ? <PairRow label="ပေးရွှေ" value={`- ${new Intl.NumberFormat().format(tradeInPrice)}`} /> : '' }
                                { depositPrice ? <PairRow label="စရံငွေ" value={`- ${new Intl.NumberFormat().format(depositPrice)}`} /> : '' }
                                { discountPrice ? <PairRow label="လျှော့" value={`- ${new Intl.NumberFormat().format(discountPrice)}`} /> : '' }
                            </div>

                            {/* divider */}
                            <div className="border-b border-gray"></div>

                            <div>
                                <span className="font-bold">
                                    <PairRow label={finalLabel} value={new Intl.NumberFormat().format(Math.abs(finalPrice))}/>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(forwardRef(SaleVoucherWithoutTax));