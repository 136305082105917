import React, { useEffect, useState } from "react";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import { Button, HiddenActionButtons } from "../components/inputs";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import NoticeBanner from "../components/NoticeBanner";
import useNavigation from "../hooks/useNavigation";
import ActivityIndicator from "../components/ActivityIndicator";
import { useSelector } from "react-redux";

const GroupsScreen = () => {

    const { handleError, getGroups } = useStoreHelper();
    const navigation = useNavigation(); 
    const currentRoute = useSelector( state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const newGroupId = routeState?.newGroupId ?? null;

    const [groups, setGroups] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    const [showLoading, setShowLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    
    useEffect( () => {
        setShowLoading(true);

        const controller = new AbortController();
        const signal = controller.signal;

        axios_custom.get('groups', { signal })
        .then( (res) => {
            setShowLoading(false);

            let data = res.data.results;
            const newGroup = data.find( e => e.id === newGroupId );
            if(newGroup){
                data = [
                    newGroup,
                    ...data.filter( e => e.id !== newGroupId )
                ]
            } 
            setGroups(data);
        })
        .catch( (error) => {
            setShowLoading(false);
            handleError(error);
        });

        return () => {
            controller.abort();
        }
    }, []);

    //methods
        const submitDelete = (id) => {
            if(window.confirm('\nဖျက်မှာ သေချာပါသလား?')){
                setIsDeleting(true);
                axios_custom.delete('groups/' + id)
                .then( () => {
                    setGroups( prev => prev.filter( e => e.id !== id ));
                    setIsEditMode(false);
                    setIsDeleting(false);
                    getGroups();
                })
                .catch( error => {
                    setIsDeleting(false);
                    handleError(error);
                });
            }
        }

    return (
        <StackScreen
            renderRight={
                <Button
                    title={isEditMode ? 'Cancel' : 'Edit'}
                    color="blue"
                    onClick={ () => setIsEditMode( prev => !prev )}
                />
            }
        >
            <ScrollView className="flex flex-col flex-1 bg-soft-muted">

                <div className="flex flex-col mb-10">
                    <NoticeBanner
                        title="သတိပြုရန်"
                        icon={faCircleExclamation}
                        color="yellow"                                            
                        msges={[
                            'ပစ္စည်းထည့်ပြီးပါက ပြန်ဖျက်ခွင့်မပြုပါ။',
                            'ဖျက်လိုပါက ထိုပစ္စည်းအမျိုးအစားမှ ပစ္စည်းအားလုံးအား ဖျက် (or) အခြားအမျိုးအစားတစ်ခုသို့ ပြောင်းပေးရပါမည်။'
                        ]}
                    />

                    {/* header bar */}
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center px-3 justify-between h-14 bg-dim-white border-b border-b-dim-gray">
                            <div className="flex justify-start">
                                { showLoading ? (
                                    <ActivityIndicator size="sm"/>
                                ) : (
                                    <div className="flex flex-row space-x-1 items-center">
                                        <span className="text-red">
                                            { groups.length }
                                        </span>
                                        <span className="text-gray text-sm">
                                            ခု
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className="flex justify-end ">
                                <button 
                                    className="flex items-center justify-center rounded bg-green"
                                    onClick={ () => navigation.navigate('group_form') }
                                >
                                    <span className="text-white text-xs m-2">
                                        + Add New
                                    </span>
                                </button>
                            </div> 
                        </div>
                    </div>
                    
                    {/* groups list */}
                    <div className="flex flex-col">
                        { groups.map( group => 
                            <div 
                                key={group.id} 
                                className="bg-white border-b border-b-dim-gray"
                            >
                                <div className={`flex flex-row items-center h-14 ${
                                    newGroupId === group.id ? 'bg-dim-green' : 'bg-white'
                                }`}>
                                    <div 
                                        className="flex flex-1 items-center justify-start cursor-pointer h-full ml-4"
                                        onClick={() => navigation.navigate('categories', {
                                            state: {
                                                groupId: group.id
                                            }
                                        })}
                                    >
                                        <div className="flex flex-row space-x-2 items-center">
                                            { newGroupId === group.id ? (
                                                <span className="text-red text-xs italic font-bold">* New</span>
                                            ) : ''}
                                            <div className={`flex rounded bg-${group.color}`}>
                                                <span className="text-xs text-white mx-3 my-2">
                                                    { group.name }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* size / count_unit */}
                                    <div className="flex items-center justify-end h-full mr-4">
                                        <span className="text-soft-black text-2xs">
                                            { group.size_name ?? 'အရွယ်အစား'} | { group.count_unit }
                                        </span>
                                    </div>
                                    
                                    {/* hidden tools */}
                                    <HiddenActionButtons
                                        show={isEditMode}
                                        buttons={group.deleteable
                                            ? ['edit', 'delete']
                                            : ['edit']
                                        }
                                        disabled={isDeleting ? ['*'] : []}
                                        onEdit={() => navigation.navigate('group_form', {
                                            state: {
                                                group
                                            }
                                        })}
                                        onDelete={() => submitDelete(group.id)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </ScrollView>

        </StackScreen>
    );
};

export default GroupsScreen;
