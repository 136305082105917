import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { useSelector } from "react-redux";
import { PopUpPicker } from "../../inputs";
import { BaseInput } from "../../bases";

const DoubleInputWithOptions = ({
    title,
    type,
    left,
    right,
    onPressEnterKey = () => null,
    onClick,
}) => {

    const isDesktop = useSelector( state => state.app.isDesktop );

    return (
        <div className="flex flex-col space-y-2">
            <span className="text-white text-xs">
                {title}
            </span>

            { isDesktop ? (
                <div className="flex flex-col">

                    <div className="flex flex-row rounded-t overflow-hidden h-11">
                        <BaseInput
                            type={type}
                            className="flex-1 h-11 pl-4 text-sm bg-white overflow-auto placeholder:text-xs focus:outline-none"
                            placeholder={left.placeholder}
                            value={left.value}
                            onPressEnterKey={onPressEnterKey}
                            onChange={ text => left.onChange(text)}
                            {...left.props}
                        />
                        <PopUpPicker
                            position="top center"
                            triggerClassName="flex justify-center items-center px-2 bg-gray"
                            disabled={false}
                            data={left.option.data}
                            value={left.option.defaultValue}
                            onPick={ weightUnitId => left.option.onChange(weightUnitId)}
                        />
                    </div>
                    <div className="border-b border-b-dim-gray"></div>
                    <div className="flex flex-row rounded-b overflow-hidden">
                        <BaseInput
                            type={type}
                            className="flex-1 h-11 pl-4 text-sm bg-white overflow-auto placeholder:text-xs focus:outline-none"
                            placeholder={right.placeholder}
                            value={right.value}
                            onPressEnterKey={onPressEnterKey}
                            onChange={ text => right.onChange(text)}
                            {...right.props}
                        />
                        <PopUpPicker
                            position="top center"
                            triggerClassName="flex justify-center items-center px-2 bg-gray"
                            disabled={false}
                            data={right.option.data}
                            value={right.option.defaultValue}
                            onPick={ weightUnitId => right.option.onChange(weightUnitId)}
                        />
                    </div>
                    <div className="flex mt-1">
                        <button
                            className="bg-sky items-center justify-center rounded h-8 px-3"
                            onClick={onClick}
                        >
                            <div className="flex flex-row items-center justify-center space-x-1">
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                    color="white"
                                    size="xs"
                                />
                                <span className="text-white text-sm">Find</span>
                            </div>
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex flex-row h-11 bg-white rounded">
                    <BaseInput
                        type={type}
                        className="flex-1 pl-4 text-sm bg-white overflow-auto rounded-l placeholder:text-xs focus:outline-none"
                        placeholder={left.placeholder}
                        value={left.value}
                        onPressEnterKey={onPressEnterKey}
                        onChange={ text => left.onChange(text)}
                        {...left.props}
                    />
                    <PopUpPicker
                        position="top center"
                        triggerClassName="flex justify-center items-center px-2 bg-gray"
                        disabled={false}
                        data={left.option.data}
                        value={left.option.defaultValue}
                        onPick={ weightUnitId => left.option.onChange(weightUnitId)}
                    />
                    <BaseInput
                        type={type}
                        className="flex-1 pl-4 text-sm bg-white overflow-auto placeholder:text-xs focus:outline-none"
                        placeholder={right.placeholder}
                        value={right.value}
                        onPressEnterKey={onPressEnterKey}
                        onChange={ text => right.onChange(text)}
                        {...right.props}
                    />
                    <PopUpPicker
                        position="top center"
                        triggerClassName="flex justify-center items-center px-2 bg-gray"
                        disabled={false}
                        data={right.option.data}
                        value={right.option.defaultValue}
                        onPick={ weightUnitId => right.option.onChange(weightUnitId)}
                    />
                    <button
                        className="px-3 bg-sky items-center justify-center rounded-r"
                        onClick={onClick}
                    >
                        <div className="flex flex-row items-center justify-center space-x-1">
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                color="white"
                                size="xs"
                            />
                            <span className="text-white text-sm">Find</span>
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
};

export default memo(DoubleInputWithOptions);
