const useFormDataConverter = () => {

    const stringify = (formData) => {
        const obj = {};
        formData.forEach((value, key) => obj[key] = value);
        const jsonString = JSON.stringify(obj);

        return jsonString;
    }

    const parse = (jsonString) => {
        const obj = JSON.parse(jsonString);
        const formData = new FormData();
        for (const key in obj) {
            formData.append(key, obj[key]);
        }
        return formData;
    }

    return { stringify, parse }
}

export default useFormDataConverter;