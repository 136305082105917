import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { BaseInput } from "../../bases";

const SingleInput = ({ 
    title,
    value,
    type,
    onClick,
    onChange,
    onPressEnterKey = () => null,
    placeholder = ''
}) => {

    return (
        <div className="flex flex-col space-y-2">
            <span className="text-white text-xs">
                {title}
            </span>
            <div className="flex flex-row bg-white rounded h-11">
                <BaseInput
                    className="flex-1 pl-4 text-sm overflow-auto bg-white rounded-l placeholder:text-xs focus:outline-none"
                    type={type}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    onPressEnterKey={onPressEnterKey}
                />
                <button
                    className="px-3 bg-sky items-center justify-center rounded-r"
                    onClick={onClick}
                >
                    <div className="flex flex-row items-center justify-center space-x-1">
                        <FontAwesomeIcon icon={faMagnifyingGlass} color="white" size="xs"/>
                        <span className="text-white text-sm">Find</span>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default memo(SingleInput);