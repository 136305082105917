import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';

const CheckBoxList = ({ 
    containerClassName, 
    rowClassName, 
    onSelect,
    data,
    values
}) => {

    return (
        <div className={`flex flex-col ${containerClassName}`}>
            { data.map( (item, index) => 
                <button
                    key={index}
                    onClick={() => onSelect(item.id || item)}
                >
                    <div className={`flex flex-row border-b border-muted items-center rounded ${rowClassName} ${values.includes(item.id || item) && 'bg-dim-sky'}`}>
                        <div className="flex">
                            <div className={`flex items-center justify-center h-4 w-4 rounded border mx-5 ${values.includes(item.id || item) ? 'bg-blue border-blue' : 'border-gray '}`}>
                                <FontAwesomeIcon icon={faCheck} color="white" size="xs"/>
                            </div>
                        </div>
                        <span className="text-xs">{ item.name || Number(item) }</span>
                    </div>
                </button>
            )}
        </div>
    )
}

export default memo(CheckBoxList);