import { memo, useMemo } from "react";
import { BaseInput } from "../bases";

const Input = ({
    type = 'text',
    title, //string
    value,
    errors = [],
    step = '',
    warningMsges = [], //array
    onChange,
    placeholder = '',
    onPressEnterKey = () => null,
    disabled,
}) => {

    const showError = useMemo( () => {
        return errors.some( e => e ? e.show : false );
    }, [errors]);

    return (
        <div className="flex flex-col space-y-2">

            { title ? (
                <span className="text-xs font-bold text-gray">{title}</span>
            ) : ''}

            { type === 'textarea' ? (
                <textarea 
                    className={`text-sm pl-3 pt-3 placeholder: placeholder:text-xs placeholder:text-dim-gray focus:outline-none w-full h-11 rounded border 
                        ${showError ? 'border-red' : 'border-dim-gray' }
                        ${disabled ? 'bg-muted' : 'bg-white'}
                    `}
                    value={ value ? value.toString() : ''}
                    onChange={ e => onChange(e.currentTarget.value)}
                />
            ) : (
                <BaseInput 
                    type={type}
                    step={step}
                    className={`text-sm pl-3 placeholder:text-xs placeholder:text-dim-gray focus:outline-none w-full h-11 rounded border 
                        ${showError ? 'border-red' : 'border-dim-gray' }
                        ${disabled ? 'bg-muted' : 'bg-white'}
                    `}
                    placeholder={placeholder}
                    value={value}
                    onPressEnterKey={onPressEnterKey}
                    onChange={onChange}
                />
            )}
            
            { showError &&
                errors.map( (error, index) => 
                    error.show ? (
                        <div key={index} className="flex text-2xs text-red flex-row space-x-1">
                            <span>*</span>
                            <span>{ error.msg }</span>
                        </div>
                    ) : ''
                )
            }
            { warningMsges.map( (msg, index) => 
                <div key={index} className="flex text-2xs text-gray flex-row space-x-1">
                    <span>*</span>
                    <span>{ msg }</span>
                </div>
            )}
        </div>
    )
}

export default memo(Input);