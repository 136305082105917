import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGroup, defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import { useSelector } from 'react-redux';
import ItemCard from '../components/ItemCard';
import { AddTraderWeightModal } from '../components/modals';
import ItemListFrame from '../components/ItemListFrame';

const TraderWeightScreen = () => {

    const groups = useSelector( state => state.app.groups);
    const fetchApi = useApiFetcher();
    
    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        sort_by: {
            type: 'added_date',
            order: 'asc'
        },
    });

    const [showLoading, setShowLoading] = useState(false);
    const [page, setPage] = useState(2);
    const [response, setResponse] = useState({
        count: 0,
        items: [],

        count_by: {
            group: [],
            user: []
        }
    });

    //for modal
    const [targetItemId, setTargetItemId] = useState(null);
    const [showAddTraderWeightModal, setShowAddTraderWeightModal] = useState(false);

    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === search_filters.group ) || defaultGroup
        }, [groups, search_filters.group]);

        const endResult = useMemo( () => response.count === response.items.length, [response.items.length]);

    //api_fetch
        useEffect( () => {
            setResponse({
                count: 0,
                items: [],
        
                count_by: {
                    group: [],
                    user: []
                }
            });
            fetchApi(
                'items', 
                {page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setResponse
            );
            (page !== 2) && setPage(2);
        }, [search_filters]);

    //methods
        const loadMore = () => {
            if(!endResult){
                fetchApi(
                    'items', 
                    {page, ...search_filters}, 
                    response.items, 
                    setShowLoading, 
                    setResponse,
                    setPage
                );
            }
        }

        const openAddTraderWeightModal = useCallback( id => {
            setTargetItemId(id);
            setShowAddTraderWeightModal(true);
        }, []);

        const closeAddTraderWeightModal = useCallback( () => {
            setShowAddTraderWeightModal(false);
        }, []);

        const onTraderWeightAdded = useCallback( (addedItemId) => {
            closeAddTraderWeightModal();

            //update list
            setResponse(prev => {
                const newSet = {...prev};
                newSet.count = newSet.count - 1;
                newSet.items = newSet.items.filter(e => e.id !== addedItemId);
                return newSet;
            });
        }, []);
        
    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="groups"
                searchOnNavChange={false}
                enableAllSearch={true} 
                enabledSearches={['id', 'word', 'price', 'type', 'size', 'weight']}
                countBy={response.count_by.group}
            />

            <ItemListFrame
                enabledSortTypes={['id', 'price', 'added_date']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ response.count }
                countUnit={ currentGroup.count_unit }
                isEnd={endResult && response.count !== 0}
            >
                <div className="flex-1 flex flex-row flex-wrap">
                    { response.items.map( item => 
                        <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                            <ItemCard
                                key={item.id}
                                item={item}

                                displayMode="all"

                                details={{
                                    show: ['added'],
                                    requireSettingMode: true,
                                }}
                                
                                traderWeight={{
                                    show: true,
                                    type: 'inline',
                                    requireSettingMode: true,
                                }}

                                buttons={{
                                    show: true,
                                    normal_buttons:[
                                                        {
                                                            id: 1,
                                                            name: 'အလျော့ထည့်မယ်',
                                                            color: 'soft-gray',
                                                            onClick: () => openAddTraderWeightModal(item.id)
                                                        }
                                                    ],

                                    setting_mode_buttons:[]
                                }}
                            />
                        </div>
                    )}
                </div>
            </ItemListFrame>

            <AddTraderWeightModal
                show={showAddTraderWeightModal}
                id={targetItemId}
                onAdded={onTraderWeightAdded}
                onClose={closeAddTraderWeightModal}
            />
        </>
    )
}

export default TraderWeightScreen;