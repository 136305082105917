
import React, { useEffect, useState } from "react";
import logo from "../assets/system_images/hsu_logo_long.png";
import axios_custom from "../axios_config/axios_custom";
import { notiPropsByStatus } from "../helper/notiProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser, unauthenticateUser, clearAlert } from '../store/reducers/app-reducer';
import useStoreHelper from "../hooks/useStoreHelper";
import { SubmitButton } from "../components/inputs";
import Hasher from "../helper/hasher";
import colors from "../helper/colors";
import { BaseInput } from "../components/bases";

const LoginScreen = () => {

    const hasher = new Hasher();

    const alert = useSelector( state => state.app.alert );
    const dispatch = useDispatch();
    const { getGroups, handleError } = useStoreHelper();
    
    const [errorCheckable, setErrorCheckable] = useState(false);
    const [isLogging, setIsLogging] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect( () => {
        const metaTag = document.querySelector('meta[name="theme-color"]');
        const backgroundColor = colors.find( e => e.org === 'yellow').dim;
        if (metaTag) {
            metaTag.setAttribute('content', backgroundColor);
        } else {
            const newMetaTag = document.createElement('meta');
            newMetaTag.name = 'theme-color';
            newMetaTag.content = backgroundColor;
            document.head.appendChild(newMetaTag);
        }
    }, []);

    //methods
        const login = () => {
            setErrorCheckable(true);

            if (username !== '' && password !== '') {
                setIsLogging(true);

                axios_custom.post('login', { username, password })
                .then((res) => {
                    const encryptedToken = hasher.encrypt(res.data.token);
                    const encryptedUser = hasher.encrypt(JSON.stringify(res.data.user));

                    localStorage.setItem('token', encryptedToken);
                    localStorage.setItem('user', encryptedUser);

                    setIsLogging(false);
                    dispatch(authenticateUser(res.data.user));
                    dispatch(clearAlert());
                    getGroups();
                })
                .catch( error => {
                    setIsLogging(false);
                    dispatch(unauthenticateUser());
                    handleError(error);
                });
            }
        }

    return (
        <div className="flex flex-1 justify-center items-end bg-dim-yellow sm:items-center">

            <div className="flex flex-col items-center w-full sm:w-96">
                <div className="flex justify-center w-full">
                    <img
                        className="-mb-14"
                        src={logo}  
                        width={300}
                        height={300}
                        alt="Logo"
                    />
                </div>

                {/* white form card */}
                <div className="flex-1 flex flex-col bg-white/70 backdrop-blur shadow-t shadow w-full rounded-t-3xl sm:rounded-3xl">
                    {/* form */}
                    <div className="flex-1 flex flex-col m-5 space-y-6">

                        <div>
                            {alert.msg !== null ? (
                                <div className="flex flex-row justify-center items-center space-x-2 mb-4">
                                    <FontAwesomeIcon
                                        icon={
                                            notiPropsByStatus.find(
                                                (e) => e.status === alert.status
                                            ).icon
                                        }
                                        color={
                                            notiPropsByStatus.find(
                                                (e) => e.status === alert.status
                                            ).color
                                        }
                                    />
                                    <span className="font-bold text-black text-sm">
                                        {alert.msg}
                                    </span>
                                </div>
                            ) : ''}
                        </div>

                        {/* Username */}
                        <div>
                            <div className="flex flex-row items-center justify-between">
                                <span className="font-bold text-sm">Username</span>
                                {errorCheckable && username === '' ? (
                                    <span className="text-red text-xs">
                                        Username ထည့်ပါ!
                                    </span>
                                ) : ''}
                            </div>
                            <div className="flex flex-row items-center border-b border-gray space-x-3">
                                <FontAwesomeIcon icon={faUser} />
                                <BaseInput
                                    type="text"
                                    value={username}
                                    className="bg-white text-sm bg-opacity-0 py-4 flex-1 focus:outline-none placeholder:text-xs"
                                    placeholder="Enter Username"
                                    onPressEnterKey={login}
                                    onChange={ text => setUsername(text.toLowerCase())}
                                />
                            </div>
                        </div>

                        {/* password */}
                        <div>
                            <div className="flex flex-row items-center justify-between">
                                <span className="font-bold text-sm">Password</span>
                                {errorCheckable && password === '' ? (
                                    <span className="text-red text-xs">
                                        Password ထည့်ပါ!
                                    </span>
                                ) : ''}
                            </div>

                            <div className="flex flex-row items-center border-b border-gray space-x-3">
                                <FontAwesomeIcon icon={faKey} />
                                <BaseInput
                                    type="password"
                                    value={password}
                                    className="bg-white text-sm bg-opacity-0 py-4 flex-1 focus:outline-none placeholder:text-xs"
                                    placeholder="Enter Password"
                                    onPressEnterKey={login}
                                    onChange={setPassword}
                                />
                            </div>
                        </div>

                        {/* button */}
                        <div className="flex flex-row">
                            <SubmitButton
                                radius="rounded-xl"
                                color="soft-yellow"
                                activated={isLogging}
                                activatedText="Logging In..."
                                activatedColor="dim-yellow"
                                title="Log In"
                                textColor="black"
                                textBold={true}
                                onClick={login}
                            />
                        </div>
                    </div>

                    {/* details footer */}
                    <div className="flex flex-col items-center my-8">
                        <div className="flex flex-row space-x-2">
                        <span className="text-soft-gray font-ballpark text-lg">
                            Modern Charm
                        </span>
                        <span className="text-2xs text-soft-gray font-semibold"
                            style={{
                                marginTop: 7.5
                            }}
                        >
                            v3.2.7
                        </span>
                        </div>
                        <span className="text-soft-gray text-2xs">
                            Copyright &copy; {new Date().getFullYear()} All Rights Reserved
                        </span>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default LoginScreen;
