import { memo } from "react";
import { BaseInput } from "../bases";

const CounterInput = ({
    title, //string
    value,
    placeholder = '',
    warningMsges = [], //array
    onChange,
    onPressEnterKey = () => null,
    disabled = false,
    min,
    max,
}) => {

    return (
        <div className="flex flex-col space-y-2">
            <span className="text-xs font-bold text-gray">
                {title}
            </span>
            <div className="flex flex-row space-x-2 items-center h-10">
                <BaseInput
                    type="number"
                    value={value}
                    showZero={true}
                    disabled={disabled}
                    placeholder={placeholder}
                    onPressEnterKey={onPressEnterKey}
                    onChange={ text => onChange(Number(text))}
                    className="pl-3 h-full text-sm w-20 focus:outline-none border border-dim-gray rounded"
                />
                <button
                    className={`flex items-center justify-center h-9 w-9 rounded-full ${
                        value <= min ? "bg-dim-gray" : "bg-gray"
                    }`}
                    onClick={() => onChange(value - 1)}
                    disabled={(value <= min) || disabled}
                >
                    <span className="text-white">-</span>
                </button>
                <button
                    className={`flex items-center justify-center h-9 w-9 rounded-full ${
                        value >= max ? "bg-dim-gray" : "bg-gray"
                    }`}
                    onClick={() => onChange(value + 1)}
                    disabled={(value >= max) || disabled}
                >
                    <span className="text-white">+</span>
                </button>
            </div>
            
            { (value < min || value > max) ? (
                <div className="flex text-2xs text-red flex-row space-x-1">
                    <span>*</span>
                    <span>
                        {`Limit exceeded! Only Allowed ${min} to ${max}!`}
                    </span>
                </div>
            ) : ''}

            { warningMsges.map( (msg, index) => 
                <div key={index} className="flex text-2xs text-gray flex-row space-x-1">
                    <span>*</span>
                    <span>{ msg }</span>
                </div>
            )}

        </div>
    );
};

export default memo(CounterInput);
