import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo } from 'react'

const Modal = ({ 
    children, 
    show, 
    title,
    titlePosition = 'start',
    titleColor,
    submitButtonText,
    isSubmitting = false,
    onSubmit,
    onClose,
    size = 'sm',
    withCard = true,
    fixedTopBar,
    disableSubmit
}) => {
    return (
        <div
            className={`fixed flex flex-col items-center justify-center h-screen w-screen bg-black z-40 transition-all duration-500 
                ${ show ? 'bg-opacity-50' : 'bg-opacity-0' }`}
            style={{
                bottom: show ? '0%' : '-150%',
            }}
        >
            {/* card */}
            { withCard ? (
                <div className={`flex flex-col bg-white w-11/12 max-h-screen my-10 overflow-hidden rounded-lg ${ size === 'sm' ? 'max-w-96' : ''}`}>
                    
                    {/* header */}
                    <div className={`flex items-center border-b border-b-dim-gray h-14 justify-${titlePosition}`}>
                        <span className={`font-semibold text-sm mx-3 text-${titleColor}`}>{ title }</span>
                    </div>

                    {fixedTopBar}

                    {/* body */}
                    <div className="flex-1 overflow-auto">
                        {children}
                    </div>
                    
                    {/* footer */}
                    <div className="flex items-center justify-end border-t border-t-dim-gray h-14">
                        <div className="flex flex-row space-x-2 mx-3">

                            <button 
                                className="flex items-center justify-center bg-soft-gray rounded"
                                onClick={onClose}
                            >
                                <span className="text-xs text-white p-2">
                                    Cancel
                                </span>
                            </button>

                            { onSubmit ? 
                                isSubmitting ? (
                                    <button
                                        className="flex items-center justify-center bg-soft-green rounded"
                                        disabled={true}
                                    >
                                        <div className="flex flex-row items-center justify-center space-x-1 p-2">
                                            <FontAwesomeIcon 
                                                className="animate-spin-right"
                                                icon={faSpinner}
                                                color="white"
                                                
                                            />
                                            <span className="text-xs text-white">
                                                Please Wait...
                                            </span>
                                        </div>
                                    </button>
                                ) : (
                                    <button 
                                        className="flex items-center justify-center bg-green rounded"
                                        disabled={disableSubmit}
                                        onClick={onSubmit}
                                    >
                                        <span className="text-xs text-white py-2 px-4">
                                            { submitButtonText }
                                        </span>
                                    </button>
                                )
                            : ''}

                        </div>
                    </div>
                </div>
            ) : (
                children
            )}
        </div>
    )
}

export default memo(Modal)