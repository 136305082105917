import { memo } from "react";

const ProgressBar = ({completedPercent, color}) => {

    return (
        <div className="relative overflow-hidden bg-dim-gray w-full rounded-full h-1">
            <div 
                className={`absolute top-0 bottom-0 rounded-full transition-width duration-200 bg-${color}`}
                style={{
                    width: `${completedPercent}%`
                }}
            ></div>
        </div>
    );
};

export default memo(ProgressBar);
