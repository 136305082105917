import React from 'react';

const DateStats = ({ date }) => {

    const day = Number(date.day);
    const month = Number(date.month);
    const year = Number(date.year);

    return (
        <>
            { day + month + year === 0 ? (
                <span>0 ရက်</span>
            ) : (
                <div className="flex flex-row space-x-1">
                    { ( year !== 0 ) && ( <span>{year} နှစ်</span>) }
                    { ( month !== 0 ) && ( <span>{month} လ</span>) }
                    { ( day !== 0 ) && ( <span>{day} ရက်</span>) }
                </div>
            )}
        </>
    )
}

export default DateStats;