import React from "react";

const DarkModeSwitch = ({ value, onChange }) => {
    return (
        <div 
            className={`relative flex h-7 w-12 items-center cursor-pointer rounded-full transition-all duration-300 border-2 
                ${ value ? 'border-dark-gray bg-dark-gray' : 'border-sky bg-sky'}
            `}
            onClick={ () => onChange(!value)}
        >
            <div 
                className={`absolute h-6 w-6 rounded-full transition-all duration-300 shadow bg-white 
                ${ value ? 'right-0 bg-gradient-to-br from-dark-yellow to-soft-gray' : 'right-5 bg-gradient-to-bl from-yellow to-dim-yellow' }
            `}>
            </div>
        </div>
    );
};
                
export default DarkModeSwitch;