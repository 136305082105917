import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import imageCompression from "browser-image-compression";

const errorMsges = [
    {
        type: "exceed",
        msg: "ရွေးထားသောပုံအရေအတွက်သည် သတ်မှတ် limit ထက်ကျော်လွန်နေပါသည်! (သို့) ထည့်ရမည့်ပစ္စည်းအရေအတွက်ထက်များနေပါသည်!",
    },
    {
        type: "type",
        msg: "ရွေးထားသောပုံများထဲတွင် jpg (သို့) jpeg (သို့) png (သို့) gif format မဟုတ်သောပုံပါနေပါသည်!",
    },
    {
        type: "size",
        msg: "ရွေးထားသောပုံများထဲတွင် 8MB ထက်ကျော်သောပုံပါနေပါသည်!",
    },
];

const compressOptions = {
    maxSizeMB: 2,
    fileType: 'image/jpeg',
    useWebWorker: true
};

const ImagePicker = ({
    title, //string
    onPick, //func
    errors = [],
    warningMsges = [], //array
    pickableCount = 1, //num
    disabled = false,
    buttonLabel = "ပုံရွေးရန်နှိပ်ပါ",
    isPicking
}) => {
    
    const inputRef = useRef(null);
    const [pickedRawImages, setPickedRawImages] = useState([]);
    const [imageErrors, setImageErrors] = useState([]);
    const [compressedImages, setCompressedImages] = useState([]);
    const [hasCompressionError, setHasCompressionError] = useState(false);

    const showError = useMemo(() => {
        return errors.some((e) => (e ? e.show : false));
    }, [errors]);

    //seqential compress
    useEffect( () => {
        if(pickedRawImages.length !== 0){
            if((pickedRawImages.length !== compressedImages.length) && !hasCompressionError){
                
                const targetImage = pickedRawImages[compressedImages.length];
                imageCompression(targetImage, compressOptions)
                .then( (res) => {
                    setCompressedImages( prev => {
                        return [...prev, {
                            id: compressedImages.length + 1, 
                            file: res, 
                            src: URL.createObjectURL(res) 
                        }]
                    })
                })
                .catch( () => {
                    setHasCompressionError(true);
                })
            } else {
                //done
                isPicking?.(false);
                onPick(Number(pickableCount) === 1 ? compressedImages[0] : compressedImages);
                inputRef.current.value = null;
            }
        }
    }, [pickedRawImages, compressedImages, hasCompressionError]);

    //computed
        const compressedPercent = useMemo( () => {
            if(pickedRawImages.length === 0){
                return 0;
            }
            return Math.round((compressedImages.length / pickedRawImages.length) * 100);
        }, [compressedImages, pickedRawImages]);

    //methods
        const pickImage = (e) => {
            const rawImages = Array.from(e.currentTarget.files);
            
            //check error in rawImages 
                const errors = []; //exceed, type, size

                if (rawImages.length > Number(pickableCount)) {
                    errors.push("exceed");
                }

                rawImages.forEach((image) => {
                    const allowedTypes = [
                        "image/jpeg",
                        "image/jpg",
                        "image/png",
                        "image/gif",
                    ];
                    if (!allowedTypes.includes(image.type) &&!errors.includes("type")) {
                        errors.push("type");
                    }
                    if (image.fileSize > 8000000 && !errors.includes("size")) {
                        errors.push("size");
                    }
                });

                setImageErrors(errors);

            //if no error, proceed
                if (errors.length !== 0) {
                    onPick(null);
                } else {
                    isPicking?.(true);
                    setPickedRawImages( () => {
                        setCompressedImages([]);
                        setHasCompressionError(false);
                    return rawImages;
                    });
                }
        };

    return (
        <div className="flex flex-col space-y-2">
            { title ? (
                <span className="text-xs font-bold text-gray">{title}</span>
            ) : (
                ''
            )}

            <div
                className={`flex flex-row rounded bg-white h-11 border
                ${showError ? "border-red" : "border-muted"}
            `}
            >
                <input
                    ref={inputRef}
                    type="file"
                    id="image-picker"
                    className="hidden"
                    onChange={pickImage}
                    multiple={pickableCount > 1}
                    disabled={disabled}
                />
                <label
                    htmlFor="image-picker"
                    className={`flex-1 flex flex-row justify-between items-center bg-white shadow pl-5 pr-3 w-full rounded
                        ${
                            disabled
                            ? "opacity-50 cursor-not-allowed"
                            : "cursor-pointer active:opacity-50"
                        }
                    `}
                >
                    <div className="flex flex-row items-center space-x-4">
                        
                        <FontAwesomeIcon icon={faCamera} color="black" />
                        <span className="text-xs text-black font-bold">
                            {buttonLabel}
                        </span>
                    </div>

                    { (compressedPercent !== 0 || hasCompressionError)  ? (
                        <div className="flex items-center">
                            { hasCompressionError ? (
                                <span className="text-red text-2xs">
                                    Compression Error!
                                </span>
                            ) : (
                                <span className={`text-2xs
                                    ${compressedPercent === 100 ? 'text-green' : 'text-soft-gray'}
                                `}>
                                    {compressedPercent === 100 
                                        ? 'Ready' 
                                        : `Compressing... ${compressedPercent}%`
                                    }
                                </span>
                            )}
                        </div>
                    ) : ''}
                    
                </label>
            </div>

            {showError &&
                errors.map((error, index) =>
                    error.show ? (
                        <div
                            key={index}
                            className="flex text-2xs text-red flex-row space-x-1"
                        >
                            <span>*</span>
                            <span>{error.msg}</span>
                        </div>
                    ) : (
                        ""
                    )
                )}
            {imageErrors.map((error, index) => (
                <div
                    key={index}
                    className="flex text-2xs text-red flex-row space-x-1"
                >
                    <span>*</span>
                    <span>{errorMsges.find((e) => e.type === error).msg}</span>
                </div>
            ))}
            {warningMsges.map((msg, index) => (
                <div
                    key={index}
                    className="flex text-2xs text-gray flex-row space-x-2"
                >
                    <span>*</span>
                    <span>{msg}</span>
                </div>
            ))}
        </div>
    );
};

export default memo(ImagePicker);
