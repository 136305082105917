import React, { useEffect, useState, useMemo, memo } from "react";
import axios_custom from "../axios_config/axios_custom";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../store/reducers/app-reducer';
import useStoreHelper from "../hooks/useStoreHelper";
import ProgressBar from "./ProgressBar";
import { SubmitButton } from "./inputs";

const FinanceSummaryBanner = ({
    collectable,
    onCollected,
    date,
    user_id,
    count,
    solds,
    total_paid_debts,
    total_paid_interests,
    total_collaterals,
    all_sold_ids,
    all_paid_debt_ids,
    all_paid_interests_ids,
    all_collaterals_ids
}) => {

    const authUser = useSelector( state => state.app.authUser);
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const [allUsers, setAllUsers] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittedCount, setSubmittedCount] = useState(0);

    //mounted
        useEffect(() => {
            const controller = new AbortController();
            const signal = controller.signal;

            axios_custom.get('users', {signal})
                .then( (res) => {
                    setAllUsers(res.data.results);
                })
                .catch( (error) => {
                    handleError(error);
                });
        
            return () => controller.abort();
        }, []);

    //computed
        const userName = useMemo(() => {
            return allUsers.find( e => e.id === user_id)?.nickname || 'ဝန်ထမ်းအားလုံး';
        }, [allUsers, user_id]);
        
        const dateLabel = useMemo( () => {
            if(date){
                if(date.toDateString() === new Date().toDateString()){
                    return 'ယနေ့အတွက်';
                } else {
                    return date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear() + ' အတွက်';
                }
            } else {
                return '';
            }
        }, [date]);

        const final = useMemo( () => {
            const price = (solds.final_price + total_paid_debts + total_paid_interests) - total_collaterals;
            return {
                price: Math.abs(price),
                label: price < 0 ? 'စုစုပေါင်းငွေအမ်းပေးရန်' : 'စုစုပေါင်းအပ်ရန်',
                color: price < 0 ? 'sky' : 'green'
            }
        }, [solds.final_price, total_paid_debts, total_paid_interests, total_collaterals]);

        const submittableIds = useMemo( () => {
            const soldIds = all_sold_ids.map( e => { 
                return { id: e, source: 'items' } 
            });
            const paidDebtsIds = all_paid_debt_ids.map( e => { 
                return { id: e, source: 'paid_debts' } 
            });
            const paidInterestsIds = all_paid_interests_ids.map( e => { 
                return { id: e, source: 'paid_interests' } 
            });
            const collateralIds = all_collaterals_ids.map( e => { 
                return { id: e, source: 'collaterals' } 
            });
            return [...soldIds, ...paidDebtsIds, ...paidInterestsIds, ...collateralIds];
        }, [all_sold_ids, all_paid_debt_ids, all_paid_interests_ids, all_collaterals_ids]);

        const submittedPercent = useMemo( () => {
            const percent = ( submittedCount / submittableIds.length ) * 100;
            return Math.floor(percent);
        }, [submittedCount, submittableIds]);
    
    //sequential submitter
        useEffect( () => {
            if(isSubmitting){

                if(submittedCount < submittableIds.length){
                    const { id, source } = submittableIds[submittedCount];
                    
                    axios_custom.put(`${source}/${id}/collect_money`, {
                        user: authUser.id,
                    }).then(() => {
                        setSubmittedCount( prev => prev + 1 );
                    })
                    .catch(() => {
                        setSubmittedCount( prev => prev + 1 );
                    });
                } else {
                    setSubmittedCount( () => {
                        setIsSubmitting(false);
                        return 0;
                    });
                    dispatch(setAlert({
                        msg: `${count} ခု စာရင်းရှင်းပြီး`,
                        status: "success",
                        timeout: 3000,
                    }));
                    collectable && onCollected();
                }
            }
        }, [isSubmitting, submittedCount]);

    //methods
        const submitCollectAll = () => {
            if(window.confirm(`\nအောက်ပါပစ္စည်း ${count} ခုအား စာရင်းရှင်းပြီးအဖြစ်သတ်မှတ်ပါမည်!`)){
                setIsSubmitting(true);
            }
        }
    
    return (
        <div className="bg-dim-purple border border-teal rounded-lg w-full max-w-96">
            <div className="flex flex-col space-y-5 m-5">
                
                <div className="flex justify-center">
                    <span className="text-orange text-sm text-center">{`${dateLabel} ${userName} ငွေစာရင်းရှင်းရန် စာရင်းချုပ်`}</span>
                </div>

                <div className="flex flex-col space-y-1">
                    <div className="flex flex-row items-center justify-between">
                        <div>
                            <span className="text-gray text-xs">လက်ငင်းရောင်းငွေ</span>
                        </div>
                        <div className="justify-end">
                            <span className="text-gray text-xs">{ solds.total_instant_sold }</span>
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-between">
                        <div>
                            <span className="text-gray text-xs">စရံပေးငွေ</span>
                        </div>
                        <div className="justify-end">
                            <span className="text-gray text-xs">{ solds.total_deposit_price }</span>
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-between">
                        <div>
                            <span className="text-gray text-xs">အကြွေးဆပ်</span>
                        </div>
                        <div className="justify-end">
                            <span className="text-gray text-xs">{ total_paid_debts }</span>
                        </div>
                    </div>

                    { total_paid_interests > 0 ? (
                        <div className="flex flex-row items-center justify-between">
                            <div>
                                <span className="text-gray text-xs">အတိုးဆပ်ငွေ</span>
                            </div>
                            <div className="justify-end">
                                <span className="text-gray text-xs">{ total_paid_interests }</span>
                            </div>
                        </div>
                    ) : ''}

                    {/* divider */}
                    { ( solds.total_cash_back < 0 || total_collaterals > 0 ) ? ( 
                        <div className="border-b border-b-dim-gray w-full pb-2"></div>
                    ) : '' }

                    { ( solds.total_cash_back < 0 ) ? (
                        <div className="flex flex-row items-center justify-between">
                            <div>
                                <span className="text-red text-xs">ပြန်အမ်းငွေ</span>
                            </div>
                            <div className="justify-end">
                                <span className="text-red text-xs">{ solds.total_cash_back }</span>
                            </div>
                        </div>
                    ) : ''}

                    { ( total_collaterals > 0 ) ? (
                        <div className="flex flex-row items-center justify-between">
                            <div>
                                <span className="text-red text-xs">အပေါင်ပေးငွေ</span>
                            </div>
                            <div className="justify-end">
                                <span className="text-red text-xs"> - { total_collaterals }</span>
                            </div>
                        </div>
                    ) : ''}

                    <div className="border-b border-b-dim-gray w-full pb-2"></div>

                    <div className="flex flex-row items-center justify-between">
                        <div>
                            <span className="text-black font-bold text-xs">
                                { final.label }
                            </span>
                        </div>
                        <div className="justify-end">
                            <span className={`font-bold text-xs text-${final.color}`}>
                                { new Intl.NumberFormat().format(final.price) }
                            </span>
                        </div>
                    </div>
                </div>

                {collectable ? (
                    <div className="flex flex-col space-y-2">
                        {isSubmitting ? (
                            <ProgressBar
                                color="green"
                                completedPercent={submittedPercent}
                            />
                        ) : ''}
                        <div className="flex">
                            <SubmitButton
                                title="အားလုံးစာရင်းရှင်းပြီးအဖြစ်သတ်မှတ်မည်"
                                color="teal"
                                onClick={submitCollectAll}
                                height="8"
                                fontSize="xs"
                                icon={faHandHoldingDollar}
                                activated={isSubmitting}
                                activatedColor="dark-teal"
                            />
                        </div>
                    </div>
                ) : ''}

            </div>
        </div>
    );
};

export default memo(FinanceSummaryBanner);
