import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import { useSelector } from 'react-redux';
import { months } from '../helper/monthsAndYears';
import colors from '../helper/colors';
import WeightStats from '../components/WeightStats';
import AnimatedCard from '../components/AnimatedCard';
import ScrollView from '../components/ScrollView';
import ActivityIndicator from '../components/ActivityIndicator';
import { Line, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import useNavigation from '../hooks/useNavigation';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import NoticeBanner from '../components/NoticeBanner';
import ReportCard from '../components/ReportCard';

const ReportScreen = () => {
	
    const allGroups = useSelector( state => state.app.groups); 
    const groups = allGroups.filter( e => e.id !== null);
    const fetchApi = useApiFetcher();

    const navigation = useNavigation();

    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        year: new Date().getFullYear()
    });

    const [showLoading, setShowLoading] = useState(false);
    const [response, setResponse] = useState({
        total_price: 0,
        total_service_weight: {
            kyat: 0,
            pel: 0,
            yway: 0,
            chan: 0,
            sate: 0
        },
        total_trader_weight: {
            kyat: 0,
            pel: 0,
            yway: 0,
            chan: 0,
            sate: 0
        },
        total_profit_weight: {
            kyat: 0,
            pel: 0,
            yway: 0,
            chan: 0,
            sate: 0
        },
        total_count: 0,
        by_group: [],
        by_month: []
    });

    //watchers
		useEffect( () => {
			fetchApi(
                'items', 
                search_filters, 
                [], 
                setShowLoading, 
                setResponse
            );
		}, [search_filters]);

    //before mounted
        useLayoutEffect( () => {
            ChartJS.register(
                CategoryScale,
                PointElement,
                LineElement,
                ArcElement,
                Tooltip,
                Legend,
                LinearScale
            );
        }, []);

    //chart data and options
        const data = useMemo( () => {
            return {
                lineChart: {
                    labels: months.map( e => e.short ),
                    datasets: [
                        {
                            label: 'သိန်း',
                            data: response.by_month.map( e => Math.round(e.total_price / 100000) ),
                            borderColor: 'skyblue',
                            backgroundColor: 'pink',
                        }
                    ]
                },
                pieChart: {
                    labels: groups.map( e => {
                        return `${e.name} ${response.by_group.find( g => g.id === e.id )?.total_count ?? 0 }`  
                    }),
                    datasets: [
                        {
                            data: response.by_group.sort( (a, b) => a.id - b.id ).map( e => e.total_count ),
                            backgroundColor: groups.map( g => colors.find( c => c.org === g.color).normal ),
                            borderColor: groups.map( g => colors.find( c => c.org === g.color).normal ),
                            borderWidth: 1,
                        },
                    ],
                },
                
                by_group: (response.by_group.length === 0) ? [] :

                    groups.filter( e => e.id !== null).map( group => {

                    const {
                        total_count,
                        total_service_weight,
                        total_trader_weight,
                        total_profit_weight
                    } = response.by_group.find( e => e.id === group.id );
                    
                    return {
                        ...group,
                        total_count,
                        total_service_weight,
                        total_trader_weight,
                        total_profit_weight
                    }
                })
            }
        }, [response]);

        const options = {
            lineChart: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: value => `${value} lks`,
                        }
                    }
                }
            },
            pieChart: {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            boxWidth: 5,
                            padding: 20,
                            font: {
                                size: 10
                            }
                        }
                    }
                }
            }
        }
        
    return(
        <>
            <Nav
                onSearch={setSearchFilters}
                navType="calendar"
                searchOnNavChange={true}
                enableAllSearch={false} 
                enabledSearches={[]}
            />
            
            <ScrollView className="flex flex-col flex-1 bg-white">

                <div className="flex flex-col pt-28 sm:pt-14 mb-10">

                    <NoticeBanner
                        title="Info"
                        icon={faCircleInfo}
                        color="sky"                                            
                        msges={[
                            'နှစ်ချုပ် / လချုပ် အရောင်း report များဖြစ်သည်။',
                            <span>တစ်ရက်ချင်းအရောင်း report ကို <a className="text-blue cursor-pointer underline" onClick={ () => navigation.navigate('sold')}>ရောင်းပြီးစာရင်းများ Page</a> တွင်ကြည့်နိုင်ပါသည်။</span>
                        ]}
                    />

                    { showLoading ? (
                        <div className="flex justify-center sm:mr-16 mt-16">
                            <ActivityIndicator/>
                        </div>
                    ) : (
                        <div className="flex flex-col mt-2">

                            <div className="flex flex-row flex-wrap sm:mr-16">

                                {/* charts */}
                                <div className="flex flex-col w-full lg:w-1/2">
                                    {(search_filters.month === null) ? (
                                        <AnimatedCard show={true}>
                                            <div className="bg-white mx-3 my-2 rounded-xl shadow-custom shadow-t">
                                                <div className="flex flex-col space-y-5 p-3">
                                                    <div className="flex flex-row space-x-2 items-center ml-1">
                                                        <span className="text-sm text-green">{search_filters.year}</span>
                                                        <span className="text-xs">ခုနှစ် လစဉ်ရောင်းရငွေဇယား</span>
                                                    </div>
                                                    <Line 
                                                        data={data.lineChart}
                                                        options={options.lineChart}
                                                        height={180}
                                                    />
                                                    <div className="flex flex-row items-center justify-center space-x-1 h-6">
                                                        <span className="text-xs">တစ်နှစ်စာစုစုပေါင်း</span>
                                                        <span className="text-xs font-bold text-red">{ new Intl.NumberFormat().format(response.total_price) }</span>
                                                        <span className="text-xs">ကျပ်</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </AnimatedCard>
                                    ) : ''}

                                    { response.total_count !== 0 ? ( 
                                        <AnimatedCard show={true}>
                                            <div className="bg-white mx-3 my-2 rounded-xl shadow-custom shadow-t">
                                                <div className="flex flex-col space-y-5 p-3">
                                                    <div className="flex flex-row items-center space-x-1">
                                                        <span className="text-sm text-green">{search_filters.year}</span>
                                                        <span className="text-xs"> ခုနှစ် </span>
                                                        { (search_filters.month !== null) ? (
                                                            <span className="text-sky text-sm">{ months.find( e => e.id === search_filters.month).name } </span>
                                                        ) : ''}
                                                        <span className="text-xs">အရောင်းရဆုံးပစ္စည်းများ</span>
                                                    </div>
                                                    <Pie
                                                        className="max-h-64 sm:max-h-80"
                                                        data={data.pieChart}
                                                        options={options.pieChart}
                                                    />
                                                </div>
                                            </div>
                                        </AnimatedCard>
                                    ) : ''}

                                    <AnimatedCard show={true}>
                                        <div className="flex bg-white mx-3 my-2 rounded-xl shadow-custom">
                                            <div className="flex flex-col w-full mx-3 mt-3 mb-5 space-y-8">

                                                <div className="flex flex-row items-center space-x-1">
                                                    <span className="text-green text-sm">{search_filters.year}</span>
                                                    <span className="text-xs">ခုနှစ်</span>
                                                    { (search_filters.month === null) ? (
                                                        <span className="text-black text-xs">
                                                            တစ်နှစ်လုံးစာ
                                                        </span>
                                                    ) : (
                                                        <span className="text-sky text-sm">{ months.find( e => e.id === search_filters.month).name } </span>
                                                    )}
                                                    <span className="text-xs">
                                                        အတွက် Overall Report
                                                    </span>
                                                </div>

                                                <div className="flex flex-col space-y-4">
                                                    <div className="flex flex-row items-center justify-center space-x-2">
                                                        <div className="flex w-1/2 justify-end">
                                                            <span className="text-xs">ရောင်းပြီးအခုရေ</span>
                                                        </div>
                                                        <div className="flex flex-1 flex-row items-center space-x-2">
                                                            <span className="text-xs text-red">
                                                                { response.total_count }
                                                            </span>
                                                            <span className="text-xs text-gray">ခု</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row items-center justify-center space-x-2">
                                                        <div className="flex w-1/2 justify-end">
                                                            <span className="text-xs">စုစုပေါင်းရောင်းရငွေ</span>
                                                        </div>
                                                        <div className="flex flex-1 flex-row items-center space-x-2">
                                                            <span className="text-xs text-red">
                                                                { new Intl.NumberFormat().format(response.total_price) }
                                                            </span>
                                                            <span className="text-xs text-gray">ကျပ်</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                                <div className="flex flex-col space-y-4">

                                                    <div className="flex flex-row items-center justify-center space-x-2">
                                                        <div className="flex w-2/5 justify-end">
                                                            <span className="text-xs text-yellow">ရောင်းအလျော့</span>
                                                        </div>
                                                        <div className="flex flex-1">
                                                            <span className="text-xs text-gray">
                                                                <WeightStats total={response.total_service_weight}/>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-row items-center justify-center space-x-2">
                                                        <div className="flex w-2/5 justify-end">
                                                            <span className="text-xs text-sky">အရင်း</span>
                                                        </div>
                                                        <div className="flex flex-1">
                                                            <span className="text-xs text-gray">
                                                                <WeightStats total={response.total_trader_weight}/>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="border-b border-b-dim-gray"></div>

                                                    <div className="flex flex-row items-center justify-center space-x-2">
                                                        <div className="flex w-2/5 justify-end">
                                                            <span className="text-xs text-green">အမြတ်အလျော့</span>
                                                        </div>
                                                        <div className="flex flex-1">
                                                            <span className="text-xs text-gray">
                                                                <WeightStats total={response.total_profit_weight}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* notice */}
                                                <div className="flex flex-col space-y-2">
                                                    <div className="flex flex-row text-2xs space-x-2 text-gray">
                                                        <span>*</span>
                                                        <span>ပစ္စည်းများတွင် ကုန်သည်အလျော့ (သို့) အရင်းအလျော့ ထည့်ထားမှသာ အမြတ်/အရင်း တွက်ချက်မှုမှန်ကန်နိုင်မည်ဖြစ်သည်။</span>
                                                    </div>
                                                    <div className="flex flex-row text-2xs space-x-2 text-gray">
                                                        <span>*</span>
                                                        <span>ကုန်သည်အလျော့များကို <a className="text-blue cursor-pointer underline" onClick={ () => navigation.navigate('trader_weight')}>ကုန်သည်အလျော့ထည့်ရန် Page</a> တွင်ထည့်နိုင်ပါသည်။</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </AnimatedCard>
                                </div>

                                {/* by each item */}
                                {(response.total_count !== 0) ? (
                                    <div className="flex flex-col w-full lg:w-1/2 mt-4 space-y-1">

                                        <div className="flex flex-row items-center space-x-1 mx-3">
                                            <span className="text-sm text-green">{search_filters.year}</span>
                                            <span className="text-xs"> ခုနှစ် </span>
                                            { (search_filters.month === null) ? (
                                                <span className="text-black text-xs">
                                                    တစ်နှစ်လုံးစာ
                                                </span>
                                            ) : (
                                                <span className="text-sky text-sm">{ months.find( e => e.id === search_filters.month).name } </span>
                                            )}
                                            <span className="text-xs">ပစ္စည်းအလိုက်အရောင်းစာရင်း</span>
                                        </div>

                                        <div className="flex flex-col">
                                            { data.by_group.map( (group, index) => 
                                                <ReportCard
                                                    key={index}
                                                    cardColor={group.color}
                                                    title={
                                                        <div className="flex flex-row space-x-1 items-center justify-between">
                                                            <span className={`font-bold text-xs text-${group.color}`}>
                                                                {group.name}
                                                            </span>
                                                            <div className={`flex items-center justify-center font-bold rounded-full bg-soft-${group.color}`}>
                                                                <span className="text-2xs mx-2 my-1 font-bold text-soft-black">{group.total_count}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    rowData={[
                                                        {
                                                            label: 
                                                                <span className="text-xs font-bold text-gray">
                                                                    ရောင်းအလျော့
                                                                </span>,
                                                            value: 
                                                                <span className="text-xs text-gray">
                                                                    <WeightStats total={group.total_service_weight}/>
                                                                </span>
                                                        },
                                                        {
                                                            label: 
                                                                <span className="text-xs font-bold text-gray">
                                                                    အရင်း
                                                                </span>,
                                                            value: 
                                                                <span className="text-xs text-gray">
                                                                    <WeightStats total={group.total_trader_weight}/>
                                                                </span>
                                                        },
                                                        'divider',
                                                        {
                                                            label: 
                                                                <span className="text-xs font-bold text-gray">
                                                                    အမြတ်
                                                                </span>,
                                                            value: 
                                                                <span className="text-xs text-gray">
                                                                    <WeightStats total={group.total_profit_weight}/>
                                                                </span>
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ) : ''}

                            </div>

                            <div className="flex items-center justify-center h-16">
                                { !showLoading ? (
                                    <span className="text-soft-gray text-sm font-bold">End of Results</span>
                                ) : ''}
                            </div>

                        </div>
                    )}
                </div>
            </ScrollView>
        </>
    )
}

export default ReportScreen;