

export const months = [
	{   
        id: 1,
		short: "Jan",
		name: "January"
	},
	{
        id: 2,
		short: "Feb",
		name: "February"
	},
	{
        id: 3,
		short: "Mar",
		name: "March"
	},
	{
        id: 4,
		short: "Apr",
		name: "April"
	},
	{
        id: 5,
		short: "May",
		name: "May"
	},
	{
        id: 6,
		short: "Jun",
		name: "June"
	},
	{
        id: 7,
		short: "Jul",
		name: "July"
	},
	{
        id: 8,
		short: "Aug",
		name: "August"
	},
	{
        id: 9,
		short: "Sep",
		name: "September"
	},
	{
        id: 10,
		short: "Oct",
		name: "October"
	},
	{
        id: 11,
		short: "Nov",
		name: "November"
	},
	{
        id: 12,
		short: "Dec",
		name: "December"
	}
];

export let years = [];

for(let i = new Date().getFullYear() - 5; i <= new Date().getFullYear() + 5; i++){
    years.push(i);
};

years = years.sort( (a, b) => b - a );

