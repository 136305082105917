import moment from "moment";

class DateParser {

    toString = (date) => {
        const dateObj = typeof(date) === 'string' ? new Date(date) : date;

        let day = dateObj.day ?? dateObj.getDate();
        let month = dateObj.month ?? dateObj.getMonth();
        let year = dateObj.year ?? dateObj.getFullYear();
        
        day = day < 10 ? '0' + day : day;
        month = (month + 1);

        //add zero for better readability 
        month = month < 10 ? '0' + month : month;

        // Adding 1 to convert from 0-11 to 1-12
        return `${day}-${month}-${year}`;
    };

    compareDateDiff = (fromDate, toDate) => {
        const start = new Date(fromDate);
        const end = new Date(toDate);

        const startInMonths = start.getFullYear() * 12 + (start.getMonth() + 1);
        const endInMonths = end.getFullYear() * 12 + (end.getMonth() + 1);

        const monthDiff = endInMonths - startInMonths;

        let day = end.getDate() - start.getDate();
        let month = day < 0 ? monthDiff - 1 : monthDiff;
        let year = 0;

        if (month > 12) {
            year = Math.floor(month / 12);
            month = Math.max(month - year * 12, 0);
        }

        if (day < 0) {
            const previousMonth = new Date(end);
            previousMonth.setMonth(end.getMonth());
            previousMonth.setDate(0);

            const totalDaysOfPreviousMonth = previousMonth.getDate();
            day = totalDaysOfPreviousMonth - start.getDate() + end.getDate();
        }

        return { year, month, day }
    }

    compareMonthDiff = (fromDate, toDate) => {
        const start = moment(new Date(fromDate));
        const end = moment(new Date(toDate));
        return end.diff(start, 'months');
    }

    compareDayDiff = (fromDate, toDate) => {
        const start = moment(new Date(fromDate));
        const end = moment(new Date(toDate));
        return end.diff(start, 'days');
    }
}

export default DateParser;