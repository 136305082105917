import React, { memo, useMemo } from 'react'
import Weight from '../../helper/weight'
import TagInput from './TagInput';

const WeightInput = ({ 
    title, 
    value,
    nullable = false,
    onChange, 
    onPressEnterKey,
    emptyCheckable = false,
    disableResponsive = false
}) => {
    
    const weight = new Weight();

    //check error on value change
    const checkedWeight = useMemo( () => {
        return weight.checkError(value);
    }, [value]);

    const checkAndSubmit = (newWeight) => {
        const checked = weight.checkError(newWeight);
        const hasError = nullable ? 
        (checked.errors.length !== 0) :
        (checked.errors.length !== 0) || (checked.isEmpty);

        onChange({
            weight: newWeight, 
            hasError: hasError
        });
    }

    const handleWeightChange = (text, unitName) => {
        const newWeight = {...value, [unitName]: text};
        checkAndSubmit(newWeight);
    }

    return (
        <div className="flex flex-col space-y-2">
            { title ? (
                <span className="text-xs font-bold text-gray">{title}</span>
            ) : ''}

            <div className={`flex flex-row flex-wrap justify-between space-y-1
                ${ !disableResponsive ? 'md:space-x-3 md:space-y-0' : ''} 
                ${ emptyCheckable && !nullable && checkedWeight.isEmpty ? 'border border-red rounded' : ''}
            `}>
                    {weight.units.map( unit =>
                        <div key={unit.id} className={`w-full ${ !disableResponsive ? 'md:flex-1' : ''}`}>
                            <TagInput
                                key={unit.id}
                                type="number"
                                tagLabel={unit.label}
                                value={value[unit.name]}
                                onChange={ text => handleWeightChange(text, unit.name)}
                                onPressEnterKey={onPressEnterKey}
                                disabled={checkedWeight.disabled.includes(unit.name)}
                                min={unit.min}
                                max={unit.max}
                                errors={[
                                    {
                                        show: checkedWeight.errors.includes(unit.name),
                                        msg: unit.error
                                    }
                                ]}
                            />
                        </div>
                    )}
            </div>
            { ( emptyCheckable && !nullable && checkedWeight.isEmpty) ? (
                <span className="text-red text-2xs">
                    * အလေးချိန်ထည့်ပါ
                </span>
            ) : ''}
        </div>
  )
}

export default memo(WeightInput);