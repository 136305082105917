import React, { useMemo, useState } from "react";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import colors from "../helper/colors";
import { Input, PopUpPicker, SubmitButton } from "../components/inputs";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import useNavigation from "../hooks/useNavigation";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../store/reducers/app-reducer";

const GroupFormScreen = () => {

    const groups = useSelector( state => state.app.groups);
    const dispatch = useDispatch();
    const { handleError, getGroups } = useStoreHelper();
    const navigation = useNavigation();

    const currentRoute = useSelector( state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const isEdit = Boolean(routeState);
    const group = isEdit ? routeState.group : null;

    //inputs
    const [name, setName] = useState(isEdit ? group.name : '');
    const [colorId, setColorId] = useState(isEdit ? colors.find( e => e.org === group.color).id : null);
    const [sizeName, setSizeName] = useState(isEdit ? group.size_name : '');
    const [countUnit, setCountUnit] = useState(isEdit ? group.count_unit : '');

    const [errorCheckable, setErrorCheckable] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    //computed
        const color = useMemo( () => {
            const targetColor = colors.find( e => e.id === colorId);
            return targetColor ? targetColor.org : '' 
        }, [colorId]);

        const inputErrors = useMemo( () => {
            const errors = [];

            name === '' && errors.push('name');
            colorId === null && errors.push('color');
            countUnit === '' && errors.push('count_unit');

            if(isEdit){
                if(name !== group.name && groups.map( e => e.name).includes(name)){
                    errors.push('duplicate_name');
                }
            } else {
                if(groups.map( e => e.name).includes(name)){
                    errors.push('duplicate_name');
                }
            }

            return errors;
        }, [name, colorId, sizeName, countUnit]);

        const availableColors = useMemo( () => {
            const unUsedColors = colors.filter( color => {

                const bannedColors = ['black', 'white', 'muted', 'milk'];
                const checkableGroups = isEdit ? groups.filter( e => e.color !== group.color) : groups;
                const alreadyUsed = checkableGroups.map( group => group.color ).includes(color.org);

                return !alreadyUsed && !bannedColors.includes(color.org)
            }).map( e => {
                return {
                    id: e.id,
                    name: e.org.charAt(0).toUpperCase() + e.org.slice(1)
                }
            });

            return unUsedColors;
        }, [groups, colors]);

    //methods
        const submit = () => {
            setErrorCheckable(true);

            if(inputErrors.length === 0){
                setIsSubmitting(true);

                if(isEdit){
                    axios_custom.put('/groups/' + group.id, {
                        name,
                        color,
                        size_name: sizeName,
                        count_unit: countUnit
                    } )
                    .then( res => {
                        setIsSubmitting(false);
                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));
                        navigation.navigate('groups', {
                            state: {
                                editedGroup: res.data.result
                            }
                        });
                        getGroups();
                    })
                    .catch( error => {
                        setIsSubmitting(false);
                        handleError(error);
                    });
                } else {

                    axios_custom.post('/groups', { 
                        name,
                        color,
                        size_name: sizeName,
                        count_unit: countUnit
                    })
                    .then( res => {
                        setIsSubmitting(false);
                        navigation.navigate('groups', {
                            state: {
                                newGroupId: res.data.result.id
                            }
                        });
                        getGroups();
                    })
                    .catch( error => {
                        setIsSubmitting(false);
                        handleError(error);
                    });
                }
            }
        }

    return (
        <StackScreen>
            <ScrollView className="flex flex-col flex-1">
                <div className="flex flex-col space-y-8 mx-5 mt-7 mb-16">

                    <div className="flex flex-col space-y-5">

                        <Input
                            title="ပစ္စည်း Group အမည်"
                            type="text"
                            placeholder="eg. လက်စွပ်၊ လက်ကောက်၊ စသည်..."
                            value={name}
                            onChange={ text => setName(text)}
                            errors={[
                                {
                                    show: errorCheckable && inputErrors.includes('name'),
                                    msg: `Group အမည်ထည့်ပါ!`
                                },
                                {
                                    show: errorCheckable && inputErrors.includes('duplicate_name'),
                                    msg: 'ရှိပြီးသား Group နှင့် အမည်တူနေပါသည်။ အခြားအမည်ရွေးပါ!'
                                }
                            ]}
                        />

                        <div className="flex flex-col space-y-2">
                            <div className="flex flex-row items-center space-x-2">
                                <span className="text-xs text-gray font-bold">အရောင်</span>
                                <div className={`flex items-center rounded bg-${color === '' ? 'dim-gray' : color}`}>
                                    <span className="text-xs text-white mx-2 my-1">
                                        { name || 'Example' }
                                    </span>
                                </div>
                            </div>
                            <PopUpPicker
                                triggerClassName={`h-11 flex items-center pl-5 bg-white rounded border border-${ errorCheckable && inputErrors.includes('color') ? 'red' : 'dim-gray'}`}
                                disabled={false}
                                nullable={true}
                                value={colorId}
                                theme="light"
                                textColor="black"
                                enableIcon={true}
                                data={availableColors}
                                onPick={setColorId}
                            />
                            { errorCheckable && inputErrors.includes('color') ? (
                                <span className="text-2xs text-red">
                                    * အရောင်ရွေးပါ!
                                </span>
                            ) : ''}
                        </div>

                        <Input
                            title="အရွယ်အစားအမည်"
                            type="text"
                            placeholder="eg. အရှည်၊ ကွင်းတိုင်း၊ စသည်..."
                            value={sizeName}
                            onChange={ text => setSizeName(text)}
                            warningMsges={[
                                'အရွယ်အစားရှိပါကထည့်ပါ။ မထည့်ပဲထားခဲ့လဲရသည်။'
                            ]}
                        />

                        <Input
                            title="ပစ္စည်း ရေတွက်သည့် Unit"
                            type="text"
                            placeholder="eg. ခု၊ ကွင်း၊ ကုံး စသည်..."
                            value={countUnit}
                            onChange={ text => setCountUnit(text)}
                            errors={[
                                {
                                    show: errorCheckable && inputErrors.includes('count_unit'),
                                    msg: `ရေတွက်သည့် Unit ထည့်ပါ!!`
                                }
                            ]}
                        />

                    </div>

                    <div className="flex">
                        <SubmitButton
                            title={ isEdit ? 'ပြင်မယ်' : 'ထည့်မယ်' }
                            color="green"
                            height="12"
                            radius="rounded-lg"
                            activated={isSubmitting}
                            onClick={submit}
                        />
                    </div>

                </div>
            </ScrollView>
        </StackScreen>
    );
};

export default GroupFormScreen;
