import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'

const NotificationComponent = () => {
    return (
        <div className="bg-soft-black border border-gray rouned-lg rounded-lg w-80">
            <div className="m-2">
                
                <div className="flex flex-row my-1 justify-between items-center mx-1">
                    <span className="text-white text-xl font-bold">Notifications</span>
                    <button className="flex items-center justify-center border border-yellow rounded w-8 h-8">
                        <div className="p-2">
                            <FontAwesomeIcon icon={faBell} color="#eab308"/>
                        </div>
                    </button>
                </div>

                <div className="mt-2 bg-white rounded">
                    <div className="flex flex-row justify-between items-center">

                        <span>
                            {/* icon */}
                        </span>

                        <div className="flex-1 h-10 flex items-center">
                            <span className="flex-1 text-xs text-gray mx-3">
                                Coming Soon...
                            </span>
                            <div className="flex flex-row justify-between">
                                <span>
                                    {/* commiter */}
                                </span>
                                <span>
                                    {/* date */}
                                </span>
                            </div>
                        </div>

                        <span>
                            {/* icon */}
                        </span>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default NotificationComponent