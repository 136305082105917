import React, { useEffect, useState } from "react";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import NoticeBanner from "../components/NoticeBanner";
import useApiFetcher from "../hooks/useApiFetcher";
import useNavigation from "../hooks/useNavigation";
import ReportCard from "../components/ReportCard";
import WeightStats from "../components/WeightStats";
import ActivityIndicator from "../components/ActivityIndicator";

const NetWorthScreen = () => {

    const allGroups = useSelector( state => state.app.groups ); 
    const groups = allGroups.filter( e => e.id !== null);
    const fetchApi = useApiFetcher();
    const navigation = useNavigation();

    const [showLoading, setShowLoading] = useState(false);
    const [response, setResponse] = useState({
        total_count: 0,
        total_org_weight: {
            kyat: 0,
            pel: 0,
            yway: 0,
            chan: 0,
            sate: 0
        },
        total_net_weight: {
            kyat: 0,
            pel: 0,
            yway: 0,
            chan: 0,
            sate: 0
        }, 
        total_net_price: 0,
        by_group: [],
    });
          
    //mounted
        useEffect( () => {
            fetchApi(
                'items', 
                {}, 
                [], 
                setShowLoading, 
                setResponse
            );
        }, []);

    return (
        <StackScreen>
            <ScrollView className="flex flex-col flex-1 bg-soft-muted">

                <NoticeBanner
                    title="Info"
                    icon={faCircleInfo}
                    color="sky"                                            
                    msges={[
                        'ပစ္စည်းများတွင် ကုန်သည်အလျော့ (သို့) အရင်းအလျော့ ထည့်ထားမှသာ အမြတ်/အရင်း တွက်ချက်မှုမှန်ကန်နိုင်မည်ဖြစ်သည်။',
                        <span>ကုန်သည်အလျော့များကို <a className="text-blue cursor-pointer underline" onClick={ () => navigation.navigate('trader_weight')}>ကုန်သည်အလျော့ထည့်ရန် Page</a> တွင်ထည့်နိုင်ပါသည်။</span>
                    ]}
                />

                { showLoading ? (
                    <div className="flex items-center justify-center mt-16">
                        <ActivityIndicator/>
                    </div>
                ) : (
                    
                    (response.total_count !== 0 && response.by_group.length !== 0) ? (

                        <div className="flex flex-col space-y-5">
                            <div className="flex flex-row flex-wrap">
                        
                                <div className="flex items-center justify-center w-full">
                                    <div className="w-full sm:w-2/5">
                                        <ReportCard
                                            border="black"
                                            rowLabelWidth="1/2"
                                            title={
                                                <div className="flex items-center justify-center my-1">
                                                    <span className="text-sm font-bold">
                                                        စာရင်းရှိပစ္စည်းအားလုံး အနှစ်ချုပ်
                                                    </span>
                                                </div>
                                            }
                                            rowData={[
                                                {
                                                    label: 
                                                        <span className="text-xs font-bold text-soft-black">
                                                            စုစုပေါင်းအရေအတွက်
                                                        </span>,
                                                    value: 
                                                        <span className="text-xs text-gray">
                                                            { response.total_count } ခု
                                                        </span>
                                                },
                                                {
                                                    label: 
                                                        <span className="text-xs font-bold text-soft-black">
                                                            စုစုပေါင်းကျောက်ပါအလေးချိန်
                                                        </span>,
                                                    value: 
                                                        <span className="text-xs text-gray">
                                                            <WeightStats total={response.total_org_weight}/>
                                                        </span>
                                                },
                                                {
                                                    label: 
                                                        <span className="text-xs font-bold text-soft-black">
                                                            စုစုပေါင်းအသားတင်အလေးချိန်
                                                        </span>,
                                                    value: 
                                                        <span className="text-xs text-gray">
                                                            <WeightStats total={response.total_net_weight}/>
                                                        </span>
                                                },
                                                {
                                                    label: 
                                                        <span className="text-xs font-bold text-soft-black">
                                                            စုစုပေါင်းပျမ်းမျှအသားတင်တန်ဖိုး
                                                        </span>,
                                                    value: 
                                                        <span className="text-xs text-gray">
                                                            {new Intl.NumberFormat().format(response.total_net_price)} ကျပ်
                                                        </span>
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
                                    <ReportCard
                                        rowLabelWidth="1/2"
                                        title={
                                            <div className="flex items-center my-1">
                                                <span className="text-sm font-bold">
                                                    စာရင်းရှိအရေအတွက်
                                                </span>
                                            </div>
                                        }
                                        rowData={[
                                            ...groups.map( group => {
                                                return {
                                                    label: 
                                                        <span className={`text-xs font-bold text-${group.color}`}>
                                                            {group.name}
                                                        </span>,
                                                    value: 
                                                        <span className="text-xs text-soft-black">
                                                            { response.by_group.find( e => e.id === group.id).total_count }
                                                        </span>
                                                }
                                            }),
                                            'divider',
                                            {
                                                label: 
                                                    <span className="text-xs font-bold text-black">
                                                        စုစုပေါင်း
                                                    </span>,
                                                value: 
                                                    <span className="text-xs text-soft-black">
                                                        { response.total_count } ခု
                                                    </span>
                                            }

                                        ]}
                                    />
                                </div>

                                <div className="flex items-center justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
                                    <ReportCard
                                        rowLabelWidth="2/5"
                                        title={
                                            <div className="flex items-center my-1">
                                                <span className="text-sm font-bold">
                                                    အသားတင်တန်ဖိုးများ
                                                </span>
                                            </div>
                                        }
                                        rowData={[
                                            ...groups.map( group => {
                                                return {
                                                    label: 
                                                        <span className={`text-xs font-bold text-${group.color}`}>
                                                            {group.name}
                                                        </span>,
                                                    value: 
                                                        <span className="text-xs text-soft-black">
                                                            { new Intl.NumberFormat().format(response.by_group.find( e=> e.id === group.id ).total_net_price) }
                                                        </span>
                                                }
                                            }),
                                            'divider',
                                            {
                                                label: 
                                                    <span className="text-xs font-bold text-black">
                                                        စုစုပေါင်း
                                                    </span>,
                                                value: 
                                                    <span className="text-xs text-soft-black">
                                                        { new Intl.NumberFormat().format(response.total_net_price) } ကျပ်
                                                    </span>
                                            }

                                        ]}
                                    />
                                </div>

                                <div className="flex items-center justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
                                    <ReportCard
                                        rowLabelWidth="2/6"
                                        title={
                                            <div className="flex items-center my-1">
                                                <span className="text-sm font-bold">
                                                    အသားတင်အလေးချိန်များ
                                                </span>
                                            </div>
                                        }
                                        rowData={[
                                            ...groups.map( group => {
                                                return {
                                                    label: 
                                                        <span className={`text-xs font-bold text-${group.color}`}>
                                                            {group.name}
                                                        </span>,
                                                    value: 
                                                        <span className="text-xs text-soft-black">
                                                            <WeightStats total={response.by_group.find( e=> e.id === group.id ).total_net_weight}/>
                                                        </span>
                                                }
                                            }),
                                            'divider',
                                            {
                                                label: 
                                                    <span className="text-xs font-bold text-black">
                                                        စုစုပေါင်း
                                                    </span>,
                                                value: 
                                                    <span className="text-xs text-soft-black">
                                                        <WeightStats total={response.total_net_weight}/>
                                                    </span>
                                            }

                                        ]}
                                    />
                                </div>

                                <div className="flex items-center justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
                                    <ReportCard
                                        rowLabelWidth="2/6"
                                        title={
                                            <div className="flex items-center my-1">
                                                <span className="text-sm font-bold">
                                                    ကျောက်ချိန်ပါအလေးချိန်များ
                                                </span>
                                            </div>
                                        }
                                        rowData={[
                                            ...groups.map( group => {
                                                return {
                                                    label: 
                                                        <span className={`text-xs font-bold text-${group.color}`}>
                                                            {group.name}
                                                        </span>,
                                                    value: 
                                                        <span className="text-xs text-soft-black">
                                                            <WeightStats total={response.by_group.find( e=> e.id === group.id ).total_org_weight}/>
                                                        </span>
                                                }
                                            }),
                                            'divider',
                                            {
                                                label: 
                                                    <span className="text-xs font-bold text-black">
                                                        စုစုပေါင်း
                                                    </span>,
                                                value: 
                                                    <span className="text-xs text-soft-black">
                                                        <WeightStats total={response.total_org_weight}/>
                                                    </span>
                                            }

                                        ]}
                                    />
                                </div>
                            </div>

                            <div className="flex justify-center h-14">
                                <span className="text-soft-gray text-sm font-bold">End of Results</span>
                            </div>

                        </div>
                    ) : (
                        <div className="flex items-center justify-center mt-10">
                            <span className="font-bold text-sm text-soft-gray">
                                There is no item yet!
                            </span>
                        </div>
                    )
                )}

            </ScrollView>

        </StackScreen>
    );
};

export default NetWorthScreen;
