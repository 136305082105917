import { 
	faBan,
	faCircleCheck, 
	faCircleInfo, 
	faCrown, 
	faDollarSign, 
	faFileCirclePlus, 
	faHandHoldingDollar, 
	faPenToSquare, 
	faRotateLeft, 
	faScaleBalanced, 
	faTrashCan, 
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faOpencart } from "@fortawesome/free-brands-svg-icons";

export const notiPropsByStatus = [
	{   
        status: "success",
		color: "green",
		icon: faCircleCheck
	},
    {   
        status: "error",
		color: "red",
		icon: faTriangleExclamation
	},
	{
		status: "warning",
		color: "yellow",
        icon: faTriangleExclamation
	},
	{
		status: "info",
		color: "blue",
        icon: faCircleInfo
	}
];

export const notiPropsByType = [
	{   
        type: "change_pricings",
		color: "yellow",
		icon: faDollarSign
	},
    {   
        type: "sell",
		color: "green",
		icon: faOpencart
	},
	{
		type: "add",
		color: "green",
        icon: faFileCirclePlus
	},
	{
		type: "update",
		color: "blue",
        icon: faPenToSquare
	},
	{
		type: "delete",
		color: "pink",
        icon: faTrashCan
	},
	{
		type: "putback",
		color: "purple",
        icon: faRotateLeft
	},
	{
		type: "edit_request",
		color: "blue",
        icon: faPenToSquare
	},
	{
		type: "delete_request",
		color: "pink",
        icon: faTrashCan
	},
	{
		type: "putback_request",
		color: "purple",
        icon: faRotateLeft
	},
	{
		type: "reject",
		color: "red",
        icon: faBan
	},
	{
		type: "collect_money",
		color: "green",
        icon: faHandHoldingDollar
	},
	{
		type: "pay_debt",
		color: "green",
        icon: faHandHoldingDollar
	},
	{
		type: "add_trader_weight",
		color: "gray",
        icon: faScaleBalanced
	},
	{
		type: "make_admin",
		color: "yellow",
        icon: faCrown
	}
];