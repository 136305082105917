import React, { useCallback, useMemo, useState, memo } from 'react';
import axios_custom from '../axios_config/axios_custom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faEye, faEyeSlash, faRightLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../store/reducers/app-reducer';
import { SubmitButton } from './inputs';
import Weight from '../helper/weight';
import ActivityIndicator from './ActivityIndicator';
import useStoreHelper from '../hooks/useStoreHelper';
import Collapsible from './Collapsible';
import WeightStats from './WeightStats';
import AnimatedCard from './AnimatedCard';
import PairRows from './PairRows';
import DateParser from '../helper/dateParser';

const TaxToggle = memo(({ value, onChange}) => {
    return (
        <button 
            className={`flex flex-row items-center justify-center space-x-1 rounded-full px-2 py-1 ${ value ? 'bg-green' : 'bg-dim-gray'}`}
            onClick={onChange}    
        >
            <FontAwesomeIcon
                icon={faRightLeft}
                color={ value ? 'white' : 'gray'}
                size="2xs"
            />
            <span className={`text-2xs ${ value ? 'text-white' : 'text-gray' }`}>
                { value ? 'အခွန်ပါပြီး' : 'အခွန်မပါ' }
            </span>
        </button>
    )
})

const ItemCard = ({ 
    onTakeAction,
    item, 
    displayMode, 
    traderWeight, 
    details, 
    buttons, 
    highlightedId 
}) => {

    const dateParser = new DateParser();
    const weight = new Weight();
    const groups = useSelector( state => state.app.groups);
    const authUser = useSelector( state => state.app.authUser);
    const isSettingMode = useSelector( state => state.app.isSettingMode);
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const isCollapsible = details.show.includes('sold');

    const [show, setShow] = useState(true);//show main (the whole) card
    const [collapseItem, setCollapseItem] = useState(isCollapsible);
    const [showPriceWithTax, setShowPriceWithTax] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [showTraderWeight, setShowTraderWeight] = useState(false);

    const group = useMemo( () => groups.find( e => e.id === item.group.id), []);

    const attachmentWeightInSate = weight.convertToState(item.attachment_weight);
    const attachmentShowable = (attachmentWeightInSate !== 0) && (displayMode !== 'without_attachment') && item.category.has_attachment;
    const detailsShowable = details.requireSettingMode ? isSettingMode : true;
    const traderWeightShowable = traderWeight.requireSettingMode ? isSettingMode : true;
    const finalPrice = (item.sold_price + item.sold_tax_price) - item.tradein_price - item.deposit_price - item.discount_price;
    const taxPrice = Math.round((item.price * item.quality.tax_percent) / 100);
    const attachmentTaxPrice = Math.round((item.attachment_price * item.quality.tax_percent) / 100)

    //main status
    let main_status;

    if(item.money_collected_by !== null){

        main_status = {
            label: 'ငွေအပ်ပြီး',
            amount: null,
            color: 'teal'
        }

    } else {

        if(finalPrice < 0){

            main_status = {
                label: 'ပြန်အမ်းငွေ',
                amount: finalPrice,
                color: 'orange'
            }
    
        } else if (item.deposit_price === null || item.deposit_price === 0) {
    
            main_status = {
                label: 'ငွေအပ်ရန်',
                amount: finalPrice,
                color: 'teal'
            }
    
        } else {
    
            main_status = {
                label: 'စရံငွေအပ်ရန်',
                amount: item.deposit_price,
                color: 'sky'
            }
        }
    }

    //voucher final amount
    let voucher;

    if(finalPrice < 0){

        voucher = {
            label: 'ပိုငွေ',
            amount: finalPrice,
            color: 'sky'
        }

    } else if ( item.has_debt ) {

        voucher = {
            label: 'ကျန်ငွေ',
            amount: finalPrice,
            color: 'red'
        }

    } else {

        voucher = {
            label: 'သင့်ငွေ',
            amount: finalPrice,
            color: 'green'
        }
    }

    let header;

    switch (details.header) {
        case 'added':
            header = {
                name: item.added_by.nickname,
                date: dateParser.toString(item.added_date)
            }
        break;

        case 'sold':
            header = {
                name: item.sold_by.nickname,
                date: dateParser.toString(item.sold_date)
            }
        break;

        case 'modified':
            header = {
                name: item.modified_by.nickname,
                date: dateParser.toString(item.modified_date)
            }
        break;

        case 'requested':
            header = {
                name: item.requested_by.nickname,
                date: dateParser.toString(item.requested_date)
            }
        break;

        default:
            header = {
                name: '',
                date: ''
            };
        break;
    }

    //methods
        const denyAction = useCallback( () => {
            dispatch(setAlert({
                msg: "You don't have permission to do this action!",
                status: 'error',
                timeout: 5000
            }));
        }, []);

        const handleButtonClick = useCallback( (onClick) => {
            if(typeof(onClick) === 'function'){
                onClick();
            } else {

                switch (onClick.action) {
                    
                    case 'delete':

                        if(authUser.abilities.includes('manage_items')){

                            if(window.confirm('\nစာရင်းဖျက်မှာသေချာပါသလား?')){
                                setIsPending(true);
                                axios_custom.delete('items/' + onClick.params.id)
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, 'item');
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }
                        
                        } else {
                        
                            if(window.confirm('\nAdmin approve လုပ်ပြီးမှသာစာရင်းဖျက်ပြီးဖြစ်ပါမည်!')){
                                setIsPending(true);
                                axios_custom.post('manage_requests', { id: onClick.params.id, request_type: onClick.action, user: authUser.id })
                                .then( (res) => {
                                    setIsPending(false);
                                    dispatch(setAlert({
                                        msg: res.data.msg,
                                        status: 'success',
                                        timeout: 5000
                                    }));
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }
                        }
                    break;

                    case 'update':

                        if(authUser.abilities.includes('manage_items')){

                            if(window.confirm('\nစာရင်းပြင်မှာ သေချာပါသလား?')){
                                setIsPending(true);        
                                axios_custom.put('items/' + onClick.params.id)
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, 'item');
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }

                        } else {
                            denyAction();
                        }
                    break;

                    case 'putback':

                        if(authUser.abilities.includes('manage_items')){

                            if(window.confirm('\nစာရင်းပြန်ထည့်မှာ သေချာပါသလား?')){
                                setIsPending(true);
                                axios_custom.put('items/' + onClick.params.id + '/putback')
                                .then( (res) => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, 'item');
                                    }, 1000);

                                    dispatch(setAlert({
                                        msg: res.data.msg,
                                        status: 'success',
                                        timeout: 3000
                                    }));
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }

                        } else {

                            if(window.confirm('\nAdmin approve လုပ်ပြီးမှသာစာရင်းပြန်ထည့်ပြီးဖြစ်ပါမည်!')){
                                setIsPending(true);
                                axios_custom.post('manage_requests', { id: onClick.params.id, request_type: onClick.action, user: authUser.id })
                                .then( (res) => {
                                    setIsPending(false);
                                    dispatch(setAlert({
                                        msg: res.data.msg,
                                        status: 'success',
                                        timeout: 5000
                                    }));
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }
                        }
                        
                    break;

                    case 'reject':

                        if(authUser.abilities.includes('manage_items')){

                            if(window.confirm('\nပယ်ဖျက်မှာ သေချာပါသလား?')){
                                setIsPending(true);
                                axios_custom.delete('manage_requests/' + onClick.params.id)
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, 'item');
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }

                        } else {
                            denyAction();
                        }

                    break;

                    case 'collect_money':
                    
                        if(authUser.abilities.includes('access_finance')){

                            let collectable_money;
                            
                            if(item.deposit_price === null || item.deposit_price === 0){
                                collectable_money = finalPrice;
                            } else {
                                collectable_money = item.deposit_price;
                            }

                            if(window.confirm(`\n${collectable_money} ကျပ်`)){
                                setIsPending(true);        
                                axios_custom.put('items/' + onClick.params.id + '/collect_money', { user: authUser.id })
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, 'item');
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(true);
                                    handleError(error);
                                });
                            }

                        } else {
                            denyAction();
                        }                

                    break;

                    case 'cash_back':
                    
                        if(authUser.abilities.includes('access_finance')){

                            if(window.confirm(`\n${Math.abs(finalPrice)} ကျပ်`)){
                                setIsPending(true);
                                axios_custom.put('items/' + onClick.params.id + '/collect_money', { user: authUser.id })
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, 'item');
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }

                        } else {
                            denyAction();
                        }             
                        
                    break;

                    default:
                    break;
                }
            }
        }, []);

    return (
        <AnimatedCard show={show}>
            <div className={`flex flex-col relative m-2 rounded-xl shadow-custom ${item.id === highlightedId ? 'bg-dim-yellow' : 'bg-white'}`}>

                { isPending ? (
                    //pending mask
                    <div className="flex absolute top-0 bottom-0 left-0 right-0 items-center justify-center bg-dim-gray opacity-50 rounded-xl">
                        <ActivityIndicator/>
                    </div>
                ) : ''}
                
                {item.id === highlightedId ? (
                    <div className="mt-2">
                        <span className="text-red italic text-sm font-bold mx-3">* New</span>
                    </div>
                ) : ''}

                {/* header */}
                { (details.header !== undefined && details.header !== '') ? (
                    <div className={`flex flex-row justify-between mx-3 ${ (item.id === highlightedId) ? 'mt-2' : 'mt-4'}`}>
                        <span className="text-sm font-bold">{ header.name }</span>
                        <span className="text-sm font-bold">{ header.date }</span>
                    </div>
                ) : ''}

                {/* sold detail header */}
                { details.show.includes('sold') ? (
                    <div className="px-3 border-b border-b-dim-gray py-6 space-y-6">

                        { details.show.includes('collectable_money') ? (
                            <div className="flex flex-row justify-center items-center">
                                <span className={`text-lg text-${main_status.color}`}>
                                    { main_status.label }
                                    { (main_status.amount !== null) ? (
                                        <span> - { new Intl.NumberFormat().format(Math.abs(main_status.amount)) }</span>
                                    ) : ''}
                                </span>
                            </div>
                        ) : ''}

                        {/* voucher table */}
                        <div className="flex flex-row justify-between space-x-4">
                            <div className="flex flex-col space-y-2">
                                <span className="text-gray text-sm">{ item.customer_name }</span>
                                <span className="text-gray text-sm">{ item.customer_address }</span>
                                <span className="text-gray text-sm">{ item.customer_phone }</span>
                            </div>

                            <div className="flex-1 flex flex-col space-y-3 items-end">
                                <span className="text-gray text-sm">ရွှေစျေး</span>
                                <span className="text-gray text-sm">တန်ဖိုး</span>

                                { (item.sold_tax_price !== null && item.sold_tax_price !== 0) ? (
                                    <span className="text-gray text-sm">အခွန်</span>
                                ) : ''}

                                { (item.tradein_price !== null && item.tradein_price !== 0) ? (
                                    <span className="text-gray text-sm">ပေးရွှေ</span>
                                ) : ''}

                                { (item.deposit_price !== null && item.deposit_price !== 0) ? (
                                    <span className="text-gray text-sm">စရံငွေ</span>
                                ) : ''}

                                { (item.discount_price !== null && item.discount_price !== 0) ? (
                                    <span className="text-gray text-sm">လျှော့</span>
                                ) : ''}

                                <div className="w-full border-b border-b-gray py-1 opacity-0"></div>
                                
                                <span className={`font-bold text-sm ${(item.tradein_price + item.deposit_price + item.discount_price <= 0) ? 'mt-8' : 'mt-7' }`}>
                                    { voucher.label }
                                </span>
                            </div>

                            <div className="flex flex-col space-y-3 items-end">
                                <span className="text-gray text-sm">{ item.sold_goldprice }</span>
                                <span className="text-gray text-sm">{ item.sold_price }</span>

                                { (item.sold_tax_price !== null && item.sold_tax_price !== 0) ? (
                                    <span className="text-gray text-sm">+ { item.sold_tax_price }</span>
                                ) : ''}

                                { (item.tradein_price !== null && item.tradein_price !== 0) ? (
                                    <span className="text-gray text-sm">- { item.tradein_price }</span>
                                ) : ''}

                                { (item.deposit_price !== null && item.deposit_price !== 0) ? (
                                    <span className="text-gray text-sm">- { item.deposit_price }</span>
                                ) : ''}
                            
                                { (item.discount_price !== null && item.discount_price !== 0) ? (
                                    <span className="text-gray text-sm">- { item.discount_price }</span>
                                ) : ''}

                                <div className="w-full border-b border-b-gray py-1"></div>
                                
                                <span className={`font-bold text-sm text-${voucher.color}`}>{ Math.abs(voucher.amount) }</span>
                            </div>
                        </div>
                    </div>
                ) : ''}

                {/* item collapse toggle */}
                { isCollapsible ? (
                    <div className="cursor-pointer" onClick={ () => setCollapseItem( prev => !prev) }>
                        <div className={`flex flex-row items-center justify-center space-x-3 py-4 bg-dim-${group.color}`}>
                            <span className="font-bold text-sm">{ group.name } { item.serial.replace(/[^\d.-]/g, '') }</span>
                            <FontAwesomeIcon icon={collapseItem ? faChevronDown : faChevronUp} size="xs"/>
                        </div>
                    </div>
                ) : ''}

                {/* item */}
                <Collapsible 
                    collapsed={collapseItem}
                    onClick={ isCollapsible ? () => setCollapseItem(true) : null }
                >
                    <div className={ isCollapsible ? 'mb-5' : 'mb-3' }>

                        {/* image */}
                        <div className="flex justify-center mt-10">
                            <img
                                className="rounded-xl"
                                src={`${process.env.REACT_APP_API_URL}/images/${item.image}`}
                                style={{ width: 150, height: 150}}
                            />
                        </div>

                        {/* id */}
                        <div className="flex justify-center mt-5">
                            <span className="font-bold text-2xl">ID - { item.serial.replace(/[^\d.-]/g, '') }</span>
                        </div>

                        {/* name */}
                        <div className="flex flex-row justify-center items-center space-x-1 mt-2">
                            <span className="text-cyan font-bold text-sm">
                                { item.name }
                            </span>

                            { item.category.suffix ? (
                                <span className="text-cyan font-bold text-xs">
                                    ({ (displayMode === 'attachment_only') ? 'အတွဲသီးသန့်' : item.category.suffix })
                                </span>
                            ) : ''}
                        </div>

                        {/* price */}
                        { displayMode !== 'attachment_only' ? (
                            <div className="flex justify-center mt-2">
                                { item.status !== 'in_stock' ? (
                                    <div className="flex flex-row items-center space-x-2">
                                        <span className="text-red font-bold text-lg">
                                            { new Intl.NumberFormat().format(item.sold_price) } ကျပ်
                                        </span>
                                        <div className="flex items-center justify-center rounded-full px-2 py-1 bg-dim-red">
                                            <span className="text-red text-2xs">
                                                ရောင်းပြီး
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-row items-center space-x-2">
                                        <span className={`font-bold text-lg ${showPriceWithTax ? 'text-purple' : 'text-red'}`}>
                                            { new Intl.NumberFormat().format( showPriceWithTax ? item.price + taxPrice : item.price) } ကျပ်
                                        </span>
                                        <TaxToggle
                                            value={showPriceWithTax}
                                            onChange={() => setShowPriceWithTax(prev => !prev)}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : ''}

                        {/* quality */}
                        { displayMode !== 'attachment_only' && item.quality ? (
                            <div className="flex px-4 mt-3">
                                <div className="flex items-center justify-center rounded-lg bg-yellow">
                                    <span className="text-white text-2xs px-2 py-1">
                                        { item.quality.name }
                                    </span>
                                </div>
                            </div>
                        ) : ''}

                        {/* weight */}
                        { displayMode !== 'attachment_only' ? (
                            <PairRows
                                className={`px-4 
                                    ${ displayMode !== 'attachment_only' && item.quality ? 'mt-3' : 'mt-6'} 
                                `}
                                data={[
                                    {
                                        label: 'အသားတင်ရွှေချိန်',
                                        value: <WeightStats total={item.net_weight}/>
                                    },
                                    {
                                        label: 'အလျော့တွက်',
                                        value: <WeightStats total={item.service_weight}/>
                                    }
                                ]}
                                labelWidth="108"
                                color="yellow"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        ) : ''}

                        {/* attachment */}
                        {(attachmentShowable) ? (
                        <>
                            { displayMode !== 'attachment_only' ? (
                                <>
                                    {/* divider */}
                                    <div className="border-b mt-5 mb-4 border-b-dim-gray"></div>
                                    <div className="flex justify-center">
                                        <span className="text-cyan font-bold text-sm">
                                            အတွဲသီးသန့်
                                        </span>
                                    </div>
                                </>
                            ) : ''}

                            { item.status !== 'in_stock' ? (
                                <div className="flex flex-row items-center space-x-2 justify-center mt-2">
                                    <span className="text-red font-bold text-lg">
                                        { new Intl.NumberFormat().format(item.sold_price) } ကျပ်
                                    </span>
                                    <div className="flex items-center justify-center rounded-full px-2 py-1 bg-dim-red">
                                        <span className="text-red text-2xs">
                                            ရောင်းပြီး
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-row items-center space-x-2 justify-center mt-2">
                                    <span className={`font-bold text-lg ${showPriceWithTax ? 'text-purple' : 'text-red'}`}>
                                        { new Intl.NumberFormat().format( showPriceWithTax ? item.attachment_price + attachmentTaxPrice : item.attachment_price) } ကျပ်
                                    </span>
                                    <TaxToggle
                                        value={showPriceWithTax}
                                        onChange={() => setShowPriceWithTax(prev => !prev)}
                                    />
                                </div>
                            )}

                            {/* quality */}
                            { displayMode === 'attachment_only' && item.quality ? (
                                <div className="flex px-4 mt-3">
                                    <div className="flex items-center justify-center rounded-lg bg-yellow">
                                        <span className="text-white text-2xs px-2 py-1">
                                            { item.quality.name }
                                        </span>
                                    </div>
                                </div>
                            ) : ''}

                            {/* attachment_weight */}
                            <PairRows
                                className={`px-4 
                                    ${ displayMode === 'attachment_only' && item.quality ? 'mt-3' : 'mt-6'} 
                                `}
                                data={[
                                    {
                                        label: 'အသားတင်ရွှေချိန်',
                                        value: <WeightStats total={item.attachment_weight}/>
                                    },
                                    {
                                        label: 'အလျော့တွက်',
                                        value: <WeightStats total={item.attachment_service_weight}/>
                                    }
                                ]}
                                labelWidth="108"
                                color="yellow"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />

                            {/* divider */}
                            { displayMode !== 'attachment_only' ? (
                                <div className="border-b mt-5 mb-4 border-b-dim-gray"></div> 
                            ) : ''}
                        </>
                        ) : ''}

                        {/* additional_price */}
                        { item.category.additional_price ? (
                            <PairRows
                                className="px-4 mt-6"
                                data={[
                                    {
                                        label: item.category.additional_price_name,
                                        value: item.category.additional_price
                                    }
                                ]}
                                color="pink"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        ) : ''}

                        {/* gem */}
                        { item.has_gem ? (
                            <PairRows
                                className="px-4 mt-6"
                                data={[
                                    {
                                        label: 'ကျောက်ချိန်',
                                        value: <WeightStats total={item.gem_weight}/>
                                    },
                                    {
                                        label: 'ကျောက်ဖိုး',
                                        value: item.gem_price
                                    }
                                ]}
                                labelWidth="73"
                                color="green"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        ) : ''}

                        {/* traderWeight */}
                        {( item.trader_weight && traderWeightShowable && traderWeight.show && traderWeight.type === 'inline' ) ? (
                            <PairRows
                                className="px-4 mt-6"
                                data={[
                                    {
                                        label: 'ကုန်သည်အလျော့',
                                        value: <WeightStats total={item.trader_weight}/>
                                    }
                                ]}
                                color="red"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        ) : ''}

                        {/* spec */}
                        <PairRows
                            className="px-4 mt-6"
                            data={ 
                                item.category.has_size ? [
                                    {
                                        label: item.group.size_name,
                                        value: Number(item.size)
                                    },
                                    {
                                        label: 'အမျိုးအစား',
                                        value: item.category.name
                                    }
                                ] : [
                                    {
                                        label: 'အမျိုးအစား',
                                        value: item.category.name
                                    }
                                ]
                            }
                            labelWidth="75"
                            color="gray"
                            size="sm"
                            spaceY="2"
                            spaceX="1"
                        />

                        { ( item.trader_weight_added && traderWeightShowable && traderWeight.show && traderWeight.type === 'toggle') ? (
                        <>
                            <div className="flex flex-row px-4 items-center justify-center space-x-3 mt-6">
                                <span className="font-bold text-green text-sm">
                                    ကုန်သည်အလျော့ auto ထည့်ပြီး
                                </span>
                                { item.trader_weight ? (
                                    <button onClick={ () => setShowTraderWeight( prev => !prev) }>
                                        <FontAwesomeIcon icon={showTraderWeight ? faEyeSlash : faEye} color="gray"/>
                                    </button>
                                ) : ''}
                            </div>
                            { (item.trader_weight && showTraderWeight) ? (
                                <div className="flex flex-row items-center justify-center mt-3">
                                    <span className="text-xs text-gray">
                                        <WeightStats total={item.trader_weight}/>
                                    </span>
                                </div>
                            ) : ''}
                        </>
                        ) : ''}
                        
                    </div>
                </Collapsible>

                {/* other details */}
                { detailsShowable ? (
                    <div className={ isCollapsible ? 'pt-5 border-t space-y-6 border-t-dim-gray' : 'mt-3'}>

                        {/* added_detail */}
                        { details.show.includes('added') ? (
                            <PairRows
                                className="px-4"
                                data={[
                                    {
                                        label: 'စာရင်းထည့်သူ',
                                        value: item.added_by.nickname
                                    },
                                    {
                                        label: 'စာရင်းထည့်သောနေ့',
                                        value: dateParser.toString(item.added_date)
                                    }
                                ]}
                                labelWidth="119"
                                color="gray"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        ) : ''}

                        {/* sold_detail */}
                        { details.show.includes('sold') ? (
                            <PairRows
                                className="px-4"
                                data={[
                                    {
                                        label: 'အရောင်းဝန်ထမ်း',
                                        value: item.sold_by.nickname
                                    },
                                    {
                                        label: 'ရောင်းသောနေ့',
                                        value: dateParser.toString(item.sold_date)
                                    }
                                ]}
                                labelWidth="103"
                                color="gray"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        ) : ''}

                        {/* manage_request_detail */}
                        { details.show.includes('requested') ? (
                            <PairRows
                                className="px-4"
                                data={[
                                    {
                                        label: 'စာရင်းပြင်ခွင့်တောင်းသူ',
                                        value: item.requested_by.nickname
                                    },
                                    {
                                        label: 'တောင်းဆိုသောနေ့',
                                        value: dateParser.toString(item.requested_date)
                                    }
                                ]}
                                labelWidth="144"
                                color="gray"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        ) : ''}
                        
                        {/* money_collected_details */}
                        { details.show.includes('money_collected') && (item.money_collected_by !== null) ? (
                            <PairRows
                                className="px-4"
                                data={[
                                    {
                                        label: 'ငွေအပ်လက်ခံသူ',
                                        value: item.money_collected_by.nickname
                                    },
                                    {
                                        label: 'ငွေအပ်သောနေ့',
                                        value: dateParser.toString(item.money_collected_date)
                                    }
                                ]}
                                labelWidth="103"
                                color="gray"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        ) : ''}
                    </div>
                ) : ''}
                
                {/* buttons */}
                {/* extra view wrap for some space while empty buttons */}
                <div className="flex items-center justify-center py-3">
                    { buttons.show ? (
                        <div className="flex flex-1 flex-row items-center mx-3 my-2">
                                
                            { (!isSettingMode && buttons.normal_buttons.length !== 0) &&
                                buttons.normal_buttons.map( (button, index) =>
                                    <SubmitButton
                                        key={index}
                                        title={button.name}
                                        color={button.color}
                                        textColor={button.textColor}
                                        icon={button.icon}
                                        height="12"
                                        radius={`${ index === 0 && 'rounded-l-lg'} ${ button.id === buttons.normal_buttons.length && 'rounded-r-lg'}`}
                                        onClick={ () => handleButtonClick(button.onClick) }
                                    />
                            )}

                            { (isSettingMode && buttons.setting_mode_buttons.length !== 0) && 
                                buttons.setting_mode_buttons.map( (button, index) =>
                                    <SubmitButton
                                        key={index}
                                        title={button.name}
                                        color={button.color}
                                        textColor={button.textColor}
                                        icon={button.icon}
                                        height="12"
                                        radius={`${ index === 0 && 'rounded-l-lg'} ${ button.id === buttons.setting_mode_buttons.length && 'rounded-r-lg'}`}
                                        onClick={ () => handleButtonClick(button.onClick) }
                                    />
                            )}

                            {/* when setting_mode_buttons are null, show normal */}
                            { (isSettingMode && buttons.setting_mode_buttons.length === 0) && 
                                buttons.normal_buttons.map( (button, index) =>
                                    <SubmitButton
                                        key={index}
                                        title={button.name}
                                        color={button.color}
                                        textColor={button.textColor}
                                        icon={button.icon}
                                        height="12"
                                        radius={`${ index === 0 && 'rounded-l-lg'} ${ button.id === buttons.normal_buttons.length && 'rounded-r-lg'}`}
                                        onClick={ () => handleButtonClick(button.onClick) }
                                    />
                            )}

                        </div>
                    ) : ''}
                </div>

            </div>
        </AnimatedCard>
    )
}

export default memo(ItemCard, () => true);