import React, { memo } from "react";
import loader from "../assets/system_images/loader.gif";

const ActivityIndicator = ({size = ''}) => {

    const offsets = [
        {
            size: '2xs',
            offset: 10,
        },
        {
            size: 'xs',
            offset: 15,
        },
        {
            size: 'sm',
            offset: 20,
        },
        {
            size: '',
            offset: 25,
        },
        {
            size: 'lg',
            offset: 30,
        },
        {
            size: 'xl',
            offset: 40,
        },
        {
            size: '2xl',
            offset: 50,
        },
    ];

    return (
        <img 
            src={loader} 
            alt="Loading..."
            style={{
                width: offsets.find( e => e.size === size ).offset,
                height: offsets.find( e => e.size === size ).offset
            }}

        />
    );
};

export default memo(ActivityIndicator);
