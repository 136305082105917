import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import colors from "../helper/colors";
import ItemCard from "../components/ItemCard";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import SectionHeader from "../components/SectionHeader";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../components/inputs";
import { faCheck, faChevronDown, faChevronUp, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

const ImageAddedScreen = () => {

    const { handleError } = useStoreHelper();

    const currentRoute = useSelector( state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const addedIds = routeState.addedIds;
    const failedItems = JSON.parse(routeState.failedItems);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    //Retry
    const [currentRetryingId, setCurrentRetryingId] = useState(null);
    const [isRetryingAll, setIsRetryingAll] = useState(false);
    const [retriedIds, setRetriedIds] = useState([]);
    const [retrySuccessIds, setRetrySuccessIds] = useState([]);

    //reDelete
    const [isDeleting, setIsDeleting] = useState(false);
    const [deletedIds, setDeletedIds] = useState([]);

    //fetched
    const [fetchedItems, setFetchedItems] = useState({
        added: [],
        retried: []
    });

    //computed
        const fetchableItems = useMemo( () => {
            const added = addedIds.map( e => {
                return {
                    id: e,
                    type: 'added'
                }
            });
            const retried = retrySuccessIds.map( e => {
                return {
                    id: e,
                    type: 'retried'
                }
            })
            return [...added, ...retried];
        }, [addedIds, retrySuccessIds]);

    //watchers
        //retry
        useEffect( () => {
            const targetItem = failedItems.find( e => !retrySuccessIds.includes(e.id) && !retriedIds.includes(e.id) );

            if(!targetItem){
                setIsRetryingAll(false);
                setRetriedIds([]);
            }

            if(isRetryingAll && targetItem){
                setRetriedIds( prev => [...prev, targetItem.id]);
                retry(targetItem.id, targetItem.image);
            }
        }, [isRetryingAll, retrySuccessIds]);
        
        //delete
        useEffect( () => {
            if(isDeleting){

                const deleteableIds = [...addedIds, ...retrySuccessIds];
                const targetItemId = deleteableIds.find( id => !deletedIds.includes(id) );
                
                if(targetItemId){
                    axios_custom.delete('images/' + targetItemId)
                    .then( () => {
                        setDeletedIds( prev => [...prev, targetItemId]);
                    })
                    .catch( () => {
                        setDeletedIds( prev => [...prev, targetItemId]);
                    });
                } else {
                    setIsDeleting(false);
                    setFetchedItems({
                        added: [],
                        retried: []
                    });
                }
            }
        }, [isDeleting, deletedIds]);

        //fetch
        useEffect( () => {
            const fetchedItemIds = [...fetchedItems.added.map( e => e.id ), ...fetchedItems.retried.map( e => e.id )];
            const targetItem = fetchableItems.find( e => !fetchedItemIds.includes(e.id) );

            if(targetItem){
                axios_custom.get('items/' + targetItem.id)
                .then( (res) => {
                    setFetchedItems( prev => {
                        const newData = {...prev};
                        newData[targetItem.type] = [...newData[targetItem.type], res.data.result];
                        return newData;
                    });
                })
                .catch( (error) => {
                    handleError(error);
                });
            } 
        }, [fetchableItems, fetchedItems]);

    //other methods
        const retry = (id, image) => {

            setCurrentRetryingId(id);
            
            const formData = new FormData();
            formData.append('id', id);
            formData.append('image', image);

            axios_custom.post('images', formData)
            .then( () => {
                setRetrySuccessIds( prev => [...prev, id]);
                setCurrentRetryingId(null);
            })
            .catch( () => {
                setRetrySuccessIds([...retrySuccessIds]);
                setCurrentRetryingId(null);
            });
        }

        const reDelete = useCallback( () => {
            if(window.confirm('\nပုံအားလုံးပြန်ဖျက်မှာသေချာပါသလား?')){
                setIsDeleting(true);
            }
        }, []);

        const getFailedItemColor = (id, type) => {
            if(retrySuccessIds.includes(id)){
                return type === 'text' ? 'text-green' : colors.find( e => e.org === 'green').normal;
            }

            if(!currentRetryingId){
                return type === 'text' ? 'text-red' : colors.find( e => e.org === 'red').normal;
            }

            if(currentRetryingId === id){
                return type === 'text' ? 'text-red' : colors.find( e => e.org === 'red').normal;
            } else {
                return type === 'text' ? 'text-soft-red' : colors.find( e => e.org === 'red').soft;
            }
        }

    return (
        <StackScreen
            backRequireConfirmation={true}
            confirmationMsg={`\nပုံအားလုံးမှန်ကန်စွာထည့်ပြီးပါသလား?\n Back လုပ်ပြီးနောက် ပြန်ဖျက်လိုပါက တစ်ပုံချင်းစီသာပြန်ဖျက်နိုင်မည်ဖြစ်သည်!`}
            renderRight={
                <Button
                    title="Delete All"
                    color="red"
                    activated={isDeleting}
                    activatedText="Deleting"
                    position="left"
                    onClick={reDelete}
                />
            }
        >
            <div className={`flex flex-col w-screen overflow-auto bg-soft-white transition-height duration-300 ${ isDrawerOpen ? 'h-72 border-b border-b-muted' : 'h-0'}`}>

                { failedItems.length === 0 ? (
                    <div className="flex flex-col space-y-2 flex-1 items-center justify-center overflow-auto">
                        <span className="text-sm text-green font-bold">
                            All images are uploaded successfully!
                        </span>
                        <span className="text-soft-gray text-xs">
                            Failed items will appear here.
                        </span>
                    </div>
                ) : (
                    <div className="flex-1 flex flex-col">
                        <div className="mt-5 mx-5">
                            <div className="flex justify-end">
                                <button
                                    className="flex flex-row items-center justify-end space-x-1"
                                    onClick={ () => setIsRetryingAll(true) }
                                >
                                    { isRetryingAll ? (
                                        <FontAwesomeIcon
                                            className="animate-spin-left"
                                            size="sm"
                                            icon={faRotateLeft} 
                                            color={colors.find( e => e.org === 'green').soft}    
                                            direction="left"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            size="sm"
                                            icon={faRotateLeft} 
                                            color={colors.find( e => e.org === 'green').normal}    
                                        />
                                    )}
                                    <span className="flex self-end text-sm text-green font-bold">
                                        Retry All
                                    </span>
                                </button>
                            </div>
                            
                            {/* failed items list */}
                            <div className="my-5">

                                { failedItems.map( item => 
                                    <div 
                                        key={item.id} 
                                        className="flex flex-row justify-between items-center space-x-8 my-4"
                                    >
                                        <div className="flex-1 flex flex-col">
                                            <span 
                                                className={`font-bold text-sm ${getFailedItemColor(item.id, 'text')}`}
                                            >
                                                ID: {item.serial.replace(/[^\d.-]/g, '')}
                                            </span>
                                            <span className={`text-2xs ${getFailedItemColor(item.id, 'text')}`}>
                                                { retrySuccessIds.includes(item.id) ? 
                                                    'Succeed! Check it in the list.' : 
                                                    'Error: ' + item.errorMsg
                                                }
                                            </span>
                                        </div>
                                        
                                        { retrySuccessIds.includes(item.id) ? (
                                            <div className="flex flex-row items-center justify-center space-x-1">
                                                <FontAwesomeIcon 
                                                    icon={faCheck} 
                                                    color={colors.find(e => e.org === 'green').normal}
                                                    size="sm"    
                                                />
                                                <span className="text-green text-sm">Done</span>
                                            </div>
                                        ) : (
                                            <button 
                                                className="flex flex-row items-center space-x-1"
                                                onClick={ () => retry(item.id, item.image) }
                                                disabled={currentRetryingId !== null}
                                            >
                                                { currentRetryingId === item.id ? (
                                                    <FontAwesomeIcon
                                                        className="animate-spin-left"
                                                        icon={faRotateLeft} 
                                                        color={getFailedItemColor(item.id, 'icon')}
                                                        direction="left"
                                                        size="sm"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        size="sm"
                                                        icon={faRotateLeft} 
                                                        color={getFailedItemColor(item.id, 'icon')}
                                                    />
                                                )}
                                                <span className={`text-sm font-bold ${getFailedItemColor(item.id, 'text')}`}>
                                                    Retry
                                                </span>
                                            </button>
                                        )}
                                        
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                )}
                
            </div>
            
            <button 
                className="flex flex-row h-12 bg-white justify-between items-center border-b border-b-dim-gray"
                onClick={ () => setIsDrawerOpen( prev => !prev )}
            >
                <div className="flex flex-row items-center justify-center space-x-1 ml-4">
                    <span className="text-green font-bold text-xs">
                        { addedIds.length + retrySuccessIds.length }
                    </span>
                    <span className="text-green text-xs">
                        Succeed
                    </span>
                    <span className="text-sm"> | </span>
                    <span className="text-red font-bold text-xs">
                        { failedItems.length - retrySuccessIds.length}
                    </span>
                    <span className="text-red text-xs">
                        Failed
                    </span>
                </div>
                <div className="mr-5">
                    <FontAwesomeIcon 
                        icon={ isDrawerOpen ? faChevronUp : faChevronDown} 
                        color={colors.find( e => e.org === 'gray').normal }    
                    />
                </div>
            </button>

            <ScrollView className="flex-1 flex flex-col">
                <div className="flex flex-col my-2">

                    <div className="flex flex-col space-y-5">
                        { fetchedItems.added.length !== 0 ? (
                            <div className="flex flex-col">
                                <div className="flex-1 flex flex-row flex-wrap">
                                    {fetchedItems.added.map( item =>
                                        <div key={item.id} className="flex justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
                                            <ItemCard
                                                item={item}
                                                displayMode="all"
                                                details={{ show: [] }}
                                                traderWeight={{ show: false }}
                                                buttons={{ show: false }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : ''}
                        
                        { fetchedItems.retried.length !== 0 ? (
                            <div className="flex flex-col">
                                <SectionHeader
                                    title="Retry Succeed Items"
                                    color="green"
                                />
                                <div className="flex-1 flex flex-row flex-wrap">
                                    {fetchedItems.retried.map( item =>
                                        <div key={item.id} className="flex justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
                                            <ItemCard
                                                item={item}
                                                displayMode="all"
                                                details={{ show: [] }}
                                                traderWeight={{ show: false }}
                                                buttons={{ show: false }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : ''}
                    </div>
                    
                    { fetchedItems.added.length + fetchedItems.retried.length === 0 ? (
                        <div className="flex items-center justify-center h-16">
                            <span className="text-soft-gray font-bold">No Images Added</span>
                        </div>
                    ) : (
                        fetchableItems.length === fetchedItems.added.length + fetchedItems.retried.length ? (
                            <div className="flex items-center justify-center h-16">
                                <span className="text-soft-gray text-sm font-bold">End of Results</span>
                            </div>
                        ) : ''
                    )}

                </div>
            </ScrollView>

        </StackScreen>
    );
};

export default ImageAddedScreen;
