import React, { useState, useCallback, memo, useMemo} from 'react';
import axios_custom from '../axios_config/axios_custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import WeightStats from './WeightStats';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../store/reducers/app-reducer';
import useStoreHelper from "../hooks/useStoreHelper";
import AnimatedCard from './AnimatedCard';
import Collapsible from './Collapsible';
import ActivityIndicator from './ActivityIndicator';
import PairRows from './PairRows';
import { SubmitButton } from './inputs';
import DateParser from '../helper/dateParser';

const DebtCard = ({ 
    onTakeAction, 
    data, 
    type, 
    highlightedId, 
    details, 
    showVoucher, 
    buttons
}) => {

    const dateParser = new DateParser();
    const authUser = useSelector( state => state.app.authUser);
    const groups = useSelector( state => state.app.groups );
    const isSettingMode = useSelector( state => state.app.isSettingMode);
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const [show, setShow] = useState(true);//show main (the whole) card
    const [isPending, setIsPending] = useState(false);
    const [showCollapsed, setShowCollapsed] = useState(false);

    const itemExists = type === 'paid_debt' ? Boolean(data.debt.item_id) : Boolean(data.item_id);
    const item = type === 'paid_debt' ? data.debt.item : data.item;

    const group = useMemo( () => itemExists ? groups.find( e => e.id === item.group.id ) : {}, []);

    let customer;
    if( type === 'paid_debt' ){
        customer = {   
            name: data.debt.customer_name,
            address: data.debt.customer_address,
            phone: data.debt.customer_phone,
        }
    } else {
        customer = {   
            name: data.customer_name,
            address: data.customer_address,
            phone: data.customer_phone,
        }
    }

    let card_color;
    if(data.id === highlightedId){
        card_color = 'bg-milk';
    } else if (data.amount <= 0){
        card_color = 'bg-muted';
    } else {
        card_color = 'bg-white';
    }

    //methods
        const deletePaidDebt = useCallback( (paid_debt_id) => {

            if( authUser.abilities.includes('manage_items') ){
                
                if(window.confirm('\nဖျက်မှာသေချာပါသလား?')){
                    axios_custom.delete('paid_debts/' + paid_debt_id)
                    .then( (res) => {

                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));

                        onTakeAction();
                    })
                    .catch( (error) => {
                        handleError(error);
                    });
                }

            } else {

                if(window.confirm('\nAdmin approve လုပ်ပြီးမှသာစာရင်းဖျက်ပြီးဖြစ်ပါမည်!')){
                    axios_custom.put('paid_debts/' + paid_debt_id, { user: authUser.id })
                    .then( (res) => {

                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));
                        
                        onTakeAction();
                    })
                    .catch( (error) => {
                        handleError(error);
                    });
                }
            }
        }, []);

        const denyAction = useCallback( () => {
            dispatch(setAlert({
                msg: "You don't have permission to do this action!",
                status: 'error',
                timeout: 5000
            }));
        }, []);

        const handleButtonClick = useCallback ( (onClick) => {
            if(typeof(onClick) === 'function'){
                onClick();
            } else {
                //instant actions
                switch (onClick.action) {

                    case 'collect_money':

                        //only for owner
                        if( authUser.abilities.includes('access_finance') ){
                            
                            if(window.confirm(`\n${data.amount} ကျပ်`)){
                                setIsPending(true);
                                axios_custom.put('paid_debts/' + onClick.params.id + '/collect_money', { user: authUser.id })
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, 'debt');
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                })
                            }

                        } else  {
                            denyAction();
                        }       
                            
                    break;

                    case 'delete':

                        if( authUser.abilities.includes('manage_items') ){

                            if(window.confirm(`\nဖျက်မှာ သေချာပါသလား?`)){
                                setIsPending(true);
                                const url = type === 'paid_debt' ? 'paid_debts/' : 'debts/';
                                axios_custom.delete(url + onClick.params.id)
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, type);
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }

                        } else {

                            if(window.confirm(`\nAdmin approve လုပ်ပြီးမှသာစာရင်းဖျက်ပြီးဖြစ်ပါမည်!`)){
                                setIsPending(true);
                                axios_custom.put('debts/' + onClick.params.id, { user: authUser.id })
                                .then( (res) => {
                                    setIsPending(false);
                                    dispatch(setAlert({
                                        msg: res.data.msg,
                                        status: 'success',
                                        timeout: 3000
                                    }));
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }
                        }
                        
                    break;

                    case 'reject':
                        
                        if( authUser.abilities.includes('manage_items') ){

                            if(window.confirm('\nပယ်ဖျက်မှာ သေချာပါသလား?')){
                                setIsPending(true);
                                const url = type === 'paid_debt' ? 'paid_debts/' : 'debts/';
                                axios_custom.put(url + onClick.params.id + '/reject')
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, type);
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }
                        }

                    break;

                    default:
                    break;
                }
            }
        }, []);

    return (
        <AnimatedCard show={show}>
            <div className={`flex flex-col relative m-2 rounded-xl pb-5 shadow-custom ${card_color}`}>

                { isPending ? (
                    //pending mask
                    <div className={`flex absolute top-0 bottom-0 left-0 right-0 items-center justify-center bg-dim-gray opacity-50 rounded-xl`}>
                        <ActivityIndicator/>
                    </div>
                ) : ''}

                {data.id === highlightedId ? (
                    <div className="mt-2">
                        <span className="text-red italic text-sm font-bold mx-3">* New</span>
                    </div>
                ) : ''}

                {/* header */}
                <div className={`flex flex-row justify-between mx-3 ${ (data.id === highlightedId) ? 'mt-2' : 'mt-4'}`}>
                    <span className="text-sm font-bold">{ customer.name }</span>
                    <span className="text-sm font-bold">
                        { type === 'paid_debt' ? dateParser.toString(data.received_date) : dateParser.toString(data.sold_date) }
                    </span>
                </div>

                <div className="px-3 border-b border-b-dim-gray py-6 space-y-6">

                    <div className="flex flex-row justify-center items-center">
                        { (data.amount > 0) ? (
                            <span className="text-lg text-orange">
                                { type === 'paid_debt' ? 'အကြွေးဆပ်' : 'ကြွေးကျန်'} - { new Intl.NumberFormat().format(data.amount) }
                            </span>
                        ) : (
                            <span className="text-lg text-orange">
                                အကြေပေးပြီး
                            </span>
                        )}
                    </div>

                    {/* voucher calculation */}
                    { showVoucher ? ( 
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-row justify-between space-x-4">
                                <div className="flex flex-col space-y-2">
                                    <span className="text-gray text-sm">{ customer.name }</span>
                                    <span className="text-gray text-sm">{ customer.address }</span>
                                    <span className="text-gray text-sm">{ customer.phone }</span>
                                </div>

                                { itemExists ? (
                                <>
                                    <div className="flex-1 flex flex-col space-y-3 items-end">
                                        <span className="text-gray text-sm">ရွှေစျေး</span>
                                        <span className="text-gray text-sm">တန်ဖိုး</span>

                                        { (item.sold_tax_price !== null && item.sold_tax_price !== 0) ? (
                                            <span className="text-gray text-sm">အခွန်</span>
                                        ) : ''}

                                        { (item.tradein_price !== null && item.tradein_price !== 0) ? (
                                            <span className="text-gray text-sm">ပေးရွှေ</span>
                                        ) : ''}

                                        { (item.deposit_price !== null && item.deposit_price !== 0) ? (
                                            <span className="text-gray text-sm">စရံငွေ</span>
                                        ) : ''}

                                        { (item.discount_price !== null && item.discount_price !== 0) ? (
                                            <span className="text-gray text-sm">လျှော့</span>
                                        ) : ''}

                                        <div className="w-full border-b opacity-0 border-b-gray py-1"></div>
                                        
                                        <span className={`font-bold text-sm ${type === 'debt' && data.paid_debts.length === 0 ? 'text-red' : 'text-black'}`}>
                                            { type === 'debt' && data.paid_debts.length === 0 ? 'ကျန်ငွေ' : 'မူရင်းကျန်ငွေ'}
                                        </span>

                                    </div>

                                    <div className="flex flex-col space-y-3 items-end">
                                        <span className="text-gray text-sm">{ item.sold_goldprice }</span>
                                        <span className="text-gray text-sm">{ item.sold_price }</span>

                                        { (item.sold_tax_price !== null && item.sold_tax_price !== 0) ? (
                                            <span className="text-gray text-sm" >+ { item.sold_tax_price }</span>
                                        ) : ''}

                                        { (item.tradein_price !== null && item.tradein_price !== 0) ? (
                                            <span className="text-gray text-sm">- { item.tradein_price }</span>
                                        ) : ''}

                                        { (item.deposit_price !== null && item.deposit_price !== 0) ? (
                                            <span className="text-gray text-sm">- { item.deposit_price }</span>
                                        ) : ''}
                                    
                                        { (item.discount_price !== null && item.discount_price !== 0) ? (
                                            <span className="text-gray text-sm">- { item.discount_price }</span>
                                        ) : ''}

                                        <div className="w-full border-b border-b-gray py-1"></div>
                                        
                                        <span className={`font-bold text-sm text-${type === 'debt' && data.paid_debts.length === 0 ? 'text-red' : 'text-black'}`}>
                                            { (item.sold_price + item.sold_tax_price) - item.tradein_price - item.deposit_price - item.discount_price }
                                        </span>

                                    </div>
                                </>
                                ) : ''}
                            </div>
                            { (type === 'paid_debt') ? (
                                <div className="flex flex-row items-center justify-end space-x-4">
                                    <div className="flex flex-col space-y-4 items-end">
                                        { !itemExists ? (
                                            <span className="font-bold text-sm text-black">မူရင်းကျန်ငွေ</span>
                                        ) : ''}
                                        <span className="font-bold text-sm text-green">ဆပ်ပြီးငွေပေါင်း</span>
                                        <div className="w-full border-b opacity-0 border-b-gray py-1"></div>
                                        <span className="font-bold text-sm text-red">ကျန်ငွေ</span>
                                    </div>
                                    <div className="flex flex-col space-y-4 items-end">
                                        { !itemExists ? (
                                            <span className="font-bold text-sm text-black">{ data.debt.amount + data.total_paid }</span>
                                        ) : ''}
                                        <span className="font-bold text-sm text-green">- { data.total_paid }</span>
                                        <div className="w-full border-b border-b-gray py-1"></div>
                                        <span className="font-bold text-sm text-red">{  data.debt.amount }</span>
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                    ) : ''}                    
                    
                    { (data.description && type === 'debt') ? ( 
                        <div className="flex mt-3 justify-center items-center space-x-2">
                            <span className="text-soft-gray text-xs pt-2">{ data.description } !</span>
                        </div>
                    ) : ''}
                </div>
        
                {/* paid_debts */}
                { (type === 'debt' && data.paid_debts.length !== 0) ? (
                    <div className="py-4 border-b border-b-dim-gray">

                        <div className="flex items-center justify-center mt-1">
                            <span className="text-cyan text-sm font-bold">
                                အကြွေးဆပ်မှတ်တမ်း
                            </span>
                        </div>

                        <div className="mx-3 mt-5 mb-1">
                            { data.paid_debts.map( (paid_debt, index) => 
                                <div key={index} className="flex my-1 flex-row items-center justify-between h-7">
                                    <div className="flex flex-row items-center space-x-2">
                                        <span className="font-bold text-sm">{ dateParser.toString(paid_debt.received_date) }</span>
                                        <span className="text-gray text-xs">({ paid_debt.received_by.nickname })</span>
                                    </div>
                                    <div className="flex flex-row items-center space-x-4">
                                        <span className="text-sm">{ paid_debt.amount }</span>
                                        { isSettingMode ? (
                                            <button 
                                                className="flex items-center justify-center bg-red rounded h-7 w-7" 
                                                onClick={ () => deletePaidDebt(paid_debt.id)}
                                            >
                                                <FontAwesomeIcon icon={faTrashCan} color="white" size="xs"/>
                                            </button>
                                        ) : ''}
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-row justify-end py-1">
                                <div className="w-32 border-b border-b-gray py-1"></div>
                            </div>

                            <div className="flex flex-row mt-3 justify-end items-center space-x-4">
                                <span className="text-red text-sm font-bold">ကျန်ငွေ</span>
                                <span className="text-red text-sm font-bold">{ data.amount < 0 ? 0 : data.amount }</span>
                            </div>
                        </div>

                    </div>
                ) : ''}

                {/* item collapse toggle */}
                <div className="cursor-pointer" onClick={ () => setShowCollapsed( prev => !prev ) }>
                    <div className={`flex flex-row items-center justify-center space-x-3 py-4 ${itemExists ? 'bg-dim-' + group.color : 'bg-dim-gray'}`}>
                        { itemExists ? (
                            <span className="font-bold text-sm">{ item.group.name } { item.serial.replace(/[^\d.-]/g, '') }</span>
                        ) : (
                            <span className="font-bold text-sm">{ type === 'paid_debt' ? data.debt.name : data.name }</span>
                        )}
                        <FontAwesomeIcon icon={showCollapsed ? faChevronUp : faChevronDown} size="xs"/>
                    </div>
                </div>

                <Collapsible 
                    collapsed={!showCollapsed}
                    onClick={() => setShowCollapsed(false)}
                >
                    {!itemExists ? (
                        <div className="items-center m-2">
                            <img
                                className="w-full"
                                src={`${process.env.REACT_APP_API_URL}/images/${type === 'paid_debt' ? data.debt.image : data.image }`}
                            />
                        </div>
                    ) : (
                        <div className="mb-5">
                            {/* image */}
                            <div className="flex justify-center mt-10">
                                <img
                                    className="rounded-xl"
                                    src={`${process.env.REACT_APP_API_URL}/images/${item.image}`}
                                    style={{ width: 150, height: 150}}
                                />
                            </div>

                            {/* id */}
                            <div className="flex justify-center mt-5">
                                <span className="font-bold text-2xl">ID - { item.serial.replace(/[^\d.-]/g, '') }</span>
                            </div>

                            {/* name */}
                            <div className="flex flex-row justify-center items-center space-x-1 mt-2">
                                <span className="text-cyan font-bold text-sm">
                                    { item.name }
                                </span>
                                { item.category.suffix ? (
                                    <span className="text-cyan font-bold text-xs">
                                        ({ (show === 'attachment_only') ? 'အတွဲသီးသန့်' : item.category.suffix })
                                    </span>
                                ) : ''}
                            </div>

                            {/* price */}
                            <div className="flex justify-center mt-2">
                                <div className="flex flex-row items-center space-x-2">
                                    <span className="text-red font-bold text-lg">
                                        { new Intl.NumberFormat().format(item.sold_price) } ကျပ်
                                    </span>
                                    <div className="flex items-center justify-center rounded-full px-2 py-1 bg-dim-red">
                                        <span className="text-red text-2xs">
                                            ရောင်းပြီး
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* attachment_weight */}
                            { (item.status === 'attachment_only_sold') ? (
                                <PairRows
                                    className="px-4 mt-6"
                                    data={[
                                        {
                                            label: 'အသားတင်ရွှေချိန်',
                                            value: <WeightStats total={item.attachment_weight}/>
                                        },
                                        {
                                            label: 'အလျော့တွက်',
                                            value: <WeightStats total={item.attachment_service_weight}/>
                                        }
                                    ]}
                                    labelWidth="108"
                                    color="yellow"
                                    size="sm"
                                    spaceY="2"
                                    spaceX="1"
                                />
                            ) : ''}

                            {/* weight */}
                            { (item.status === 'sold') ? (
                                <PairRows
                                    className="px-4 mt-6"
                                    data={[
                                        {
                                            label: 'အသားတင်ရွှေချိန်',
                                            value: <WeightStats total={item.net_weight}/>
                                        },
                                        {
                                            label: 'အလျော့တွက်',
                                            value: <WeightStats total={item.service_weight}/>
                                        }
                                    ]}
                                    labelWidth="108"
                                    color="yellow"
                                    size="sm"
                                    spaceY="2"
                                    spaceX="1"
                                />
                            ) : ''}

                            {/* additional_price */}
                            { item.category.additional_price ? (
                                <PairRows
                                    className="px-4 mt-6"
                                    data={[
                                        {
                                            label: item.category.additional_price_name,
                                            value: item.category.additional_price
                                        }
                                    ]}
                                    color="pink"
                                    size="sm"
                                    spaceY="2"
                                    spaceX="1"
                                />
                            ) : ''}

                            {/* gem */}
                            { item.has_gem ? (
                                <PairRows
                                    className="px-4 mt-6"
                                    data={[
                                        {
                                            label: 'ကျောက်ချိန်',
                                            value: <WeightStats total={item.gem_weight}/>
                                        },
                                        {
                                            label: 'ကျောက်ဖိုး',
                                            value: item.gem_price
                                        }
                                    ]}
                                    labelWidth="73"
                                    color="green"
                                    size="sm"
                                    spaceY="2"
                                    spaceX="1"
                                />
                            ) : ''}

                            {/* spec */}
                            <PairRows
                                className="px-4 mt-6"
                                data={ 
                                    item.category.has_size ? [
                                        {
                                            label: item.group.size_name,
                                            value: Number(item.size)
                                        },
                                        {
                                            label: 'အမျိုးအစား',
                                            value: item.category.name
                                        }
                                    ] : [
                                        {
                                            label: 'အမျိုးအစား',
                                            value: item.category.name
                                        }
                                    ]
                                }
                                labelWidth="75"
                                color="gray"
                                size="sm"
                                spaceY="2"
                                spaceX="1"
                            />
                        </div>
                    )}
                </Collapsible>

                <div className="flex flex-col pt-5 space-y-6 border-t border-t-dim-gray">
                    {/* sold_detail */}
                    { details.includes('sold') ? (
                        <PairRows
                            className="px-4"
                            data={[
                                {
                                    label: 'အရောင်းဝန်ထမ်း',
                                    value: data.sold_by.nickname
                                },
                                {
                                    label: 'ရောင်းသောနေ့',
                                    value: dateParser.toString(data.sold_date)
                                }
                            ]}
                            labelWidth="103"
                            color="gray"
                            size="sm"
                            spaceY="2"
                            spaceX="1"
                        />
                    ) : ''}

                    {/* received_detail */}
                    { details.includes('received') ? (
                        <PairRows
                            className="px-4"
                            data={[
                                {
                                    label: 'အကြွေးပေးလက်ခံသူ',
                                    value: data.received_by.nickname
                                },
                                {
                                    label: 'အကြွေးပေးသည့်နေ့',
                                    value: dateParser.toString(data.received_date)
                                }
                            ]}
                            labelWidth="131"
                            color="gray"
                            size="sm"
                            spaceY="2"
                            spaceX="1"
                        />
                    ) : ''}

                    {/* requested_detail */}
                    { details.includes('requested') ? (
                        <PairRows
                            className="px-4"
                            data={[
                                {
                                    label: 'စာရင်းဖျက်ခွင့်တောင်းသူ',
                                    value: data.requested_by.nickname
                                },
                                {
                                    label: 'တောင်းဆိုသောနေ့',
                                    value: dateParser.toString(data.requested_date)
                                }
                            ]}
                            labelWidth="150"
                            color="gray"
                            size="sm"
                            spaceY="2"
                            spaceX="1"
                        />
                    ) : ''}
                </div>

                {/* button */}
                { (buttons.show) ? (
                    <div className="flex flex-row items-center mx-3 mt-6">
                            
                        { (isSettingMode && buttons.setting_mode_buttons.length !== 0) ? (  
                            buttons.setting_mode_buttons.map( (button, index) =>
                                <SubmitButton
                                    key={index}
                                    title={button.name}
                                    color={button.color}
                                    height="12"
                                    textColor={button.textColor}
                                    icon={button.icon}
                                    radius={`${ index === 0 && 'rounded-l-lg'} ${ button.id === buttons.setting_mode_buttons.length && 'rounded-r-lg'}`}
                                    onClick={ () => handleButtonClick(button.onClick) }
                                />
                            )
                        ) : (
                            buttons.normal_buttons.map( (button, index) =>
                                <SubmitButton
                                    key={index}
                                    title={button.name}
                                    color={button.color}
                                    height="12"
                                    textColor={button.textColor}
                                    icon={button.icon}
                                    radius={`${ index === 0 && 'rounded-l-lg'} ${ button.id === buttons.normal_buttons.length && 'rounded-r-lg'}`}
                                    onClick={ () => handleButtonClick(button.onClick) }
                                />
                            )
                        )}

                    </div>
                ) : ''}

            </div>
        </AnimatedCard>
    )
}

export default memo(DebtCard, () => true);