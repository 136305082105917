import React, { memo } from 'react'
import WeightStats from '../WeightStats'
import Modal from './Modal'

const CalculationResultModal = ({ show, data, onClose }) => {
  return (
    <Modal
        show={show}
        title="Result"
        titleColor="soft-black"
        onClose={onClose}
        isSubmitting={false}
    >
        {/* card */}
        <div className="flex flex-col space-y-4 m-5">
            <div className="flex flex-col space-y-5">

                <div className="flex justify-center">
                    <span className="text-green font-bold">
                        {new Intl.NumberFormat().format(data.finalPrice)} ကျပ်
                    </span>
                </div>

                {/* results */}
                <div className="flex flex-col space-y-4">
                
                    <div className="flex flex-row justify-between">
                        <span className="text-xs pt-2 text-dark-gray font-bold">
                            ရွှေစျေး
                        </span>
                        <span className="text-xs pt-1 text-gray">
                            {data.goldPrice === "" ? 0 : data.goldPrice}
                        </span>
                    </div>

                    <div className="flex flex-row justify-between">
                        <span className="text-xs pt-2 text-dark-gray font-bold">
                            အသားတင်ရွှေချိန်
                        </span>
                        <span className="text-xs pt-2 text-gray">
                            <WeightStats total={data.netWeight}/>
                        </span>
                    </div>
                    
                    <div className="flex flex-row justify-between">
                        <span className="text-xs pt-2 text-dark-gray font-bold">
                            အလျော့တွက်
                        </span>
                        <span className="text-xs pt-2 text-gray">
                            <WeightStats total={data.serviceWeight}/>
                        </span>
                    </div>

                    {data.hasGem ? (
                        <div className="space-y-4">
                            <div className="flex flex-row justify-between">
                                <span className="text-xs pt-2 text-dark-gray font-bold">
                                    ကျောက်ချိန်
                                </span>
                                <span className="text-xs pt-2 text-gray">
                                    <WeightStats total={data.gemWeight}/>
                                </span>
                            </div>
                            <div className="flex flex-row justify-between">
                                <span className="text-xs pt-2 text-dark-gray font-bold">
                                    ကျောက်ဖိုး
                                </span>
                                <span className="text-xs pt-1 text-gray">
                                    {data.gemPrice === "" ? 0 : data.gemPrice}
                                </span>
                            </div>
                        </div>
                    ) : ''}

                    <div className="flex flex-row justify-between">
                        <span className="text-xs pt-2 text-dark-gray font-bold">
                            အခြားသင့်ငွေ
                        </span>
                        <span className="text-xs pt-1 text-gray">
                            {data.otherPrice}
                        </span>
                    </div>

                    <div className="flex flex-row justify-between">
                        <span className="text-xs pt-2 text-dark-gray font-bold">
                            အခွန် ( {data.taxPercent} % )
                        </span>
                        <span className="text-xs pt-1 text-gray">
                            {data.taxPrice}
                        </span>
                    </div>

                </div>
            </div>
        </div>
            
    </Modal>
  )
}

export default memo(CalculationResultModal)