import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useNavigation from "../hooks/useNavigation";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const StackScreen = ({
    backable = true,
    backRequireConfirmation = false,
    confirmationMsg = 'Are your sure to go back?',
    backTo, //obj
    title,
    renderRight,
    children,
    onBeforeGoBack
}) => {

    useEffect( () => {
        const metaTag = document.querySelector('meta[name="theme-color"]');
        const backgroundColor = "white";
        if (metaTag) {
            metaTag.setAttribute('content', backgroundColor);
        } else {
            const newMetaTag = document.createElement('meta');
            newMetaTag.name = 'theme-color';
            newMetaTag.content = backgroundColor;
            document.head.appendChild(newMetaTag);
        }
    }, []);

    const currentRoute = useSelector( state => state.router.currentRoute );
    const navigation = useNavigation();

    const goBack = () => {
        if(backable){
            if(backRequireConfirmation){
                if(window.confirm(confirmationMsg)){
                    backTo 
                        ? navigation.navigate(backTo.name, backTo.params ?? {} ) 
                        : navigation.goBack();

                    onBeforeGoBack?.();
                }
            } else {
                backTo 
                    ? navigation.navigate(backTo.name, backTo.params ?? {} ) 
                    : navigation.goBack();
                onBeforeGoBack?.();
            }
        }
    }

    return (
        <>
            <div className="flex flex-row h-12 items-center bg-white border-b border-b-dim-gray">
                <div className="w-1/5 flex justify-start ml-3">
                    { backable ? (
                        <button 
                            className="flex items-center text-blue space-x-1"
                            onClick={goBack}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} size="lg"/>
                            <span className="font-semibold">
                                Back
                            </span>
                        </button>
                    ) : ''}
                </div>
                <div className="flex-1 flex justify-center">
                    <span className="text-sm font-bold">
                        { title ?? currentRoute.title }
                    </span>
                </div>
                <div className="w-1/5 flex justify-end mr-3">
                    {renderRight}
                </div>
            </div>

            {children}
        </>
    );
};

export default StackScreen;
