import React, { useState, useMemo, useEffect, useCallback } from "react";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import Weight from "../helper/weight";
import useNavigation from "../hooks/useNavigation";
import Collapsible from "../components/Collapsible";
import ProgressBar from "../components/ProgressBar";
import WeightStats from "../components/WeightStats";
import Nav from "../components/nav";
import useFormDataConverter from "../hooks/useFormDataConverter";
import {
    ImagePreviewer, 
    Input, 
    SubmitButton, 
    Switch, 
    WeightInput, 
    ImagePicker, 
    PopUpPicker,
    CounterInput
} from "../components/inputs";
import { ExistedImagePickerModal } from "../components/modals";
import { useIsFirstRender } from "@uidotdev/usehooks";
import StackScreen from "../components/StackScreen";

const FormScreen = () => {

    const weight = new Weight();
    const formDataConverter = useFormDataConverter();
    const { handleError, getGroups } = useStoreHelper();
    const navigation = useNavigation();
    const isFirstRender = useIsFirstRender();

    const authUser = useSelector( state => state.app.authUser);
    const allGroups = useSelector( state => state.app.groups);
    const qualities = useSelector( state => state.app.qualities);

    const groups = allGroups.filter( e => e.id !== null);

    const currentRoute = useSelector( state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const isEdit = Boolean(routeState);
    const editItem = isEdit ? routeState.item : null;

    const savedGroup = localStorage.getItem('addingGroup');
    const savedData = JSON.parse(localStorage.getItem('savedFormData'));

    const [group, setGroup] = useState(isEdit ? editItem.group_id : (savedGroup ? Number(savedGroup) : 1));
    const [serial, setSerial] = useState(isEdit ? editItem.serial.replace(/[^\d.-]/g, '') : 1);
    const [serviceWeightSuggestions, setServiceWeightSuggestions] = useState([]);
    const [showExistedImagePickerModal, setShowExistedImagePickerModal] = useState(false); 
    const [saveForNextTime, setSaveForNextTime] = useState( savedData ? true : false );
    const [isPickingImage, setIsPickingImage] = useState(false);

    //api data
        const [categories, setCategories] = useState([]);

    //inputs
        const [name, setName] = useState(isEdit ? editItem.name : (savedData ? savedData.name : ''));
        const [size, setSize] = useState(isEdit ? Number(editItem.size).toString() : ''); //change decimal to int, then to string
        const [category, setCategory] = useState(isEdit ? editItem.category_id : (savedData ? savedData.category : null)); //int
        const [quality, setQuality] = useState(isEdit ? editItem.quality_id : (savedData ? savedData.quality : null)); //int
        const [hasGem, setHasGem] = useState(isEdit ? editItem.has_gem : false);
        const [gemPrice, setGemPrice] = useState(isEdit ? (editItem.gem_price ? editItem.gem_price.toString() : '') : ''); 
        const [price, setPrice] = useState(isEdit ? (editItem.price ? editItem.price.toString() : '') : '');

        const [orgWeight, setOrgWeight] = useState(isEdit ? editItem.org_weight : weight.defaultValue );
        const [serviceWeight, setServiceWeight] = useState(isEdit ? editItem.service_weight : weight.defaultValue );
        const [attachmentWeight, setAttachmentWeight] = useState(isEdit ? editItem.attachment_weight : weight.defaultValue );
        const [attachmentServiceWeight, setAttachmentServiceWeight] = useState(isEdit ? editItem.attachment_service_weight : weight.defaultValue );
        const [gemWeight, setGemWeight] = useState(isEdit ? editItem.gem_weight : weight.defaultValue );

        const [image, setImage] = useState(isEdit ? {
            id: 1,
            file: editItem.image,
            src: `${process.env.REACT_APP_API_URL}/images/${editItem.image}`,
        } : null);
    
    //submit handlers
        const [addCount, setAddCount] = useState('1');
        const [submitData, setSubmitData] = useState(null);
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [addedItems, setAddedItems] = useState([]);
        const [failedCount, setFailedCount] = useState(0);

    //computed
        const isPlainSell = useMemo( () => {
            const targetQuality = qualities.find( e => e.id === quality);
            return targetQuality ? targetQuality.is_plainsell : false;
        }, [qualities, quality]);

        const weightTypes = [
            {
                type: 'org',
                set: setOrgWeight
            },
            {
                type: 'service',
                set: setServiceWeight
            },
            {
                type: 'attachment',
                set: setAttachmentWeight
            },
            {
                type: 'attachment_service',
                set: setAttachmentServiceWeight
            },
            {
                type: 'gem',
                set: setGemWeight
            },
        ];
    
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === group );
        }, [group, groups]);

        const currentCategory = useMemo( () => {
            return categories.find((e) => e.id === Number(category)) || {
                name: '',
                group_id: 1,
                has_size: false,
                gem_includable: false,
                has_attachment: false,
                base_price: 0,
                additional_price: 0,
                trader_weight_calculation: 'none',
            }
        }, [category, categories]);

        const completedPercent = useMemo( () => {
            const submittedCount = addedItems.length + failedCount;
            const percent = ( submittedCount / Number(addCount)) * 100;
            return Math.floor(percent);
        }, [addedItems, failedCount]);

    //mounted
        useEffect(() => {
            //to update latest serial
            if(!isEdit){
                getGroups();        
            } else {
                navigation.setCurrentRouteTitle('ပစ္စည်းစာရင်းပြင်ရန်');
            }
        }, []);

    //watchers
        useEffect( () => {
            const controller = new AbortController();
            const signal = controller.signal;

            axios_custom.get('options', { params: {group}, signal })
            .then( (res) => {
                setCategories(res.data.categories);
            })
            .catch( (error) => {
                handleError(error);
            });
            
            return () => {
                controller.abort();
            }
        }, [group]);

        useEffect( () => {
            if(!isEdit){
                setSerial(currentGroup.next_serial);
            }
        }, [currentGroup]);

        useEffect( () => {
            if(!isEdit && !isFirstRender){
                setCategory(null);
            }
        }, [currentGroup.id]);

        useEffect( () => {
            if(
                name !== '' &&
                currentCategory !== '' &&
                weight.convertToState(orgWeight) !== 0
            ){
                const controller = new AbortController();
                const signal = controller.signal;

                axios_custom.get('service_weight_suggestions',
                    { 
                        signal: signal,
                        params: { 
                                name: name, 
                                category: category,
                                org_weight: JSON.stringify(orgWeight),
                                gem_weight: JSON.stringify(gemWeight)
                            } 
                    })
                .then( (res) => {
                    setServiceWeightSuggestions(res.data.service_weight_suggestions);
                })
                .catch( () => {
                    setServiceWeightSuggestions([]);
                })

                return () => {
                    controller.abort();
                }
            }
        }, [name, currentCategory, orgWeight, gemWeight]);
   
    //error handlers
        const [errorCheckable, setErrorCheckable] = useState(false);
        const [weightsWithError, setWeightsWithError] = useState([]);

        const inputErrors = useMemo( () => {
            const errors = [];

            if(name === ''){
                errors.push('name');
            }
            if(currentCategory.has_size && size === ''){
                errors.push('size');
            }
            if(currentCategory.has_size && size){
                if(Number(size) <= 0 || Number(size) > 99){
                    errors.push('size_exceed');
                }
            }
            if(category === null){
                errors.push('category');
            }
            if(quality === null){
                errors.push('quality');
            }
            if(isPlainSell && price === '' ){
                errors.push('price');
            }
            if(Number(addCount) <= 0 || Number(addCount) > 20){
                errors.push('add_count');
            }

            return errors;
        }, [name, size, category, hasGem, price, addCount, quality, isPlainSell]);

    //methods
        const handleWeightChange = useCallback( (newWeight, weightType) => {
            const targetWeight = weightTypes.find( e => e.type === weightType);
            targetWeight.set(newWeight.weight);

            setWeightsWithError( prevWeights => {
                const weightSet = new Set(prevWeights);

                if(newWeight.hasError){
                    if(!weightSet.has(weightType)){
                        weightSet.add(weightType);
                    }
                } else {
                    weightSet.delete(weightType);
                }

                return [...weightSet];
            })
        }, []);

        const changeHasGem = (value) => {
            setHasGem(value);
            setGemPrice('');
            setGemWeight(weight.defaultValue);
        }
    
        const pickGroup = useCallback( (searchFilters) => {
            setGroup(searchFilters.group);
        }, []);

        const pickCategory = useCallback( (id) => {
            setCategory(id);
        }, []);

        const pickQuality = useCallback( (id) => {
            setQuality(id);
        }, []);

        const submit = () => {
            setErrorCheckable(true);

            //save group for next time
            localStorage.setItem('addingGroup', group);

            //save data for next time
            if(saveForNextTime){
                localStorage.setItem('savedFormData', JSON.stringify({
                    name,
                    category,
                    quality
                }))
            } else {
                localStorage.removeItem('savedFormData')
            }

            let requireWeights = ['org'];

            if (currentCategory.has_attachment) {
                requireWeights = [...requireWeights, 'attachment'];
            }

            if (hasGem) {
                requireWeights = [...requireWeights, 'gem'];
            }

            if (isPlainSell) {
                requireWeights = [];
            }

            const someWeightHasError = weightsWithError.some( e => requireWeights.includes(e) );

            //submit data preparation
            if (inputErrors.length === 0 && !someWeightHasError) {

                const formData = new FormData();

                //only_for_edit, pass id
                if(isEdit){
                    formData.append('id', editItem.id);
                }

                formData.append('group', group);
                formData.append('user', authUser.id);
                formData.append('name', name);
                formData.append('size', size);
                formData.append('category', category);
                formData.append('quality', quality);
                formData.append('price', price);
                formData.append('has_gem', hasGem);
                formData.append('gem_price', gemPrice);

                //weight objs have to be converted into string
                formData.append('org_weight', JSON.stringify(orgWeight));
                formData.append('service_weight', JSON.stringify(serviceWeight));
                formData.append('attachment_weight', JSON.stringify(attachmentWeight));
                formData.append('attachment_service_weight', JSON.stringify(attachmentServiceWeight));
                formData.append('gem_weight', JSON.stringify(gemWeight));

                if(image){
                    formData.append('image', image.file);
                }

                setIsSubmitting( () => {
                    setSubmitData(formData);
                    return true;
                });
            }
        };

        //sequential submitter
            useEffect( () => {
                if(isSubmitting){
                    const submittedCount = addedItems.length + failedCount;

                    if(submittedCount < Number(addCount)){
                        axios_custom.post(isEdit ? 'manage_requests' : 'items', submitData)
                        .then( (res) => {
                            setAddedItems( prev => {
                                return [...prev, res.data.result]
                            });
                        })
                        .catch( (error) => {
                            if(isEdit){
                                setIsSubmitting(false);
                                handleError(error);
                            } else {
                                setFailedCount( prev => prev + 1);
                            }
                        });
                    } else {
                        setIsSubmitting(false);

                        if(isEdit){
                            navigation.navigate('edit_requested', { 
                                state: addedItems[0]
                            });
                        } else {
                            navigation.navigate('added', { 
                                state: {
                                    addedItems,
                                    submitData: formDataConverter.stringify(submitData),//cuz redux not allow FormData
                                    addCount: Number(addCount),
                                    failedCount,
                                    saveForNextTime
                                }
                            });
                        }
                    }
                }
            }, [isSubmitting, addedItems, failedCount]);

    return (
        <>
            { !isEdit ? (
                <Nav
                    onSearch={pickGroup}
                    values={{ group }}
                    navType="groups" 
                    searchOnNavChange={true}
                    disableNavChoice={isEdit}
                    enableAllSearch={false} 
                    enabledSearches={[]}
                    fixedTop={false}
                />
            ) : (
                <StackScreen/>
            )}

            <div className="flex-1 flex flex-col bg-white overflow-auto sm:mr-16">

                <div className="flex flex-col space-y-8 mx-5 mt-7 mb-16">

                    {/* serial */}
                    <div className="flex">
                        <div className="flex flex-row space-x-3">
                            <div className="flex items-center justify-center h-9 px-3 min-w-16 bg-soft-milk border border-dashed shadow border-black rounded">
                                <span className="font-mono font-bold text-black">
                                    { serial }
                                </span>
                            </div>
                            { !isEdit ? (
                                <div className="flex items-center justify-center">
                                    <span className="text-2xs font-bold text-soft-gray">
                                        ယခုထည့်မည့်ပစ္စည်း ID
                                    </span>
                                </div>
                            ) : ''}
                        </div>
                    </div>

                    <div className="flex flex-col space-y-5">
                        
                        <div className="flex flex-col space-y-2">
                            <span className="text-xs text-gray font-bold">ပစ္စည်းအမျိုးအစား</span>
                            <PopUpPicker
                                triggerClassName={`h-11 flex items-center pl-5 bg-white rounded border border-${ errorCheckable && inputErrors.includes('category') ? 'red' : 'dim-gray'}`}
                                data={categories}
                                value={category}
                                theme="light"
                                textColor="black"
                                enableIcon={true}
                                onPick={pickCategory}
                                nullable={true}
                            />
                            { errorCheckable && inputErrors.includes('category') ? (
                                <span className="text-2xs text-red">
                                    * အမျိုးအစားရွေးပါ
                                </span>
                            ) : ''}
                        </div>

                        <div className="space-y-2">

                            <div className="flex flex-row items-center space-x-3">
                                <span className="text-xs font-bold text-gray">ပစ္စည်းအမည်</span>
                                { category ? (
                                    <button
                                        className="flex flex-row items-center shadow justify-center rounded border border-dim-gray"
                                        onClick={ () => setName(categories.find( e => e.id === category).name)}
                                    >
                                        <span className="text-2xs mx-2 my-1 text-soft-black">
                                            အမျိုးအစားအမည်အတိုင်းသုံးမယ်
                                        </span>
                                    </button>

                                ) : ''}
                            </div>
                            
                            <Input
                                title=""
                                type="text"
                                placeholder="eg. မောင်းကွင်း (စက်ဖြတ်)၊ ရွှေဥစီလက်ကောက်၊ စသည်..."
                                value={name}
                                onChange={ text => setName(text)}
                                errors={[
                                    {
                                        show: errorCheckable && inputErrors.includes('name'),
                                        msg: 'ပစ္စည်းအမည်ထည့်ပါ!'
                                    }
                                ]}
                            />
                        </div>

                        <div className="flex flex-col space-y-2">
                            <span className="text-xs text-gray font-bold">ရွှေရည်</span>
                            <PopUpPicker
                                triggerClassName={`h-11 flex items-center pl-5 bg-white rounded border border-${ errorCheckable && inputErrors.includes('quality') ? 'red' : 'dim-gray'}`}
                                data={qualities}
                                value={quality}
                                theme="light"
                                textColor="black"
                                enableIcon={true}
                                onPick={pickQuality}
                                nullable={true}
                            />
                            
                            { isPlainSell ? (
                                <div className="flex flex-row space-x-1 text-2xs text-gray">
                                    <span>*</span>
                                    <span>ပုံသေစျေးနှုန်းဖြင့်ရောင်းမည့်ပစ္စည်းဖြစ်ပါက ရွှေစျေးပြောင်းသည်နှင့် တန်ဖိုးလိုက်မပြောင်းတော့ပါ။</span>
                                </div>
                            ) : ''}
                            { errorCheckable && inputErrors.includes('quality') ? (
                                <span className="text-2xs text-red">
                                    * ရွှေရည်ရွေးပါ
                                </span>
                            ) : ''}
                        </div>
                        
                        <Collapsible mt={20} collapsed={!currentCategory.has_size}>
                            <Input
                                title={ currentGroup.size_name }
                                type="decimal"
                                min="0"
                                max="99"
                                step="0.01"
                                value={size}
                                onChange={ text => setSize(text)}
                                errors={[
                                    {
                                        show: errorCheckable && inputErrors.includes('size'),
                                        msg: `${currentGroup.size_name}ထည့်ပါ!`
                                    },
                                    {
                                        show: errorCheckable && inputErrors.includes('size_exceed'),
                                        msg: `0.01 မှ 99 အတွင်းသာထည့်ပါ!`
                                    }
                                ]}
                            />
                        </Collapsible>

                        {/* weight_form */}
                        <Collapsible mt={20} collapsed={isPlainSell}>
                            <div className="space-y-5">
                                <WeightInput
                                    title="အလေးချိန်"
                                    value={orgWeight}
                                    onChange={ newWeight => handleWeightChange(newWeight, 'org')}
                                    emptyCheckable={errorCheckable}
                                />

                                <div className="space-y-2">

                                    <div className="flex flex-row items-center space-x-3">
                                        <span className="text-xs font-bold text-gray">အလျော့တွက်</span>

                                        { serviceWeightSuggestions.length !== 0 ? (
                                            <div className="flex flex-1 items-center overflow-x-scroll h-5">
                                                <div className="flex flex-row items-center space-x-2 flex-shrink-0 text-2xs text-soft-gray">
                                                    <span>ပုံစံတူပစ္စည်းအလျော့တွက်</span>
                                                    <span>-</span>
                                                    { serviceWeightSuggestions.map( e =>
                                                        <div key={e.id} className="border-r last:border-none">
                                                            <span className="mr-2">
                                                                <WeightStats total={e.service_weight}/>
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : ''}
                                    </div>

                                    <WeightInput
                                        value={serviceWeight}
                                        onChange={ newWeight => handleWeightChange(newWeight, 'service')}
                                        nullable
                                    />
                                </div>

                            </div>
                        </Collapsible>

                        {/* attachment_form */}
                        <Collapsible mt={20} collapsed={!currentCategory.has_attachment}>
                            <div className="space-y-5">
                                <WeightInput
                                    title="အတွဲသီးသန့် အလေးချိန်"
                                    value={attachmentWeight}
                                    onChange={ newWeight => handleWeightChange(newWeight, 'attachment')}
                                    emptyCheckable={errorCheckable}
                                />
                                <WeightInput
                                    title="အတွဲသီးသန့် အလျော့တွက်"
                                    value={attachmentServiceWeight}
                                    onChange={ newWeight => handleWeightChange(newWeight, 'attachment_service')}
                                    nullable
                                />
                            </div>
                        </Collapsible>

                        <Collapsible mt={20} collapsed={!isPlainSell}>
                            <Input
                                title="ပုံသေစျေးနှုန်း"
                                type="number"
                                min="0"
                                value={price}
                                onChange={ text => setPrice(text)}
                                warningMsges={[]}
                                errors={[
                                    {
                                        show: errorCheckable && inputErrors.includes('price'),
                                        msg: 'စျေးနှုန်းထည့်ပါ!'
                                    }
                                ]}
                            />
                        </Collapsible>

                        <div className="flex flex-col space-y-1">
                            <ImagePicker
                                title="ပစ္စည်းပုံ"
                                buttonLabel="ပုံအသစ်ရွေးရန်"
                                isPicking={setIsPickingImage}
                                onPick={setImage}
                            />
                            <button
                                className="flex pl-5 flex-row items-center h-11 border border-dim-gray rounded"
                                onClick={() => setShowExistedImagePickerModal(true)}
                            >
                                <div className="flex flex-row items-center space-x-3">
                                    <FontAwesomeIcon icon={faImages}/>
                                    <span className="text-xs font-bold">ထည့်ပြီးသားပုံထဲကရွေးရန်</span>
                                </div>
                            </button>
                            <span className="text-2xs text-gray">* ပုံမထည့်သေးဘဲ ထားခဲ့လဲရသည်။</span>
                        </div>

                        <ImagePreviewer
                            id={image?.id}
                            src={image?.src}
                            onRemove={ () => setImage(null) }
                            removeable={!isEdit}
                        />

                        { currentCategory.gem_includable ? (
                            <div>
                                <div className="flex flex-row space-x-2 items-center my-3">
                                    <span className="text-xs font-bold text-gray">ကျောက်ထည်?</span>
                                    <Switch
                                        value={hasGem}
                                        onChange={changeHasGem}
                                    />
                                </div>
                            </div>
                        ) : ''}

                        <Collapsible
                            mt={20}
                            collapsed={!(currentCategory.gem_includable && hasGem)}
                        >
                            <div className="space-y-5">
                                <Input
                                    title="ကျောက်ဖိုး"
                                    type="number"
                                    value={gemPrice}
                                    onChange={ text => setGemPrice(text)}
                                />
                                <WeightInput
                                    title="ကျောက်ချိန်"
                                    value={gemWeight}
                                    onChange={ newWeight => handleWeightChange(newWeight, 'gem')}
                                    nullable
                                />
                            </div>
                        </Collapsible>
                        
                        { !isEdit ? (
                            <CounterInput
                                title="ထည့်လိုသည့်အရေအတွက်"
                                min="1"
                                max="20"
                                value={addCount}
                                onChange={setAddCount}
                                warningMsges={[
                                    'တစ်ခါထည့်လျှင် အခု 20 ထိသာ အများဆုံးထည့်ခွင့်ပြုသည်။'
                                ]}
                            />
                        ) : ''}

                        { !isEdit ? (
                            <div className="space-y-2">
                                <div className="flex flex-row space-x-2 items-center">
                                    <span className="text-xs font-bold text-gray">Save for next time?</span>
                                    <Switch
                                        value={saveForNextTime}
                                        onChange={setSaveForNextTime}
                                    />
                                </div>
                                <div className="flex text-2xs text-gray flex-row space-x-1">
                                    <span>*</span>
                                    <span>အမျိုးအစား ၊ အမည် နှင့် ရွှေရည် ကို နောက်တစ်ကြိမ်အတွက် Save ထားပေးမည်ဖြစ်သည်။</span>
                                </div>
                            </div>
                        ) : ''}

                    </div>
                    
                    <div className="flex flex-col space-y-2">
                        { isSubmitting ? (
                            <div>
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center justify-end space-x-3">
                                        <span className="text-xs text-soft-black">
                                            { completedPercent }%
                                        </span>
                                    </div>
                                    <div className="flex flex-row items-center justify-end space-x-3">
                                        <span className="text-xs text-green">
                                            { addedItems.length } Succeed
                                        </span>
                                        <span className="text-xs text-red">
                                            { failedCount } Failed
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <ProgressBar
                                        completedPercent={completedPercent}
                                        color="green"
                                    />
                                </div>
                            </div>
                        ) : ''}
                        <div className="flex">
                            <SubmitButton
                                title={ isEdit ? 'ပြင်မယ်' : 'ထည့်မယ်'}
                                color={ isEdit ? 'blue' : 'green'}
                                height="12"
                                disabled={isPickingImage}
                                radius="rounded-lg"
                                activated={isSubmitting}
                                onClick={submit}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <ExistedImagePickerModal
                show={showExistedImagePickerModal}
                onClose={() => setShowExistedImagePickerModal(false)}
                onPick={setImage}
                filters={{
                    group,
                    category,
                    word: name
                }}
            />
        </>
    );
};

export default FormScreen;
