import React from "react";
import colors from "../helper/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoticeBanner = ({
    title ,
    icon,
    color,
    msges = [] // array
}) => {
    return (
        <div className={`bg-dim-${color} border-b border-b-dim-gray`}>
            <div className="flex flex-col space-y-5 mx-3 my-4">
                <div className="flex flex-row items-center justify-center space-x-2">
                    <FontAwesomeIcon
                        icon={icon}
                        color={colors.find((e) => e.org === color).normal}
                    />
                    <span className={`text-${color} text-sm`}>{title}</span>
                </div>

                <div className="flex flex-col space-y-3">
                    { msges.map( (msg, index) =>
                        <div key={msg + index} className="flex flex-row space-x-2">
                            <span className="flex text-gray">*</span>
                            <span className="flex text-xs text-gray">
                                {msg}
                            </span>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default NoticeBanner;
