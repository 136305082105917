import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGroup, defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import { useDispatch, useSelector } from 'react-redux';
import ItemCard from '../components/ItemCard';
import ItemListFrame from '../components/ItemListFrame';
import WeightStats from '../components/WeightStats';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { SaleVoucherPreviewModal } from '../components/modals';
import { setAlert } from '../store/reducers/app-reducer';
import DateParser from '../helper/dateParser';

const PairRow = ({ label, value }) => {
    return (
        <div className="flex flex-row items-center justify-between">
            <div>
                <span className="text-xs font-bold text-soft-black">
                    {label}
                </span>
            </div>
            <div className="flex items-center justify-end">
                <span className="text-xs text-gray">
                    { value }
                </span>
            </div>
        </div>
    )
}

const SoldScreen = () => {

    const dateParser = new DateParser();

    const authUser = useSelector( state => state.app.authUser);
    const currentRoute = useSelector( state => state.router.currentRoute);
    const groups = useSelector( state => state.app.groups);
    const fetchApi = useApiFetcher();
    const dispatch = useDispatch();
    const routeState = currentRoute.params?.state;
    
    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        date: new Date(),
        id: routeState?.id ?? null,
        group: routeState?.group ?? null,
        sort_by: {
            type: 'sold_date',
            order: 'desc'
        }
    });

    const [showLoading, setShowLoading] = useState(false);
    const [page, setPage] = useState(2);
    const [response, setResponse] = useState({
        count: 0,
        items: [],

        count_by: {
            group: [],
            user: []
        }
    });

    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === search_filters.group ) || defaultGroup
        }, [groups, search_filters.group]);

        const endResult = useMemo( () => response.count === response.items.length, [response.items.length]);

        const dateLabel = useMemo( () => {
            if(!search_filters.date){
                return '';
            }
            return new Date(search_filters.date).toDateString() === new Date().toDateString()
            ? 'ယနေ့'
            : dateParser.toString(search_filters.date);
        }, [search_filters.date]);
        
    //api_fetch
        useEffect( () => {
            setResponse({
                count: 0,
                items: [],
        
                count_by: {
                    group: [],
                    user: []
                }
            });

            fetchApi(
                'items', 
                {page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setResponse
            );
            (page !== 2) && setPage(2);
        }, [search_filters]);

    //reload on navigate watcher
        useEffect( () => {
            if(routeState?.reload){
                setSearchFilters({...search_filters});
            }
        }, [routeState]);

    //methods
        const loadMore = () => {
            if(!endResult){
                fetchApi(
                    'items', 
                    {page, ...search_filters}, 
                    response.items, 
                    setShowLoading, 
                    setResponse, 
                    setPage
                );
            }
        }

        const updateList = useCallback( (id) => {
            setResponse( prev => {
                const newData = {...prev};
                newData.count = prev.count - 1;
                newData.items = prev.items.filter( e => e.id !== id);
                return newData;
            })
        }, []);

        const denyAction = useCallback( () => {
            dispatch(setAlert({
                msg: "You don't have permission to do this action!",
                status: 'error',
                timeout: 5000
            }));
        }, []);

        //voucher modal
        const [voucherData, setVoucherData ] = useState(null);
        const [showVoucher, setShowVoucher ] = useState(false);

        const openVoucher = (item) => {
            setShowVoucher( () => {
                setVoucherData({
                    item: item,
                    saleType: item.status === 'attachment_only_sold' ? 'attachment_only' : 'all',
                    goldPrice: Number(item.sold_goldprice),
                    customer: {
                        name: item.customer_name,
                        address: item.customer_address,
                        phone: item.customer_phone,
                    },
                    date: item.sold_date,
                    hasDebt: item.has_debt,
                    hasTax: item.sold_tax_price !== 0,
                    tradeInPrice: Number(item.tradein_price),
                    depositPrice: Number(item.deposit_price),
                    discountPrice: Number(item.discount_price),
                    soldBy: item.sold_by
                });
                return true;
            });
        }

        const closeVoucher = () => {
            setShowVoucher(false);
            setVoucherData(null);
        }

    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="groups"
                searchOnNavChange={false}
                enableAllSearch={true} 
                enabledSearches={['id', 'date', 'word', 'price', 'customer', 'type', 'size', 'weight', 'user']}
            />

            <ItemListFrame
                enabledSortTypes={['id', 'price', 'added_date', 'sold_date']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ response.count }
                countUnit={ currentGroup.count_unit }
                isEnd={endResult && response.count !== 0}
                banner={ ( search_filters.date && !showLoading ) ? (
                    response.count === 0 ? (
                        <div className="flex items-center justify-center w-full max-w-80 rounded-xl bg-dim-milk border border-dashed">
                            <span className="text-red text-xs font-bold py-6">
                                { dateLabel } အတွက်ရောင်းထားသောပစ္စည်းမရှိပါ။
                            </span>
                        </div>
                    ) : (
                        authUser.abilities.includes('view_report') ? (
                            <div className="flex w-full lg:w-2/3 bg-soft-milk border border-dashed rounded-xl">
                                <div className="flex flex-col w-full space-y-3 mt-3 mb-5">

                                    <div className="flex items-center justify-center">
                                        <span className="text-yellow text-sm font-bold">
                                            {dateLabel} အတွက် အရောင်း Report
                                        </span>
                                    </div>

                                    <div className="flex flex-row flex-wrap w-full">

                                        <div className="flex items-center justify-center w-full lg:w-1/3 border-r border-dashed border-dark-gray">
                                            <div className="flex flex-col w-full space-y-2 mx-8">
                                                { response.count_by.group.map( e => 
                                                    (e.count !== 0) ? (
                                                        <PairRow
                                                            key={e.id}
                                                            label={groups.find( g => e.id === g.id).name}
                                                            value={e.count}
                                                        />
                                                    ) : ''
                                                )}
                                                <div className="border-b border-dim-gray"></div>
                                                <PairRow
                                                    label="စုစုပေါင်း"
                                                    value={response.count}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex justify-center w-full lg:w-2/3 mt-5 lg:mt-0">
                                            <div className="flex flex-col w-full space-y-4 mx-8">
                                                
                                                <div className="border-b border-dashed border-dark-gray lg:border-none"></div>
                                            
                                                <PairRow
                                                    label="စုစုပေါင်းရောင်းရငွေ"
                                                    value={`${ new Intl.NumberFormat().format(response.collectable_money.total_instant_sold)} ကျပ်`}
                                                />

                                                <PairRow
                                                    label="ရောင်းရအသားတင်အလေးချိန်"
                                                    value={<WeightStats total={response.profit_calculation.total_net_weight}/>}
                                                />

                                                <div className="border-b border-dim-gray"></div>

                                                <PairRow
                                                    label="ရောင်းရအလျော့တွက်ပေါင်း"
                                                    value={<WeightStats total={response.profit_calculation.total_service_weight}/>}
                                                />

                                                <PairRow
                                                    label="အရင်းအလျော့တွက်ပေါင်း"
                                                    value={<WeightStats total={response.profit_calculation.total_trader_weight}/>}
                                                />

                                                <div className="border-b border-dim-gray"></div>

                                                <PairRow
                                                    label="အမြတ်အလျော့တွက်ပေါင်း"
                                                    value={<WeightStats total={response.profit_calculation.total_profit_weight}/>}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ) : ''
                    )
                ) : ''}
            >
                <div className="flex-1 flex flex-row flex-wrap">
                    { response.items.map( item => 
                        <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                            <ItemCard
                                key={item.id}
                                onTakeAction={updateList}
                                item={item}
                    
                                displayMode={item.status === 'attachment_only_sold' ? 'attachment_only' : 'without_attachment'}
                    
                                details={{
                                    show: authUser.abilities.includes('access_finance') ?  ['sold', 'collectable_money'] :  ['sold'],
                                    header: 'sold',
                                    requireSettingMode: false,
                                }}
                    
                                traderWeight={{
                                    show: true,
                                    type: 'inline',
                                    requireSettingMode: true,
                                }}
                    
                                buttons={{
                                    show: true,
                                    normal_buttons: [
                                                        {
                                                            id: 1,
                                                            name: 'စာရင်းပြန်ထည့်',
                                                            color: 'purple',
                                                            onClick: {
                                                                action: 'putback',
                                                                params: {
                                                                    id: item.id,
                                                                }
                                                            }
                                                        },
                                                        {
                                                            id: 2,
                                                            name: 'Print',
                                                            icon: faPrint,
                                                            textColor: 'black',
                                                            color: 'muted',
                                                            onClick: () => {
                                                                if(authUser.abilities.includes('sell')){
                                                                    openVoucher(item);
                                                                } else {
                                                                    denyAction();
                                                                }
                                                            }
                                                        },
                                                    ],
                    
                                    setting_mode_buttons:[
                                                            {
                                                                id: 1,
                                                                name: 'Delete',
                                                                color: 'red',
                                                                onClick: {
                                                                    action: 'delete',
                                                                    params: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                id: 2,
                                                                name: 'စာရင်းပြန်ထည့်',
                                                                color: 'purple',
                                                                onClick: {
                                                                    action: 'putback',
                                                                    params: {
                                                                        id: item.id,
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                id: 3,
                                                                name: 'Print',
                                                                icon: faPrint,
                                                                textColor: 'black',
                                                                color: 'muted',
                                                                onClick: () => {
                                                                    if(authUser.abilities.includes('sell')){
                                                                        openVoucher(item);
                                                                    } else {
                                                                        denyAction();
                                                                    }
                                                                }
                                                            },
                                                        ]
                                }}
                            />
                        </div>
                    )}
                </div>
            </ItemListFrame>

            <SaleVoucherPreviewModal
                tasks={['print']}
                show={showVoucher}
                data={voucherData}
                onDone={closeVoucher}
                onClose={closeVoucher}
            />

        </>
    )
}

export default SoldScreen;