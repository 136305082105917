import React, { memo } from "react";

const PairRows = ({ 
    data, 
    labelWidth, 
    spaceX, 
    spaceY,
    color,
    size,
    isBold = true,
    ...props
}) => {
    return (
        <div className={`${props.className} space-y-${spaceY} flex flex-col`}>
            { data.map( ({label, value}, index) => (
                <div key={index} className={`flex flex-row text-${size} text-${color}`}>
                    <span className={`text-sm ${isBold ? 'font-bold' : ''}`}
                        style={{
                            width: labelWidth ? Number(labelWidth) : ''
                        }}
                    >
                        { label }
                    </span>
                    <span className={`mx-${spaceX}`}>-</span>
                    <span>
                        { value }
                    </span>
                </div>
            ))}
        </div>
    );
};

export default memo(PairRows);
