import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGroup, defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import { useSelector } from 'react-redux';
import DebtCard from '../components/DebtCard';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NewDebtModal, PayDebtModal } from '../components/modals';
import ItemListFrame from '../components/ItemListFrame';

const DebtScreen = () => {

    const currentRoute = useSelector( state => state.router.currentRoute);
    const groups = useSelector( state => state.app.groups);
    const fetchApi = useApiFetcher();
    const routeState = currentRoute.params?.state;
    
    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        id: routeState?.id ?? null,
        group: routeState?.group ?? null,
        sort_by: {
            type: 'sold_date',
            order: 'desc'
        }
    });

    const [newDebtId, setNewDebtId] = useState(null);
    const [debt_type, setDebtType] = useState('left_to_pay');
    const [showLoading, setShowLoading] = useState(false);
    const [page, setPage] = useState(2);
    const [response, setResponse] = useState({
        count: 0,
        items: []
    });
    
    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === search_filters.group ) || defaultGroup
        }, [groups, search_filters.group]);

        const endResult = useMemo( () => response.count === response.items.length, [response.items.length]);

    //watcher
        useEffect( () => {
            setSearchFilters({
                ...defaultSearchFilters,
                sort_by: {
                    type: 'sold_date',
                    order: 'desc'
                }
            });
        }, [debt_type]);

    //api_fetch
        useEffect( () => {
            setResponse({
                count: 0,
                items: []
            });
            fetchApi(
                'debts', 
                {debt_type, page: 1, ...search_filters}, 
                [], 
                setShowLoading, 
                setResponse
            );
            (page !== 2) && setPage(2);
        }, [search_filters, debt_type]);

    //methods
        const loadMore = () => {
            if(!endResult){
                fetchApi(
                    'debts', 
                    {debt_type, page, ...search_filters}, 
                    response.items, 
                    setShowLoading, 
                    setResponse, 
                    setPage
                );
            }
        }

        const updateList = useCallback( (id) => {
            setResponse( prev => {
                return {
                    count: prev - 1,
                    items: prev.items.filter( e => e.id !== id )
                }
            })
        }, []);

        const handleScroll = (e) => {
            setShowNewDebtButton(e.direction === 'up');
        }

    //for modal
        //pay debt mdal
        const [targetPayDebtId, setTargetPayDebtId] = useState(null);
        const [showPayDebtModal, setShowPayDebtModal] = useState(false);

        const openPayDebtModal = useCallback( id => {
            setTargetPayDebtId(id);
            setShowPayDebtModal(true);
        }, []);

        const closePayDebtModal = useCallback( () => {
            setShowPayDebtModal(false);
        }, []);

        const onDebtPaid = useCallback( () => {
            closePayDebtModal();
            setSearchFilters({...search_filters}); 
        }, []);

        //new debt modal
        const [showNewDebtButton, setShowNewDebtButton] = useState(true);
        const [showNewDebtModal, setShowNewDebtModal] = useState(false);

        const closeNewDebtModal = useCallback( () => {
            setShowNewDebtModal(false);
        }, []);

        const onNewDebtAdded = useCallback( (id) => {
            setNewDebtId(id)
            closeNewDebtModal();
            setSearchFilters({...search_filters}); 
        }, []);

    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="groups"
                searchOnNavChange={false}
                enableAllSearch={true}
                enabledSearches={['date', 'id', 'word', 'customer']}
            />

            <ItemListFrame
                enabledSortTypes={['id', 'sold_date']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onScroll={handleScroll}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ response.count }
                countUnit={ currentGroup.count_unit }
                isEnd={endResult && response.count !== 0}
                toggle={{
                    value: debt_type,
                    color: 'orange',
                    left: {
                        value: 'left_to_pay',
                        label: 'ကြွေးကျန်'
                    },
                    right: {
                        value: 'fully_paid',
                        label: 'အကြေပေးပြီး'
                    },
                    onChange: setDebtType
                }}
            >
                <div className="flex-1 flex flex-row flex-wrap mt-2">
                    { response.items.map( item => 
                        <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                            <DebtCard
                                key={item.id}
                                onTakeAction={updateList}
                                data={item}
                                type="debt"
                                details={['sold']}
                                showVoucher={true}
                                highlightedId={newDebtId}
                                buttons={{
                                    show: debt_type === 'left_to_pay',
                                    normal_buttons: [
                                                        {
                                                            id: 1,
                                                            name: 'Pay',
                                                            color: 'green',
                                                            onClick: () => openPayDebtModal(item.id)
                                                        }
                                                    ],

                                    setting_mode_buttons:[
                                                            {
                                                                id: 1,
                                                                name: 'Delete',
                                                                color: 'red',
                                                                onClick: {
                                                                    action: 'delete',
                                                                    params: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                id: 2,
                                                                name: 'Pay',
                                                                color: 'green',
                                                                onClick: () => openPayDebtModal(item.id)
                                                            }
                                                        ]
                                }}
                            />
                        </div>
                    )}
                </div>
            </ItemListFrame>

            <div 
                className="fixed mb-5 right-6 sm:right-24 transition-position duration-500"
                style={{
                    bottom: showNewDebtButton ? 40 : -100
                }}
            >
                <button
                    className="rounded-full backdrop-blur-sm bg-orange/70 w-14 h-14"
                    onClick={() => setShowNewDebtModal(true)}
                >
                    <FontAwesomeIcon icon={faPlus} color="white"/>
                </button>
            </div>

            <NewDebtModal
                show={showNewDebtModal}
                onAdded={onNewDebtAdded}
                onClose={closeNewDebtModal}
            />

            <PayDebtModal
                show={showPayDebtModal}
                id={targetPayDebtId}
                onPaid={onDebtPaid}
                onClose={closePayDebtModal}
            />
        </>
    )
}

export default DebtScreen;