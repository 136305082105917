import React, { memo, useEffect, useState } from "react";
import axios_custom from "../../axios_config/axios_custom";
import Modal from "./Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SelectorTab = ({value, onChange}) => {
    return (
        <div className="flex flex-row h-8 bg-muted rounded-lg overflow-hidden border-2 border-muted">
            <button
                className={`w-16 items-center justify-center rounded ${ value === 'match' && 'bg-white' }`}
                onClick={ () => onChange('match')}
            >
                <span className="mx-2 my-1 text-xs">Match</span>
            </button>
            <button 
                className={`w-16 items-center justify-center rounded ${ value === 'all' && 'bg-white' }`}
                onClick={ () => onChange('all')}
            >
                <span className="mx-2 my-1 text-xs">All</span>
            </button>
        </div>
    )
}

const ExistedImagePickerModal = ({ 
    show, 
    filters, 
    onPick, 
    onClose 
}) => {

    const [type, setType] = useState('match');
    const [pickedImage, setPickedImage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [endResult, setEndResult] = useState(false);
    const [currentFilters, setCurrentFilters] = useState({
        page: 1,
        group: '',
        category: '',
        word: ''
    })

    const [images, setImages] = useState({
        all: [],
        match: [],
    });

    //fresh fetch
    useEffect( () => {
        if(show &&
            (filters.group !== currentFilters.group ||
            filters.category !== currentFilters.category ||
            filters.word !== currentFilters.word)
        ){
            setCurrentFilters({
                page: 1,
                ...filters
            })
        }
    }, [show]);

    //fetch
    useEffect( () => {
        if(filters.category || filters.word){
            setShowLoading(true);

            const controller = new AbortController();
            const signal = controller.signal; 

            axios_custom.get('images', {
                signal,
                params: currentFilters
            })
            .then( res => {
                setShowLoading(false);
                setImages( prevImages => {
                    const all = currentFilters.page === 1
                    ? res.data.all_images.data
                    : [...prevImages.all, ...res.data.all_images.data];

                    setEndResult(res.data.all_images.total === all.length);

                    return {
                        all: all,
                        match: res.data.best_matches
                    }
                });
            })
            .catch( () => {
                setShowLoading(false);
            });

            return () => controller.abort();
        }
    }, [currentFilters]);

    //methods
    const loadMore = () => {
        setCurrentFilters( prev => {
            return {
                ...prev, page: prev.page + 1
            }
        })
    }

    const pick = (newImage) => {
        const targetImage = newImage ?? pickedImage;
        onPick({
            id: targetImage.id,
            file: targetImage.name,
            src: `${process.env.REACT_APP_API_URL}/images/${targetImage.name}`
        });
        onClose();
    }

    return (
        <>
            <Modal
                show={show}
                title={
                    <SelectorTab
                        value={type} 
                        onChange={setType}
                    />
                }
                titlePosition="center"
                submitButtonText="Select"
                onClose={onClose}
                onSubmit={ () => pick(pickedImage)}
                isSubmitting={false}
                size="lg"
            >
                <div className="min-h-96">
                    { type === 'all' ? (
                        <div className="flex flex-col">
                            <div className="flex flex-row flex-wrap">
                                {images.all.map( image =>
                                    <div 
                                        key={image.id} 
                                        className="w-1/3 max-w-44"
                                        style={{
                                            maxWidth: '13.75rem'
                                        }}
                                        onClick={() => setPickedImage(image)}
                                        onDoubleClick={() => pick(image)}
                                    >
                                        <img 
                                            className={`w-full ${ ( pickedImage && image.id === pickedImage.id ) ? 'border-4 border-blue' : 'border border-white'}`}
                                            src={`${process.env.REACT_APP_API_URL}/images/${image.name}`} 
                                            alt="Loading..."    
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="flex items-center justify-center mt-5 mb-10">
                                { images.all.length === 0 ? (
                                    <span className="mt-5 text-sm font-bold text-soft-gray">
                                        No Images Found
                                    </span>
                                ) : (
                                    <>
                                        { endResult ? (
                                            <span className="text-soft-gray text-xs font-bold">End of Results</span>
                                        ) : (
                                            <button 
                                                className={`flex flex-row items-center justify-center px-2 h-8 space-x-2 rounded border border-gray}`}
                                                disabled={showLoading}
                                                onClick={loadMore}
                                            >
                                                { showLoading ? (
                                                    <FontAwesomeIcon className="animate-spin-right" icon={faSpinner} size="sm"/>
                                                ) : ''}
                                                <span className="text-gray text-xs">
                                                    {showLoading ? 'Loading...' : 'Load More'}
                                                </span>
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col">
                            <div className="flex flex-row flex-wrap">
                                {images.match.map( image =>
                                    <div 
                                        key={image.id} 
                                        className="w-1/3 max-w-44"
                                        style={{
                                            maxWidth: '13.75rem'
                                        }}
                                        onClick={() => setPickedImage(image)}
                                        onDoubleClick={() => pick(image)}
                                    >
                                        <img 
                                            className={`w-full ${ ( pickedImage && image.id === pickedImage.id ) ? 'border-4 border-blue' : 'border border-white'}`}
                                            src={`${process.env.REACT_APP_API_URL}/images/${image.name}`} 
                                            alt="Loading..."    
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="flex items-center justify-center mt-5 mb-10">
                                { images.match.length === 0 ? (
                                    <span className="mt-5 text-sm font-bold text-soft-gray">
                                        No Images Found
                                    </span>
                                ) : (
                                    <span className="text-soft-gray text-xs font-bold">End of Results</span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </>        
    );
};

export default memo(ExistedImagePickerModal);
