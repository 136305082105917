import React, { useEffect } from "react";
import Router from "./router/Router";
import useStoreHelper from "./hooks/useStoreHelper";
import { useDispatch } from "react-redux";
import LoadColors from "./components/LoadColors";
import { setAlert, setIsDesktop } from "./store/reducers/app-reducer";
import Toast from "./components/Toast";

const App = () => {

    const dispatch = useDispatch();
    const { getGroups } = useStoreHelper();
    
    useEffect( () => {
        const handleResize = () => {
            dispatch(setIsDesktop(window.innerWidth > 640)); 
        }

        getGroups();
        handleResize(); //initial resize

        const handleOnline = () => {
            dispatch(setAlert({
                msg: "You are back online.",
                status: 'success',
                timeout: 3000
            }));
        };
        const handleOffline = () => {
            dispatch(setAlert({
                msg: "No internet connection!",
                status: 'error',
                timeout: false
            }));
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('resize', handleResize);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="fixed h-screen w-screen flex flex-col overflow-hidden">
            <Toast/>
            {/* router_outlet */}
            <Router/>
            <LoadColors/>
        </div>
    );
}

export default App;
