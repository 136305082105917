import React, { useState, useCallback, memo } from 'react';
import axios_custom from '../axios_config/axios_custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faDotCircle, faPrint, faRightLong, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import WeightStats from './WeightStats';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../store/reducers/app-reducer';
import useStoreHelper from "../hooks/useStoreHelper";
import AnimatedCard from './AnimatedCard';
import Collapsible from './Collapsible';
import ActivityIndicator from './ActivityIndicator';
import PairRows from './PairRows';
import { SubmitButton } from './inputs';
import colors from '../helper/colors';
import DateParser from '../helper/dateParser';
import DateStats from './DateStats';

const CollateralCard = ({ 
    onTakeAction, 
    onPrintPaidInterest,
    data, 
    type, 
    highlightedCollateralId,
    highlightedInterestId, 
    details, 
    buttons
}) => {

    const dateParser = new DateParser();

    const authUser = useSelector( state => state.app.authUser);
    const isSettingMode = useSelector( state => state.app.isSettingMode);
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const [show, setShow] = useState(true);//show main (the whole) card
    const [isPending, setIsPending] = useState(false);

    const collateral = type === 'paid_interest' ? data.collateral : data;

    const [showCollapsed, setShowCollapsed] = useState(
        type === 'collateral' 
        ? Boolean(collateral.paid_interests.find( e => e.id === highlightedInterestId || e.serial === highlightedInterestId ))
        : false 
    );

    let card_color;
    if(collateral.id === highlightedCollateralId){
        card_color = 'bg-milk';
    } else if (collateral.has_withdrawn){
        card_color = 'bg-muted';
    } else {
        card_color = 'bg-white';
    }

    let status;
    if(collateral.has_withdrawn){
        status = {
            text: 'ရွေးပြီး',
            color: 'gray'
        }
    } else {
        if(collateral.has_expired){
            status = {
                text: 'Expired',
                color: 'red'
            }
        } else {
            status = {
                text: 'Active',
                color: 'green'
            }
        }
    }

    const costPerMonth = Math.round((collateral.amount * collateral.rate) / 100);

    //methods
        const deletePaidInterest = useCallback( (paid_interest_id) => {

            if( authUser.abilities.includes('manage_items') ){
                
                if(window.confirm('\nဖျက်မှာသေချာပါသလား?')){
                    axios_custom.delete('paid_interests/' + paid_interest_id)
                    .then( (res) => {

                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));

                        onTakeAction();
                    })
                    .catch( (error) => {
                        handleError(error);
                    });
                }

            } else {

                if(window.confirm('\nAdmin approve လုပ်ပြီးမှသာစာရင်းဖျက်ပြီးဖြစ်ပါမည်!')){
                    axios_custom.put('paid_interests/' + paid_interest_id, { user: authUser.id })
                    .then( (res) => {

                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));
                        
                        onTakeAction();
                    })
                    .catch( (error) => {
                        handleError(error);
                    });
                }
            }
        }, []);

        const denyAction = useCallback( () => {
            dispatch(setAlert({
                msg: "You don't have permission to do this action!",
                status: 'error',
                timeout: 5000
            }));
        }, []);

        const handleButtonClick = useCallback ( (onClick) => {
            if(typeof(onClick) === 'function'){
                onClick();
            } else {
                //instant actions
                switch (onClick.action) {

                    case 'collect_money':
                        //only for owner
                        if( authUser.abilities.includes('access_finance') ){
                            
                            if(window.confirm(`\n${data.amount} ကျပ်`)){
                                setIsPending(true);
                                axios_custom.put(`${type}s/${onClick.params.id}/collect_money`, { user: authUser.id })
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, 'collateral');
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                })
                            }

                        } else  {
                            denyAction();
                        }       
                            
                    break;

                    case 'delete':

                        if( authUser.abilities.includes('manage_items') ){

                            if(window.confirm(`\nဖျက်မှာ သေချာပါသလား?`)){
                                setIsPending(true);
                                const url = type === 'paid_interest' ? 'paid_interests/' : 'collaterals/';
                                axios_custom.delete(url + onClick.params.id)
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, type);
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }

                        } else {

                            if(window.confirm(`\nAdmin approve လုပ်ပြီးမှသာစာရင်းဖျက်ပြီးဖြစ်ပါမည်!`)){
                                setIsPending(true);
                                axios_custom.put('collaterals/' + onClick.params.id, { user: authUser.id })
                                .then( (res) => {
                                    setIsPending(false);
                                    dispatch(setAlert({
                                        msg: res.data.msg,
                                        status: 'success',
                                        timeout: 3000
                                    }));
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }
                        }
                        
                    break;

                    case 'reject':
                        
                        if( authUser.abilities.includes('manage_items') ){

                            if(window.confirm('\nပယ်ဖျက်မှာ သေချာပါသလား?')){
                                setIsPending(true);
                                const url = type === 'paid_interest' ? 'paid_interests/' : 'collaterals/';
                                axios_custom.put(url + onClick.params.id + '/reject')
                                .then( () => {
                                    setShow(false);
                                    setTimeout(() => {
                                        onTakeAction(onClick.params.id, type);
                                    }, 1000);
                                })
                                .catch( (error) => {
                                    setIsPending(false);
                                    handleError(error);
                                });
                            }
                        }

                    break;

                    default:
                    break;
                }
            }
        }, []);

    return (
        <AnimatedCard show={show}>
            <div className={`flex flex-col relative m-2 rounded-xl pb-5 shadow-custom ${card_color}`}>

                { isPending ? (
                    //pending mask
                    <div className={`flex absolute top-0 bottom-0 left-0 right-0 items-center justify-center bg-dim-gray opacity-50 rounded-xl`}>
                        <ActivityIndicator/>
                    </div>
                ) : ''}

                {collateral.id === highlightedCollateralId ? (
                    <div className="mt-2">
                        <span className="text-red italic text-sm font-bold mx-3">* New</span>
                    </div>
                ) : ''}

                {/* header */}
                <div className={`flex flex-row justify-between mx-3 ${ (collateral.id === highlightedCollateralId) ? 'mt-2' : 'mt-4'}`}>
                    <div className="flex flex-row space-x-1 items-center justify-center">
                        <FontAwesomeIcon
                            icon={faDotCircle}
                            size="xs"
                            color={ colors.find( e => e.org === status.color ).normal }
                        />
                        <span className={`text-sm font-bold text-${status.color}`}>{ status.text }</span>
                    </div>
                    <div className="flex flex-row space-x-1 items-center">
                        <span className="text-sm font-bold text-gray">Voucher No:</span>
                        <span className="text-sm font-bold">{ type === 'collateral' ? collateral.serial : data.serial }</span>
                    </div>
                </div>

                <div className="px-3 border-b border-b-dim-gray pt-6 pb-4 space-y-6">

                    <div className="flex flex-row justify-center items-center">
                        { (type === 'collateral') ? (
                            <span className="text-lg text-sky">
                                { 'ယူငွေ - ' + new Intl.NumberFormat().format(data.amount) }
                            </span>
                        ) : (
                            <span className="text-lg text-sky">
                                အတိုးပေး - { new Intl.NumberFormat().format(data.amount) }
                            </span>
                        )}
                    </div>

                    {/* voucher */}
                    <div className="flex flex-col space-y-5">

                        { type === 'collateral' ? (
                            <div className="flex flex-col space-y-2">
                                <div className="flex flex-row justify-between">
                                    <span className="text-black text-sm">စတင်ယူသည့်ရက်စွဲ</span>
                                    <span className="text-black text-sm">{ dateParser.toString(collateral.sold_date) }</span>
                                </div>
                                { collateral.last_paid_date ? (
                                    <div className="flex flex-row justify-between">
                                        <span className="text-black text-sm">{ collateral.has_withdrawn ? 'အပေါင်ရွေးသည့်နေ့' : 'အတိုးရှင်းပြီးရက်စွဲ'}</span>
                                        <div className="flex flex-row items-center space-x-1">
                                            <span className="text-black text-sm">{ dateParser.toString(collateral.last_paid_date) }</span>
                                            { !collateral.has_withdrawn ? (
                                                <span className="text-xs text-gray">ထိရှင်းပြီး</span>
                                            ) : ''}
                                        </div>
                                        
                                    </div>
                                ) : ''}
                            </div>
                        ) : (
                            <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-row items-center space-x-1">
                                    <span className="text-sm">{ dateParser.toString(data.from_date) }</span>
                                    <FontAwesomeIcon
                                        icon={faRightLong}
                                        color={ colors.find( e => e.org === 'gray').normal }
                                        size="2xs"
                                    />
                                    <span className="text-sm">{ dateParser.toString(data.to_date) }</span>
                                </div>
                                <span className="text-sm">
                                    { dateParser.compareMonthDiff(data.from_date, data.to_date) === 0
                                        ? dateParser.compareDayDiff(data.from_date, data.to_date) + ' ရက်စာ'
                                        : dateParser.compareMonthDiff(data.from_date, data.to_date) + ' လစာ'
                                    }
                                </span>
                            </div>
                        )}

                        <div className="flex flex-col space-y-2">
                            <span className="text-gray text-sm">{ collateral.customer_name }</span>
                            <span className="text-gray text-sm">{ collateral.customer_address }</span>
                            { collateral.customer_phone ? (
                                <span className="text-gray text-sm">{ collateral.customer_phone }</span>
                            ) : ''}
                        </div>

                        <div className="flex flex-row justify-center">
                            <span className="text-gray text-sm">{ collateral.name }</span>
                        </div>

                        <PairRows
                            data={[
                                {
                                    label: 'အလေးချိန်',
                                    value: <WeightStats total={collateral.weight}/>
                                },
                                {
                                    label: 'အတိုးနှုန်း',
                                    value: `${Number(collateral.rate)} % (တစ်လ ${new Intl.NumberFormat().format(costPerMonth)} ကျပ်)`
                                }
                            ]}
                            isBold={false}
                            labelWidth="70"
                            color="gray"
                            size="sm"
                            spaceY="2"
                            spaceX="1"
                        />

                    </div>
                    
                    { (collateral.description && type === 'collateral') ? ( 
                        <div className="flex mt-3 justify-center items-center space-x-2">
                            <span className="text-soft-gray text-xs pt-2">{ collateral.description } !</span>
                        </div>
                    ) : ''}
                </div>

                {/* interests collapse toggle */}
                { type === 'collateral' ? ( 
                    <>
                        { collateral.paid_interests.length !== 0 ? (
                            <div className="cursor-pointer" onClick={ () => setShowCollapsed( prev => !prev ) }>
                                <div className="flex flex-row items-center justify-center space-x-3 py-4 bg-dim-sky">
                                        <span className="font-bold text-sm">
                                            အတိုးပေးမှတ်တမ်း
                                        </span>
                                    <FontAwesomeIcon icon={showCollapsed ? faChevronUp : faChevronDown} size="xs"/>
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-center bg-muted py-4">
                                <span className="font-bold text-sm">
                                    အတိုးပေးထားခြင်းမရှိပါ
                                </span>
                            </div>
                        )}

                        <Collapsible 
                            collapsed={!showCollapsed}
                            maxHeight={300}
                        >
                            <div className="flex flex-col space-y-3 mx-2 my-1 py-2 max-h-64 overflow-auto">
                                { data.paid_interests.map( (paid_interest, index) => 
                                    <div 
                                        key={paid_interest.id} 
                                        className={`flex flex-row space-x-2 pb-2
                                            ${ (highlightedInterestId === paid_interest.id) ? 'bg-dim-yellow' : 'bg-dim-muted'}
                                        `}
                                    >
                                        <div className="flex-1 flex flex-col space-y-2">
                                            <div className="flex flex-row items-center justify-between bg-gray/20 h-8 px-2">
                                                <div className="flex flex-row space-x-1">

                                                    { (highlightedInterestId === paid_interest.id) ? (
                                                        <span className="text-red italic text-2xs font-bold mr-1">
                                                            * New
                                                        </span>
                                                    ) : ''}

                                                    <span className="text-gray font-bold text-xs">
                                                        Voucher No:
                                                    </span>
                                                    <span className={`font-bold text-xs ${ highlightedInterestId === paid_interest.serial ? 'bg-soft-yellow' : ''}`}>
                                                        { paid_interest.serial }
                                                    </span>
                                                </div>
                                                <div className="flex flex-row space-x-2">
                                                    { isSettingMode ? (
                                                        <button 
                                                            className="flex items-center justify-center bg-red rounded h-6 w-6" 
                                                            onClick={ () => deletePaidInterest(paid_interest.id)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrashCan}
                                                                size="xs"
                                                                color={ colors.find( e => e.org === 'white').normal }
                                                            />
                                                        </button>
                                                    ) : ''}
                                                    <button 
                                                        className="flex items-center justify-center bg-white rounded h-6 w-7" 
                                                        onClick={ () => onPrintPaidInterest(collateral, paid_interest)}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPrint}
                                                            size="xs"
                                                            color={ colors.find( e => e.org === 'blue').normal }
                                                        />
                                                    </button>
                                                </div>
                                            </div>

                                            <div key={index} className="flex flex-row items-center justify-between mx-2">
                                                <div className="flex flex-row items-center space-x-1">
                                                    <span className="text-xs">{ dateParser.toString(paid_interest.from_date) }</span>
                                                    <FontAwesomeIcon
                                                        icon={faRightLong}
                                                        color={ colors.find( e => e.org === 'gray').normal }
                                                        size="2xs"
                                                    />
                                                    <span className="text-xs">{ dateParser.toString(paid_interest.to_date) }</span>
                                                </div>
                                                <div className="flex flex-row">
                                                    <span className="text-xs">
                                                        <DateStats
                                                            date={dateParser.compareDateDiff(paid_interest.from_date, paid_interest.to_date)}
                                                        />
                                                    </span>
                                                    <span className="text-xs">
                                                        စာ
                                                    </span>
                                                </div>
                                                <span className="text-xs font-bold">
                                                    { new Intl.NumberFormat().format(paid_interest.amount) }
                                                </span>
                                            </div>

                                            <div className="flex flex-row space-x-2 justify-between mx-2">
                                                <span className="text-2xs text-soft-gray">
                                                    ငွေလက်ခံသူ - { paid_interest.received_by.name }
                                                </span>
                                                <span className="text-2xs text-soft-gray">
                                                    အတိုးပေးသောနေ့ - { dateParser.toString(paid_interest.received_date) }
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </Collapsible>
                    </>
                ): '' }

                <div className={`flex flex-col pt-5 space-y-6 ${type === 'collateral' ? 'border-t border-t-dim-gray' : ''}`}>
                    {/* sold_detail */}
                    { details.includes('sold') ? (
                        <PairRows
                            className="px-4"
                            data={[
                                {
                                    label: 'အပေါင်ပေးသူ',
                                    value: data.sold_by.nickname
                                },
                                {
                                    label: collateral.has_withdrawn ? 'အပေါင်ရွေးသည့်နေ့' : 'သက်တမ်းကုန်ဆုံးရက်',
                                    value: dateParser.toString(collateral.has_withdrawn ? collateral.last_paid_date : data.expiry_date)
                                }
                            ]}
                            labelWidth={ collateral.has_withdrawn ? '120' : '140'}
                            color="gray"
                            size="sm"
                            spaceY="2"
                            spaceX="1"
                        />
                    ) : ''}

                    {/* received_detail */}
                    { details.includes('received') ? (
                        <PairRows
                            className="px-4"
                            data={[
                                {
                                    label: 'အတိုးပေးငွေလက်ခံသူ',
                                    value: data.received_by.nickname
                                },
                                {
                                    label: 'အတိုးပေးသည့်နေ့',
                                    value: dateParser.toString(data.received_date)
                                }
                            ]}
                            labelWidth="137"
                            color="gray"
                            size="sm"
                            spaceY="2"
                            spaceX="1"
                        />
                    ) : ''}

                    {/* requested_detail */}
                    { details.includes('requested') ? (
                        <PairRows
                            className="px-4"
                            data={[
                                {
                                    label: 'စာရင်းဖျက်ခွင့်တောင်းသူ',
                                    value: data.requested_by.nickname
                                },
                                {
                                    label: 'တောင်းဆိုသောနေ့',
                                    value: dateParser.toString(data.requested_date)
                                }
                            ]}
                            labelWidth="150"
                            color="gray"
                            size="sm"
                            spaceY="2"
                            spaceX="1"
                        />
                    ) : ''}
                </div>

                {/* button */}
                { (buttons.show) ? (
                    <div className="flex flex-row items-center mx-3 mt-6">
                            
                        { (isSettingMode && buttons.setting_mode_buttons.length !== 0) ? (  
                            buttons.setting_mode_buttons.map( (button, index) =>
                                <SubmitButton
                                    key={index}
                                    title={button.name}
                                    color={button.color}
                                    height="12"
                                    textColor={button.textColor}
                                    icon={button.icon}
                                    radius={`${ index === 0 && 'rounded-l-lg'} ${ button.id === buttons.setting_mode_buttons.length && 'rounded-r-lg'}`}
                                    onClick={ () => handleButtonClick(button.onClick) }
                                />
                            )
                        ) : (
                            buttons.normal_buttons.map( (button, index) =>
                                <SubmitButton
                                    key={index}
                                    title={button.name}
                                    color={button.color}
                                    height="12"
                                    textColor={button.textColor}
                                    icon={button.icon}
                                    radius={`${ index === 0 && 'rounded-l-lg'} ${ button.id === buttons.normal_buttons.length && 'rounded-r-lg'}`}
                                    onClick={ () => handleButtonClick(button.onClick) }
                                />
                            )
                        )}

                    </div>
                ) : ''}

            </div>
        </AnimatedCard>
    )
}

export default memo(CollateralCard, () => true);