import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import axios_custom from "../../axios_config/axios_custom";
import { defaultGroup } from '../../helper/defaults';
import { useSelector } from 'react-redux';
import useStoreHelper from "../../hooks/useStoreHelper";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import colors from '../../helper/colors';
import { useIsFirstRender } from '@uidotdev/usehooks';

const Nav = ({
    values,
    navType, //groups, users, calendar
    enableAllSearch, //boolean
    enabledSearches, //array: id, date, word, customer, price, size, type, weight, user
    disableNavChoice, //boolean
    countBy, //obj: { group: [], user: []} 
    onSearch, //func
    searchOnNavChange, //boolean
    fixedTop = true,
}) => {

    const currentRoute = useSelector( state => state.router.currentRoute ); 
    const isDesktop = useSelector( state => state.app.isDesktop );
    const groups = useSelector( state => state.app.groups);
    const isFirstRender = useIsFirstRender();
    const { handleError } = useStoreHelper();

    const defaultInputFilters = {
        date: '',
        id: '',
        word: '',
        min_price: '',
        max_price: '',
        min_weight: '',
        max_weight: '',
        customer_name: '',
        customer_address: '',
        customer_phone: ''
    }

    //initial values and insert api data
    const [users, setUsers] = useState([
        {
            id: null,
            name: 'Loading...',
            nickname: 'Loading...',
        },
        {
            id: 1,
            name: 'Loading...',
            nickname: 'Loading...',
        }
    ]);

    const [options, setOptions] = useState({
        categories: [],
        sizes: [],
    });

    const [showSearchForm, setShowSearchForm] = useState(false);
    const [optionSearchable, setOptionSearchable] = useState(false);
    const enableAdvanceSearch = (enabledSearches.length !== 0) && (navType !== 'calendar');
    const [highlightedDates, setHighlightedDates] = useState([]);

    //inputs
    const [inputFilters, setInputFilters] = useState(defaultInputFilters);
    const [group, setGroup] = useState(values?.group);
    const [user, setUser] = useState(values?.user);
    const [year, setYear] = useState( new Date().getFullYear() );
    const [month, setMonth] = useState(null);
    const [min_weight_unit, setMinWeightUnit] = useState('pel');
    const [max_weight_unit, setMaxWeightUnit] = useState('pel');
    const [checked_categories, setCheckedCategories] = useState([]);
    const [checked_sizes, setCheckedSizes] = useState([]);
    
    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === group) || defaultGroup
        }, [groups, group]);

    //mounted
        useEffect( () => {
            const metaTag = document.querySelector('meta[name="theme-color"]');
            const backgroundColor = colors.find( e => e.org === 'black').soft;
            if (metaTag) {
                metaTag.setAttribute('content', backgroundColor);
            } else {
                const newMetaTag = document.createElement('meta');
                newMetaTag.name = 'theme-color';
                newMetaTag.content = backgroundColor;
                document.head.appendChild(newMetaTag);
            }

            const controller = new AbortController();
            const signal = controller.signal;

            axios_custom.get('users', { signal })
            .then( (res) => {
                let users = res.data.results.filter( e => e.type !== 'owner');
                users = [
                    {
                        id: null,
                        name: 'All',
                        nickname: 'All'
                    },
                    ...users
                ]
                setUsers(users);
            })
            .catch( (error) => {
                handleError(error);
            });
    
            return () => {
                controller.abort();
            }
        }, []);

    //watchers
        useEffect( () => {
            const controller = new AbortController();
            const signal = controller.signal;

            axios_custom.get('options', { params: {group}, signal })
            .then( (res) => {
                setOptions(res.data);
            })
            .catch( (error) => {
                handleError(error);
            });

            return () => {
                controller.abort();
            }
        }, [group, groups]);

        useEffect( () => {
            if(['add_record', 'sold', 'finance', 'debt', 'manage_request'].includes(currentRoute.name)){
                const controller = new AbortController();
                const signal = controller.signal;

                axios_custom.get('dates', {
                    signal,
                    params: {
                        for: currentRoute.name,
                        group,
                        user
                    }
                })
                    .then( (res) => {
                        setHighlightedDates(res.data.results.map( e => new Date(e)));
                    })
                    .catch( () => {
                        //void
                    });
            
                return () => controller.abort();
            }
        }, [currentRoute, group, user]);

    //watch and search
        //search options
        useEffect( () => {
            if(optionSearchable && onSearch){
                const search = setTimeout(() => {
                    onSearch({
                        ...values,
                        group,
                        user,
                        min_weight_unit,
                        max_weight_unit,
                        checked_categories,
                        checked_sizes,
                        ...inputFilters
                    });
                }, 800);
                return () => clearTimeout(search);
            }
        }, [checked_categories, checked_sizes]);

        useEffect( () => {
            clearOptions();
            if(searchOnNavChange || isDesktop){
                if(navType === 'calendar'){
                    setTimeout(() => {
                        onSearch({ 
                            ...values,
                            group,
                            user,
                            year, 
                            month 
                        });
                    }, 0);
                } else {
                    if(!isFirstRender){
                        searchAll();
                    }
                }
            }
        }, [group, user, year])

        useEffect( () => {
            if(onSearch && navType === 'calendar'){
                setTimeout(() => {
                    onSearch({ 
                        ...values,
                        group,
                        user,
                        year, 
                        month 
                    });
                }, 0);
            }
        }, [month]);

        useEffect( () => {
            if(navType !== 'calendar'){
                setTimeout(() => {
                    if(!isFirstRender){
                        search();
                    }
                }, 0);
            }
        }, [inputFilters.date]);

    //methods
        const changeCategories = useCallback( id => {
            setOptionSearchable(true);
            setCheckedCategories( prevIds => {
                const idSet = new Set(prevIds);
                idSet.has(id) ? idSet.delete(id) : idSet.add(id);
                return [...idSet];
            });
        }, []);

        const changeSizes = useCallback( size => {
            setOptionSearchable(true);
            setCheckedSizes( prevSizes => {
                const sizeSet = new Set(prevSizes);
                sizeSet.has(size) ? sizeSet.delete(size) : sizeSet.add(size);
                return [...sizeSet];
            });
        }, []);

        const clearOptions = () => {
            setCheckedCategories(() => {
                setCheckedSizes(() => {
                    //disable OptionSearchable first
                    //to prevent onSearch multiple times
                    setOptionSearchable(false);
                    return [];
                });
                return [];
            });
        };
        
        //current searchType is used only for id
        const search = (searchType) => {
            setShowSearchForm(false);

            if(searchType === 'id'){
                clearOptions();
            } else {
                setInputFilters({...inputFilters, id: '' });
            }
            
            if(onSearch){
                setTimeout(() => {
                    onSearch({
                        ...values,
                        id: searchType === 'id' ? inputFilters.id : '',
                        group,
                        user,
                        min_weight_unit,
                        max_weight_unit,
                        checked_categories: searchType === 'id' ? [] : checked_categories,
                        checked_sizes: searchType === 'id' ? [] : checked_sizes,
                        ...inputFilters,
                    });
                }, 0);
            }
        };

        const searchAll = () => {
            setInputFilters(defaultInputFilters);
            setShowSearchForm(false);
            clearOptions();

            if(onSearch){
                setTimeout(() => {
                    onSearch({
                        ...values,
                        group,
                        user,
                        min_weight_unit,
                        max_weight_unit,
                        checked_categories: [],
                        checked_sizes: [],
                        ...defaultInputFilters,
                    });
                }, 0);
            }
        };

    //data preparation to parse
        const props = {
            navType,
            enableAllSearch,
            enabledSearches,
            disableNavChoice,
            countBy, 
            onSearch,
            searchOnNavChange,
            fixedTop,

            defaultInputFilters,
            users,
            options,
            currentGroup,
            enableAdvanceSearch,
            showSearchForm, setShowSearchForm,
            highlightedDates,
            
            inputFilters, setInputFilters,
            group, setGroup,
            user, setUser,
            year, setYear,
            month, setMonth,
            min_weight_unit, setMinWeightUnit,
            max_weight_unit, setMaxWeightUnit,
            checked_categories, setCheckedCategories,
            checked_sizes, setCheckedSizes,

            changeCategories, 
            changeSizes,
            clearOptions,
            search,
            searchAll
        }

    return (
        isDesktop 
        ? <DesktopNav {...props}/> 
        : <MobileNav {...props}/>
    );
};

export default memo(Nav, (prevProps, newProps) => prevProps.countBy === newProps.countBy);