import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGroup, defaultSearchFilters } from '../helper/defaults';
import useApiFetcher from '../hooks/useApiFetcher';
import Nav from '../components/nav';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../store/reducers/app-reducer';
import useNavigation from '../hooks/useNavigation';
import ItemCard from '../components/ItemCard';
import { SaleVoucherFormModal } from '../components/modals';
import ItemListFrame from '../components/ItemListFrame';

const SaleScreen = () => {

    const authUser = useSelector( state => state.app.authUser);
    const currentRoute = useSelector( state => state.router.currentRoute);
    const groups = useSelector( state => state.app.groups);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const fetchApi = useApiFetcher();
    const routeState = currentRoute.params?.state;
    
    const [search_filters, setSearchFilters] = useState({
        ...defaultSearchFilters,
        id: routeState?.id ?? null,
        group: routeState?.group ?? 1,
        sort_by: {
            type: 'price',
            order: 'asc'
        },
    });

    const [showLoading, setShowLoading] = useState(false);
    const [page, setPage] = useState(2);
    const [response, setResponse] = useState({
        count: 0,
        items: [],

        count_by: {
            group: [],
            user: []
        }
    });

    //computed
        const currentGroup = useMemo( () => {
            return groups.find( e => e.id === search_filters.group ) || defaultGroup
        }, [groups, search_filters.group]);

        const endResult = useMemo( () => response.count === response.items.length, [response.items.length]);

    //api_fetch
        useEffect( () => {
            setResponse({
                count: 0,
                items: [],
        
                count_by: {
                    group: [],
                    user: []
                }
            });

            fetchApi('items', {page: 1, ...search_filters}, [], setShowLoading, setResponse);
            (page !== 2) && setPage(2);
        }, [search_filters]);

    //reload on navigate watcher
        useEffect( () => {
            if(routeState?.reload){
                setSearchFilters({...search_filters});
            }
        }, [routeState]);

    //methods
        const loadMore = () => {
            if(!endResult && response.items.length !== 0){
                fetchApi('items', {page, ...search_filters}, response.items, setShowLoading, setResponse, setPage);
            }
        }

        const denyAction = useCallback( () => {
            dispatch(setAlert({
                msg: "You don't have permission to do this action!",
                status: 'error',
                timeout: 5000
            }));
        }, []);

        const getNormalButtons = useCallback( (item) => {
            if(item.category.has_attachment){

                return [
                            {
                                id: 1,
                                name: 'အတွဲသီးသန့်',
                                color: 'yellow',
                                onClick: () => {
                                    if(authUser.abilities.includes('sell')){
                                        openVoucher(item, 'attachment_only');
                                    } else {
                                        denyAction();
                                    }
                                }
                            },
                            {
                                id: 2,
                                name: 'အစုံလိုက်',
                                color: 'green',
                                onClick: () => {
                                    if(authUser.abilities.includes('sell')){
                                        openVoucher(item, 'all');
                                    } else {
                                        denyAction();
                                    }
                                }
                            },
                        ]
            } else {

                return [
                            {
                                id: 1,
                                name: 'ရောင်း',
                                color: 'green',
                                onClick: () => {
                                    if(authUser.abilities.includes('sell')){
                                        openVoucher(item, 'all')
                                    } else {
                                        denyAction();
                                    }
                                }
                            }
                        ]
            }
        }, []);

        const updateList = useCallback( (id) => {
            setResponse( prev => {
                const newData = {...prev};
                newData.count = prev.count - 1;
                newData.items = prev.items.filter( e => e.id !== id);
                return newData;
            })
        }, []);
        
        //voucher modal
        const defaultSaleData = { item: null, saleType: null};
        const [saleData, setSaleData ] = useState(defaultSaleData);
        const [showVoucher, setShowVoucher ] = useState(false);

        const openVoucher = (item, saleType) => {
            setShowVoucher( () => {
                setSaleData({
                    item, saleType
                });
                return true;
            });
        }

        const closeVoucher = () => {
            setShowVoucher(false);
            setSaleData(defaultSaleData);
        }

    return(
        <>
            <Nav
                values={search_filters}
                onSearch={setSearchFilters}
                navType="groups"
                searchOnNavChange={false}
                enableAllSearch={false} 
                enabledSearches={['id' ,'word', 'price', 'type', 'size', 'weight']}
            />

            <ItemListFrame
                enabledSortTypes={['id', 'price', 'added_date']}
                sortByValue={search_filters.sort_by}
                onSortByChange={ newSortBy => setSearchFilters({...search_filters, sort_by: newSortBy})}
                onEndReached={loadMore}
                isLoading={showLoading}
                onPricingsChange={() => setSearchFilters({...search_filters})}
                totalCount={ response.count }
                countUnit={ currentGroup.count_unit }
                isEnd={endResult && response.count !== 0}
            >
                <div className="flex-1 flex flex-row flex-wrap">
                    { response.items.map( item => 
                        <div key={item.id} className="flex justify-center w-full lg:w-1/2 xl:w-1/3">
                            <ItemCard
                                onTakeAction={updateList}
                                item={item}
                                displayMode="all"
                                details={{
                                    show: ['added'],
                                    requireSettingMode: true,
                                }}
                                traderWeight={{
                                    show: true,
                                    type: 'inline',
                                    requireSettingMode: true,
                                }}
                                buttons={{
                                    show: true,
                                    normal_buttons: getNormalButtons(item),
                                    setting_mode_buttons:[
                                                            {
                                                                id: 1,
                                                                name: 'Edit',
                                                                color: 'sky',
                                                                onClick: () => {
                                                                    navigation.navigate('form', {
                                                                        state: {
                                                                            id: item.id,
                                                                            item: item
                                                                        }
                                                                    })
                                                                }
                                                            },
                                                            {
                                                                id: 2,
                                                                name: 'Delete',
                                                                color: 'red',
                                                                onClick: {
                                                                    action: 'delete',
                                                                    params: {
                                                                        id: item.id
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                id: 3,
                                                                name: 'ရောင်း',
                                                                color: 'green',
                                                                onClick: () => {
                                                                    if(authUser.abilities.includes('sell')){
                                                                        openVoucher(item, 'all');
                                                                    } else {
                                                                        denyAction();
                                                                    }
                                                                }
                                                            },
                                                        ]
                                }}
                            />
                        </div>
                    )}
                </div>
            </ItemListFrame>

            <SaleVoucherFormModal
                show={showVoucher}
                data={saleData}
                onSold={ () => {
                    updateList(saleData.item.id);
                    closeVoucher();
                }}
                onClose={closeVoucher}
            />
           
        </>
    )
}

export default SaleScreen;