import React from "react";
import ActivityIndicator from "./ActivityIndicator";

const FallBackLoader = () => {
    return (
        <div className="flex-1 flex flex-col items-center justify-center bg-white">
            <ActivityIndicator />
        </div>
    );
};

export default FallBackLoader;
