import React, { useMemo, useState, useEffect, useCallback } from "react";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import colors from "../helper/colors";
import { Input, SubmitButton } from "../components/inputs";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import useNavigation from "../hooks/useNavigation";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../store/reducers/app-reducer";
import { faCheck, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PasswordResetModal } from "../components/modals";

const UserFormScreen = () => {

    const authUser = useSelector( state => state.app.authUser);
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const navigation = useNavigation();

    const currentRoute = useSelector( state => state.router.currentRoute);
    const routeState = currentRoute.params?.state;
    const isEdit = Boolean(routeState);
    const user = isEdit ? routeState.user : null;
    const isSelfEdit = isEdit ? Boolean(user.id === authUser.id) : false;

	//from api
    const [users, setUsers] = useState([]);
	const [allUserAbilities, setAllUserAbilitites] = useState([]);

    //inputs
    const [name, setName] = useState( isEdit ? user.name : '');
    const [nickname, setNickname ] = useState( isEdit ? user.nickname : '');
    const [username, setUsername ] = useState( isEdit ? user.username : '');
    const [password, setPassword ] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [checked_abilities, setCheckedAbilities] = useState( isEdit ? user.abilities : []);

    const [errorCheckable, setErrorCheckable] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

    //mounted
    useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
	  
		axios_custom.get('users', { signal })
		.then( res => {
			setUsers(res.data.results);
		})
		.catch((error) => {
			handleError(error);
		});
	  
		axios_custom.get('all_user_abilities', { signal })
		.then( res => {

            const colorsByRiskLvl = [
                {
                    lvl: 1,
                    color: 'green'
                },
                {
                    lvl: 2,
                    color: 'yellow'
                },
                {
                    lvl: 3,
                    color: 'red'
                }
            ];

			setAllUserAbilitites(res.data.results.map( ability => {
                return {
                    ...ability,
                    color: colorsByRiskLvl.find( e => e.lvl === ability.risk_lvl ).color
                }
            }));
		})
		.catch((error) => {
			handleError(error);
		});
	  
		return () => {
		  	controller.abort();
		};
	  }, []);
	  

    //computed
	const existed = useMemo( () => {
        return {
			names: users.map( e => e.name),
			nicknames: users.map( e => e.nickname),
			usernames: users.map( e => e.username)
		}
    }, [users]);

    const inputErrors = useMemo( () => {
        const errors = [];

        name === '' && errors.push('name');
        username === '' && errors.push('username');
		checked_abilities.length === 0 && errors.push('abilities');

        if(!isEdit){

            password === '' && errors.push('password');
			confirm_password === '' && errors.push('confirm_password');

			//check password match
			if(password !== '' && confirm_password !== ''){
				if(password !== confirm_password){
					errors.push('passwords_not_match');
				}
			}

        }

		if(isEdit){

			if(name !== user.name && existed.names.includes(name)){
				errors.push('duplicate_name');
			}

			if(nickname !== user.nickname && existed.nicknames.includes(nickname) && existed.names.includes(nickname)){
				errors.push('duplicate_nickname');
			}

			if(username !== user.username && existed.usernames.includes(username)){
				errors.push('duplicate_username');
			}

		} else {

			if(existed.names.includes(name)){
				errors.push('duplicate_name');
			}

			if(existed.names.includes(nickname) && existed.nicknames.includes(nickname)){
				errors.push('duplicate_nickname');
			}

			if(existed.usernames.includes(username)){
				errors.push('duplicate_username');
			}
		}

        return errors;

    }, [name, nickname, username, password, confirm_password, checked_abilities, existed]);

    //methods
		const changeCheckedAbilities = useCallback( abilityName => {
			setCheckedAbilities( prevAbilities => {
				const abilitySet = new Set(prevAbilities);
				abilitySet.has(abilityName) ? abilitySet.delete(abilityName) : abilitySet.add(abilityName);
				return [...abilitySet];
			});
		}, []);

        const submit = () => {
            setErrorCheckable(true);
            if(inputErrors.length === 0){
                setIsSubmitting(true);

                const abilities = [...checked_abilities];
                
                if(checked_abilities.some( e => e === 'add_new_collaterals' || e === 'accept_interest_payments' )){
                    abilities.push('view_collaterals');
                }

                if(isEdit){
                    axios_custom.put('/users/' + user.id, {
                        name,
                        nickname,
                        username,
                        abilities: abilities
                    } )
                    .then( res => {

                        setIsSubmitting(false);
                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));

                        navigation.navigate('user_details', {
                            state: {
                                user: res.data.result,
                            }
                        });
                    })
                    .catch( error => {
                        setIsSubmitting(false);
                        handleError(error);
                    });

                } else {
                    axios_custom.post('/users', { 
                        name,
                        nickname,
                        username,
                        password,
                        confirm_password,
                        abilities: abilities 
                    })
                    .then( res => {

                        setIsSubmitting(false);
                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));

                        navigation.navigate('users', {
                            state: {
                                newUserId: res.data.result.id,
                            }
                        });
                    })
                    .catch( error => {
                        setIsSubmitting(false);
                        handleError(error);
                    });
                }

            }
        }

        const closePasswordResetModal = useCallback( () => {
            setShowPasswordResetModal(false);
        }, []);

    return (
        <StackScreen>
            <ScrollView className="flex flex-col flex-1">
                <div className="flex flex-col space-y-8 mx-5 mt-7 mb-16">

                    <div className="flex flex-col space-y-5">

                        <Input
                            title="ဝန်ထမ်းအမည်"
                            type="text"
                            value={name}
                            onChange={ text => setName(text)}
                            errors={[
                                {
                                    show: errorCheckable && inputErrors.includes('name'),
                                    msg: `အမည်ထည့်ပါ!`
                                },
                                {
                                    show: errorCheckable && inputErrors.includes('duplicate_name'),
                                    msg: 'အခြားဝန်ထမ်းတစ်ယောက်နှင့် အမည်တူနေပါသည်။ နာမည်တူဖြစ်နေပါက 1 / 2 အဖြစ်ထည့်ပေးပါ။ (ဥပမာ. မောင်မောင်1, မောင်မောင်2)'
                                }
                            ]}
                        />

                        <Input
                            title="Nickname"
                            type="text"
                            value={nickname}
                            onChange={ text => setNickname(text)}
                            warningMsges={[
                                'မထည့်လဲရသည်။'
                            ]}
                            errors={[
                                {
                                    show: errorCheckable && inputErrors.includes('duplicate_nickname'),
                                    msg: `အခြားဝန်ထမ်းတစ်ယောက်နှင့် အမည်တူနေပါသည်။ နာမည်တူဖြစ်နေပါက 1 / 2 အဖြစ်ထည့်ပေးပါ။ (ဥပမာ. မောင်မောင်1, မောင်မောင်2)!`
                                }
                            ]}
                        />

                        <Input
                            title="Username"
                            type="text"
                            value={username}
                            onChange={ text => setUsername(text.toLowerCase())}
                            warningMsges={[
                                'English စာလုံးအသေးများသာထည့်ပါ။ / Space မခြားရ။'
                            ]}
                            errors={[
                                {
                                    show: errorCheckable && inputErrors.includes('username'),
                                    msg: `Username ထည့်ပါ!`
                                },
                                {
                                    show: errorCheckable && inputErrors.includes('duplicate_username'),
                                    msg: `အခြားဝန်ထမ်း၏ username နှင့်တူနေပါသည်။ အခြားအမည်ရွေးပါ!`
                                }
                            ]}
                        />

                        { !isEdit ? (
                            <div className="flex flex-col space-y-5">  
                                <Input
                                    title="Password"
                                    type="password"
                                    value={password}
                                    onChange={ text => setPassword(text)}
                                    errors={[
                                        {
                                            show: errorCheckable && inputErrors.includes('password'),
                                            msg: `Password ထည့်ပါ!`
                                        },
                                        {
                                            show: errorCheckable && inputErrors.includes('passwords_not_match'),
                                            msg: `Password ၂ ခု မညီပါ! ပြန်ရိုက်ပါ!!`
                                        }
                                    ]}
                                />
                                <Input
                                    title="Confirm Password"
                                    type="password"
                                    value={confirm_password}
                                    onChange={ text => setConfirmPassword(text)}
                                    warningMsges={[
                                        'အပေါ်မှာထည့်ခဲ့သည့် password ကိုပြန်ရိုက်ပါ။'
                                    ]}
                                    errors={[
                                        {
                                            show: errorCheckable && inputErrors.includes('confirm_password'),
                                            msg: `Password Confirm လုပ်ပါ!`
                                        },
                                        {
                                            show: errorCheckable && inputErrors.includes('passwords_not_match'),
                                            msg: `Password ၂ ခု မညီပါ! ပြန်ရိုက်ပါ!!`
                                        }
                                    ]}
                                />
                            </div>  
                        ) : ''}

                        { !isSelfEdit ? (
                            <div className="flex flex-col space-y-2">
                                <span className="text-xs font-bold text-gray">လုပ်ပိုင်ခွင့်များ</span>
                                <div className={`flex flex-col ${(errorCheckable && inputErrors.includes('abilities')) && 'border border-red'}`}>
                                    { allUserAbilities.map( (ability, index) => 
                                        <button
                                            key={index}
                                            onClick={() => changeCheckedAbilities(ability.name)}
                                        >
                                            <div className={`flex flex-row bg-white h-12 items-center border-b border-muted border-l-4 border-l-${ability.color}`}>
                                                <div className={`flex p-1 mx-3 rounded border ${checked_abilities.includes(ability.name) ? 'bg-blue border-blue' : 'border-gray '}`}>
                                                    <FontAwesomeIcon icon={faCheck} color="white" size="xs" />
                                                </div>
                                                <span className="text-xs">{ability.desc}</span>
                                            </div>
                                        </button>
                                    )}
                                </div>
                                { (errorCheckable && inputErrors.includes('abilities')) ? (
                                    <span className="text-red text-2xs">* လုပ်ပိုင်ခွင့် အနည်းဆုံးတစ်ခုရွေးပေးပါ!</span>
                                ) : ''}
                            </div>
                        ) : ''}

                        { isEdit ? (
                            <div className="flex flex-row">
                                <button
                                    className="border border-blue rounded"
                                    onClick={ () => setShowPasswordResetModal(true)}
                                >
                                    <div className="flex flex-row items-center p-2 space-x-2">
                                        <FontAwesomeIcon icon={faKey} color={colors.find( e => e.org === 'blue').normal }/>
                                        <span className="text-blue text-sm">Change Password</span>
                                    </div>
                                </button>
                            </div>
                        ) : ''}

                    </div>

                    <div className="flex">
                        <SubmitButton
                            title={ isEdit ? 'ပြင်မယ်' : 'ထည့်မယ်' }
                            color="green"
                            height="12"
                            radius="rounded-lg"
                            activated={isSubmitting}
                            onClick={submit}
                        />
                    </div>

                </div>
            </ScrollView>

            { isEdit ? (
                <PasswordResetModal
                    show={showPasswordResetModal}
                    id={user.id}
                    onReset={closePasswordResetModal}
                    onClose={closePasswordResetModal}
                />
            ) : ''}
        </StackScreen>
    );
};

export default UserFormScreen;
