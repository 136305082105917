import React, { memo, useCallback, useMemo, useState } from "react";
import axios_custom from "../../axios_config/axios_custom";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../store/reducers/app-reducer';
import useStoreHelper from "../../hooks/useStoreHelper";
import Modal from './Modal';
import { Input } from "../inputs";

const PayDebtModal = ({ show, id, onPaid, onClose }) => {

    const authUser = useSelector( state => state.app.authUser);
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const [amount, setAmount] = useState('');

    const [errorCheckable, setErrorCheckable] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const hasError = useMemo( () => {
        return amount === '';
    }, [amount]);

    //methods
        const close = useCallback( () => {
            setAmount('');
            setErrorCheckable(false);
            onClose();
        }, []);

        const submitPayDebt = () => {
            setErrorCheckable(true);
                
            if(!hasError){
                setIsSubmitting(true);

                axios_custom.post('paid_debts', {
                    id: id,
                    amount: amount,
                    user: authUser.id
                })
                .then( res => {
                    dispatch(setAlert({
                        msg: res.data.msg,
                        status: 'success',
                        timeout: 3000
                    }));
                    onPaid();
                    setAmount('');
                    setErrorCheckable(false);
                    setIsSubmitting(false);
                })
                .catch( error => {
                    setIsSubmitting(false);
                    handleError(error);
                });
            }
        }

    return (
        <Modal
            show={show}
            title="ကြွေးကျန်ငွေပေးရန်"
            titleColor="orange"
            submitButtonText="Pay"
            onClose={close}
            onSubmit={submitPayDebt}
            isSubmitting={isSubmitting}
        >
            <div className="flex flex-col mx-3 my-5">
                <Input
                    title="ပေးလိုသည့်ငွေပမာဏ"
                    type="number"
                    placeholder="eg. 12000"
                    value={amount}
                    onChange={ text => setAmount(text)}
                    onPressEnterKey={submitPayDebt}
                    errors={[
                        {
                            show: errorCheckable && hasError,
                            msg: `ငွေပမာဏ ထည့်ပါ!`
                        }
                    ]}
                />
            </div>
        </Modal>
    );
};

export default memo(PayDebtModal);
