import React, { useState } from "react";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import { SubmitButton } from "../components/inputs";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import { useDispatch, useSelector } from "react-redux";
import useNavigation from "../hooks/useNavigation";
import { setAlert } from "../store/reducers/app-reducer";

const Row = ({label, value}) => {
    return (
        <div className="flex flex-row items-center space-x-3">
            <div className="flex items-center flex-1">
                <span className="text-sm text-soft-black">
                    {label}
                </span>
            </div>
            <span>:</span>
            <div className="flex items-center flex-1">
                <span className="text-sm text-gray">
                    {value}
                </span>
            </div>
        </div>
    )
}

const CategoryDetailsScreen = () => {

    const dispatch = useDispatch();
    const currentRoute = useSelector( state => state.router.currentRoute );
    const category = currentRoute.params.state.category;
    const navigation = useNavigation();
    const { handleError } = useStoreHelper();

    const [isDeleting, setIsDeleting] = useState(false);

    const submitDelete = () => {
        if(window.confirm('\nဖျက်မှာ သေချာပါသလား?')){
            setIsDeleting(true);
            axios_custom.delete('categories/' + category.id)
            .then( res => {
                setIsDeleting(false);
                dispatch(setAlert({
                    msg: res.data.msg,
                    status: 'success',
                    timeout: 3000
                }));
                navigation.navigate('categories', {
                    state: {
                        deletedCategory: category
                    }
                });
            })
            .catch( error => {
                setIsDeleting(false);
                handleError(error);
            });
        }
    }

    return (
        <StackScreen>
            <ScrollView className="flex flex-col flex-1 bg-soft-muted">
                <div className="flex flex-col mt-8 mb-10 mx-7 space-y-10">

                    <div className="flex justify-center">
                        <span className="font-bold text-soft-black text-lg">
                            {category.name}
                        </span>
                    </div>

                    <div className="flex flex-col space-y-5">
                        <Row
                            label="ပစ္စည်းအမျိုးအစား"
                            value={
                                <div className={`flex items-center rounded p-2 bg-${category.group.color}`}>
                                    <span className="text-white text-xs">
                                        { category.group.name }
                                    </span>
                                </div>
                            }
                        />

                        <Row
                            label="လက်ခ"
                            value={category.base_price}
                        />

                        { (category.additional_price_name !== '' && category.additional_price !== 0) ? (
                            <Row
                                label={category.additional_price_name}
                                value={category.additional_price}
                            />
                        ) : ''}

                        <Row
                            label="အရွယ်အစားပါ"
                            value={category.has_size 
                                ? <span className="text-green">Yes</span>
                                : <span className="text-red">No</span>
                            }
                        />

                        <Row
                            label="ကျောက်ချိန်ပါနိုင်"
                            value={category.gem_includable 
                                ? <span className="text-green">Yes</span>
                                : <span className="text-red">No</span>
                            }
                        />

                        <Row
                            label="သီးသန့်ဖြုတ်ရောင်းနိုင်"
                            value={category.has_attachment 
                                ? <span className="text-green">Yes</span>
                                : <span className="text-red">No</span>
                            }
                        />
                    </div>

                    <div className="flex flex-row">
                        { category.deleteable ? (
                            <SubmitButton
                                title="Delete"
                                activated={isDeleting}
                                radius="rounded-l-lg"
                                color="red"
                                height = "12"
                                onClick={submitDelete}
                            />
                        ) : ''}
                        <SubmitButton
                            title="Edit"
                            radius={ category.deleteable ? 'rounded-r-lg' : 'rounded-lg' }
                            color="blue"
                            height = "12"
                            onClick={ () => navigation.navigate('category_form', {
                                state: {
                                    category: category
                                }
                            })}
                        />
                    </div>

                </div>
            </ScrollView>
        </StackScreen>
    );
};

export default CategoryDetailsScreen;
