import axios_custom from "../axios_config/axios_custom";
import { useDispatch, useSelector } from 'react-redux';
import {
    authenticateUser, 
    unauthenticateUser, 
    setGroups, 
    setAlert,
    clearAlert 
} from '../store/reducers/app-reducer';
import Hasher from "../helper/hasher";

const useStoreHelper = () => {    

    const hasher = new Hasher();

    const authenticated = useSelector( state => state.app.authenticated);
    const dispatch = useDispatch();

    const getGroups = () => {
        //for initial check auth and get groups
        axios_custom.get('groups')
        .then( (res) => {

            dispatch(setGroups(res.data.results));

            if(!authenticated){
                const currentAuthUser = hasher.decrypt(localStorage.getItem('user'));
                dispatch(authenticateUser(JSON.parse(currentAuthUser)));
            }
        })
        .catch( (error) => {
            handleError(error);
        })
    }

    const handleError = (error) => {

        if(error.code !== 'ERR_CANCELED'){
            
            //if return without status code
            if(error.response === undefined ){

                dispatch(setAlert({
                    msg: 'Cannot Connect to Server!',
                    status: 'error',
                    timeout: false
                }));
                dispatch(unauthenticateUser());

            } else {

                switch (error.response.status){

                    case 500:
                        dispatch(setAlert({
                            msg: 'Cannot Connect to Server!',
                            status: 'error',
                            timeout: false
                        }));
                        dispatch(unauthenticateUser());
                    break;
                
                    case 401:
                        dispatch(unauthenticateUser());

                        dispatch(setAlert({
                            msg: authenticated ? 'Session Expired!' : error.response.data.msg || null,
                            status: 'error',
                            timeout: false
                        }));
                        
                    break;
                
                    default:
                        dispatch(setAlert({
                            msg: error.response.data.msg,
                            status: 'error',
                            timeout: false
                        }));
                    break;
                }
            }
        }
    }

    const logOut = (setIsLoggingOut) => {
        
        setIsLoggingOut?.(true);

        axios_custom.post('logout')
        .then( () => {
            localStorage.clear();
            setIsLoggingOut?.(false);
            dispatch(unauthenticateUser());
            dispatch(clearAlert());            
        })
        .catch( () => {
            setIsLoggingOut?.(false);
            dispatch(unauthenticateUser());
            dispatch(clearAlert());
        })
    }

    return { 
        getGroups,
        handleError,
        logOut
    };
}

export default useStoreHelper;
            