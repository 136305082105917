import { faPlus, faSignOut, faSpinner, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useState } from "react";
import useNavigation from "../../hooks/useNavigation";
import { useSelector } from "react-redux";
import useStoreHelper from "../../hooks/useStoreHelper";
import routes from "../../router/routes";
import colors from "../../helper/colors";
import { SubmitButton } from "../inputs";

const MobileSideDrawer = ({ show, onClose }) => {

    const currentRoute = useSelector( state => state.router.currentRoute );
    const authUser = useSelector( state => state.app.authUser );
    const navigation = useNavigation();
    const { logOut } = useStoreHelper();
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    return (
        <div 
            className="fixed flex flex-row top-0 bottom-0 w-screen z-30 transition-position duration-300"
            style={{
                right: show ? '0%' : '-100%'
            }}
        >
            {/* overlay area to close drawer */}
            <div 
                className="flex-1"
                onClick={ () => onClose()}
            >
            </div>

            <div className="flex flex-col w-80 bg-soft-black">

                <div 
                    className="flex items-center justify-between h-16 px-3"
                    style={{
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.4,
                    }}
                >
                    <button 
                        className="flex items-center justify-center border border-gray rounded h-9 w-9" 
                        onClick={onClose}
                    >
                        <FontAwesomeIcon icon={faX} color="white"/>
                    </button>

                    <button onClick={ () => navigation.navigate('individual_sold', {
                        state: {
                            user: authUser
                        }
                    })}>
                        <span className="text-white text-sm">{authUser?.name}</span>
                    </button>

                    <SubmitButton
                        title="Log Out"
                        icon={faSignOut}
                        fullSize={false}
                        color="gray"
                        height="8"
                        fontSize="xs"
                        activated={isLoggingOut}
                        onClick={ () => logOut(setIsLoggingOut) }
                    />
                </div>

                { authUser.abilities.includes('add_new_items') ? (
                    <div 
                        className="flex items-center justify-center h-16 px-3"
                        style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 0.4,
                        }}
                    >
                        <button 
                            className="flex flex-row space-x-2 flex-1 items-center justify-center border border-green rounded h-10"
                            onClick={ () => navigation.navigate('form')}
                        >
                            <FontAwesomeIcon 
                                icon={faPlus}
                                color={ colors.find( e => e.org === 'green').normal }    
                            />
                            <span className="text-green text-sm">
                                ပစ္စည်းအသစ်ထည့်ရန်
                            </span>
                        </button>
                    </div>
                ) : ''}

                <div className="flex-1 overflow-auto">
                    <div className="flex flex-col mx-3 mt-3 mb-5 space-y-3">

                        { routes.filter( e => e.showInDrawer ).map( (route) => (
                            (route.requireAbility === null || authUser.abilities.includes(route.requireAbility)) ? (
                                <button 
                                    key={route.id} 
                                    className={`flex flex-row items-center h-14 rounded-lg ${ (route.id === currentRoute.id) && 'bg-gray'}`}
                                    onClick={ () => navigation.navigate(route.name)}
                                >
                                    <div className="flex flex-row items-center space-x-3 ml-5">
                                        <div className="w-10">
                                            <FontAwesomeIcon 
                                                icon={route.icon} 
                                                color={colors.find( e => route.color === e.org).normal} 
                                                size="lg"
                                            />
                                        </div>
                                        <span className="text-white text-sm">{route.title}</span>
                                        {/* fix <div className="flex items-center justify-center bg-red rounded-full h-5">
                                            <span className="text-xs text-white px-1">
                                                123
                                            </span>
                                        </div> */}
                                    </div>
                                </button>
                            ) : ''
                        ))}
                        
                    </div>
                </div>
            </div>
            
        </div>
    )
};

export default memo(MobileSideDrawer);
