import { useDispatch, useSelector } from "react-redux";
import useNavigation from "../hooks/useNavigation";
import useStoreHelper from "../hooks/useStoreHelper";
import axios_custom from "../axios_config/axios_custom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { setAlert } from "../store/reducers/app-reducer";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Input, LabeledSwitch, PopUpPicker, SubmitButton, Switch, TagInput } from "../components/inputs";
import NoticeBanner from "../components/NoticeBanner";

const CategoryFormScreen = () => {

    const groups = useSelector( state => state.app.groups);
    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();
    const navigation = useNavigation();

    const currentRoute = useSelector( state => state.router.currentRoute );
    const routeState = currentRoute.params?.state;
    const isEdit = Boolean(routeState);
    const category = isEdit ? routeState.category : null;

    const [categories, setCategories] = useState([]);

    //inputs
    const [name, setName] = useState(isEdit ? category.name : '');
    const [basePrice, setBasePrice] = useState(isEdit ? (category.base_price ? category.base_price.toString() : '') : '');
    const [additionalPriceName, setAdditionalPriceName] = useState(isEdit ? (category.additional_price_name || '') : '');
    const [additionalPrice, setAdditionalPrice] = useState(isEdit ? (category.additional_price ? category.additional_price.toString() : '') : '');
    const [group, setGroup] = useState(isEdit ? category.group_id : null);
    const [hasSize, setHasSize] = useState(isEdit ? category.has_size : false);
    const [gemIncludable, setGemIncludable] = useState(isEdit ? category.gem_includable : true);
    const [hasAttachment, setHasAttachment] = useState(isEdit ? category.has_attachment : false);

    //exclusive for hsu
    const suffix = isEdit ? category.suffix : '';
    const traderWeightCalculation = isEdit ? category.trader_weight_calculation : 'weight';

    const [errorCheckable, setErrorCheckable] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    //mounted
    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;

        axios_custom.get('categories', { signal })
        .then( (res) => {
            setCategories(res.data.results);
        })
        .catch( (error) => {
            handleError(error);
        });

        return () => {
            controller.abort();
        }
    }, []);

    //computed
    const existedCategoryNames = useMemo( () => {
        return categories.filter( e => e.group_id === group ).map( e => e.name);
    }, [categories]);

    const inputErrors = useMemo( () => {
        const errors = [];

        name === '' && errors.push('name');
        group === null && errors.push('group');

        if(isEdit){
            if(name !== category.name && existedCategoryNames.includes(name)){
                errors.push('duplicate_name');
            }
        } else {
            if(existedCategoryNames.includes(name)){
                errors.push('duplicate_name');
            }
        }

        if(additionalPriceName !== '' && additionalPrice === ''){
            errors.push('additional_price');
        }

        if(additionalPrice !== '' && additionalPriceName === ''){
            errors.push('additional_price_name');
        }

        return errors;
    }, [name, group, additionalPriceName, additionalPrice, existedCategoryNames]);

    const availableGroups = useMemo( () => {
        const filteredGroups = groups.filter( e => e.id !== null).map( e => {
            return {
                id: e.id,
                name: e.name
            }
        });
        return filteredGroups
    }, [groups]);

    //methods
        const changeGemIncludable = useCallback((value) => {
            setGemIncludable(value);
            setHasAttachment(!value);
        }, []);

        const changeAttachment = useCallback((value) => {
            setGemIncludable(!value);
            setHasAttachment(value);
        }, []);

        const submit = () => {
            setErrorCheckable(true);

            if(inputErrors.length === 0){
                setIsSubmitting(true);
                if(isEdit){
                    axios_custom.put('/categories/' + category.id, {
                        name: name,
                        suffix: suffix,
                        base_price: basePrice,
                        additional_price_name: additionalPriceName,
                        additional_price: additionalPrice,
                    } )
                    .then( res => {
                        setIsSubmitting(false);

                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));

                        navigation.navigate('category_details', {
                            state: {
                                category: res.data.result
                            }
                        });
                    })
                    .catch( error => {
                        setIsSubmitting(false);
                        handleError(error)
                    });

                } else {

                    axios_custom.post('/categories', { 
                        name: name,
                        suffix: suffix,
                        group_id: group,
                        has_size: hasSize,
                        gem_includable: gemIncludable,
                        has_attachment: hasAttachment,
                        base_price: basePrice,
                        additional_price_name: additionalPriceName,
                        additional_price: additionalPrice,
                        trader_weight_calculation: traderWeightCalculation  
                    })
                    .then( res => {
                        setIsSubmitting(false);

                        dispatch(setAlert({
                            msg: res.data.msg,
                            status: 'success',
                            timeout: 3000
                        }));

                        navigation.navigate('categories', {
                            state: {
                                newCategoryId: res.data.result.id,
                                groupId: res.data.result.group_id,
                            }
                        });
                    })
                    .catch( error => {
                        setIsSubmitting(false);
                        handleError(error);
                    });
                }
            }
        }

    return (
        <StackScreen>
            <ScrollView className="flex flex-col flex-1">
                <div className="flex flex-col space-y-7 mb-16">

                    <NoticeBanner
                        title="သတိပြုရန်"
                        icon={faCircleExclamation}
                        color="yellow"                                            
                        msges={[
                            'အမျိုးအစားခွဲအသစ်လုပ်ပြီးနောက် ပစ္စည်းထည့်ပြီးပါက ပြန်ဖျက်နိုင်မည်မဟုတ်တော့ပါ။',
                            'ဤအမျိုးအစားခွဲ၏ (အမည်, လက်ခ, အခြားတန်ဆာခ) တို့မှလွဲ၍ ပြင်နိုင် (Edit လုပ်နိုင်) မည်မဟုတ်တော့ပါ။'
                        ]}
                    />

                    <div className="flex flex-col mx-5 space-y-8">

                        <div className="flex flex-col space-y-5">

                            { !isEdit ? (
                                <div className="flex flex-col space-y-2">
                                    <span className="text-xs text-gray font-bold">Main ပစ္စည်းအမျိုးအစား</span>
                                    <PopUpPicker
                                        triggerClassName={`h-11 flex items-center pl-5 bg-white rounded border border-${ errorCheckable && inputErrors.includes('color') ? 'red' : 'dim-gray'}`}
                                        disabled={false}
                                        nullable={true}
                                        value={group}
                                        theme="light"
                                        textColor="black"
                                        enableIcon={true}
                                        data={availableGroups}
                                        onPick={setGroup}
                                    />
                                    { errorCheckable && inputErrors.includes('group') ? (
                                        <span className="text-2xs text-red">
                                            * ပစ္စည်းအမျိုးအစားရွေးပါ
                                        </span>
                                    ) : ''}
                                </div>
                            ) : ''}

                            <Input
                                title="အမျိုးအစားခွဲအမည်"
                                type="span"
                                placeholder="eg. မောင်းကွင်း (စက်ဖြတ်)၊ ရွှေဥစီလက်ကောက်၊ စသည်..."
                                value={name}
                                onChange={ span => setName(span)}
                                errors={[
                                    {
                                        show: errorCheckable && inputErrors.includes('name'),
                                        msg: 'အမည်ထည့်ပါ!'
                                    },
                                    {
                                        show: errorCheckable && inputErrors.includes('duplicate_name'),
                                        msg: 'ဤအမည်ဖြင့်အမျိုးအစားခွဲတစ်ခု ရှိပြီးသားဖြစ်သည်။ အခြားအမည်ရွေးပါ!'
                                    }
                                ]}
                            />

                            <Input
                                title="ဝန်ဆောင်ခ (သို့) လက်ခ"
                                type="number"
                                value={basePrice}
                                onChange={ span => setBasePrice(span)}
                                warningMsges={[
                                    'ဤအမျိုးအစားခွဲတွင်ထည့်မည့်ပစ္စည်းများအတွက် ရွှေဖိုးကျသင့်ငွေအပြင် အခြား service ခ ရှိပါကထည့်နိုင်ပါသည်။',
                                    'ဤအမျိုးအစားခွဲမှပစ္စည်းများ၏တန်ဖိုး နှင့် ဘောင်ချာတွင် auto ထည့်ပေါင်းသွားမည်ဖြစ်သည်။',
                                ]}
                            />

                            <div className="flex flex-col space-y-2">
                                <span className="text-gray text-sm font-bold">
                                    အခြားတန်ဆာခ
                                </span>
                                <div className="flex flex-row space-x-2">
                                    <Input
                                        title="အမည်"
                                        type="span"
                                        placeholder="eg. နောက်ခံကလစ်ဖိုး"
                                        value={additionalPriceName}
                                        onChange={ span => setAdditionalPriceName(span)}
                                        errors={[
                                            {
                                                show: errorCheckable && inputErrors.includes('additional_price_name'),
                                                msg: 'အမည်ထည့်ပါ'
                                            }
                                        ]}
                                    />
                                    <TagInput
                                        title="တန်ဖိုး"
                                        tagLabel="ကျပ်"
                                        type="number"
                                        value={additionalPrice}
                                        onChange={ span => setAdditionalPrice(span)}
                                        errors={[
                                            {
                                                show: errorCheckable && inputErrors.includes('additional_price'),
                                                msg: 'တန်ဆာခထည့်ပါ'
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="flex flex-row text-2xs text-gray space-x-1">
                                    <span>*</span>
                                    <span>ဤအမျိုးအစားခွဲတွင်ထည့်မည့်ပစ္စည်းများအတွက် ရွှေဖိုးအပြင် အခြားထပ်ပေါင်းတန်ဖိုး ရှိပါကထည့်နိုင်ပါသည်။</span>
                                </div>
                                <div className="flex flex-row text-2xs text-gray space-x-1">
                                    <span>*</span>
                                    <span>ဤအမျိုးအစားခွဲမှပစ္စည်းများ၏တန်ဖိုး နှင့် ဘောင်ချာတွင် auto ထည့်ပေါင်းသွားမည်ဖြစ်သည်။</span>
                                </div>
                            </div>

                            { !isEdit ? ( 
                                <div className="flex flex-col space-y-5">
                                    <div className="flex flex-col border rounded-lg">
                                        <div className="mx-3 my-4 items-center space-y-7">

                                            <span className="flex justify-center text-sm">
                                                ဤအမျိုးအစားခွဲတွင်
                                            </span>

                                            <div className="flex flex-col space-y-5">
                                                <div className="flex flex-row justify-between items-center space-x-5">
                                                    <div className="flex flex-col flex-1 space-y-2">
                                                        <span className="text-xs">အရွယ်အစားပါဝင်ပါသလား?</span>
                                                        <span className="text-gray text-2xs">* ဥပမာ. ကွင်းတိုင်း / အရှည်</span>
                                                    </div>
                                                    <Switch
                                                        value={hasSize}
                                                        onChange={setHasSize}
                                                    />
                                                </div>
                                                <div className="flex flex-row justify-between items-center space-x-5 bg">
                                                    <div className="flex flex-col flex-1 space-y-2">
                                                        <span className="text-xs">သီးသန့်ဖြုတ်ရောင်းနိုင်ပါသလား?</span>
                                                        <span className="text-gray text-2xs">* ဥပမာ. နားဆွဲတွင် အဆွဲသီးသန့် ဖြုတ်ရောင်းနိုင်သကဲ့သို့</span>
                                                    </div>
                                                    <Switch
                                                        value={hasAttachment}
                                                        onChange={changeAttachment}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-row justify-center text-2xs text-dark-yellow space-x-1">
                                                <span>မှတ်ချက်။ </span>
                                                <div className="flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-1">
                                                    <span className="flex">
                                                        သီးသန့်ဖြုတ်ရောင်းနိုင်ပါက ကျောက်ချိန်ထည့်လို့မရပါ။
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            ) : ''}

                        </div>

                        <div className="flex">
                            <SubmitButton
                                title={ isEdit ? 'ပြင်မယ်' : 'ထည့်မယ်' }
                                color="green"
                                height="12"
                                radius="rounded-lg"
                                activated={isSubmitting}
                                onClick={submit}
                            />
                        </div>
                    </div>

                </div>
            </ScrollView>
        </StackScreen>
    );
};

export default CategoryFormScreen;
