import React, { useCallback, useEffect, useState } from "react";
import { faCrown, faKey, faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios_custom from "../axios_config/axios_custom";
import useStoreHelper from "../hooks/useStoreHelper";
import { SubmitButton } from "../components/inputs";
import StackScreen from "../components/StackScreen";
import ScrollView from "../components/ScrollView";
import { useDispatch, useSelector } from "react-redux";
import useNavigation from "../hooks/useNavigation";
import { setAlert } from "../store/reducers/app-reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../helper/colors";
import { PasswordResetModal } from "../components/modals";

const UserDetailsScreen = () => {

    const authUser = useSelector( state => state.app.authUser);
    const navigation = useNavigation();
    const { handleError, logOut } = useStoreHelper();
    const dispatch = useDispatch();

    const currentRoute = useSelector( state => state.router.currentRoute);
    const user = currentRoute.params.state.user;
    const isSelfEdit = authUser.id === user.id;

    const [allUserAbilities, setAllUserAbilitites] = useState([]);
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isRevoking, setIsRevoking] = useState(false);
    const [isMakingAdmin, setIsMakingAdmin] = useState(false);

    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;

        axios_custom.get('all_user_abilities', {signal})
        .then( res => {
            setAllUserAbilitites(res.data.results);
        })
        .catch( error => {
            handleError(error);
        });

        return () => controller.abort();
    }, []);

    //methods
    const submitDelete = () => {
        if(window.confirm('\nဖျက်မှာ သေချာပါသလား?')){
            setIsDeleting(true);

            axios_custom.delete('users/' + user.id)
            .then( res => {
                setIsDeleting(false);

                dispatch(setAlert({
                    msg: res.data.msg,
                    status: 'success',
                    timeout: 3000
                }));

                navigation.navigate('users', {
                    state: {
                        deletedUser: user
                    }
                });
            })
            .catch( error => {
                handleError(error);
            })          
        };
    }

    const makeAdmin = useCallback( () => {

        if(window.confirm(
            `\nဤဝန်ထမ်းအား Admin လွှဲပြောင်းမှာ သေချာပါသလား?
            \nAdmin ဖြစ်သွားလျှင် လုပ်ပိုင်ခွင့်အားလုံးရရှိသွားမည်ဖြစ်သည်။
            \nယခုသင့် account သည် ဝန်ထမ်းအကောင့် အဖြစ်ပြောင်းသွားပါမည်။`)
        ){
            setIsMakingAdmin(true);
            axios_custom.put(`users/${user.id}/make_admin`)
            .then( res => {
                                                
                dispatch(setAlert({
                    msg: res.data.msg + ' ကျေးဇူးပြု၍ Login ပြန်ဝင်ပေးပါ။',
                    status: 'success',
                    timeout: 3000
                }));
                
                logOut(setIsMakingAdmin);
            })
            .catch( error => {
                setIsMakingAdmin(false);
                handleError(error);
            });
        }
    }, []);

    const toggleRevoke = useCallback( (revoke) => {

        const msg = revoke 
        ? '\nဤအကောင့်အား ပိတ်သိမ်းမှာသေချာပါသလား?'
        : '\nဤအကောင့်အား ပြန်ဖွင့်ပေးမှာသေချာပါသလား?';

        if(window.confirm(msg)){
            setIsRevoking(true);
            axios_custom.put(`users/${user.id}/toggle_revoke`, {
                revoke: revoke
            })
            .then( res => {
                setIsRevoking(false);

                dispatch(setAlert({
                    msg: res.data.msg,
                    status: 'success',
                    timeout: 3000
                }));

                navigation.navigate('users', {
                    state: {
                        revocationChangedUser: {
                            ...user,
                            is_revoked: revoke
                        }
                    }
                });
            })
            .catch( error => {
                setIsRevoking(false);
                handleError(error);
            });
        }
    }, []);

    return (
        <StackScreen>
            <ScrollView className="flex flex-col flex-1 bg-soft-muted">

                <div className="flex flex-col mt-6 mb-10 mx-5 space-y-8">

                    {/* header */}
                    { isSelfEdit ? (
                        <div className="flex justify-center">
                            <span className="text-lg font-bold">
                                Your Account
                            </span>
                        </div>
                    ) : ''}

                    { user.is_revoked ? (
                        <div className="flex justify-center">
                            <span className="text-gray font-bold">
                                ဤအကောင့်အား ပိတ်သိမ်းထားပါသည်။
                            </span>
                        </div>
                    ) : ''}

                    <div className="flex flex-col space-y-3">
                        { user.is_admin ? (
                            <div className="flex flex-row items-center space-x-3">
                                <FontAwesomeIcon icon={faCrown} color={colors.find( e => e.org === 'yellow').normal }/>
                                <span className="font-bold">Admin</span>
                            </div>
                        ) : ''}
                    
                        <div className="flex flex-row items-center space-x-4">
                            <div className="w-1/4">
                                <span className="text-sm">
                                    အမည်
                                </span>
                            </div>
                            <span>:</span>
                            <span className="text-sm text-gray">
                                {user.name}
                            </span> 
                        </div>

                        <div className="flex flex-row items-center space-x-4">
                            <div className="w-1/4">
                                <span className="text-sm">
                                    Nickname
                                </span>
                            </div>
                            <span>:</span>
                            <span className="text-sm text-gray">
                                {user.nickname}
                            </span> 
                        </div>

                        <div className="flex flex-row items-center space-x-4">
                            <div className="w-1/4">
                                <span className="text-sm">
                                    Username
                                </span>
                            </div>
                            <span>:</span>
                            <span className="text-sm text-gray">
                                {user.username}
                            </span> 
                        </div>
                    </div>

                    <div className="flex flex-col space-y-3">
                        <span className="text-sm">လုပ်ပိုင်ခွင့်များ</span>
                        <div className="flex flex-col">
                            { user.abilities.map( (ability, index) => (
                                <div key={index} className="flex flex-row space-x-2 items-center">
                                    <span className="text-gray text-2xl">•</span>
                                    <span className="text-xs text-gray pt-1">
                                        { allUserAbilities.find( e => e.name === ability )?.desc }
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-row space-x-3">
                        { (authUser.is_admin && !user.is_admin) ? (
                            <button
                                className={`rounded border border-yellow bg-${ isMakingAdmin ? 'soft-yellow' : 'yellow'}`}
                                onClick={makeAdmin}
                            >
                                { isMakingAdmin ? (
                                    <div className="flex flex-row items-center rounded p-2 px-3 space-x-2">
                                        <FontAwesomeIcon className="animate-spin-left" icon={faSpinner} color={colors.find( e => e.org === 'black').normal }/>
                                        <span className="text-black text-sm">Please Wait...</span>
                                    </div>
                                ) : (
                                    <div className="flex flex-row items-center rounded p-2 px-3 space-x-2">
                                        <FontAwesomeIcon icon={faCrown} color={colors.find( e => e.org === 'black').normal }/>
                                        <span className="text-black text-sm">Make Admin</span>
                                    </div>
                                )}
                            </button>
                        ) : ''}

                        <button
                            className="border border-blue rounded"
                            onClick={ () => setShowPasswordResetModal(true)}
                        >
                            <div className="flex flex-row items-center p-2 space-x-2">
                                <FontAwesomeIcon icon={faKey} color={colors.find( e => e.org === 'blue').normal }/>
                                <span className="text-blue text-sm">Change Password</span>
                            </div>
                        </button>
                    </div>

                    <div className="flex flex-row">
                        <SubmitButton
                            title="Edit"
                            radius={ isSelfEdit ? 'rounded-lg' : 'rounded-l-lg'}
                            color="blue"
                            height = "12"
                            onClick={() => navigation.navigate('user_form', {
                                state: {
                                    user: user
                                }
                            })}
                        />
                        { (!user.is_admin && user.deleteable) ? (
                            <SubmitButton
                                title="Delete"
                                radius=""
                                color="red"
                                height = "12"
                                activated={isDeleting}
                                onClick={submitDelete}
                            />
                        ) : ''}
                        { !isSelfEdit ? (
                            <SubmitButton
                                title={ user.is_revoked ? 'Restore' : 'Ban'}
                                activated={isRevoking}
                                radius="rounded-r-lg"
                                color={ user.is_revoked ? 'green' : 'soft-gray'}
                                height = "12"
                                onClick={ () => toggleRevoke(!user.is_revoked)}
                            />
                        ) : ''}
                    </div>
                </div>
            </ScrollView>

            <PasswordResetModal
                show={showPasswordResetModal}
                id={user.id}
                onClose={() => setShowPasswordResetModal(false)}
                onReset={() => setShowPasswordResetModal(false)}
            />
        </StackScreen>
    );
};

export default UserDetailsScreen;
