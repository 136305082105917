import routes from "../router/routes";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentRoute, setRouteHistory } from "../store/reducers/router-reducer";

const useNavigation = () => {

    const dispatch = useDispatch();
    const currentRoute = useSelector( state => state.router.currentRoute );
    const routeHistory = useSelector( state => state.router.routeHistory );

    const navigate = (routeName, params = {} ) => {
        //write history
        if (routeName === 'login') {
            dispatch(setRouteHistory([]));
        } else {
            const targetRouteIndex = routeHistory.findIndex(e => e.name === routeName);
            const alreadyOpened = targetRouteIndex > -1;
        
            if (alreadyOpened) {
                const targetRouteIsLastRoute = targetRouteIndex + 1 === routeHistory.length;
                if (!targetRouteIsLastRoute) {
                    dispatch(setRouteHistory([...routeHistory, { name: routeName, params }]));
                }
            } else {
                dispatch(setRouteHistory([...routeHistory, { name: routeName, params }]));
            }
        }        

        //navigate
        const targetRoute = routes.find( e => e.name === routeName );
        dispatch(setCurrentRoute({...targetRoute, params}));
    }

    const goBack = () => {
        const prevRouteIndex = routeHistory.length - 2;
        const prevRoute = routeHistory[prevRouteIndex];
        const prevRouteProps = routes.find( e => e.name === prevRoute.name );

        const newRouteHistory = routeHistory.slice(0, prevRouteIndex + 1);
        dispatch(setRouteHistory(newRouteHistory));
        dispatch(setCurrentRoute({...prevRouteProps, params: prevRoute.params}));
    }

    const setCurrentRouteTitle = (title) => {
        dispatch(setCurrentRoute({...currentRoute, title: title}));
    }

    return { navigate, setCurrentRouteTitle, goBack };
};

export default useNavigation;
