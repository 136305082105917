import React, { memo, useCallback, useMemo, useState } from "react";
import axios_custom from "../../axios_config/axios_custom";
import { useSelector } from 'react-redux';
import useStoreHelper from "../../hooks/useStoreHelper";
import { ImagePicker, ImagePreviewer, Input, TagInput } from "../inputs";
import Modal from "./Modal";

const NewDebtModal = ({ show, onAdded, onClose }) => {

    const authUser = useSelector( state => state.app.authUser);
    const { handleError } = useStoreHelper();
    
    const [isSubmitting, setIsSubmitting] = useState (false);
    const [isImagePicking, setIsImagePicking] = useState (false);
    const [errorCheckable, setErrorCheckable] = useState (false);

    const defaultInputValues = {
        customer_name: '',
        customer_address: '',
        customer_phone: '',
        amount: '',
        name: '',
        description: ''
    }

    const [inputs, setInputs] = useState(defaultInputValues);
    const [image, setImage] = useState(null);

    //computed
        const inputErrors = useMemo( () => {
            const errors = [];

            inputs.customer_name === '' && errors.push('customer_name');
            inputs.name === '' && errors.push('name');
            inputs.amount === '' && errors.push('amount');

            return errors;
        }, [inputs.customer_name, inputs.name, inputs.amount]);

    //methods
        const close = useCallback( () => {
            setImage(null)
            setInputs(defaultInputValues);
            setErrorCheckable(false);
            onClose();
        }, []);

        const submitNewDebt = () => {
            setErrorCheckable(true); 

            if(inputErrors.length === 0 && image !== null) {

                setIsSubmitting(true);

                const formData = new FormData();
                
                formData.append('customer_name', inputs.customer_name);
                formData.append('customer_address', inputs.customer_address);
                formData.append('customer_phone', inputs.customer_phone);
                formData.append('amount', inputs.amount);
                formData.append('name', inputs.name);
                formData.append('description', inputs.description);
                formData.append('user', authUser.id);
                formData.append('image', image.file);

                axios_custom.post('debts', formData)
                .then( res => {
                    onAdded(res.data.new_debt_id);
                    setImage(null);
                    setInputs(defaultInputValues);
                    setErrorCheckable(false);
                    setIsSubmitting(false);
                })
                .catch( error => {
                    handleError(error);
                });
            }
        };

    return (
        <Modal
            show={show}
            title="အကြွေးစာရင်းအသစ်ထည့်ရန်"
            titleColor="orange"
            submitButtonText="Add"
            onClose={close}
            onSubmit={submitNewDebt}
            disableSubmit={isImagePicking}
            isSubmitting={isSubmitting}
        >
            <div className="flex flex-col space-y-4 mx-3 mt-4 mb-8">
                <span className="font-bold">Customer</span>

                <Input
                    title="ဝယ်သူအမည်"
                    type="text"
                    placeholder="eg. ဦးမောင်မောင်"
                    value={inputs.customer_name}
                    onChange={ text => setInputs({ ...inputs, customer_name: text })}
                    onPressEnterKey={submitNewDebt}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('customer_name'),
                            msg: 'ဝယ်သူအမည်ထည့်ပါ!'
                        }
                    ]}
                />

                <Input
                    title="ဝယ်သူလိပ်စာ"
                    type="text"
                    placeholder="eg. ရန်ကုန်"
                    value={inputs.customer_address}
                    warningMsges={['မထည့်ပဲထားခဲ့လဲရသည်။']}
                    onChange={ text => setInputs({ ...inputs, customer_address: text })}
                    onPressEnterKey={submitNewDebt}
                />

                <Input
                    title="ဝယ်သူ Phone"
                    type="number"
                    placeholder="eg. 09123456789"
                    value={inputs.customer_phone}
                    warningMsges={['မထည့်ပဲထားခဲ့လဲရသည်။']}
                    onChange={ text => setInputs({ ...inputs, customer_phone: text })}
                    onPressEnterKey={submitNewDebt}
                />

                {/* divider */}
                <div className="border-b border-b-dim-gray"></div>

                <span className="font-bold pb-2">ပစ္စည်း</span>

                <TagInput
                    title="ကြွေးကျန်ငွေ"
                    type="number"
                    placeholder="eg. 99999"
                    tagLabel="ကျပ်"
                    value={inputs.amount}
                    onChange={ text => setInputs({ ...inputs, amount: text })}
                    onPressEnterKey={submitNewDebt}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('amount'),
                            msg: 'ကြွေးကျန်ငွေပမာဏထည့်ပါ!'
                        }
                    ]}
                />

                <Input
                    title="ပစ္စည်းအမည်"
                    type="text"
                    placeholder="eg. ကျောက်လက်စွပ်"
                    value={inputs.name}
                    onChange={ text => setInputs({ ...inputs, name: text })}
                    onPressEnterKey={submitNewDebt}
                    errors={[
                        {
                            show: errorCheckable && inputErrors.includes('name'),
                            msg: 'ကြွေးကျန်ပစ္စည်းအမည်ထည့်ပါ!'
                        }
                    ]}
                />

                <ImagePicker
                    title="ဘောင်ချာပုံ"
                    isPicking={setIsImagePicking}
                    onPick={setImage}
                    errors={[
                        {
                            show: errorCheckable && image === null,
                            msg: 'ဘောင်ချာပုံထည့်ပေးရန်လိုအပ်ပါသည်'
                        }
                    ]}
                />

                {/* image_preview */}
                <ImagePreviewer
                    id={image?.id}
                    src={image?.src}
                    onRemove={ () => setImage(null)}
                />

                <Input
                    title="မှတ်ချက်"
                    type="textarea"
                    placeholder="Write something..."
                    value={inputs.description}
                    warningMsges={['မထည့်ပဲထားခဲ့လဲရသည်။']}
                    onChange={ text => setInputs({ ...inputs, description: text })}
                    onPressEnterKey={submitNewDebt}
                />
            </div>
        </Modal>        
    );
};

export default memo(NewDebtModal);
