import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import colors from "../../helper/colors";

const Button = ({
    title,
    color = "blue",
    activated = false,
    activatedText = "",
    onClick,
    disabled = false,
}) => {
    
    const btnColor = disabled ? `text-soft-${color}` : `text-${color}`;

    return ( 
        activated ? (
            <div className="flex flex-row space-x-1 items-center justify-center">
                <FontAwesomeIcon
                    className="animate-spin-left"
                    icon={faRotateLeft}
                    color={colors.find((e) => e.org === color).soft}
                    direction="left"
                />
                <span className={`font-bold text-sm text-soft-${color}`}>
                    {activatedText}
                </span>
            </div>
        ) : (
            <button
                onClick={onClick}
                disabled={disabled}
            >
                <span className={`font-bold text-sm ${btnColor}`}>
                    {title}
                </span>
            </button>
        )
    )
};

export default memo(Button);
