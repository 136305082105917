import React, { memo } from "react";
import AnimatedCard from "./AnimatedCard";

const sampleData = [
    {
        label: 'test',
        value: 'value'
    },
    'divider',
    {
        label: 'test',
        value: 'value'
    },
];

const ReportCard = ({
    title = '',
    cardColor = 'white',
    rowLabelWidth = '2/5',
    rowData = sampleData,
    border,
    msges = [],
    rowJustify = 'center',
    rounded = 'xl'
}) => {
    return (
        <AnimatedCard show={true}>
            <div className={`flex my-2 mx-3 shadow-custom 
                bg-dim-${cardColor} 
                ${ border ? 'border border-' + border : '' }
                ${ rounded === 'md' ? 'rounded' : `rounded-${rounded}` }
            `}>
                <div className="flex flex-col w-full mx-3 mt-3 mb-5 space-y-5">

                    { title }

                    <div className="flex flex-col space-y-4 mx-3">
                        {rowData.map( (e, index) => 
                            e === 'divider' ? (
                                <div key={index} className="border-b border-b-dim-gray"></div>
                            ) : (
                                <div key={index} className="flex flex-row items-center justify-center space-x-2">
                                    <div 
                                        className={`flex 
                                            ${ rowJustify === 'center' ? 'justify-end text-end' : '' } 
                                            w-${rowLabelWidth}
                                        `}
                                    >
                                        { e.label }
                                    </div>
                                    
                                    <div 
                                        className={`flex flex-1 
                                            ${ rowJustify === 'center' ? '' : 'justify-end text-end' } 
                                        `}
                                    >
                                        { e.value }
                                    </div>
                                </div>
                            )
                        )}
                    </div>

                    {/* msges */}
                    { msges.length !== 0 ? (
                        <div className="flex flex-col space-y-2">  
                            {msges.map( (e, index) => 
                                <div key={index} className="flex flex-row text-2xs space-x-2 text-gray">
                                    <span>*</span>
                                    <span>{e}</span>
                                </div>
                            )}
                        </div>
                    ) : ''}
                </div>
            </div>
        </AnimatedCard>
    );
};

export default memo(ReportCard);
