import React from 'react';

const WeightStats = ({ total }) => {

    const kyat = Number(total.kyat);
    const pel = Number(total.pel);
    const yway = Number(total.yway);
    const chan = Number(total.chan);
    const sate = Number(total.sate);

    return (
        <>
            { kyat + pel + yway + chan + sate === 0 ? (
                <span>0</span>
            ) : (
                <>
                    { ( kyat !== 0 ) && ( <span className="mr-1">{kyat} ကျပ်</span>) }
                    { ( pel !== 0 ) && ( <span className="mr-1">{pel} ပဲ</span>) }
                    { ( yway !== 0 ) && ( <span className="mr-1">{yway} ရွေး</span>) }
                    { ( chan !== 0 )&& ( <span className="mr-1">{chan} ခြမ်း</span>) }
                    { ( sate !== 0 ) && ( <span className="">{sate} စိတ်</span>) }
                </>
            )}
        </>
    )
}

export default WeightStats;