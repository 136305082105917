import React, { memo, useMemo } from 'react'
import { BaseDatePicker } from '../../bases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import DateParser from '../../../helper/dateParser';

const DatePicker = ({
    title = '',
    value,
    onChange,
    highlightedDates = []
}) => {

    const dateParser = new DateParser();

    const isDesktop = useSelector( state => state.app.isDesktop);

    const changeDate = (action) => {
        const currentDate = value || new Date();
        if(action === 'up'){
            currentDate.setDate(currentDate.getDate() + 1);
        } else {
            currentDate.setDate(currentDate.getDate() - 1);
        }
        onChange(new Date(currentDate));
    }

    const maxDateReached = useMemo( () => {
        return value === '' || new Date(value).toDateString() === new Date().toDateString();
    }, [value])

    return (
        <div className="flex flex-col space-y-2">
            <span className="text-white text-xs">{ title }</span>

            {isDesktop ? (
                <div className="flex flex-col space-y-1">
                    <div className="flex flex-row h-11">
                        <button
                            className="px-6 bg-white items-center justify-center rounded-l"
                            onClick={ () => changeDate('down')}
                        >
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </button>
                        <BaseDatePicker
                            value={value}
                            onPick={onChange}
                            width={270}
                            offsetX={50}
                            highlightedDates={highlightedDates}
                            trigger={
                                <button className="flex-1 flex justify-center items-center border-x border-x-dim-gray bg-white">
                                    <span className="text-xs">
                                        { value === '' ? 'Select Date' : dateParser.toString(value)}
                                    </span>
                                </button>
                            }
                        />
                        <button
                            className={`px-6 items-center justify-center rounded-r ${maxDateReached ? 'bg-muted' : 'bg-white' }`}
                            disabled={maxDateReached}
                            onClick={ () => changeDate('up')}
                        >
                            <FontAwesomeIcon icon={faChevronRight} color={maxDateReached ? 'gray' : 'black'}/>
                        </button>
                    </div>
                    <div className="flex">
                        <button
                            className="bg-purple items-center justify-center rounded h-8 px-3"
                            onClick={() => onChange(new Date())}
                        >
                            <div className="flex flex-row items-center justify-center space-x-1">
                                <FontAwesomeIcon
                                    icon={faCalendarCheck}
                                    color="white"
                                    size="sm"
                                />
                                <span className="text-white text-sm">Today</span>
                            </div>
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex flex-row h-11">
                    <button
                        className="px-6 bg-white items-center justify-center rounded-l"
                        onClick={ () => changeDate('down')}
                    >
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                    <BaseDatePicker
                        value={value}
                        onPick={onChange}
                        width={270}
                        offsetX={50}
                        highlightedDates={highlightedDates}
                        trigger={
                            <button className="flex-1 flex justify-center items-center border-x border-x-dim-gray bg-white">
                                <span className="text-xs">
                                    { value === '' ? 'Select Date' : dateParser.toString(value)}
                                </span>
                            </button>
                        }
                    />
                    <button
                        className={`px-6 items-center justify-center ${maxDateReached ? 'bg-muted' : 'bg-white' }`}
                        disabled={maxDateReached}
                        onClick={ () => changeDate('up')}
                    >
                        <FontAwesomeIcon icon={faChevronRight} color={maxDateReached ? 'gray' : 'black'}/>
                    </button>
                    <button
                        className="px-3 bg-purple items-center justify-center rounded-r"
                        onClick={() => onChange(new Date())}
                    >
                        <div className="flex flex-row items-center justify-center space-x-1">
                            <FontAwesomeIcon icon={faCalendarCheck} color="white" size="sm"/>
                            <span className="text-white text-xs">Today</span>
                        </div>
                    </button>

                </div>
            )}
        </div>
    )
}

export default memo(DatePicker);