import React, { useMemo, useState, useCallback } from "react";
import StackScreen from "../components/StackScreen";
import Weight from "../helper/weight";
import { CounterInput, Input, SubmitButton, Switch, TagInput, WeightInput } from "../components/inputs";
import Collapsible from "../components/Collapsible";
import ScrollView from "../components/ScrollView";
import { CalculationResultModal } from "../components/modals";

const CalculatorScreen = () => {
	
    const weight = new Weight();

    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState({
        goldPrice: 0,
        netWeight: weight.defaultValue,
        serviceWeight: weight.defaultValue,
        hasGem: false,
        gemWeight: weight.defaultValue,
        gemPrice: 0,
        otherPrice: 0,
        taxPrice: 0,
        finalPrice: 0
    });

    const [goldPrice, setGoldPrice] = useState('');
    const [hasGem, setHasGem] = useState(false);
    const [gemPrice, setGemPrice] = useState('');
    const [taxPercent, setTaxPercent] = useState('0');
    const [otherPrice, setOtherPrice] = useState('2000');

    const [orgWeight, setOrgWeight] = useState(weight.defaultValue);
    const [serviceWeight, setServiceWeight] = useState(weight.defaultValue);
    const [gemWeight, setGemWeight] = useState(weight.defaultValue);

    const weightTypes = [
        {
            type: 'org',
            set: setOrgWeight
        },
        {
            type: 'service',
            set: setServiceWeight
        },
        {
            type: 'gem',
            set: setGemWeight
        },
    ];

    //error handlers
        const [errorCheckable, setErrorCheckable] = useState(false);
        const [weightsWithError, setWeightsWithError] = useState([]);

        const inputErrors = useMemo(() => {
            const errors = [];
            if (goldPrice === "") {
                errors.push("gold_price");
            }
            return errors;
        }, [goldPrice]);

	//methods
        const handleWeightChange = useCallback( (newWeight, weightType) => {
            const targetWeight = weightTypes.find( e => e.type === weightType);
            targetWeight.set(newWeight.weight);

            setWeightsWithError( prevWeights => {
                const weightSet = new Set(prevWeights);

                if(newWeight.hasError){
                    if(!weightSet.has(weightType)){
                        weightSet.add(weightType);
                    }
                } else {
                    weightSet.delete(weightType);
                }

                return [...weightSet];
            })
        }, []);

		const changeHasGem = () => {
			setHasGem( prev => !prev);
			setGemPrice('');
			setGemWeight(weight.defaultValue);
		}

		const submit = () => {
			setErrorCheckable(true);

            let requireWeights = ['org', 'service'];

            if (hasGem) {
                requireWeights = [...requireWeights, 'gem'];
            }

            const someWeightHasError = weightsWithError.some( e => requireWeights.includes(e) );

            //submit data preparation
            if (inputErrors.length === 0 && !someWeightHasError) {
				const { finalPrice, netWeight, taxPrice } = weight.calculatePrice({
					goldPrice: Number(goldPrice),
					orgWeight,
					serviceWeight,
					hasGem,
					taxPercent: Number(taxPercent),
					otherPrice: Number(otherPrice),
					gemPrice: Number(gemPrice),
					gemWeight,
				});

				setResult({
					goldPrice,
					netWeight,
					serviceWeight,
					hasGem,
					gemWeight,
					gemPrice,
					otherPrice,
                    taxPercent,
					taxPrice,
					finalPrice
				});
				setShowResult(true);
			}
		};
	
    return (
        <StackScreen>
            <ScrollView className="flex flex-col flex-1 bg-soft-muted">
                <div className="mx-3 mt-6 mb-12 space-y-5">

                    <TagInput
                        title="ရွှေစျေး"
                        tagLabel="ကျပ်"
                        type="number"
                        value={goldPrice}
                        onChange={ text => setGoldPrice(text)}
                        errors={[
                            {
                                show: errorCheckable && inputErrors.includes('gold_price'),
                                msg: 'ရွှေစျေးထည့်ပါ!'
                            }
                        ]}
                    />

                    {/* weight_form */}
                    <WeightInput
                        title="အလေးချိန်"
                        value={orgWeight}
                        onChange={ newWeight => handleWeightChange(newWeight, 'org')}
                        emptyCheckable={errorCheckable}
                    />
                        
                    <WeightInput
                        title="အလျော့တွက်"
                        value={serviceWeight}
                        onChange={ newWeight => handleWeightChange(newWeight, 'service')}
                        emptyCheckable={errorCheckable}
                        nullable={true}
                    />

                    <TagInput
                        title="အခြားသင့်ငွေ"
                        tagLabel="ကျပ်"
                        type="number"
                        placeholder="eg. service ခ"
                        value={otherPrice}
                        onChange={ text => setOtherPrice(text)}
                    />

                    <CounterInput
                        title="အခွန် ရာခိုင်နှုန်း"
                        min="0"
                        max="100"
                        value={taxPercent}
                        onChange={setTaxPercent}
                        warningMsges={[
                            'အခွန်ပါ၀င်ပါကထည့်ပါ။'
                        ]}
                    />

                    {/* toggles */}
                    <div>
                        <div className="flex flex-row space-x-2 items-center my-3">
                            <span className="text-xs font-bold text-gray">ကျောက်ထည်?</span>
                            <Switch
                                value={hasGem}
                                onChange={changeHasGem}
                            />
                        </div>
                    </div>
                    
                    <Collapsible
                        mt={20}
                        collapsed={!hasGem}
                    >
                        <div className="space-y-5">
                            <Input
                                title="ကျောက်ဖိုး"
                                type="number"
                                value={gemPrice}
                                onChange={ text => setGemPrice(text)}
                            />
                            <WeightInput
                                title="ကျောက်ချိန်"
                                value={gemWeight}
                                onChange={ newWeight => handleWeightChange(newWeight, 'gem')}
                                nullable
                            />
                        </div>
                    </Collapsible>

                    {/* button */}
                    <div className="flex">
                        <SubmitButton
                            title="Calculate"
                            color="green"
                            height="12"
                            radius="rounded-lg"
                            activated={false}
                            onClick={submit}
                        />
                    </div>

                </div>
            </ScrollView>

            <CalculationResultModal
				show={showResult}
				onClose={() => setShowResult(false)}
				data={result}
			/>
        </StackScreen>
    );
};

export default CalculatorScreen;
