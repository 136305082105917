class Weight {

    defaultValue = {
        kyat: '',
        pel: '',
        yway: '',
        chan: '',
        sate: '',
    };

    units = [
        {
            id: 1, 
            name: 'kyat', 
            label: 'ကျပ်', 
            min: 0,
            max: null,
            error: 'Positive ကိန်းဂဏန်းသာထည့်ပါ!'
        },
        
        {
            id: 2, 
            name: 'pel', 
            label: 'ပဲ', 
            min: 0, 
            max: 15, 
            error: '1 မှ 15 အတွင်းသာထည့်ပါ!'
        },
    
        {
            id: 3, 
            name: 'yway', 
            label: 'ရွေး', 
            min: 0, 
            max: 7, 
            error: '1 မှ 7 အတွင်းသာထည့်ပါ!'
        },
    
        {
            id: 4, 
            name: 'chan', 
            label: 'ခြမ်း',
            min: 0, 
            max: 1, 
            error: '1 ခြမ်း တစ်မျိုးသာထည့်ခွင့်ပြုသည်!'
        },
    
        {
            id: 5, 
            name: 'sate', 
            label: 'စိတ်', 
            min: 0, 
            max: 3, 
            error: '1 (သို့မဟုတ်) 3 စိတ်သာထည့်ပါ!'
        }
    ];

    checkError(total) {

        const kyat = Number(total.kyat);
        const pel = Number(total.pel);
        const yway = Number(total.yway);
        const chan = Number(total.chan);
        const sate = Number(total.sate);
    
        let errors = [];
    
        // 0 means empty
        if( kyat < 0 ){
            errors = [...errors, 'kyat'];
        }
    
        if( pel < 0 || pel > 15 ){
            errors = [...errors, 'pel'];
        }
    
        if( yway < 0 || yway > 7 ){
            errors = [...errors, 'yway'];
        }
    
        if( chan !== 1 && chan !== 0 ){
            errors = [...errors, 'chan'];
        }
    
        if( sate !== 1 && sate !== 3 && sate !== 0 ){
            errors = [...errors, 'sate'];
        }
    
        const disabledWeights = [];
    
        if( chan !== 0 ){
            disabledWeights.push('sate')
        }
    
        if( sate !== 0 ){
            disabledWeights.push('chan')
        }
    
        return {
            errors: errors,
            isEmpty: Boolean(kyat + pel + yway + chan + sate === 0),
            disabled: disabledWeights
        }
    }

    convertToState = ({ kyat, pel, yway, chan, sate }) => {
        const kyatInSate = Number(kyat) * 512;
        const pelInSate = Number(pel) * 32;
        const ywayInSate = Number(yway) * 4;
        const chanInSate = Number(chan) * 2;
        const weightInSate = kyatInSate + pelInSate + ywayInSate + chanInSate + Number(sate);
    
        return Number(weightInSate);
    }

    splitWeight(weightInSate) {
        // Convert sate to kyat
        const kyat = Math.floor(weightInSate / 512);
        const kyatRemainder = Math.max(weightInSate - (kyat * 512), 0);
    
        // Convert net kyat to pel
        const pel = Math.floor(kyatRemainder / 32);
        const pelRemainder = Math.max(kyatRemainder - (pel * 32), 0);
    
        // Convert net pel to yway
        const yway = Math.floor(pelRemainder / 4);
        const ywayRemainder = Math.max(pelRemainder - (yway * 4), 0);
    
        // Convert net yway to chan or sate
        let chan, sate;
        if (ywayRemainder === 3) {
            chan = 0;
            sate = 3;
        } else {
            chan = Math.floor(ywayRemainder / 2);
            const chanRemainder = Math.max(ywayRemainder - chan * 2, 0);
            sate = chanRemainder;
        }
    
        return {kyat, pel, yway, chan, sate};
    }

    calculatePrice = ({
        goldPrice,
        orgWeight,
        serviceWeight,
        hasGem,
        gemPrice,
        gemWeight,
        taxPercent,
        otherPrice,
    }) => {
        
        const orgWeightInSate = this.convertToState(orgWeight);
        const serviceWeightInSate = this.convertToState(serviceWeight);
    
        const gemWeightInSate = hasGem ? this.convertToState(gemWeight) : 0;
        const gemValue = hasGem ? Number(gemPrice) : 0;
    
        const netWeightInSate = orgWeightInSate - gemWeightInSate;
    
        const netWeightPrice = Math.floor(netWeightInSate * Number(goldPrice) / 512);
        const serviceWeightPrice = Math.floor(serviceWeightInSate * Number(goldPrice) / 512);
    
        const netPrice = netWeightPrice + serviceWeightPrice + gemValue + Number(otherPrice);
        const taxPrice = taxPercent === 0 ? 0 : Math.round((netPrice * taxPercent) / 100);
        const finalPrice = netPrice + taxPrice;
        
        return {
            finalPrice: finalPrice, 
            taxPrice: taxPrice,
            netWeight: this.splitWeight(netWeightInSate),
        };
    }
}

export default Weight;