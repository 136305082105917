import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef, memo } from "react";

const ScrollView = ({ 
    children, 
    onEndReached, 
    onScroll, 
    disableBackToTop = false, 
    ...props 
}) => {
    const scrollViewRef = useRef(null);
    const [hasReachedEnd, setHasReachedEnd] = useState(false);
    const [showBackToTop, setShowBackToTop] = useState(false);
    const prevScrollPosition = useRef(0);
    const prevScrollDirection = useRef('up');

    const handleScroll = () => {
        const scrollView = scrollViewRef.current;

        if (scrollView) {
            const offsetHeight = scrollView.offsetHeight;
            const scrollHeight = scrollView.scrollHeight;
            const currentScrollPosition = scrollView.scrollTop;
            const reachedEnd = (currentScrollPosition !== 0) && (scrollHeight - (offsetHeight + currentScrollPosition) <= 10);

            if(!disableBackToTop){
                setShowBackToTop( currentScrollPosition >= 2500 );
            }

            //check direction
            if(onScroll){
                const direction =
                    (currentScrollPosition > 0) && 
                    (currentScrollPosition > prevScrollPosition.current)
                    ? 'down'
                    : 'up';

                if( (prevScrollDirection.current !== direction)){
                    onScroll({ direction });
                    prevScrollDirection.current = direction;
                }
            }

            prevScrollPosition.current = currentScrollPosition;

            //check scroll reach end
            if (reachedEnd !== hasReachedEnd) {
                setHasReachedEnd(reachedEnd);
                if (reachedEnd && onEndReached) {
                    onEndReached();
                }
            }
        }
    };

    const goBackToTop = () => {
        scrollViewRef.current.scrollTo({ top: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        const scrollView = scrollViewRef.current;
        scrollView.addEventListener("scroll", handleScroll);

        return () => {
            scrollView.removeEventListener("scroll", handleScroll);
        };
    }, [hasReachedEnd, onEndReached]);

    return (
        <div
            ref={scrollViewRef}
            className={`overflow-y-scroll ${props.className}`}
        >
            {children}

            { !disableBackToTop ? (
                <div 
                    className="fixed z-10 mb-5 left-6 sm:left-72 transition-position duration-500"
                    style={{
                        bottom: showBackToTop ? 40 : -100
                    }}
                >
                    <button
                        className="rounded-lg backdrop-blur-sm bg-gray/70 w-12 h-12"
                        onClick={goBackToTop}
                    >
                        <FontAwesomeIcon icon={faArrowUp} color="white"/>
                    </button>
                </div>
            ) : ''}
        </div>
    );
};

export default memo(ScrollView);
