
export const defaultGroup = {
    id: 1,
    name: "Loading...",
    color: "gray",
    count_unit: "ခု",
    size_name: "အရွယ်အစား"
}

export const defaultSearchFilters = {
    
    group: null,
    user: null,

    date: '',
    id: '',
    word: '',
    min_price: '',
    max_price: '',
    min_weight: '',
    max_weight: '',
    customer_name: '',
    customer_address: '',
    customer_phone: '',
    min_weight_unit: 'pel',
    max_weight_unit: 'pel',
    checked_categories: [],
    checked_sizes: [],
    year: new Date().getFullYear(),
    month: null,
    sort_by: {
        type: 'id',
        order: 'asc'
    },
}