import { useRef } from "react";
import axios from "axios";
import axios_custom from "../axios_config/axios_custom";
import { useDispatch, useSelector } from 'react-redux';
import { setQualities } from '../store/reducers/app-reducer';
import useStoreHelper from "../hooks/useStoreHelper";

const useApiFetcher = () => {

    const dispatch = useDispatch();
    const { handleError } = useStoreHelper();

    const currentRoute = useSelector( state => state.router.currentRoute);
    const pendingApiCall = useRef(null);
    const pendingSource = useRef(null);
    
    return function fetchApi(
        source, //enums: items, debts
        search_filters,//obj array
        old_items, //obj array
        setShowLoading, //setState func
        setResponse, //setState func
        setPage //setState func // nullable
    ) {

        if(pendingApiCall.current && (pendingSource.current === source)){
            pendingApiCall.current.cancel();
        }

        const newSource = axios.CancelToken.source();
        pendingApiCall.current = newSource;
        pendingSource.current = source;
        
        setShowLoading(true);

        const filters = {
            for: currentRoute.name,
            ...search_filters
        }

        axios_custom.get(source, {
            params: filters, 
            cancelToken: newSource.token 
        })
        .then( (res) => {

            setShowLoading(false);
            setPage?.( prev => prev + 1)

            if(res.data.qualities){
                dispatch(setQualities(res.data.qualities));
            }

            setResponse( () => {
                
                switch (currentRoute.name) {

                    case 'report':

                        return { 
                            total_count: res.data.total_count,
                            total_price: res.data.total_price, 
                            total_service_weight: res.data.total_service_weight, 
                            total_trader_weight: res.data.total_trader_weight,
                            total_profit_weight: res.data.total_profit_weight, 
                            by_group: res.data.by_group,
                            by_month: res.data.by_month
                        } 
                    
                    case 'networth':

                        return { 
                            total_count: res.data.total_count,
                            total_org_weight: res.data.total_org_weight,
                            total_net_weight: res.data.total_net_weight, 
                            total_net_price: res.data.total_net_price,
                            by_group: res.data.by_group,
                        }
                
                    default:

                        let response = {
                            count: res.data.results.total,
                            items: [...old_items, ...res.data.results.data],
                            count_by: {
                                group: res.data.count_by.group.length !== 0 
                                        ? res.data.count_by.group.map( e => {
                                            return {
                                                id: e.group,
                                                count: e.count
                                            }
                                        }) 
                                        : [],

                                user: res.data.count_by.user.length !== 0 
                                        ? res.data.count_by.user.map( e => {
                                            return {
                                                id: e.user,
                                                count: e.count
                                            }
                                        }) 
                                        : [],
                            }
                        }
            
                        if( source === 'items' ){
                            response = {
                                ...response, 
                                profit_calculation: res.data.profit_calculation,
                                collectable_money: res.data.collectable_money,
                                all_item_ids: res.data.all_item_ids
                            }
                        }
            
                        if( source === 'paid_debts' ){
                            response = {
                                ...response, 
                                collectable_money: res.data.collectable_money,
                                all_item_ids: res.data.all_paid_debt_ids
                            }
                        }

                        if( source === 'collaterals' ){
                            response = {
                                ...response, 
                                collectable_money: res.data.collectable_money,
                                all_item_ids: res.data.all_collateral_ids
                            }
                        }

                        if( source === 'paid_interests' ){
                            response = {
                                ...response, 
                                collectable_money: res.data.collectable_money,
                                all_item_ids: res.data.all_paid_interest_ids
                            }
                        }

                        return response;
                }
            });
        })
        .catch( (error) => {
            handleError(error);
        });
    }
};

export default useApiFetcher;