import React, { memo } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubmitButton = ({
    title,
    textColor = "white",
    textBold = false,
    color,
    fontSize = 'sm',
    height = '12',
    radius = 'rounded',
    activated = false,
    activatedColor,
    activatedText = 'Please Wait...',
    onClick,
    disabled = false,
    icon,
    fullSize = true,
}) => {

    const btnColor = (activated || disabled) 
    ? (activatedColor ? `bg-${activatedColor}` : `bg-soft-${color}`)
    : `bg-${color}`;

    return (
        <button 
            className={`flex shadow items-center justify-center
                h-${height}
                ${fullSize ? 'flex-1' : ''}
                ${radius} 
                ${btnColor}
            `}
            disabled={activated || disabled}
            onClick={onClick}
        >
            { activated ? (
                <div className="flex flex-row items-center justify-center mx-2 space-x-1 min-w-8">
                    <FontAwesomeIcon 
                        className="animate-spin-right" 
                        icon={faSpinner} 
                        color={textColor}    
                        size={fontSize}
                    />
                    <span className={`${textBold && 'font-bold'} text-${fontSize} text-${textColor}`}>
                        { activatedText }
                    </span>
                </div>
            ) : (
                <div className="flex flex-row items-center justify-center mx-2 space-x-1 min-w-8">
                    { icon ? (
                        <FontAwesomeIcon 
                            icon={icon} 
                            color={textColor}
                            size={fontSize}
                        />
                    ) : ''}
                    <span className={`${textBold && 'font-bold'} text-${fontSize} text-${textColor}`}>
                        { title }
                    </span>
                </div>
            )}
        </button>
    );
};

export default memo(SubmitButton);
