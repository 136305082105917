import React, { memo } from "react";

const SectionHeader = ({ title, color, mt }) => {
    return (
        <div 
            className={`flex items-center justify-center h-10 border rounded-lg mx-2 mb-2
                mt-${mt} border-${color} bg-dim-${color} 
            `}
        >
            <span className={`text-xs text-${color}`}>
                { title }
            </span>
        </div>
    );
};

export default memo(SectionHeader);
