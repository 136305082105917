const LoadColors = () => {
    return (
        <div className="flex-row hidden">
            {/* text */}
            <span className="text-dark-yellow"></span>
            <span className="text-yellow"></span>
            <span className="text-soft-yellow"></span>
            <span className="text-dim-yellow"></span>

            <span className="text-dark-green"></span>
            <span className="text-green"></span>
            <span className="text-soft-green"></span>
            <span className="text-dim-green"></span>

            <span className="text-dark-red"></span>
            <span className="text-red"></span>
            <span className="text-soft-red"></span>
            <span className="text-dim-red"></span>

            <span className="text-dark-blue"></span>
            <span className="text-blue"></span>
            <span className="text-soft-blue"></span>
            <span className="text-dim-blue"></span>

            <span className="text-dark-sky"></span>
            <span className="text-sky"></span>
            <span className="text-soft-sky"></span>
            <span className="text-dim-sky"></span>

            <span className="text-dark-purple"></span>
            <span className="text-purple"></span>
            <span className="text-soft-purple"></span>
            <span className="text-dim-purple"></span>

            <span className="text-dark-pink"></span>
            <span className="text-pink"></span>
            <span className="text-soft-pink"></span>
            <span className="text-dim-pink"></span>

            <span className="text-dark-orange"></span>
            <span className="text-orange"></span>
            <span className="text-soft-orange"></span>
            <span className="text-dim-orange"></span>

            <span className="text-dark-teal"></span>
            <span className="text-teal"></span>
            <span className="text-soft-teal"></span>
            <span className="text-dim-teal"></span>

            <span className="text-dark-cyan"></span>
            <span className="text-cyan"></span>
            <span className="text-soft-cyan"></span>
            <span className="text-dim-cyan"></span>

            <span className="text-dark-lime"></span>
            <span className="text-lime"></span>
            <span className="text-soft-lime"></span>
            <span className="text-dim-lime"></span>

            <span className="text-dark-rose"></span>
            <span className="text-rose"></span>
            <span className="text-soft-rose"></span>
            <span className="text-dim-rose"></span>

            <span className="text-dark-gray"></span>
            <span className="text-gray"></span>
            <span className="text-soft-gray"></span>
            <span className="text-dim-gray"></span>

            <span className="text-dark-black"></span>
            <span className="text-black"></span>
            <span className="text-soft-black"></span>
            <span className="text-dim-black"></span>

            <span className="text-dark-white"></span>
            <span className="text-white"></span>
            <span className="text-soft-white"></span>
            <span className="text-dim-white"></span>

            <span className="text-dark-muted"></span>
            <span className="text-muted"></span>
            <span className="text-soft-muted"></span>
            <span className="text-dim-muted"></span>

            <span className="text-dark-milk"></span>
            <span className="text-milk"></span>
            <span className="text-soft-milk"></span>
            <span className="text-dim-milk"></span>

            <span className="text-dark-metal"></span>
            <span className="text-metal"></span>
            <span className="text-soft-metal"></span>
            <span className="text-dim-metal"></span>

            {/* bg */}
            <span className="bg-dark-yellow"></span>
            <span className="bg-yellow"></span>
            <span className="bg-soft-yellow"></span>
            <span className="bg-dim-yellow"></span>

            <span className="bg-dark-green"></span>
            <span className="bg-green"></span>
            <span className="bg-soft-green"></span>
            <span className="bg-dim-green"></span>

            <span className="bg-dark-red"></span>
            <span className="bg-red"></span>
            <span className="bg-soft-red"></span>
            <span className="bg-dim-red"></span>

            <span className="bg-dark-blue"></span>
            <span className="bg-blue"></span>
            <span className="bg-soft-blue"></span>
            <span className="bg-dim-blue"></span>

            <span className="bg-dark-sky"></span>
            <span className="bg-sky"></span>
            <span className="bg-soft-sky"></span>
            <span className="bg-dim-sky"></span>

            <span className="bg-dark-purple"></span>
            <span className="bg-purple"></span>
            <span className="bg-soft-purple"></span>
            <span className="bg-dim-purple"></span>

            <span className="bg-dark-pink"></span>
            <span className="bg-pink"></span>
            <span className="bg-soft-pink"></span>
            <span className="bg-dim-pink"></span>

            <span className="bg-dark-orange"></span>
            <span className="bg-orange"></span>
            <span className="bg-soft-orange"></span>
            <span className="bg-dim-orange"></span>

            <span className="bg-dark-teal"></span>
            <span className="bg-teal"></span>
            <span className="bg-soft-teal"></span>
            <span className="bg-dim-teal"></span>

            <span className="bg-dark-cyan"></span>
            <span className="bg-cyan"></span>
            <span className="bg-soft-cyan"></span>
            <span className="bg-dim-cyan"></span>

            <span className="bg-dark-lime"></span>
            <span className="bg-lime"></span>
            <span className="bg-soft-lime"></span>
            <span className="bg-dim-lime"></span>

            <span className="bg-dark-rose"></span>
            <span className="bg-rose"></span>
            <span className="bg-soft-rose"></span>
            <span className="bg-dim-rose"></span>

            <span className="bg-dark-gray"></span>
            <span className="bg-gray"></span>
            <span className="bg-soft-gray"></span>
            <span className="bg-dim-gray"></span>

            <span className="bg-dark-black"></span>
            <span className="bg-black"></span>
            <span className="bg-soft-black"></span>
            <span className="bg-dim-black"></span>

            <span className="bg-dark-white"></span>
            <span className="bg-white"></span>
            <span className="bg-soft-white"></span>
            <span className="bg-dim-white"></span>

            <span className="bg-dark-muted"></span>
            <span className="bg-muted"></span>
            <span className="bg-soft-muted"></span>
            <span className="bg-dim-muted"></span>

            <span className="bg-dark-milk"></span>
            <span className="bg-milk"></span>
            <span className="bg-soft-milk"></span>
            <span className="bg-dim-milk"></span>

            <span className="bg-dark-metal"></span>
            <span className="bg-metal"></span>
            <span className="bg-soft-metal"></span>
            <span className="bg-dim-metal"></span>

            {/* border */}
            <span className="border-dark-yellow"></span>
            <span className="border-yellow"></span>
            <span className="border-soft-yellow"></span>
            <span className="border-dim-yellow"></span>

            <span className="border-dark-green"></span>
            <span className="border-green"></span>
            <span className="border-soft-green"></span>
            <span className="border-dim-green"></span>

            <span className="border-dark-red"></span>
            <span className="border-red"></span>
            <span className="border-soft-red"></span>
            <span className="border-dim-red"></span>

            <span className="border-dark-blue"></span>
            <span className="border-blue"></span>
            <span className="border-soft-blue"></span>
            <span className="border-dim-blue"></span>

            <span className="border-dark-sky"></span>
            <span className="border-sky"></span>
            <span className="border-soft-sky"></span>
            <span className="border-dim-sky"></span>

            <span className="border-dark-purple"></span>
            <span className="border-purple"></span>
            <span className="border-soft-purple"></span>
            <span className="border-dim-purple"></span>

            <span className="border-dark-pink"></span>
            <span className="border-pink"></span>
            <span className="border-soft-pink"></span>
            <span className="border-dim-pink"></span>

            <span className="border-dark-orange"></span>
            <span className="border-orange"></span>
            <span className="border-soft-orange"></span>
            <span className="border-dim-orange"></span>

            <span className="border-dark-teal"></span>
            <span className="border-teal"></span>
            <span className="border-soft-teal"></span>
            <span className="border-dim-teal"></span>

            <span className="border-dark-cyan"></span>
            <span className="border-cyan"></span>
            <span className="border-soft-cyan"></span>
            <span className="border-dim-cyan"></span>

            <span className="border-dark-lime"></span>
            <span className="border-lime"></span>
            <span className="border-soft-lime"></span>
            <span className="border-dim-lime"></span>

            <span className="border-dark-rose"></span>
            <span className="border-rose"></span>
            <span className="border-soft-rose"></span>
            <span className="border-dim-rose"></span>

            <span className="border-dark-gray"></span>
            <span className="border-gray"></span>
            <span className="border-soft-gray"></span>
            <span className="border-dim-gray"></span>
            <span className="border-b-dim-gray"></span>

            <span className="border-dark-black"></span>
            <span className="border-black"></span>
            <span className="border-soft-black"></span>
            <span className="border-dim-black"></span>

            <span className="border-dark-white"></span>
            <span className="border-white"></span>
            <span className="border-soft-white"></span>
            <span className="border-dim-white"></span>

            <span className="border-dark-muted"></span>
            <span className="border-muted"></span>
            <span className="border-soft-muted"></span>
            <span className="border-dim-muted"></span>

            <span className="border-dark-milk"></span>
            <span className="border-milk"></span>
            <span className="border-soft-milk"></span>
            <span className="border-dim-milk"></span>

            {/* active bg */}
            <span className="active:bg-dark-yellow"></span>
            <span className="active:bg-yellow"></span>
            <span className="active:bg-soft-yellow"></span>
            <span className="active:bg-dim-yellow"></span>

            <span className="active:bg-dark-green"></span>
            <span className="active:bg-green"></span>
            <span className="active:bg-soft-green"></span>
            <span className="active:bg-dim-green"></span>

            <span className="active:bg-dark-red"></span>
            <span className="active:bg-red"></span>
            <span className="active:bg-soft-red"></span>
            <span className="active:bg-dim-red"></span>

            <span className="active:bg-dark-blue"></span>
            <span className="active:bg-blue"></span>
            <span className="active:bg-soft-blue"></span>
            <span className="active:bg-dim-blue"></span>

            <span className="active:bg-dark-sky"></span>
            <span className="active:bg-sky"></span>
            <span className="active:bg-soft-sky"></span>
            <span className="active:bg-dim-sky"></span>

            <span className="active:bg-dark-purple"></span>
            <span className="active:bg-purple"></span>
            <span className="active:bg-soft-purple"></span>
            <span className="active:bg-dim-purple"></span>

            <span className="active:bg-dark-pink"></span>
            <span className="active:bg-pink"></span>
            <span className="active:bg-soft-pink"></span>
            <span className="active:bg-dim-pink"></span>

            <span className="active:bg-dark-orange"></span>
            <span className="active:bg-orange"></span>
            <span className="active:bg-soft-orange"></span>
            <span className="active:bg-dim-orange"></span>

            <span className="active:bg-dark-teal"></span>
            <span className="active:bg-teal"></span>
            <span className="active:bg-soft-teal"></span>
            <span className="active:bg-dim-teal"></span>

            <span className="active:bg-dark-cyan"></span>
            <span className="active:bg-cyan"></span>
            <span className="active:bg-soft-cyan"></span>
            <span className="active:bg-dim-cyan"></span>

            <span className="active:bg-dark-lime"></span>
            <span className="active:bg-lime"></span>
            <span className="active:bg-soft-lime"></span>
            <span className="active:bg-dim-lime"></span>

            <span className="active:bg-dark-rose"></span>
            <span className="active:bg-rose"></span>
            <span className="active:bg-soft-rose"></span>
            <span className="active:bg-dim-rose"></span>

            <span className="active:bg-dark-gray"></span>
            <span className="active:bg-gray"></span>
            <span className="active:bg-soft-gray"></span>
            <span className="active:bg-dim-gray"></span>

            <span className="active:bg-dark-black"></span>
            <span className="active:bg-black"></span>
            <span className="active:bg-soft-black"></span>
            <span className="active:bg-dim-black"></span>

            <span className="active:bg-dark-white"></span>
            <span className="active:bg-white"></span>
            <span className="active:bg-soft-white"></span>
            <span className="active:bg-dim-white"></span>

            <span className="active:bg-dark-muted"></span>
            <span className="active:bg-muted"></span>
            <span className="active:bg-soft-muted"></span>
            <span className="active:bg-dim-muted"></span>

            <span className="active:bg-dark-milk"></span>
            <span className="active:bg-milk"></span>
            <span className="active:bg-soft-milk"></span>
            <span className="active:bg-dim-milk"></span>

            {/* border l */}
            <span className="border-l-green"></span>
            <span className="border-l-yellow"></span>
            <span className="border-l-red"></span>

            <div className="w-1/2"></div>
            <div className="w-2/6"></div>
        </div>
    );
};

export default LoadColors;
