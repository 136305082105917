import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios_custom from '../axios_config/axios_custom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faX } from '@fortawesome/free-solid-svg-icons';
import useStoreHelper from "../hooks/useStoreHelper";
import ProgressBar from '../components/ProgressBar';
import useNavigation from '../hooks/useNavigation';
import colors from '../helper/colors';
import Nav from '../components/nav';
import NoticeBanner from '../components/NoticeBanner';
import { 
    ImagePicker, 
    PopUpList, 
    SubmitButton 
} from '../components/inputs';
import ActivityIndicator from '../components/ActivityIndicator';

const AddImageScreen = () => {

    const { handleError } = useStoreHelper();
    const navigation = useNavigation(); 

    const [noImageItems, setNoImageItems] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [addedIds, setAddedIds] = useState([]);
    const [failedItems, setFailedItems] = useState([]);

    const [group, setGroup] = useState(1);
    const [showLoading, setShowLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPickingImages, setIsPickingImages] = useState(false);

    //mounted
    useEffect(() => {
        setShowLoading(true);

        const controller = new AbortController();
        const signal = controller.signal;

        axios_custom.get('no_image', { signal })
        .then( (res) => {
            setShowLoading(false);
            setNoImageItems(res.data.results);
        })
        .catch( (error) => {
            setShowLoading(false);
            handleError(error);
        });
    }, [])

    //computed
        const countByGroup = useMemo( () => {
            return (noImageItems.length !== 0)
            ? noImageItems.map( (e) => { 
                return {
                            id: e.group,
                            count: e.items.length
                        }
                }) 
            : []
        }, [noImageItems]);

        const noImageItemsByCurrentGroup = useMemo( () => {
            if(noImageItems.length === 0){
                return [];
            }
            return noImageItems.find( e => e.group === group).items;
        }, [group, noImageItems]);

        const effectiveItems = useMemo( () => {
            return noImageItemsByCurrentGroup.slice(0, selectedImages.length);
        }, [noImageItemsByCurrentGroup, selectedImages]);

        const completedPercent = useMemo( () => {
            const submittedCount = addedIds.length + failedItems.length;
            const percent = ( submittedCount / Math.max(effectiveItems.length, 1) ) * 100;
            return Math.floor(percent);
        }, [addedIds, failedItems]);

        const submitable = useMemo( () => {
            return selectedImages.length !== 0
        }, [selectedImages]);

    //methods
        const pickGroup = useCallback( (searchFilters) => {
            setGroup(searchFilters.group);
        }, []);

        const clearSelectedImages = useCallback(() => {
            setSelectedImages([]);
        }, []);

        const pickImages = useCallback( (resImages) => {
            let newImages;
            if(!resImages){
                newImages = []
            } else {
                newImages = Array.isArray(resImages) ? resImages : [resImages]
            }
            setSelectedImages(newImages);
        }, []);

        //sequential submitter
        useEffect( () => {
            const submittedCount = addedIds.length + failedItems.length;

            //onSubmissionDone, before navigation
            if(isSubmitting && selectedImages.length === submittedCount){
                setIsSubmitting(false);
                navigation.navigate('image_added', {
                    state: {
                        addedIds,
                        failedItems: JSON.stringify(failedItems)
                    }
                })
            }

            if (isSubmitting 
                && submitable
                && selectedImages.length !== submittedCount  
            ) {
                const image = selectedImages[submittedCount];
                const targetItem = effectiveItems[submittedCount];
                
                const formData = new FormData();

                formData.append('id', targetItem.id);
                formData.append('image', image.file);

                axios_custom.post('images', formData)
                .then( () => {
                    setAddedIds( prev => [...prev, targetItem.id]);
                })
                .catch( (error) => {
                    const errorMsg = error.response.data 
                    ? (error.response.data.msg ?? error.response.data.message )
                    : 'This image is unprocessable!'
                    setFailedItems( prev => [...prev, {
                        id: targetItem.id,
                        serial: targetItem.serial,
                        image: image.file,
                        errorMsg: errorMsg
                    }]);
                })
            }
        }, [isSubmitting, addedIds, failedItems]);

    return (
        <>
            <Nav
                onSearch={pickGroup}
                values={{ group }}
                navType="groups" 
                searchOnNavChange={true}
                disableNavChoice={false}
                enableAllSearch={false} 
                enabledSearches={[]}
                countBy={countByGroup}
                fixedTop={false}
            />
            
            <div className="flex-1 flex flex-col bg-white overflow-auto sm:mr-16">

                <NoticeBanner
                    title="သတိပြုရန်"
                    icon={faCircleExclamation}
                    color="yellow"
                    msges={[
                        'ပုံရိုက်ခဲ့သည့်အချိန်အစဉ်လိုက်အတိုင်း ပစ္စည်း ID ငယ်စဉ်ကြီးလိုက် (1-100) ထည့်သွားမည့်စနစ်ဖြစ်သည်။', 
                        'ထိုကြောင့် ပုံရွေးသည့်အခါ ပုံများကို Sort By Name (or) Sort By Date လုပ်ထားရန်လိုအပ်ပါသည်။'
                    ]}
                />

                <div className="flex flex-col m-5 space-y-8">

                    <div className="flex flex-col space-y-5">

                        <div className="flex flex-row justify-between items-center">
                            <div className="flex justify-start">
                                { showLoading ? (
                                    <div className="ml-2">
                                        <ActivityIndicator size="sm"/>
                                    </div>
                                ) : (
                                    <div className="flex-row space-x-2 items-center">
                                        <span className="text-red text-lg">{ noImageItemsByCurrentGroup.length }</span>
                                        <span className="text-gray">ခု</span>
                                    </div>
                                )}
                            </div>
                            <PopUpList
                                data={ noImageItemsByCurrentGroup.map( e => e.serial.replace(/[^\d.-]/g, '') )}
                                triggerClassName="flex flex-row items-center justify-center rounded border border-gray space-x-1 px-2 h-8"
                                title="ပုံထည့်ရမည့် ID များ"
                                textColor = "black"
                                theme="dark"
                            />
                        </div>

                        <ImagePicker
                            isPicking={setIsPickingImages}
                            onPick={pickImages}
                            warningMsges={[
                                'တစ်ခါထည့်လျှင် ပုံ ၂၀ သာ အများဆုံးထည့်ခွင့်ပြုသည်။',
                                'jpg (သို့) jpeg (သို့) png (သို့) gif format ဖြင့် ပုံများကိုသာထည့်ခွင့်ပြုသည်။',
                                'ပုံ တစ်ပုံချင်းစီသည် 8MB ထက်နည်းသောပုံ ဖြစ်ရမည်။'
                            ]}
                            pickableCount={ noImageItemsByCurrentGroup.length < 20 ? noImageItemsByCurrentGroup.length : 20 }
                            disabled={noImageItemsByCurrentGroup.length === 0}
                        />

                        <div className="flex flex-row items-center space-x-2">
                            <span className="text-sm">
                                ရွေးထားသောပုံ
                            </span>
                            <span className="text-red">
                                { selectedImages.length }
                            </span>
                            <span className="text-sm">
                                ပုံ
                            </span>
                            { selectedImages.length !== 0 &&
                                <button 
                                    className="rounded border border-gray"
                                    onClick={clearSelectedImages}
                                >
                                    <div className="flex flex-row items-center justify-center space-x-1 mx-2 my-1">
                                        <FontAwesomeIcon 
                                            icon={faX} 
                                            size="2xs" 
                                            color={colors.find( e => e.org === 'gray').normal }    
                                        />
                                        <span className="text-gray text-xs">
                                            Clear
                                        </span>
                                    </div>
                                </button>
                            }
                        </div>

                        { submitable ? (
                            <div className="my-4 items-start">
                                <PopUpList
                                    data={ effectiveItems.map( e => e.serial.replace(/[^\d.-]/g, '') ) }
                                    triggerClassName="flex flex-row items-center justify-center rounded border border-green space-x-1 px-2 h-8"
                                    title="ယခုထည့်မည့် ID များ"
                                    textColor="green"
                                    theme="dark"
                                    position="top center"
                                />
                            </div>
                        ) : ''}

                    </div>

                    <div className="space-y-2">
                        { isSubmitting ? (
                            <div className="space-y-1">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center justify-end space-x-3">
                                        <span className="text-xs text-soft-black">
                                            {completedPercent}%
                                        </span>
                                    </div>
                                    <div className="flex flex-row items-center justify-end space-x-3">
                                        <span className="text-xs text-green">
                                            {addedIds.length} Succeed
                                        </span>
                                        <span className="text-xs text-red">
                                            {failedItems.length} Failed
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <ProgressBar
                                        completedPercent={completedPercent}
                                        color="green"
                                    />
                                </div>
                            </div>
                        ) : ''}
                        
                        <div className="flex">
                            <SubmitButton
                                disabled={!submitable || isPickingImages}
                                radius="rounded-xl"
                                color="green"
                                activated={isSubmitting}
                                activatedText="Uploading..."
                                activatedColor="soft-green"
                                title="ထည့်မယ်"
                                textColor="white"
                                textBold={false}
                                onClick={ () => setIsSubmitting(true)}
                            />
                        </div>
                    </div>

                </div>
                
            </div>
        </>
    )
}

export default AddImageScreen;