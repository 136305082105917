import { Suspense, useEffect, useMemo } from "react";
import { routesWithComponent } from "./routes";
import { useSelector } from "react-redux";
import FallBackLoader from "../components/FallBackLoader";
import useNavigation from "../hooks/useNavigation";

const Router = () => {

    const authenticated = useSelector( state => state.app.authenticated );
    const currentRoute = useSelector((state) => state.router.currentRoute);
    const navigation = useNavigation();
    
    const currentScreen = useMemo( () => {
        return routesWithComponent.find( e => e.name === currentRoute.name ).component
    }, [currentRoute]);

    useEffect( () => {
        navigation.navigate( authenticated ? 'sale' : 'login')
    }, [authenticated]);

    return (
        //if there is lazy component
        <Suspense fallback={<FallBackLoader/>}>
            {currentScreen}
        </Suspense>
    );
}

export default Router;
